import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import NotificationServiceType, {
  TeamServiceType,
} from '~/models/ServicesTypes';
import * as S from './styles';
import useTeamStore from '~/store/team';

interface TeamDataFormProps {
  setTeamModal: (active: boolean) => void;
  TeamService: TeamServiceType;
  NotificationService: NotificationServiceType;
}

const INPUT_TYPES = {
  TEAM_NAME: 'TEAM_NAME',
  MONTHLY_CAPACITY: 'MONTHLY_CAPACITY',
  OPERATIONAL_COST: 'OPERATIONAL_COST',
};
interface ValuesProps {
  teamName: string | null;
  monthlyOperationalCost: number | null;
  monthlyHoursCapacity: number | null;
}

const TeamDataForm: React.FC<TeamDataFormProps> = ({
  setTeamModal,
  TeamService,
  NotificationService,
}: TeamDataFormProps) => {
  const [isInputOnFocus, setInputOnFocs] = useState<string | null>(null);
  const { team, fetchTeamData } = useTeamStore();
  const updateTeam = (values: ValuesProps) => {
    const updatedTeam = {
      ...team,
      name: values.teamName,
      monthlyOperationalCost: values.monthlyOperationalCost,
      monthlyHoursCapacity: values.monthlyHoursCapacity,
    };
    TeamService.updateTeam(updatedTeam).then(
      (response) => {
        setTeamModal(false);
        NotificationService.showNotification(
          'Os seus dados foram atualizados com sucesso.',
          'success',
        );
        fetchTeamData();
        return response;
      },
      () => {
        NotificationService.showNotification(
          'Houve um erro ao atualizar os dados.',
          'error',
        );
      },
    );
  };

  return (
    <Formik
      initialValues={{
        teamName: team.name,
        monthlyOperationalCost: team.monthlyOperationalCost,
        monthlyHoursCapacity: team.monthlyHoursCapacity,
      }}
      onSubmit={(values) => {
        updateTeam(values);
      }}
    >
      {() => (
        <Form noValidate style={{ position: 'relative', padding: '24px 42px' }}>
          <S.FormOneStyled>
            <S.InputWrapper>
              <S.StyledLabel
                isOnFocus={isInputOnFocus === INPUT_TYPES.TEAM_NAME}
              >
                Nome *
              </S.StyledLabel>
              <S.StyledInput
                name="teamName"
                type="text"
                value=""
                placeholder=""
                onFocus={() => setInputOnFocs(INPUT_TYPES.TEAM_NAME)}
                onBlur={() => setInputOnFocs(null)}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <S.StyledLabel
                isOnFocus={isInputOnFocus === INPUT_TYPES.OPERATIONAL_COST}
              >
                Custo operacional mensal (R$)
              </S.StyledLabel>
              <S.StyledInput
                name="monthlyOperationalCost"
                type="number"
                value=""
                placeholder=""
                onFocus={() => setInputOnFocs(INPUT_TYPES.OPERATIONAL_COST)}
                onBlur={() => setInputOnFocs(null)}
              />
            </S.InputWrapper>
          </S.FormOneStyled>

          <S.InputWrapper fullWidth={true}>
            <S.StyledLabel
              isOnFocus={isInputOnFocus === INPUT_TYPES.MONTHLY_CAPACITY}
            >
              Capacidade produtiva mensal (horas)
            </S.StyledLabel>
            <S.StyledInput
              name="monthlyHoursCapacity"
              type="number"
              value=""
              placeholder=""
              onFocus={() => setInputOnFocs(INPUT_TYPES.MONTHLY_CAPACITY)}
              onBlur={() => setInputOnFocs(null)}
            />
          </S.InputWrapper>

          <S.ButtonWrapper>
            <S.StyledButton type="submit" style={{ minWidth: '168px' }}>
              Salvar Alterações
            </S.StyledButton>
          </S.ButtonWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default TeamDataForm;
