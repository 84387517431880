export class InternalService {
  constructor($http, $log, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.API_URL = API_URL;
  }

  getUsers({ format='json', owners=null }) {
    return this.$http({
      url: `${this.API_URL}/internal/users.${format}`,
      method: 'GET',
      params: { owners },
    });
  }
}
