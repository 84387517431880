export class ReportService {
  constructor($http, $log, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.API_URL = API_URL;
  }

  getReports(team, year, month, user_id) {
    let parameters = '?';
    if (year) {
      parameters = parameters.concat(`&year=${year}`);
      if (month) {
        parameters = parameters.concat(`&month=${month}`);
      }
    }
    if (user_id) {
      parameters = parameters.concat(`&user_id=${user_id}`);
    }
    return this.$http.get(`${this.API_URL}/reports${parameters}`);
  }

  getPossibleMonths(team) {
    const team_id = team.id;
    return this.$http({
      url: `${this.API_URL}/reports/possible`,
      method: 'GET',
      params: { team_id },
    });
  }

  saveReport(team, year, month) {
    if (Number.isInteger(month)) {
      month++;
    }
    const team_id = team.id;

    return this.$http({
      url: `${this.API_URL}/reports`,
      method: 'POST',
      params: { team_id, year, month },
    });
  }

  uploadDanfe(report, danfe) {
    return this.$http({
      method: 'POST',
      url: `${this.API_URL}/invoices`,
      headers: {
        'Content-Type': undefined,
      },
      data: {
        report_id: report.id,
        danfe,
      },
      transformRequest: data => {
        const formData = new FormData();
        angular.forEach(data, (value, key) => {
          formData.append(key, value);
        });
        return formData;
      },
    });
  }

  downloadDanfe(invoiceId) {
    return this.$http({
      method: 'GET',
      url: `${this.API_URL}/invoices/${invoiceId}/download`,
      responseType: 'arraybuffer',
    });
  }

  payReport(report) {
    return this.$http.post(`${this.API_URL}/reports/${report.id}/pay`);
  }
}
