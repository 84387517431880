import * as Yup from 'yup';

const socialMediaSignInSchema = () =>
  Yup.object().shape({
    name: Yup.string(),
    segment: Yup.number().required('Campo obrigatório'),
    segmentDetail: Yup.string(),
    teamSize: Yup.number().required('Campo obrigatório'),
    email: Yup.string(),
    password: Yup.string(),
    phone: Yup.string(),
    acceptedTerms: Yup.bool().oneOf(
      [true],
      'É necessário aceitar os termos de uso',
    ),
  });

export default socialMediaSignInSchema;
