import React, { useState } from 'react';

import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step as JoyrideStep,
} from 'react-joyride';

import * as S from './styles';

import CalendarImageSvg from './images/CalendarImageSvg';

import useOnboardingStore from '~/store/onboarding';

import Step from './Step';
import TourModal from './TourModal';

const steps: JoyrideStep[] = [
  {
    target: '#select-project',
    content: (
      <div className={S.ContentParagraph}>
        O último projeto em que você trabalhou sempre será exibido
        automaticamente, mas você também poderá selecionar um novo projeto aqui.
      </div>
    ),
    title: 'Trabalhe em um projeto',
    placement: 'left',
    disableBeacon: true,
  },
  {
    spotlightClicks: true,
    target: '#desciption-container',
    content: (
      <>
        <div className={S.ContentParagraph}>
          Nesta barra você poderá iniciar ou parar de acompanhar uma tarefa a
          partir de qualquer tela do Labor.{' '}
        </div>
        <div className={S.ContentParagraph}>
          Clique no campo e digite a descrição da tarefa para criá-la ou
          selecione uma existente.
        </div>{' '}
      </>
    ),
    title: 'Registre suas tarefas',
    disableBeacon: true,
  },
  {
    spotlightClicks: true,
    target: '#desciption-container',
    content: (
      <div className={S.ContentParagraph}>
        Clique no campo e digite a descrição para criá-la, ou selecione uma
        existente.
      </div>
    ),
    title: 'Crie uma nova tarefa ou selecione uma existente',
    disableBeacon: true,
  },
  {
    spotlightClicks: true,
    target: '#timer-wrapper',
    content: (
      <div className={'max-width: 300px'}>
        <div className={S.ContentParagraph}>
          Agora você pode clicar no botão para iniciar o cronômetro.
        </div>
        <div className={S.ContentParagraph}>
          {' '}
          Ao concluir o tour, caso queira registrar horas que já foram
          trabalhadas, você também pode clicar no contador para lançar o tempo
          de trabalho manualmente:
        </div>
        <CalendarImageSvg />
      </div>
    ),
    placement: 'auto',
    title: 'Cronometre o andamento da tarefa',
    disableBeacon: true,
  },
  {
    spotlightClicks: true,
    target: '#timer-wrapper',
    content: (
      <div className={S.ContentParagraph}>
        Clique no botão novamente para parar o contador e visualizar sua tarefa
        na lista.
      </div>
    ),
    title: 'Sua tarefa está sendo cronometrada',
    disableBeacon: true,
  },
  {
    spotlightClicks: true,
    target: '#Cronômetro',
    content: (
      <div className={S.ContentParagraph}>
        Na página Cronômetro você poderá visualizar a lista de tarefas
        registradas diariamente e editá-las, excluí-las ou até mesmo iniciar o
        cronômetro novamente.
      </div>
    ),
    title: 'Visualize as tarefas registradas',
    disableBeacon: true,
  },
];

const StepComponent: React.FC = () => {
  const [stepState] = useState(steps);

  const stepIndex = useOnboardingStore((state) => state.stepIndex);
  const increaseStepIndex = useOnboardingStore(
    (state) => state.increaseStepIndex,
  );
  const decreaseStepIndex = useOnboardingStore(
    (state) => state.decreaseStepIndex,
  );
  const tourIsRunning = useOnboardingStore((state) => state.isRunning);
  const isDone = useOnboardingStore((state) => state.isDone);
  const changeTourIsRunning = useOnboardingStore(
    (state) => state.changeIsRunning,
  );
  const openWelcomeTourModal = useOnboardingStore(
    (state) => state.openWelcomeTourModal,
  );
  const isRunning = useOnboardingStore((state) => state.isRunning);
  const changemodalIsWecome = useOnboardingStore(
    (state) => state.changemodalIsWecome,
  );
  const changeOnboardingPause = useOnboardingStore(
    (state) => state.changeOnboardingPause,
  );
  const changeStepIndexValue = useOnboardingStore(
    (state) => state.changeStepIndexValue,
  );
  const onboardingPause = useOnboardingStore((state) => state.onboardingPause);

  const isLoading = useOnboardingStore((state) => state.isLoading);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, type, status } = data;
    if (action === 'close') {
      changeOnboardingPause(true);
      changeStepIndexValue(0);
      changeTourIsRunning();
    }

    if ([STATUS.FINISHED].includes(status)) {
      changeTourIsRunning();
      changemodalIsWecome(false);
      changeOnboardingPause(false);
    } else if ([STATUS.SKIPPED].includes(status) && tourIsRunning) {
      changeTourIsRunning();
    } else if (
      (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) &&
      stepIndex !== 2 &&
      stepIndex !== 5 &&
      stepIndex !== 4 &&
      stepIndex !== 3
    ) {
      if (action === ACTIONS.PREV) {
        decreaseStepIndex();
      } else {
        increaseStepIndex();
      }
    } else if (type === EVENTS.TOOLTIP_CLOSE) {
      changeTourIsRunning();
    }
  };

  return (
    <>
      {openWelcomeTourModal && !isRunning && !isDone && !onboardingPause && (
        <TourModal />
      )}
      {isRunning && (
        <Joyride
          run={isRunning && !isLoading}
          continuous={true}
          steps={stepState}
          disableOverlayClose={true}
          stepIndex={stepIndex}
          callback={handleJoyrideCallback}
          tooltipComponent={Step}
          styles={{
            options: {
              arrowColor: '#303030',
              zIndex: 9999999,
            },
          }}
        />
      )}
    </>
  );
};

export default StepComponent;
