import Task from './task';

export class TaskService {
  constructor($http, $rootScope, API_URL, $log, ProjectService) {
    'ngInject';

    this.$http = $http;
    this.API_URL = API_URL;
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.ProjectService = ProjectService;
  }

  getTasks(
    starting,
    ending,
    userIds,
    projectIds,
    plannedTaskIds,
    cost = 'false',
    includes = null,
    page,
    perPage
  ) {
    return this.ProjectService.getAllProjects().then(projectsResponse => {
      const params = {
        cost,
        starting,
        ending,
        'includes[]': includes,
        'user_id[]': userIds,
        'project_id[]': projectIds,
        'planned_task_id[]': plannedTaskIds,
      }

      if(page) params['page'] = page;
      if(perPage) params['per_page'] = perPage;

      return this.$http({
        url: `${this.API_URL}/tasks`,
        method: 'GET',
        params: params,
      }).then(response => {
        angular.forEach(response.data, days => {
          angular.forEach(days.tasks, task => {
            task.project = angular.copy(
              projectsResponse.data.find(p => {
                return p.id == task.projectId;
              }),
            );
          });
        });
        return response;
      });
    });
  }

  getOpenedTask() {
    return this.$http.get(`${this.API_URL}/tasks/opened`);
  }

  saveTask(task) {
    return this.ProjectService.getAllProjects().then(projectsResponse => {
      return this.$http.post(`${this.API_URL}/tasks`, task).then(response => {
        this.$rootScope.$broadcast('taskSaved', response.data);
        response.data.project = angular.copy(
          projectsResponse.data.find(p => {
            return p.id == response.data.projectId;
          }),
        );

        return response;
      });
    });
  }

  deleteTask(task) {
    return this.$http.delete(`${this.API_URL}/tasks/${task.id}`);
  }

  updateTask(task) {
    return this.ProjectService.getAllProjects().then(projectsResponse => {
      return this.$http
        .put(`${this.API_URL}/tasks/${task.id}`, task)
        .then(response => {
          this.$rootScope.$broadcast('taskUpdated', response.data);

          response.data.project = angular.copy(
            projectsResponse.data.find(p => {
              return p.id == response.data.projectId;
            }),
          );

          return response;
        });
    });
  }

  stopTask(task) {
    return this.ProjectService.getAllProjects().then(projectsResponse => {
      return this.$http
        .put(`${this.API_URL}/tasks/${task.id}/stop`, task)
        .then(response => {
          this.$rootScope.$broadcast('taskStopped', response.data);
          response.data.project = angular.copy(
            projectsResponse.data.find(p => {
              return p.id == response.data.projectId;
            }),
          );

          return response;
        });
    });
  }

  download(starting, ending, userIds, projectIds, plannedTaskIds, orientation, format) {
    return this.$http({
      method: 'GET',
      url: `${this.API_URL}/tasks/download?format=${format}`,
      params: {
        starting,
        ending,
        orientation,
        'user_id[]': userIds,
        'project_id[]': projectIds,
        'planned_task_id[]': plannedTaskIds,
      },
      responseType: 'arraybuffer',
    });
  }

  reportsGraphData(
    starting,
    ending,
    userIds,
    projectIds,
    plannedTaskIds,
    cost = 'false',
    includes = null,
  ) {
    return this.$http({
      url: `${this.API_URL}//tasks/reports_graph_data`,
      method: 'GET',
      params: {
        cost,
        starting,
        ending,
        'includes[]': includes,
        'user_id[]': userIds,
        'project_id[]': projectIds,
        'planned_task_id[]': plannedTaskIds,
      },
    });
  }

  getLastTaskProject() {
    return this.$http.get(`${this.API_URL}/tasks/last_task_project`);
  }
}
