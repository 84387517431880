import styled from '@emotion/styled';
import Button from '~/components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
  color: #5f5f5f;
`;

export const LaborButton = styled(Button)`
  margin-top: 2%;
`;

export const HeaderText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  span {
    font-weight: 600;
  }
  line-height: 22px;
  text-align: center;
  color: #5f5f5f;
`;

export const RemindButton = styled.button`
  border: none;
  background: transparent;
  text-decoration: underline;
  margin-top: 15px;

  color: #4f587a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
