import templateUrl from './colorpicker.html';
import './colorpicker.scss';

export const ColorpickerComponent = {
  template: templateUrl,
  bindings: {
    color: '=',
    colorList: '=',
  },
  controller: class ColorpickerController {
    constructor($log) {
      'ngInject';
    }
  },
};
