import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 25px 0px 40px;
`;
export const TrelloCell = styled.div`
  width: 20%;
  padding: 0;
  margin: 0 2% 25px 0;
  transform: translate(0);
  -webkit-transform: translate(0);
  background-size: cover;
  background-position: 50%;
  color: #fff;
  background-color: rgba(0,0,0,.15);
  line-height: 20px;
  padding: 8px;
  position: relative;
  text-decoration: none;
  border-radius: 3px;
  display: block;
  cursor: pointer;
  :hover {
    .trelloFade {
      height: 100%;
      background-color: rgba(0,0,0,.25);
    }
  }
  &.modalCell{
    width: 30%;
    cursor: default;
  }
  
`
export const TrelloDetails = styled.div`
	display: flex;
  height: 80px;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
`
export const TrelloTitle = styled.div`
  overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; -webkit-line-clamp: 2;
  flex: 0 0 auto;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  overflow: hidden;
  max-height: 40px;
  width: 100%;
  word-wrap: break-word;
`
export const TrelloFade = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`
  