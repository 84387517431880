export class TourService {
  constructor(IntroJS, $log, $window, UserService) {
    'ngInject';

    this.$log = $log;
    this.$window = $window;
    this.IntroJS = IntroJS;
    this.UserService = UserService;
  }

  mainTour() {
    const menuElements = this._getChildFrom('.navbar-menu');

    if (this.UserService.getUserTour().mainTour) {
      return;
    }

    const intro = this.IntroJS.introJs;

    const tourMessages = {
      tasks:
        'Na opção "Tarefas", você poderá visualizar a lista de tarefas que você já executou e caso queira, realizar algum ajuste',
      profitability:
        'Na opção "Rentabilidade", você poderá visualizar como anda a saúde financeira dos projetos que você está atuando',
      reports:
        'Na opção "Relatórios", você poderá visualizar e emitir relatórios por projeto, além de poder fazer download e enviar para os seus clientes, caso deseje.',
      payments:
        'Na opção "Pagamentos", você terá acesso à lista de Faturas Pendentes e Faturas Concluídas.',
      projects:
        'Na opção "Projetos", você poderá gerenciar os Projetos de sua organização.',
      users:
        'Na opção "Usuários", você poderá gerenciar os Usuários de sua organização.',
    };

    const steps = intro()
      .setOptions(this.getDefaultOptions())
      .addStep(
        this.createStep(
          'default',
          "Olá! Este é o Labor!<br/><span class='warning-tour'>(Pressione 'ESC' para sair a qualquer momento do tour virtual)</span>",
          '',
        ),
      )
      .addStep(
        this.createStep(
          'default',
          'Neste Tour Virtual você irá conhecer as principais funcionalidades do Sistema.',
          '',
        ),
      )
      .addStep(
        this.createStep(
          '.navbar-menu',
          'Aqui você terá acesso às principais funcionalidades disponíveis',
          'right',
        ),
      );

    for (let i = 0; i < menuElements.length; i++) {
      const element = menuElements[i];
      // menuItem recebe a regex que filtra a última parte da url que está no atributo 'ui-sref'
      const menuItem = /(.*)\.(\w{1,})/.exec(
        element.getAttribute('ui-sref'),
      )[2];
      steps.addStep(
        this.createStep(menuElements[i], tourMessages[menuItem], 'right'),
      );
    }

    steps
      .addStep(
        this.createStep(
          '#task-bar',
          'Nesta barra, você poderá iniciar ou pausar uma tarefa a partir de qualquer tela do Labor.',
          'bottom',
        ),
      )
      .addStep(
        this.createStep(
          '#profile-button',
          'Caso deseje alterar seus dados pessoais, isso poderá ser feito através do menu "Perfil".',
          'top',
        ),
      )
      .oncomplete(() => {
        this._setFlagTourComplete('mainTour');
      })
      .onexit(() => {
        this._setFlagTourComplete('mainTour');
      })
      .start();
  }

  createStep(element_, intro_, position_) {
    return {
      element: element_,
      intro: intro_,
      position: position_,
    };
  }

  getDefaultOptions() {
    return {
      nextLabel: 'Próximo',
      prevLabel: 'Anterior',
      skipLabel: 'Sair',
      doneLabel: 'Finalizar',
      showStepNumbers: false,
    };
  }

  _setFlagTourComplete(tourName) {
    this.UserService.getUserTour()[tourName] = true;
    this.UserService.setLoggedUser(this.UserService.getLoggedUser());
  }

  _getChildFrom(_selector) {
    const childs = [];

    if (_selector == null || _selector == '') {
      return childs;
    }

    const parent = this.$window.document.querySelector(_selector);

    if (parent) {
      parent.childNodes.forEach((el, index, arr) => {
        if (arr[index].nodeType == 1) {
          childs.push(arr[index]);
        }
      });
    }

    return childs;
  }
}
