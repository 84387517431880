export class PlannedTaskService {
  constructor($http, $log, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.API_URL = API_URL;
  }

  getPlannedTasks(projectId) {
    if (!projectId) {
      return;
    }

    return this.$http.get(
      `${this.API_URL}/projects/${projectId}/planned_tasks`,
    );
  }

  importPlannedTasksFromTemplate(project, template) {
    return this.$http.post(
      `${this.API_URL}/projects/${project.id}/planned_tasks/import_template`,
      { projectId: project.id, planned_template_id: template.id },
    );
  }

  importPlannedTasksFromProject(project, importProject) {
    return this.$http.post(
      `${this.API_URL}/projects/${project.id}/planned_tasks/import_project`,
      { projectId: project.id, importProjectId: importProject.id },
    );
  }

  addPlannedTask(plannedTask) {
    return this.$http.post(
      `${this.API_URL}/projects/${plannedTask.projectId}/planned_tasks`,
      plannedTask,
    );
  }

  editPlannedTask(plannedTask) {
    return this.$http.put(
      `${this.API_URL}/projects/${plannedTask.projectId}/planned_tasks/${
        plannedTask.id
      }`,
      plannedTask,
    );
  }

  deletePlannedTask(plannedTask) {
    return this.$http.delete(
      `${this.API_URL}/projects/${plannedTask.projectId}/planned_tasks/${
        plannedTask.id
      }`,
    );
  }

  reorderList(plannedTasks) {
    const list = plannedTasks.map((pt, order) => {
      return { id: pt.id, order };
    });

    return this.$http.put(
      `${this.API_URL}/projects/${
        plannedTasks[0].projectId
      }/planned_tasks/reorder`,
      { list },
    );
  }
}
