import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import styled from '@emotion/styled';

import Modal, { ModalTitle, ModalText } from '../../../components/Modal';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Snackbar from '../../../components/Snackbar';

import AuthService from '../../../services/api/auth';

const StyledForm = styled(Form)`
  position: relative;
`;
const StyledSnackbar = styled(Snackbar)`
  position: absolute;
  bottom: 0;
  max-width: 361px;
  right: 0;
`;
const FormWrapper = styled.div`
  display: flex;
`;

export default function CreateProjectAllocation(props) {
  const { userId, ProjectAllocationService, ProjectService, ...otherProps } =
    props;
  const [message, setMessage] = useState('');
  const [loggedUser, setLoggedUser] = useState(null);
  const [projects, setProjects] = useState([{ id: '' }]);

  async function awaitProjetcsSet(filters = { all: true }) {
    const response = await ProjectService.getFilteredProjects(filters);
    setProjects(response.data);
  }

  useEffect(() => {
    setLoggedUser(AuthService.getLoggedUser());
    awaitProjetcsSet({
      all: true,
    });
  }, []);

  async function createProjectAllocation({ project_role, project_id }) {
    try {
      const response = await ProjectAllocationService.createProjectAllocation({
        body: {
          project_id,
          role: project_role,
          inviter_id: loggedUser.id,
        },
        user: userId,
      });
      return await response.data;
    } catch (e) {
      let errorMessage = e.data;
      if (typeof errorMessage !== 'string') {
        if (message.user) {
          errorMessage = message.user;
        } else {
          errorMessage = 'Ops! Não foi possível criar esta alocação.';
        }
      }
      throw new Error(errorMessage);
    }
  }

  return (
    <Modal
      {...otherProps}
      modalTrasformPosition="translate(-50%, -115%)"
      width="35%"
      height="80%"
    >
      <ModalTitle>Adicionar a um projeto</ModalTitle>
      <ModalText>
        Adicione este usuário em um projeto e atribua a ele uma função.
      </ModalText>

      <Formik
        initialValues={{ project_id: '', project_role: 'colaborador' }}
        onSubmit={(values, { setSubmitting }) => {
          createProjectAllocation(values)
            .then((json) => {
              props.onRequestClose({ json });

              setSubmitting(false);
            })
            .catch((e) => {
              setMessage(e.message);

              setSubmitting(false);
            });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <StyledForm>
            <FormWrapper>
              <Input
                as="select"
                name="project_id"
                value=""
                type="number"
                label="Projeto*"
                style={{ margin: '0 30px 30px 0', width: '100%' }}
              >
                <option defaultValue>Selecione</option>
                {projects.map((project, index) => (
                  <option key={index} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </Input>
              <Input
                as="select"
                name="project_role"
                value=""
                label="Função no Projeto*"
                style={{ margin: '0 0 30px 0', width: '100%' }}
              >
                <option value="colaborador">Colaborador </option>
                <option value="gerente">Gerente</option>
              </Input>
            </FormWrapper>
            <Button
              type="button"
              disabled={isSubmitting}
              onClick={() => submitForm()}
            >
              Salvar alterações
            </Button>
            <StyledSnackbar isVisible={!!message}>{message}</StyledSnackbar>
          </StyledForm>
        )}
      </Formik>
    </Modal>
  );
}

CreateProjectAllocation.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  userId: PropTypes.string,
  ProjectService: PropTypes.object.isRequired,
  ProjectAllocationService: PropTypes.shape({
    createProjectAllocation: PropTypes.func,
  }),
};
