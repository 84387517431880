import templateUrl from './project-list.html';
import './project-list.scss';

export const ProjectListComponent = {
  template: templateUrl,
  bindings: {
    projectList: '<',
    onEdit: '&',
    onDelete: '&',
  },
  controller: class ProjectListController {
    constructor($scope, $log, $mdDialog, ProjectService, UserService) {
      'ngInject';

      this.$scope = $scope;
      this.$log = $log;
      this.$mdDialog = $mdDialog;
      this.ProjectService = ProjectService;
      this.UserService = UserService;
      this.projectsByCategory = {};
    }

    $onInit() {
      this.categories = {
        empty: 'Sem categoria',
        internal: 'Projetos Internos',
        management: 'Projetos de Gestão',
        external: 'Projetos Pagos',
      };

      this.loggedUser = this.UserService.getLoggedUser();
      this.isLoggedUserAdmin = this.UserService.isLoggedUserAdmin();

      this.$scope.$watch('$ctrl.projectList', () => {
        this.projectsByCategory = {};

        this.projectList.forEach(project => {
          const category = project.category || 'empty';
          project.deleted = project.deletedAt !== null;

          if (
            this.UserService.loggedUserHasRole(project.team, 'administrador')
          ) {
            project.loggedUserHasAdminAccess = true;
          }

          if (!this.projectsByCategory[category]) {
            this.projectsByCategory[category] = [];
          }

          this.projectsByCategory[category].push(project);
        });
      });
    }

    editProject(project) {
      this.onEdit({ project });
    }

    onSwitchChange(project) {
      const action = project.deleted ? 'desabilitar' : 'habilitar';
      const confirm = this.$mdDialog
        .confirm()
        .title(`Deseja realmente ${action} o projeto ${project.name}?`)
        .ariaLabel('Modificar Projeto')
        .ok('Confirmar')
        .cancel('close');

      this.$mdDialog.show(confirm).then(
        () => {
          if (project.deleted === true) {
            this.ProjectService.disableProject(project);
          } else {
            this.ProjectService.restoreProject(project);
          }
        },
        () => {
          project.deleted = !project.deleted;
        },
      );
    }

    deleteProject(project) {
      this.onDelete({ project });
    }
  },
};
