import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

export class FirebaseService {
  constructor($http, $log, UserService, NotificationService, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.UserService = UserService;
    this.API_URL = API_URL;
    this.NotificationService = NotificationService;
  }

  activate() {
    this.litenTokenRefresh();
    this.registerServiceWorker();
    this.listenToArrivingMessages();
  }

  registerServiceWorker() {
    const messaging = firebase.messaging();
    const loggedUser = this.UserService.getLoggedUser() || {};
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('serviceworker.js')
        .then((registration) => {
          messaging.useServiceWorker(registration);
          registration.addEventListener('updatefound', () => {
            // updatefound means a new service worker being installed.
            const installingWorker = registration.installing;
            installingWorker.addEventListener('statechange', (e) => {
              if (installingWorker.state === 'activated') {
                const loggedUser = this.UserService.getLoggedUser();
                // if notifications configs is on then notification should be allowed
                if (loggedUser.notificationConfigs &&
                  loggedUser.notificationConfigs.length) {
                  if (Notification.permission !== 'granted') {
                    this.activateNotifications().then(token => {
                      this._sendPushTokenToBackend(token);
                    });
                  } else {
                    messaging
                      .getToken().then(refreshedToken => {
                        this._sendPushTokenToBackend(refreshedToken);
                      });
                  }
                }
              }
            });
          });
        })
        .catch(e => {
          this.$log.log('error', 'Erro ao registrar service worker.');
          this.NotificationService.showNotification(
            'Ocorreu um erro ao ativar as notificações',
            'error',
          );
        });
    } else if (loggedUser.notificationConfigs && loggedUser.notificationConfigs.length) {
      this.NotificationService.showNotification(
        'O seu navegador não suporta notificações',
        'error',
      );
    }
  }

  activateNotifications() {
    const messaging = firebase.messaging();
    return new Promise((resolve, reject) => {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          messaging.getToken().then(token => {
            resolve(token);
          });
        } else {
          this.$log.log('error', 'Unable to get permission to notify.');
          reject('Unable to get permission to notify.');
        }
      });
    });
  }

  litenTokenRefresh() {
    const messaging = firebase.messaging();
    messaging.onTokenRefresh(function () {
      messaging
        .getToken()
        .then(function (refreshedToken) {
          this.sendPushTokenToBackend(refreshedToken);
        })
        .catch(function (err) {
          this.$log.log('error', 'Erro ao recuperar push token renovado');
        });
    });
  }

  listenToArrivingMessages() {
    firebase.messaging().onMessage((payload) => {
      console.log('Message received. ', payload);
    });
  }

  _sendPushTokenToBackend(token) {
    const loggedUser = this.UserService.getLoggedUser();
    return this.$http.put(
      `${this.API_URL}/users/${loggedUser.id}`,
      {
        pushTokens: JSON.stringify({ web: token }),
      }
    );
  }
}
