import '@babel/polyfill';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import Footer from '../Footer';
import { mq } from '../../../helpers/responsive';

const PageContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflow: 'hidden',
  })}
`;

const MainContainer = styled.main`
  ${mq({
    flex: 1,
    marginTop: ['28px', '28px', '64px'],
  })}
`;

export default function Page(props) {
  return (
    <div
      style={{
        backgroundColor: '#f6f7fb',
      }}
    >
      <Global
        styles={css`
          * {
            box-sizing: border-box;
          }
        `}
      />
      <PageContainer>
        <Header />
        <MainContainer>{props.children}</MainContainer>
        <Footer />
      </PageContainer>
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
