import styled from '@emotion/styled';
import { mq } from '../../../helpers/responsive';

export default styled.h1`
  ${mq({
    padding: '0 0 15px 0',
    margin: '0 0 15px 0',
    borderBottom: '2px solid #E3E5E8',
    fontSize: '18px',
    fontWeight: 600,
    color: '#5f5f5f',
    display: 'flex',
    alignItems: 'center',
  })}
`;
