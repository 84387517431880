import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

import Table from '../../components/Table';

import Select from './components/DateSelect';
import PaymentList from './components/PaymentsList';
import PaymentModal from './components/PaymentModal';

const Payments = ({
  ReportService,
  UserService,
  $log,
  $document,
  $window,
  TeamService,
  NotificationService,
}) => {
  const currentDate = new Date();
  const currentFullYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentFullYear);
  const [period] = useState({
    year: currentFullYear,
    month: currentMonth,
    id: '11-2022',
    pending: true,
  });
  const possibleMonths = [
    { month: '12', label: 'Dezembro' },
    { month: '11', label: 'Novembro' },
    { month: '10', label: 'Outubro' },
    { month: '9', label: 'Setembro' },
    { month: '8', label: 'Agosto' },
    { month: '7', label: 'Julho' },
    { month: '6', label: 'Junho' },
    { month: '5', label: 'Maio' },
    { month: '4', label: 'Abril' },
    { month: '3', label: 'Março' },
    { month: '2', label: 'Fevereiro' },
    { month: '1', label: 'Janeiro' },
  ];

  const possibleYears = Array.from(
    { length: currentFullYear - 2015 },
    (_, index) => {
      const year = currentFullYear - index;
      return { year: year.toString(), label: year.toString() };
    },
  );

  const [reportsList, setReportsList] = useState([]);
  const [reportsByStatus, setReportsByStatus] = useState({
    finished: [],
    pending: [],
  });
  const [reportsByYear, setReportsByYear] = useState({});

  const [payData, setPayData] = useState({
    open: false,
    report: null,
    header: null,
  });

  const isAdmin = React.useMemo(
    () => UserService.isLoggedUserAdmin(),
    [UserService],
  );
  const isManager = React.useMemo(
    () => UserService.isLoggedUserManager(),
    [UserService],
  );
  const loggedUser = React.useMemo(
    () => UserService.getLoggedUser(),
    [UserService],
  );

  const getReports = () => {
    const year = period ? period.year : null;
    const month = period && (isAdmin || isManager) ? period.month : null;
    ReportService.getReports(team, year, month).then(
      (response) => {
        setReportsList(response.data);
      },
      (error) => {
        $log.log('error', error);
      },
    );
  };

  const pay = (report) => {
    const referenceDate = new Date(report.referenceDate);
    const year = referenceDate.getFullYear();
    const month = referenceDate.getMonth() + 1;
    setPayData({
      open: true,
      header: `${report.user.name}_${month}_${year}`,
      report,
    });
  };

  const confirmPayment = (report) => {
    ReportService.payReport(report).then(
      () => {
        getReports();
        NotificationService.showNotification(
          'Pagamento confirmado com sucesso!',
        );
      },
      (error) => {
        $log.log('error', error);
        NotificationService.showNotification(
          'Ocorreu um erro ao tentar confirmar o pagamento.',
          'error',
        );
      },
    );
    setPayData({ open: false, header: null });
  };

  const upload = (report, danfeFile) => {
    ReportService.uploadDanfe(report, danfeFile).then(
      () => {
        getReports();
        NotificationService.showNotification(
          'Upload da nota fiscal realizado com sucesso!',
        );
      },
      (error) => {
        $log.log('error', error);
        NotificationService.showNotification(
          'Ocorreu um erro ao tentar fazer o upload da nota fiscal!',
          'error',
        );
      },
    );
  };

  const getFileNameFromHttpResponse = (response) => {
    const contentDispositionHeader = response.headers('Content-Disposition');
    const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  };

  const saveData = (response) => {
    const blob = new Blob([response.data], { type: 'application/pdf' });

    const a = document.createElement('a');
    $document.find('body').eq(0).append(a);
    const url = $window.URL.createObjectURL(blob);

    a.style.display = 'none';
    a.href = url;
    a.download = getFileNameFromHttpResponse(response);
    a.click();
    $window.URL.revokeObjectURL(url);
  };

  const download = (report) => {
    ReportService.downloadDanfe(report.invoice.id).then(
      (response) => {
        saveData(response);
      },
      (error) => {
        $log.log('error', error);
      },
    );
  };

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    TeamService.getTeams().then((response) => {
      setTeams(response.data);
      setTeam(response.data[0]);
      return teams;
    });
  }, []);

  useEffect(() => {
    if (isAdmin || isManager) {
      setReportsByStatus(
        reportsList.reduce(
          (result, report) => {
            if (report.state === 'completed') {
              result.finished.push(report);
            } else {
              result.pending.push(report);
            }
            return result;
          },
          { finished: [], pending: [] },
        ),
      );
    } else {
      const unorderedGroup = {};
      const newReportsByYear = [];
      // Agrupa os registros por Ano
      reportsList.forEach((report) => {
        const year = new Date(report.referenceDate).getFullYear();
        if (!unorderedGroup[year]) {
          unorderedGroup[year] = [];
        }
        unorderedGroup[year].push(report);
      });
      // Garante que as chaves estejam ordenada por ano
      Object.keys(unorderedGroup)
        .sort((yearA, yearB) => Number(yearB) - Number(yearA))
        .forEach((key) =>
          newReportsByYear.push({ key, value: unorderedGroup[key] }),
        );
      setReportsByYear(newReportsByYear);
    }
  }, [reportsList]);

  const getYearInPossibleYears = React.useMemo(
    () =>
      possibleYears.map((possibleYear) => ({
        label: possibleYear.year,
        value: possibleYear.year,
      })),
    [possibleYears],
  );

  const getMonthInPossibleMonths = React.useMemo(
    () =>
      possibleMonths.map((possibleMonth) => ({
        label: possibleMonth.label,
        value: possibleMonth.month,
      })),
    [possibleMonths],
  );

  const updateMonth = (chosenMonth) => {
    period.month = chosenMonth;
    period.id = `${String(chosenMonth)}-${selectedYear.value}`;
  };

  const updateYear = (chosenYear) => {
    period.year = chosenYear;
    period.id = `${String(selectedMonth.value)}-${String(chosenYear)}`;
  };

  useEffect(() => {
    setSelectedYear((previous) => {
      if (previous) return previous;
      return currentFullYear;
    });
  }, [period, isAdmin, isManager]);

  return (
    <>
      <section id="payments">
        <S.SelectContainer>
          {isAdmin || isManager ? (
            <S.FilterContainer>
              <Select
                placeholder="Ano"
                options={getYearInPossibleYears}
                value={selectedYear}
                defaultValue={currentFullYear}
                onChange={(option) => {
                  setSelectedYear(option);
                  updateYear(option.value);
                }}
              />
              <Select
                placeholder="Mês"
                options={getMonthInPossibleMonths}
                value={selectedMonth}
                onChange={(option) => {
                  setSelectedMonth(option);
                  updateMonth(option.value);
                }}
              />
              <S.FilterButton onClick={() => getReports()}>
                <S.FilterIcon />
                Filtrar
              </S.FilterButton>
            </S.FilterContainer>
          ) : (
            <S.FilterContainer>
              <Select
                placeholder="Ano"
                options={getYearInPossibleYears}
                value={selectedYear}
                onChange={(option) => {
                  setSelectedYear(option);
                  updateYear(option.value);
                }}
              />
              <S.FilterButton onClick={() => getReports()}>
                <S.FilterIcon />
                Filtrar
              </S.FilterButton>
            </S.FilterContainer>
          )}
        </S.SelectContainer>
      </section>
      {isAdmin || isManager ? (
        <section>
          <section>
            <PaymentList
              header="Faturas Pendentes"
              isAdmin={isAdmin || isManager}
              pay={pay}
              download={download}
              upload={upload}
              reportsList={reportsByStatus.pending}
              loggedUser={loggedUser}
            />
          </section>
          <section>
            <PaymentList
              isAdmin={isAdmin || isManager}
              header="Faturas Concluídas"
              reportsList={reportsByStatus.finished}
              pay={pay}
              download={download}
              upload={upload}
              loggedUser={loggedUser}
            />
          </section>
        </section>
      ) : (
        <section>
          {reportsByYear.length ? (
            <section>
              {reportsByYear.map((year) => (
                <PaymentList
                  key={year.key}
                  isAdmin={isAdmin || isManager}
                  header={year.key}
                  reportsList={year.value}
                  pay={pay}
                  download={download}
                  upload={upload}
                  loggedUser={loggedUser}
                />
              ))}
            </section>
          ) : (
            <div>
              <Table>
                <tbody>
                  <tr>
                    <td className="center">
                      Até agora nenhum pagamento foi registrado para este
                      período.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </section>
      )}
      <PaymentModal
        payData={payData}
        onClose={() => setPayData({ open: false, report: null, header: null })}
        onConfirm={() => confirmPayment(payData.report)}
      />
    </>
  );
};

Payments.propTypes = {
  ReportService: PropTypes.object.isRequired,
  UserService: PropTypes.object.isRequired,
  $log: PropTypes.object.isRequired,
  $document: PropTypes.object.isRequired,
  $window: PropTypes.object.isRequired,
  TeamService: PropTypes.object.isRequired,
  NotificationService: PropTypes.object.isRequired,
};

export default Payments;
