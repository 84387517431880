import templateUrl from './date-range-picker.html';
import './date-range-picker.scss';

export const DateRangePickerComponent = {
  template: templateUrl,
  bindings: {
    start: '=',
    end: '=',
  },
  controller: class DateRangePickerController {
    constructor($log) {
      'ngInject';

      this.$log = $log;
    }

    $onInit() {
      this.today = new Date();
      this.today.setHours(0, 0, 0, 0);
      const date = this.start || this.today;

      this.currentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      this.nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);

      this.currentMonthDays = this._getDaysInMonth(
        date.getFullYear(),
        date.getMonth(),
      );
      this.nextMonthDays = this._getDaysInMonth(
        date.getFullYear(),
        date.getMonth() + 1,
      );
    }

    rewind() {
      this.currentMonth.setMonth(this.currentMonth.getMonth() - 1);
      this.nextMonth.setMonth(this.nextMonth.getMonth() - 1);

      this.currentMonthDays = this._getDaysInMonth(
        this.currentMonth.getFullYear(),
        this.currentMonth.getMonth(),
      );
      this.nextMonthDays = this._getDaysInMonth(
        this.nextMonth.getFullYear(),
        this.nextMonth.getMonth(),
      );
    }

    forward() {
      this.currentMonth.setMonth(this.currentMonth.getMonth() + 1);
      this.nextMonth.setMonth(this.nextMonth.getMonth() + 1);

      this.currentMonthDays = this._getDaysInMonth(
        this.currentMonth.getFullYear(),
        this.currentMonth.getMonth(),
      );
      this.nextMonthDays = this._getDaysInMonth(
        this.nextMonth.getFullYear(),
        this.nextMonth.getMonth(),
      );
    }

    selectDay(date) {
      if (!this.start) {
        this.start = date;
      } else if (!this.end) {
        if (this.start.getTime() < date.getTime()) {
          this.end = date;
        } else {
          this.end = this.start;
          this.start = date;
        }
      } else {
        this.start = date;
        this.end = null;
      }
    }

    formatDate(date) {
      if (!date) return '';

      const pad = s => (s < 10 ? `0${s}` : s);
      const format = d =>
        [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');

      return format(date);
    }

    _getDaysInMonth(year, month) {
      const result = [];

      const firstMonthDay = new Date(year, month, 1);
      const lastMonthDay = new Date(year, month + 1, 0);
      const firstCalendarDay = new Date(firstMonthDay.getTime());
      firstCalendarDay.setDate(
        firstMonthDay.getDate() - firstMonthDay.getDay(),
      );
      const numberOfWeeks = Math.ceil(
        (firstMonthDay.getDay() + lastMonthDay.getDate()) / 7,
      );

      const date = new Date(firstCalendarDay.getTime());
      for (let i = 0; i < numberOfWeeks; i++) {
        result.push([]);
        for (let j = 0; j < 7; j++) {
          result[i].push(new Date(date.getTime()));
          date.setDate(date.getDate() + 1);
        }
      }

      return result;
    }
  },
};
