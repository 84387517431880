import styled from '@emotion/styled';

export const InputContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  top: 100%;
  right: 5px;
  position: absolute;
  background: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 14px;
  z-index: 999;
`;

export const CalendarContainer = styled.div`
  flex: 1;
  margin: 0 15px;
  &::first-of-type: {
    margin-left: 0;
  }
`;
