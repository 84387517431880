import React, { useContext } from 'react';
import { PopoverStateReturn } from 'reakit/ts';
import { Popover } from '../../components/Popover';
import CalendarContext from '../CalendarContext';
import DateRangePicker from './DateRangePicker';

const PeriodPopover = (props: PopoverStateReturn) => {
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(CalendarContext);
  return (
    <Popover {...props} aria-label="Período de tempo">
      <DateRangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        hide={props.hide}
      />
    </Popover>
  );
};

export default PeriodPopover;
