import React from 'react';
import SVGIcon from '~/components/SVGIcon';

interface LaborLogoSvgProps{
    width?: String,
    height?: String
}

const LaborLogoSvg = ({width="", height=""}: LaborLogoSvgProps) => (
    <SVGIcon
        width={width }
        height= {height}
        // viewBox="0 0 262 262"
        preserveAspectRatio="xMidYMid"
        >
            <path fill="#FE506B" d="M20 .2c3.6 0 7 .9 10.1 2.7 3 1.7 5.4 4.1 7.1 7.1 1.8 3.1 2.7 6.5 2.7 10.1 0 3.6-.9 7-2.7 10.1-1.7 3-4.1 5.4-7.1 7.1C27 39.1 23.6 40 20 40c-3.6 0-7-.9-10.1-2.7-3-1.8-5.4-4.1-7.1-7.2C.9 27.2 0 23.8 0 20.2c0-3.6.9-7 2.7-10.1 1.8-3 4.1-5.4 7.2-7.2C13 1.1 16.4.2 20 .2zm-4 29l12-9-12-9v18z"/>
            <g fill="#4F4F57">
                <path d="M52.6 31V10.3H57v17.1h8.4V31zM78.7 31l-.8-2.2h-.1c-.7.9-1.5 1.6-2.2 1.9-.8.4-1.8.5-3 .5-1.5 0-2.7-.4-3.6-1.3-.9-.9-1.3-2.1-1.3-3.7 0-1.7.6-2.9 1.8-3.7 1.2-.8 2.9-1.2 5.3-1.3l2.7-.1v-.7c0-1.6-.8-2.4-2.5-2.4-1.3 0-2.8.4-4.5 1.1l-1.4-2.9c1.8-1 3.8-1.4 6.1-1.4 2.1 0 3.8.5 4.9 1.4 1.1.9 1.7 2.3 1.7 4.2V31h-3.1zm-1.3-7.3l-1.7.1c-1.3 0-2.2.3-2.8.7-.6.4-.9 1-.9 1.9 0 1.2.7 1.8 2.1 1.8 1 0 1.8-.3 2.4-.9.6-.6.9-1.3.9-2.3v-1.3zM95 14.9c1.9 0 3.3.7 4.4 2.2 1.1 1.5 1.6 3.5 1.6 6 0 2.6-.5 4.6-1.6 6.1-1.1 1.4-2.6 2.2-4.5 2.2s-3.3-.7-4.4-2h-.3l-.7 1.6h-3.3V9h4.3v5.1c0 .7-.1 1.7-.2 3.1h.2c1-1.5 2.5-2.3 4.5-2.3zm-1.4 3.4c-1.1 0-1.8.3-2.3 1-.5.7-.7 1.7-.8 3.2v.5c0 1.7.3 2.9.8 3.7.5.8 1.3 1.1 2.4 1.1.9 0 1.6-.4 2.1-1.2.5-.8.8-2 .8-3.6s-.3-2.7-.8-3.5c-.6-.8-1.3-1.2-2.2-1.2zM118.9 23.1c0 2.6-.7 4.6-2 6-1.3 1.4-3.3 2.2-5.7 2.2-1.5 0-2.9-.3-4-1-1.2-.7-2.1-1.6-2.7-2.9-.6-1.3-.9-2.7-.9-4.4 0-2.6.7-4.6 2-6 1.4-1.4 3.2-2.2 5.7-2.2 1.5 0 2.9.3 4 1 1.2.7 2.1 1.6 2.7 2.8.6 1.2.9 2.8.9 4.5zm-10.9 0c0 1.6.3 2.8.8 3.6.5.8 1.4 1.2 2.5 1.2 1.2 0 2-.4 2.5-1.2.5-.8.8-2 .8-3.6s-.3-2.7-.8-3.5c-.5-.8-1.3-1.2-2.5-1.2s-2 .4-2.5 1.2c-.6.7-.8 1.9-.8 3.5zM131.3 14.9c.6 0 1.1 0 1.5.1l-.3 4c-.3-.1-.8-.1-1.3-.1-1.4 0-2.5.4-3.2 1.1-.8.7-1.2 1.7-1.2 3v8h-4.3V15.2h3.3l.6 2.7h.2c.5-.9 1.2-1.6 2-2.1.8-.7 1.7-.9 2.7-.9z"/>
            </g>
    </SVGIcon>
);

export default LaborLogoSvg;
