import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mq = facepaint(['@media(min-width: 1280px)']);

export default styled.h1`
  ${mq({
    fontFamily: 'Bitter',
    fontSize: ['35px', '45px'],
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#5f5f5f',
    margin: '0',
  })}
`;
