/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

export const LoadMoreTasksButton = styled.button`
  display: flex;
  margin: 0 auto;
  border: none;
  margin-bottom: 30px;
  background-color: transparent;
  padding: 0 40px;
  line-height: 36px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #959595;
  &:hover {
    background-color: rgba(158, 158, 158, 0.2);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  margin-left: 50%;
`;
