import styled from '@emotion/styled';
import LinearProgress from '@mui/material/LinearProgress';

const StyledProgressBar = styled(LinearProgress)`
  > * {
    background-color: #ff3c58 !important;
  }
  background-color: #ffbcc5 !important;
  height: 10px;
`;

export default StyledProgressBar;
