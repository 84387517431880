import React, { useState, useEffect } from 'react';
import NestedMenu, { OptionsType } from '../Select/NestedMenu';
import CoreDateRangePicker from './CoreDateRangePicker';
import {
  getDates,
  selectPeriodOption,
  periodOptions,
  selectPeriodName,
} from './dateUtils';
import { CalendarIcon } from './styles';

/*
  DateRangePickerComponent
  Necessary props: startDate, setStartDate, endDate, setEndDate.
  This component will update startDate and endDate based on user interaction.

  It has a default button (example in reports page) to open the dropdown
  you can use your own button by passing it to CustomAnchorElement prop.

  onClose is called when the dropdown is closed by clicking outside.
  
  Warning: The component updates startDate and endDate in real time,
  not just when the user closes the dropdown, but with every change.

  isPending changes the default button border to blue.
*/

type Props = {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  isPending?: boolean;
  onClose?: () => void;
  CustomAnchorElement?: React.ReactNode;
  anchorOrigin?: {
    vertical: number | 'bottom' | 'top' | 'center';
    horizontal: number | 'left' | 'right' | 'center';
  };
};

const DateRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isPending,
  onClose,
  CustomAnchorElement,
  anchorOrigin,
}: Props) => {
  // Because setState is async, for the options dropdown we need to manually
  // call onClose, as the dropdown is closed before the state is updated.
  const [optionWithCloseClicked, setOptionWithCloseClicked] = useState(false);
  const [displayName, setDisplayName] = useState(
    selectPeriodName(startDate, endDate),
  );
  useEffect(() => {
    if (optionWithCloseClicked && onClose) {
      onClose();
      setOptionWithCloseClicked(false);
    } else {
      setDisplayName(selectPeriodName(startDate, endDate));
    }
  }, [startDate, endDate]);

  const applyFilter = (option: string) => {
    const { starting, ending } = selectPeriodOption(option);
    setStartDate(starting);
    setEndDate(ending);
  };

  const initialOptions = periodOptions.map((opt) => ({
    text: opt.text,
    onClick: () => {
      applyFilter(opt.code);
      setDisplayName(opt.text);
    },
  }));

  const options: OptionsType[] = [
    ...initialOptions,
    {
      text: 'Período específico',
      icon: <CalendarIcon />,
      onClickAction: 'OPEN_COMPONENT',
      onClick: () => setDisplayName('Período específico'),
    },
  ];

  return (
    <NestedMenu
      displayName={displayName}
      isPending={isPending}
      DefaultComponent={
        <CoreDateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          reuseFirstSecondMonth={true}
        />
      }
      CustomAnchorElement={CustomAnchorElement}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
      setOptionWithCloseClicked={() => setOptionWithCloseClicked(true)}
      options={options}
    />
  );
};

export default DateRangePicker;
export { getDates };
