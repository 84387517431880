import angular from 'angular';
import { CapitalizeFilter } from './capitalize.filter';
import { MsToHoursFilter } from './ms-to-hours.filter';
import { MsToTimeFilter } from './ms-to-time.filter';
import { MonthNameByIndexFilter } from './month-name-by-index.filter';

export const FiltersModule = angular
  .module('root.components.app.common.filters', [])
  .filter('capitalize', CapitalizeFilter)
  .filter('msToHours', MsToHoursFilter)
  .filter('msToTime', MsToTimeFilter)
  .filter('monthNameByIndex', MonthNameByIndexFilter).name;
