import React from 'react';
import Select, {StylesConfig  } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { PlannedTask } from '~/models/types';

const colourStyles = {
  control: () => ({
    display: 'flex',
    cursor: 'pointer',
    outline: 'none',
    height: '60px',
    addingBottom: '10px',
    margin: '0 10px 0 10px',
    marginBottom: '-1px',
    borderBottom: 'solid 1px transparent',
    '&:hover': {
      borderBottomColor: '#ff3c56',
    },
  }),
  indicatorSeparator: () => null,
  // eslint-disable-next-line
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles, // eslint-disable-next-line
    backgroundColor: isDisabled ? null : isFocused ? '#f6f7fb' : null, // eslint-disable-next-line
    color: isDisabled ? '#ccc' : data.color,
    cursor: isDisabled ? 'not-allowed' : 'default',
    fontSize: '0.8em',
    fontWeight: 900,
    textTransform: 'uppercase',
    padding: '10px 75px 10px 20px',

    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && (isSelected ? data.color : 'none'),
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: '0.9em',
    color: '#959595',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.8em',
    fontWeight: 900,
    textTransform: 'uppercase',
  }),
  menu: (styles) => ({
    ...styles,
    whiteSpace: 'nowrap',
    width: 'auto',
    left: 0,
    padding: '10px 0',
    borderRadius: 0,
    boxShadow: '0 2px 18px 4px rgba(0, 0, 0, 0.03)',
    backgroundColor: '#fff',
    zIndex: 100,
  }),
};
interface Option {
  value: string;
  label: string;
  color: string;
  disabled: boolean;
}

type PlannedTaskSelectProps <T> = {
  style?: any;
  placeholder?: string;
  value: T;
  options: T[];
  onChange: (option: T) => void;
  onMenuOpen?: () => void;
  isDisabled: boolean ;
  noOptionsMessage?: () => string;
  isLoading?: boolean;
}

const DropdownIndicator = () => (
  <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.8rem' }} />
);

export default function PlannedTaskSelect<T>({
  style,
  placeholder,
  ...otherProps
}: PlannedTaskSelectProps<T>) {
  return (
    <Select
      styles={style || colourStyles}
      placeholder={placeholder || 'Tarefa Planejada'}
      components={{ DropdownIndicator }}
      isOptionDisabled={(option) => (option as Option).disabled}
      {...otherProps}
    />
  );
}
