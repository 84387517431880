import React from 'react';
import moment from 'moment';
import { msToTime } from '~/app/utils/duration';
import { Date, DayOfWeek, Duration, Container } from './styles';

type TaskHeaderProps = {
  date: string;
  duration: number;
};
const DailyTasksHeader = ({ date, duration }: TaskHeaderProps) => {
  moment.locale('pt-br');
  const dateString = moment(date, 'YYYYMMDD')
    .format('dddd, D [de] MMMM')
    .split(',');

  dateString[0].toUpperCase();
  return (
    <Container>
      <Date>
        <DayOfWeek>{dateString[0]}</DayOfWeek>
        {`, ${dateString[1]}`}
      </Date>
      <Duration>{msToTime(duration)}</Duration>
    </Container>
  );
};

export default DailyTasksHeader;
