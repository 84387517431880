import templateUrl from './project-settings.html';
import './project-settings.scss';

export const ProjectSettingsComponent = {
  template: templateUrl,
  bindings: {
    project: '=',
    onSave: '&',
    onDelete: '&',
  },
  controller: class ProjectSettingsController {
    constructor($log, $mdDialog, $stateParams, $scope, UserService, TrelloService, ProjectService, COLORS) {
      'ngInject';

      this.$log = $log;
      this.$mdDialog = $mdDialog;
      this.$stateParams = $stateParams;
      this.$scope = $scope;
      this.TrelloService = TrelloService;
      this.projectColors = COLORS.projectColors;
      this.ProjectService = ProjectService;
      this.UserService = UserService;
    }

    $onInit() {
      this.$scope.hasTrelloBoard = this.project.hasTrelloBoard;

      this.ProjectService.getProjectById(this.$stateParams.projectId)
        .then(res => {
          this.$scope.project = response.data
        })
    }

    saveSettings() {
      this.onSave({ project: this.project });
    }

    deleteProject() {
      this.onDelete({ project: this.project });
    }

    createBoard() {
      this.TrelloService
        .createBoard(this.project)
        .then((res) => {
          this.$scope.hasTrelloBoard = true
          this.TrelloService._addTrelloBoardToProject(this.project.id, res.data)
        })
    }

    connectWithTrello() {
      if (!this.TrelloService.isTrelloConnected())
        return this.TrelloService.authenticate(() => this.createBoard())

      this.createBoard()
    }

    cancel() {
      this.$mdDialog.cancel();
    }
  },
};
