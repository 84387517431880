import React from 'react';
import styled from '@emotion/styled';
import { colorPickerOptions } from '../NewProjectModal/constants';
import ColorPicker from '~/site/onbordingModal/ColorPicker';
import Input from '~/components/Input';

const NameColorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: 4%;
  height: 60px;
`;

const NameContainer = styled.div`
  flex-grow: 1;
  min-width: 80px;
`;

const NameColorInput = styled(Input)``;

const ColorPickerContainer = styled.div`
  padding-top: 6px;
  width: 68px;
`;

const ColorPickerTF = styled(ColorPicker)`
  vertical-align: bottom;
`;

interface Props {
  name: string;
  colorName?: string | null;
  onChange?: Function;
}

const TextFieldColorPicker: React.FC<Props> = ({
  name,
  colorName,
  onChange,
}) => (
  <NameColorContainer>
    <NameContainer>
      <NameColorInput
        value={''}
        type="text"
        name={name}
        onChange={(e) => {
          if (onChange) onChange(name, e.target.value);
        }}
        placeholder="Digite o nome aqui"
      />
    </NameContainer>
    <ColorPickerContainer>
      <ColorPickerTF
        colorName={colorName}
        name={'tagColor'}
        onChange={(e) => {
          {
            if (onChange) onChange('tagColor', e.target.value);
          }
        }}
        options={colorPickerOptions}
        customMargin="0px 0px 0 0px"
      />
    </ColorPickerContainer>
  </NameColorContainer>
);

export default TextFieldColorPicker;
