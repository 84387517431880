import styled from '@emotion/styled';
import { css } from '@emotion/css';
import theme from '~/theme';

export const ContentParagraph = css`
  color: ${theme.colors.white};
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0;
  margin-bottom: 15px;
`;

export const Container = styled.div``;
