import styled from '@emotion/styled';
import { MdInfoOutline } from 'react-icons/md';

export const TooltipContainer = styled.div`
  position: absolute;
  bottom: 100%;
  left: -170px;
  font-size: 12px;
  visibility: hidden;
  color: #f6f7fb;
  background-color: #303030;
  opacity: 1;
  width: max-content;
  max-width: 340px;
  padding: 5px 5px;
  border-radius: 4px;
  z-index: 10;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, padding 0.5s;
`;

export const TooltipIcon = styled(MdInfoOutline)`
  font-size: 18px;
  color: '#959595';
  cursor: pointer;
`;

export const TooltipDiv = styled.div`
  position: relative;
  display: flex;
  & ${TooltipIcon}:hover + ${TooltipContainer} {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`;
