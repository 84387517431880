import styled from '@emotion/styled';
import { mq } from '~/helpers/responsive';
import Text from '~/components/Text';
import Snackbar from '~/components/Snackbar';
import Link from '~/components/Link';

export const WelcomeText = styled(Text)`
  ${mq({
    marginTop: ['10px', null, null, '15px'],
    fontSize: '20px',
  })}
  -webkit-font-smoothing: antialiased;
`;

export const FlexWrapper = styled.div`
${mq({
  display: 'flex',
  flexDirection: ['column', 'row'],
  marginBottom: ['50px', '184px', '90px', '120px'],
  margin: [null, null, '0 36px', '0 40px 0 36px']
})}
`;

export const FormBox = styled.div`
  ${mq({
  flex: 1,
  marginTop: ['50px', 0],
})}
`;

export const StyledSnackbar = styled(Snackbar)`
  position: absolute;
  top: -60px;
  right: 0;
`;

export const ButtonWrapper = styled.div`
  ${mq({
  display: 'flex',
  flexDirection: ['column', 'row'],
  alignItems: ['flex-start', 'center'],
  justifyContent: 'flex-start',
  marginTop: '30px',
})}
`;

export const TosText = styled(Text)`
  ${mq({
  marginLeft: [null, '0'],
  fontSize: '14px',
})}
  a {
    text-decoration: none;
    font-weight: bold;
    color: #4680FE;
  }
`;

export const HomePageLink = styled(Link)`
  ${mq({
  display: 'block',
  marginBottom: '28px',
})}
`;

