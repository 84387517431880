import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  flex: 1;
  color: #d7d7d7;
  font-weight: 600;
  font-size: 0.9em;
`;

export const Input = styled.input`
  flex: 1;
  border: 0;
  border-bottom: 2px solid #f4f4f4;
  padding: 10px 0;
  color: #959595;
  font-size: 0.9em;
  outline: none;
  &:focus {
    outline: none;
    border-color: #b2b2b2;
  }
  &:disabled {
    opacity: 0.3;
  }
`;
