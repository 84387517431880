import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const HeaderModal = styled.div`
  border-bottom: 1px solid #d7d7d7;
  display: flex;
`;

export const CloseButton = styled(FontAwesomeIcon)`
  position: relative;
  top: -15px;
  cursor: pointer;
`;
