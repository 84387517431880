import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;

  color: ${(props) => (props.tinted ? '#ff3c58' : '#959595')};
`;

const IconContainer = ({ children, tinted }) => (
  <Container tinted={tinted}>{children}</Container>
);

IconContainer.propTypes = {
  children: PropTypes.node.isRequired,
  tinted: PropTypes.bool,
};

IconContainer.defaultProps = {
  tinted: false,
};

export default IconContainer;
