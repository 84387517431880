export function dateToString(date) {
  if (date === null) return '';

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function stringToDate(string) {
  // TODO: Comeback to fix
  // eslint-disable-next-line
  const [_, day, month, year] = string.match(/(\d{2})\/(\d{2})\/(\d{4})/);

  // TODO: Comeback to fix
  // eslint-disable-next-line
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}
