import API from '~/services/api';
import useUserStore from '~/store/user';

const {getState} = useUserStore;


export const fetchData = async (get: Function, set: Function, projectId?:Number) => {
  const firstDayOfMonth = new Date();
  const monthsBeforeLast = get().numberOfLoadMorePressed;
  if(get().numberOfLoadMorePressed !== 0){
    firstDayOfMonth.setMonth(firstDayOfMonth.getMonth() - monthsBeforeLast)
  }
  firstDayOfMonth.setDate(1);
  firstDayOfMonth.setHours(0, 0, 0, 0);
  
  const lastDayOfMonth = new Date();
  lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
  lastDayOfMonth.setDate(0);
  const params = {
    starting: firstDayOfMonth.toISOString(),
    ending: lastDayOfMonth.toISOString(),
    includes: 'planned_task',
    projectIds: projectId,
  };
  if(!projectId)
    params["userIds"] = getState().user.id; 
  const responseTasks = await API.user.get_tasks(params);
  const tasksInMonth = await responseTasks.json();
  set({projectsTasks: tasksInMonth});
  const responseProjects = await API.team.get_projects();
  const projectsData = await responseProjects.json();
  set({projectsData: projectsData});
  const newProjects = [
    ...projectsData.map((p) => ({
      value: p,
      label: p.name,
      color: p.tagColor,
    })),
  ];
  set({projectSelect: newProjects})

  get().fetchTasksDurationInMonth();
};