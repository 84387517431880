import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import * as S from './styles';

const Step: React.FC<TooltipRenderProps> = ({
  index,
  step,
  size,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) => (
  <S.Container {...tooltipProps}>
    <S.IndexText>
      Etapa {index + 1} de {size}
    </S.IndexText>
    {step.title && <S.Title>{step.title}</S.Title>}
    <S.Content>{step.content}</S.Content>
    <S.Footer>
      {index === 0 && <S.Exit {...primaryProps}>Próximo</S.Exit>}
      {index === 0 && <S.Exit {...closeProps}> | Pular tour</S.Exit>}
      {index === 1 && <S.Exit {...backProps}>Voltar</S.Exit>}
    </S.Footer>
  </S.Container>
);

export default Step;
