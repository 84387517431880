import styled from '@emotion/styled';
import { mq } from '~/helpers/responsive';
import Text from '~/components/Text';
import Snackbar from '~/components/Snackbar';
import Link from '~/components/Link';


interface sizeOptionProps {
  error: boolean;
}
export const FlexWrapper = styled.div`
${mq({
  display: 'flex',
  flexDirection: ['column', 'row'],
  marginBottom: ['50px', '184px', '90px', '120px'],
  margin: [null, null, '0 36px', '0 40px 0 36px']
})}
`;

export const WelcomeBox = styled.div`
  ${mq({
  width: [null, '282px', null, '485px'],
  marginRight: [null, '72px', null, '80px'],
})}
`;

export const WelcomeSubTitle = styled(Text)`
  ${mq({
  marginTop: '25px',
  fontSize: ['15px', null, null, '18px'],
})}
-webkit-font-smoothing: antialiased;
`;

export const WelcomeText = styled(Text)`
  ${mq({
  marginTop: ['10px', null, null, '15px'],
  fontSize: '20px',
})}
-webkit-font-smoothing: antialiased;
`;

export const WelcomeLink = styled(Link)`
  ${mq({
  display: 'block',
  fontSize: ['15px', null, null, '18px'],
  marginTop: '15px',
})}
`;

export const FormBox = styled.div`
  ${mq({
  flex: 1,
  marginTop: ['50px', 0],
})}
`;

export const StyledSnackbar = styled(Snackbar)`
  position: absolute;
  top: -60px;
  right: 0;
`;

export const ButtonWrapper = styled.div`
  ${mq({
  display: 'flex',
  flexDirection: ['column', 'row'],
  alignItems: ['flex-start', 'center'],
  justifyContent: 'flex-start',
  marginTop: '30px',
})}
`;

export const LoginText = styled(Text)`
  ${mq({
  marginTop: ['35px', 0],
  marginLeft: [null, '25px'],
  fontSize: '14px',
})}
`;

export const TosText = styled(Text)`
  ${mq({
  marginLeft: [null, '0'],
  fontSize: '14px',
})}
  a {
    text-decoration: none;
    font-weight: bold;
    color: #4680FE;
  }
`;

export const RegisterImage = styled.img`
   ${mq({
  marginTop: '15%'
})}
`

export const SizeTeamText = styled(Text)`
  ${mq({
  fontSize: '16px',
  lineHeight: '22px',
  weight: '400'
})}
`;

export const SizeBox = styled.ul`
  ${mq({
  width: [null, null, null, '463px'],
})}
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;

`;
export const SizeOption = styled.li<sizeOptionProps>`
  list-style-type:none;
  width: 147px;

  &:nth-child(-n+3) {
  ${mq({
  marginBottom: [null, null, null, '10px'],
})}
    //margin-bottom: 10px;
  } 
  &:nth-child(2) {
    ${mq({
  marginLeft: [null, null, null, '10px'],
  marginRight: [null, null, null, '10px'],
})}
   
  }

  &:nth-child(5) {
     ${mq({
  marginLeft: [null, null, null, '10px'],
  marginRight: [null, null, null, '10px'],
})}

  }

  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #FFFFFF;
    padding: 10px;
    
    color: #5F5F5F;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    
    border: 1px solid rgba(149, 149, 149, 0.2);
     ${({ error }) => error && `
      border-color: #E21300;
    `}
    &:hover {
      border: 1px solid #4F587A;
    }
  }
 
  input[type="radio"]:checked + label {
    border: 1px solid #4680FE;
  }
`;

export const ErrorMessage = styled.div`
  color: #ff6340;
  font-size: 12px;
  font-weight: 500;
`;

export const ContinueButton = styled.button`
     display: inline-block;
    color: #ffffff;
    background: #ff3c58;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
    transition: background 0.5s;
    outline: none;
    border: 0;
    padding: 10px 18px;
    cursor: pointer;
    :focus,
    :hover {
      background: #4680fe;
    }
    :disabled {
      opacity: 0.5;
    }
    text-decoration: none;
    text-align: center;
`;

export const HomePageLink = styled(Link)`
  ${mq({
  display: 'block',
  marginBottom: '28px',
})}
`;

