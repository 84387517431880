export const PopoverTrigger = () => {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      onOpen: '&',
      onClose: '&',
    },
    link: (scope, element, attrs) => {
      scope.$on('popover-opened', () => {
        scope.onOpen();
      });

      scope.$on('popover-closed', () => {
        scope.onClose();
      });
    },
  };
};
