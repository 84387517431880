import React from 'react';
import shallow from 'zustand/shallow';
import Modal from '~/components/Modal';
import Button from '~/components/Button';
import { Text, HeaderText, ModalContainer, ModalIcon } from './styles';
import useUserStore from '~/store/user';
import useReportsStore from '~/store/reports/reports';
import API from '~/services/api';
import NotificationServiceType from '~/models/ServicesTypes';

type ConfirmSendModalProps = {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsOpen: boolean;
  NotificationService: NotificationServiceType;
};
const ConfirmSendModal = ({
  setModalOpen,
  modalIsOpen,
  NotificationService,
}: ConfirmSendModalProps) => {
  const user = useUserStore((state) => state.user);
  const { dateRange, fetchReportsData } = useReportsStore(
    (state) => ({
      dateRange: state.dateRange,
      fetchReportsData: state.fetchReportsData,
    }),
    shallow,
  );
  const startDate = dateRange.startDate || new Date();

  const onRequestClose = () => {
    setModalOpen(false);
  };

  const handleSendReport = async () => {
    try {
      const response = await API.report.save_report(startDate);
      const data = await response.json();
      if (!response.ok) throw new Error(data.message.base[0]);
      await fetchReportsData();
      onRequestClose();
      NotificationService.showNotification(
        'Relatório enviado com sucesso!',
        'success',
      );
    } catch (error) {
      NotificationService.showNotification(`Erro: ${error.message}`, 'error');
    }
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={modalIsOpen}
      blurBackground={true}
      backgroundColor="#ffffff"
      width="340px"
    >
      <ModalContainer>
        <HeaderText>
          <ModalIcon />
          {user.name}_{startDate.getMonth() + 1}_{startDate.getFullYear()}
        </HeaderText>
        <Text>
          Você deseja enviar este relatório para <strong>pagamentos</strong>?
        </Text>
        <Button
          onClick={() => {
            handleSendReport();
          }}
        >
          Confirmar
        </Button>
      </ModalContainer>
    </Modal>
  );
};

export default ConfirmSendModal;
