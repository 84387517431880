import angular from 'angular';
import { PlannedTemplateService } from './planned-template.service';
import { PlannedTemplatesComponent } from './planned-templates.component';

export const PlannedTemplatesModule = angular
  .module(
    'root.components.app.components.main.projects.project-dashboard.planned-templates',
    [],
  )
  .service('PlannedTemplateService', PlannedTemplateService)
  .component('plannedTemplates', PlannedTemplatesComponent).name;
