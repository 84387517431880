import React from 'react';

import { TooltipContainer, TooltipIcon, TooltipDiv } from './styles';

type TooltipProps = {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
};

const Tooltip = ({ children }: TooltipProps) => (
  <TooltipDiv>
    <TooltipIcon />
    <TooltipContainer>{children}</TooltipContainer>
  </TooltipDiv>
);

export default Tooltip;
