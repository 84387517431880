import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const SnackbarWrapper = styled.div`
  min-width: 100px;
  background-color: #4680fe;
  color: #ffffff;
  padding: 10px 15px;
  position: absolute;
  z-index: 1;
  transition: opacity 0.5s linear;
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
`;

export default function Snackbar(props) {
  return <SnackbarWrapper {...props}>{props.children}</SnackbarWrapper>;
}

Snackbar.propTypes = {
  children: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
};
