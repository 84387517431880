export default $mdThemingProvider => {
  'ngInject';

  const pallete = $mdThemingProvider.extendPalette('grey', {
    50: 'ECEFF1',
  });
  $mdThemingProvider.definePalette('neoDefault', pallete);

  $mdThemingProvider.definePalette('laborPalette', {
    '50': '#ffd5db',
    '100': '#ffbcc5',
    '200': '#ffa2af',
    '300': '#ff899a',
    '400': '#ff6f84',
    '500': '#ff3c58',
    '600': '#ff092c',
    '700': '#ef0022',
    '800': '#d5001f',
    '900': '#bc001b',
    A100: '#ff8a80',
    A200: '#ff5252',
    A400: '#ff1744',
    A700: '#d50000',
    contrastDefaultColor: 'light', // whether, by default, text (contrast)
    // on this palette should be dark or light

    contrastDarkColors: [
      '50',
      '100', // hues which contrast should be 'dark' by default
      '200',
      '300',
      '400',
      'A100',
    ],
    contrastLightColors: undefined, // could also specify this if default was 'dark'
  });

  $mdThemingProvider
    .theme('default')
    .primaryPalette('laborPalette')
    .backgroundPalette('neoDefault');
};
