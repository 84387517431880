import templateUrl from './trello-board-tile.html';
import './trello-board-tile.scss';

export const TrelloBoardTileComponent = {
  template: templateUrl,
  bindings: {
    trelloBoard: '=',
    onSelect: '&',
  },
  controller: class TrelloBoardTileComponent {
    constructor($log) {
      'ngInject';

      this.$log = $log;
    }

    $onInit() {
      const { prefs = {} } = this.trelloBoard;
      if (prefs.backgroundImageScaled && prefs.backgroundImageScaled.length) {
        const tileImage = prefs.backgroundImageScaled.find(img => img.width === 480);
        if (tileImage) {
          this.boardTileStyle = { 'background-image': `url(${tileImage.url})` }; 
        }
      } else if (prefs.backgroundColor) {
        this.boardTileStyle = { 'background-color': prefs.backgroundColor };
      }
    }

    saveSettings() {
      this.project.trelloBoardAttributes = {
        boardId: this.selectedBoard.id,
        todoListId: this.selectedTodoList.id,
        doneListId: this.selectedDoneList.id,
      };
      if (this.selectedLabel && this.selectedLabel.id) {
        this.project.trelloBoardAttributes.project_label = this.selectedLabel.id;
      }
      this.onSave({ project: this.project });
    }

    select() {
      if (this.onSelect) {
        this.onSelect(this.trelloBoard);
      }
    }
  },
};
