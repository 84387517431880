import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const objectWithoutProperties = (obj, keys) => {
  const target = {};
  // eslint-disable-next-line
  for (const i in obj) {
    // eslint-disable-next-line
    if (keys.indexOf(i) >= 0) continue;
    // eslint-disable-next-line
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
};

const dot = (color = '#ccc', filled = false) => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    color,
    border: '2px solid',
    backgroundColor: filled ? color : 'none',
    borderRadius: 4,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 4,
    width: 4,
  },
});

const colourStyles = {
  control: (_, { selectProps }) => ({
    display: 'flex',
    cursor: 'pointer',
    outline: 'none',
    addingBottom: '10px',
    margin: '0 10px 0 0',
    marginBottom: '-1px',
    borderBottom: 'solid 1px transparent',
    '&:hover': {
      borderBottomColor: '#ff3c56',
    },
    borderBottomColor: selectProps.errorState ? '#E21300' : 'transparent',
  }),
  indicatorSeparator: () => null,
  // eslint-disable-next-line
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles, // eslint-disable-next-line
    backgroundColor: isDisabled ? null : isFocused ? '#f6f7fb' : null, // eslint-disable-next-line
    color: isDisabled ? '#ccc' : data.color,
    cursor: isDisabled ? 'not-allowed' : 'default',
    fontSize: '0.8em',
    fontWeight: 900,
    textTransform: 'uppercase',
    padding: '10px 75px 10px 20px',

    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && (isSelected ? data.color : 'none'),
    },
    ...dot(data.color, isSelected),
  }),
  placeholder: (styles, { selectProps }) => ({
    ...styles,
    fontSize: '0.9em',
    color: selectProps.errorState ? '#E21300' : '#959595',
    opacity: selectProps.errorState ? '50%' : '',
  }),
  singleValue: (styles, { data }) => ({
    ...objectWithoutProperties(styles, ['maxWidth', 'position', 'transform']),
    color: data.color,
    fontSize: '0.8em',
    fontWeight: 900,
    textTransform: 'uppercase',
    ...dot(data.color, true),
  }),
  menu: (styles) => ({
    ...styles,
    whiteSpace: 'nowrap',
    width: 'auto',
    left: 0,
    padding: '10px 0',
    borderRadius: 0,
    boxShadow: '0 2px 18px 4px rgba(0, 0, 0, 0.03)',
    backgroundColor: '#fff',
    zIndex: 100,
  }),
};

const DropdownIndicator = () => (
  <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.8rem' }} />
);

export default function ProjectSelect(props) {
  return (
    <Select
      styles={colourStyles}
      placeholder={props.placeholder}
      components={{ DropdownIndicator }}
      isOptionDisabled={(option) => option.disabled}
      {...props}
    />
  );
}

ProjectSelect.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
  errorState: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onMenuOpen: PropTypes.func,
};

ProjectSelect.defaultProps = {
  placeholder: 'Selecione um projeto',
  errorState: false,
};
