import angular from 'angular';
import angularInputMask from 'angular-input-masks';
import authenticationConfig from './config/authentication.config';
import { HeaderInterceptorFactory } from './config/header-interceptor.factory';
import headerInterceptorConfig from './config/header-interceptor.config';
import materialThemeConfig from './config/material-theme.config';
import materialLocaleConfig from './config/material-locale.config';
import firebaseConfig from './config/firebase.config';
import { CommonModule } from './common/common.module';
import { ComponentsModule } from './components/components.module';

export const AppModule = angular
  .module('root.components.app', [
    angularInputMask,
    CommonModule,
    ComponentsModule,
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $stateProvider.state('app', {
      abstract: true,
      url: '/app',
      template: '<ui-view></ui-view>',
    });

    $urlRouterProvider.when('/app', '/entrar');
  })
  .factory('HeaderInterceptorFactory', HeaderInterceptorFactory)
  .config(headerInterceptorConfig)
  .config(authenticationConfig)
  .config(materialThemeConfig)
  .config(materialLocaleConfig)
  .config(firebaseConfig)
  .run(
    (
      $rootScope,
      TimerService,
      $transitions,
      $state,
      UserService,
      $log,
      $window,
      CheckVersionService,
      FirebaseService,
    ) => {
      'ngInject';

      $rootScope.TimerService = TimerService;

      // Remove o token guardado quando expirado e redireciona para a página de login
      function dealWithInvalidToken() {
        UserService.removeLoggedUser();
        $state.go('login');
      }

      const listenerExpired = $rootScope.$on('auth:session-expired', () => {
        dealWithInvalidToken();
      });

      const listenerCredentials = $rootScope.$on('auth:invalid', () => {
        dealWithInvalidToken();
      });

      CheckVersionService.checkVersion();
    },
  ).name;
