import React from 'react';
import { Container, PageIndicator } from './styles';

type PageIndexProps = {
  currentPage: number;
  numberOfPages: number;
};

const PageIndex = ({ currentPage, numberOfPages }: PageIndexProps) => (
  <Container>
    {[...Array(numberOfPages)].map((e, index) => (
      <PageIndicator
        key={index + 1}
        page={index + 1}
        currentPage={currentPage}
      />
    ))}
  </Container>
);

export default PageIndex;
