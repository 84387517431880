export const FocusMeDirective = ($log, $parse, $timeout) => {
  'ngInject';

  return {
    link(scope, element, attrs) {
      const model = $parse(attrs.focusMe);
      scope.$watch(model, value => {
        if (value === true) {
          $timeout(() => {
            element[0].focus();
          });
        }
      });
      // element.bind('blur', () => {
      //   scope.$apply(model.assign(scope, false));
      // });
    },
  };
};
