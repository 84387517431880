export class UserService {
  constructor($http, $log, TimerService, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.TimerService = TimerService;
    this.API_URL = API_URL;
    this.loggedUser = null;
  }

  getUsers(all) {
    return this.$http({
      url: `${this.API_URL}/users`,
      method: 'GET',
      params: { all },
    });
  }

  getUser(user) {
    return this.$http.get(`${this.API_URL}/users/${user.id}`);
  }

  getUsersByRole(role) {
    return this.$http.get(`${this.API_URL}/users?role=${role}`);
  }

  saveUser(user) {
    return this.$http.post(`${this.API_URL}/users`, user).then(response => {
      return response;
    });
  }

  updateUser(user) {
    return this.$http.put(`${this.API_URL}/users/${user.user.id}`, user);
  }

  getLoggedUser() {
    if (!this.loggedUser) {
      this.loggedUser = angular.fromJson(localStorage.getItem('loggedUser'));
    }

    // Deduzindo o primeiro nome para usuários já autenticados quando este código for para produção
    if (this.loggedUser && !this.loggedUser.firstName) {
      this.loggedUser.firstName = this.loggedUser.name.split(' ')[0];
    }

    return this.loggedUser;
  }

  setLoggedUser(loggedUser) {
    this.loggedUser = loggedUser;
    if (!this.loggedUser.tour) {
      this.loggedUser.tour = { mainTour: false };
    }

    this.loggedUser.firstName = this.loggedUser.name.split(' ')[0];

    localStorage.setItem('loggedUser', angular.toJson(loggedUser));
  }

  removeLoggedUser() {
    this.loggedUser = null;
    localStorage.removeItem('loggedUser');
    localStorage.removeItem('rules');
    localStorage.removeItem('remindExtension');
    localStorage.removeItem('useTeamStore');
    localStorage.removeItem('useUserStore');
    localStorage.removeItem('useProjectsStore')
    localStorage.removeItem('useProjectsTasksStore');
    this.TimerService.stopTimer();
  }

  loggedUserHasRole(team, role) {
    if (!this.getLoggedUser() || !team || !role) {
      return false;
    }

    return this.getLoggedUser().allocations.some(
      allocation => allocation.team.id == team.id && allocation.role == role,
    );
  }

  isLoggedUserAdmin() {
    return this.getLoggedUser().allocations.some(
      allocation => allocation.role == 'administrador',
    );
  }

  isLoggedUserManager() {
    return this.getLoggedUser().allocations.some(
      allocation => allocation.role == 'gerente',
    );
  }

  getUserBaseState() {
    return 'app.main.projects';
  }

  getUserTour() {
    return this.getLoggedUser().tour;
  }

  requestCancellation(cancellationRequest) {
    return this.$http.post(
      `${this.API_URL}/cancellation_request`,
      cancellationRequest,
    );
  }

  getHourValueInPeriod(userId, year, month) {
    return this.$http
      .get(
        `${
        this.API_URL
        }/users/${userId}/hour_value_in_period?year=${year}&month=${month}`,
      )
      .then(response => {
        return response.data.hourValue;
      });
  }

  validateCompleteSignin(user) {
    return this.$http.get(`${this.API_URL}/users/validate_complete_signin?email=${user.email}`);
  }
}
