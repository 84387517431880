export class NotificationService {
  constructor($mdToast) {
    'ngInject';

    this.$mdToast = $mdToast;
  }

  showNotification(message, type = 'success', delay = 10000) {
    this.$mdToast.show(
      this.$mdToast
        .simple()
        .textContent(message)
        .position('top right')
        .theme(type)
        .hideDelay(delay),
    );
  }
}
