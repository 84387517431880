import create, { SetState } from 'zustand';

type LoadingState = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const useLoadingStateStore = create<LoadingState>(
  (set: SetState<LoadingState>) => ({
    loading: false,
    setLoading: (loading: boolean) => {
      set({ loading });
    },
  }),
);

export default useLoadingStateStore;
