import styled from '@emotion/styled';
import theme from '~/theme';



export const SubItem = styled.a<{isActive: boolean}>`
  flex-wrap: wrap;
  margin: 6px 4px 0 4px;
  display: flex;
  align-items: center;
  text-decoration: none;  
  flex-direction: row;
  transition: color 0.5s;
  background-color: ${(props) => props.isActive ? theme.colors.gray21 : theme.colors.white};
  :hover {
    color: ${theme.colors.primary};
  };
  `;


export const ProjectItemText = styled.span`
  margin: 6px 0px 6px 0px;
  color:  ${theme.colors.gray18};
  font-size: 14px;
  font-weight: normal;
  :hover {
    color: ${theme.colors.primary};
  };
`;

export const ProjectItemIcon = styled.span<{iconColor: string}>`
    color: ${(props)=>props.iconColor};
    border: 2px solid;
    border-radius: 4px;
    height: 4px;
    width: 4px;
    margin-left: 24px;
    margin-right: 10px;
`

export const SeeAllItemText = styled.span`
  color:  ${theme.colors.gray23};
  font-weight: 600;
  margin-left: 42px;
  margin-bottom: 16px;
  font-size: 14px;
  :hover {
    color: ${theme.colors.primary};
  };
`;