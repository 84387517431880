import React from 'react';
import NumberFormat from 'react-number-format';
import { GrayInputBox, Description, GrayText } from '../NewProjectModal/styles';
import {
  currencyFormatter,
  truncate2decimals,
  unmaskPrice,
} from '~/app/utils/currency';

interface Props {
  totalPrice?: number | string | null;
  estimatedHours?: number | null ;
}

const HourlyCharged: React.FC<Props> = ({ totalPrice, estimatedHours }) => {
  const updateHourlyCharged = (price: number | string | null | undefined, hours: number | undefined | null) =>
    truncate2decimals(
      Number(unmaskPrice(price || 0)) / Number(String(hours || 1).replace('h', '')),
    );

  return (
    <div>
      <NumberFormat
        format={currencyFormatter}
        value={updateHourlyCharged(totalPrice, estimatedHours)}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        prefix={'R$'}
        name={''}
        renderText={(value) => (
          <GrayInputBox>
            <GrayText>{value}</GrayText>
          </GrayInputBox>
        )}
      />
      <Description>ⓘ Calculado automaticamente</Description>
    </div>
  );
};

export default HourlyCharged;
