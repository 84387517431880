import angular from 'angular';
import { react2angular } from 'react2angular';
import ProjectsReact from '../../../../../../react/app/ProjectsPage';
import { ProjectInputModule } from './project-input/project-input.module';
import { ProjectListModule } from './project-list/project-list.module';
import { ProjectDashboardModule } from './project-dashboard/project-dashboard.module';
import { ProjectService } from './project.service';
import ProjectConfig from '~/app/ProjectsPage/ProjectDashboard/ProjectConfig';
export const ProjectsModule = angular
  .module('root.components.app.components.main.projects', [
    ProjectInputModule,
    ProjectListModule,
    ProjectDashboardModule,
  ])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('app.main.projects', {
          url: '/projects',
          component: 'projects',
          resolve: {
            auth: ($auth) => {
              return $auth.validateUser();
            },
          },
        })
        .state('app.main.projects-dashboard', {
          url: '/projects/:projectId',
          component: 'projectDashboard',
          resolve: {
            auth: ($auth) => {
              return $auth.validateUser();
            },
          },
        })
        .state('app.main.projects-dashboard.planned-tasks', {
          url: '/planned-tasks',
          component: 'plannedTasks',
          resolve: {
            auth: ($auth) => {
              return $auth.validateUser();
            },
          },
        })
        .state('app.main.projects-dashboard.tasks', {
          url: '/tasks',
          component: 'tasks',
          resolve: {
            projectId: ($stateParams) => {
              return $stateParams.projectId;
            },
            auth: ($auth) => {
              return $auth.validateUser();
            },
          },
        })
        .state('app.main.projects-dashboard.project-config', {
          url: '/project-config',
          component: 'projectConfig',
          resolve: {
            auth: ($auth) => {
              return $auth.validateUser();
            },
          },
        })
        .state('app.main.projects-dashboard.profitability', {
          url: '/profitability',
          component: 'profitability',
          resolve: {
            auth: ($auth) => {
              return $auth.validateUser();
            },
          },
        })
        .state('app.main.projects-dashboard.trello-settings', {
          url: '/trello-settings',
          component: 'projectTrelloSettingsReact',
          resolve: {
            projectId: ($stateParams) => {
              return $stateParams.projectId;
            },
            auth: ($auth) => {
              return $auth.validateUser();
            },
          },
        })
        .state('app.main.projects-dashboard.trello-board-selection', {
          url: '/select-trello-board',
          component: 'trelloBoardSelection',
          resolve: {
            projectId: ($stateParams) => {
              return $stateParams.projectId;
            },
            auth: ($auth) => {
              return $auth.validateUser();
            },
          },
        });
    },
  ])
  .service('ProjectService', ProjectService)
  .component('projectConfig', react2angular(ProjectConfig,
    [],
    [
      '$state',
      '$window',
      "NotificationService",
    ]
    ))
  .component(
    'projects',
    react2angular(
      ProjectsReact,
      [],
      [
        '$state',
        '$mdDialog',
        '$scope',
        'ProjectService',
        'NotificationService',
      ],
    ),
  ).name;
