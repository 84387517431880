import styled from '@emotion/styled';

export const LoadingStateBody = styled.div`
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingStateText = styled.p`
  fontsize: 16px;
`;

export const LoadingStateLink = styled.a`
  fontsize: 16px;
`;
