export class TeamService {
  constructor($http, $log, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.API_URL = API_URL;
  }

  getTeamsIAmAdmin() {
    return this.$http.get(`${this.API_URL}/teams?only_admin=true`);
  }

  getTeams() {
    return this.$http.get(`${this.API_URL}/teams`);
  }

  saveTeam(team) {
    return this.$http.post(`${this.API_URL}/teams`, team);
  }

  getTeam(team) {
    return this.$http.get(`${this.API_URL}/teams/${team.id}`, team);
  }

  getTeamStatistics(team, starting, ending) {
    return this.$http({
      url: `${this.API_URL}/teams/${team.id}/statistics`,
      method: 'GET',
      params: { starting, ending },
    });
  }

  updateTeam(team) {
    return this.$http.put(`${this.API_URL}/teams/${team.id}`, team);
  }

  disableTeam(team) {
    return this.$http.post(`${this.API_URL}/teams/${team.id}/disable`);
  }

  restoreTeam(team) {
    return this.$http.post(`${this.API_URL}/teams/${team.id}/restore`);
  }

  deleteTeam(team) {
    return this.$http.delete(`${this.API_URL}/teams/${team.id}`);
  }
}
