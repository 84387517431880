import React, { useState } from 'react';
import * as S from './styles';
import ImportTemplateModal from './ImportTemplateModal';
type Props = {
  isAdmin: boolean;
  isEmpty: boolean;
  setPlannedTasks: Function;
};
const EmptyList = ({ isAdmin, isEmpty, setPlannedTasks }: Props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  return isEmpty ? (
    isAdmin ? (
      <>
        <S.Container>
          <S.Header>Bem-vindo(a) ao seu novo projeto!</S.Header>
          <S.SubHeader>
            Vamos começar? Que tal criar tarefas planejadas?
          </S.SubHeader>
          <S.Button
            onClick={() => {
              setPlannedTasks([
                {
                  description: '',
                  duration: 1,
                },
              ]);
            }}
          >
            <S.AddIcon className="icon" />
            <S.ButtonTextContainer>
              <S.ButtonText>Criar tarefas planejadas</S.ButtonText>
              <S.ButtonSubText>
                Cadastre manualmente as tarefas planejadas.
              </S.ButtonSubText>
            </S.ButtonTextContainer>
          </S.Button>
          <S.Button
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <S.ImportIcon className="icon" />
            <S.ButtonTextContainer>
              <S.ButtonText>Importar de template ou outro projeto</S.ButtonText>
              <S.ButtonSubText>
                Escolha uma lista de tarefas planejadas de um template pronto ou
                outro projeto.
              </S.ButtonSubText>
            </S.ButtonTextContainer>
          </S.Button>
        </S.Container>
        <ImportTemplateModal
          isOpen={modalIsOpen}
          onRequestClose={(list) => {
            setIsOpen(false);
            if (list) {
              setPlannedTasks(list);
            }
          }}
          blurBackground={true}
          width="650px"
        />
      </>
    ) : (
      <S.Container>
        <S.Header>
          Não existem tarefas planejadas cadastradas até o momento.
        </S.Header>
        <S.SubHeader>
          Peça ao administrador do seu escritório para criar tarefas planejadas
          para este projeto!
        </S.SubHeader>
      </S.Container>
    )
  ) : null;
};

export default EmptyList;
