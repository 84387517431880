import React, { useState } from 'react';
import ReactModal from 'react-modal';
import TeamDataForm from '~/components/SideBar/TeamModal/TeamDataForm';
import SubscriptionInfo from '~/components/SideBar/TeamModal/SubscriptionInfo';

import * as S from './styles';
import NotificationServiceType, {
  TeamServiceType,
} from '~/models/ServicesTypes';
import { State } from '~/models/types';

import './reactModal.scss';

const TAB_STATE = {
  TEAM_DATA: 'TEAM_DATA',
  SUBSCRIPTION: 'SUBSCRIPTION',
};
interface TeamModalProps {
  setTeamModal: (active: boolean) => void;
  isOpen: boolean;
  TeamService: TeamServiceType;
  NotificationService: NotificationServiceType;
  $state: State;
  setTeamModalCancelConfirm: Function;
}

const TeamModal: React.FC<TeamModalProps> = ({
  setTeamModal,
  isOpen,
  TeamService,
  NotificationService,
  $state,
  setTeamModalCancelConfirm,
}: TeamModalProps) => {
  const [tabSelected, setTabSelected] = useState(TAB_STATE.TEAM_DATA);

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(246, 247, 251, 0.97)',
      zIndex: 100,
    },
    content: {
      position: 'relative',
      top: '60%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      backgroundColor: 'transparent',
      borderRadius: 0,
      border: 'none',
      padding: '40px 35px',
      width: '580px',
      transform: 'translate(-50%, -80%)',
    },
  };

  return (
    <ReactModal
      closeTimeoutMS={180}
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={() => setTeamModal(false)}
    >
      <S.ModalContainer>
        <S.ModalHeader>
          <S.NavBar>
            <S.TabBar
              selected={tabSelected === TAB_STATE.TEAM_DATA}
              onClick={() => setTabSelected(TAB_STATE.TEAM_DATA)}
            >
              Dados do Time
            </S.TabBar>
            <S.TabBar
              selected={tabSelected === TAB_STATE.SUBSCRIPTION}
              onClick={() => setTabSelected(TAB_STATE.SUBSCRIPTION)}
            >
              Assinatura
            </S.TabBar>
          </S.NavBar>
          <S.ModalClose>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              onClick={() => setTeamModal(false)}
            >
              <path
                fill="#959595"
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </S.ModalClose>
        </S.ModalHeader>
        {tabSelected === TAB_STATE.TEAM_DATA ? (
          <TeamDataForm
            TeamService={TeamService}
            NotificationService={NotificationService}
            setTeamModal={setTeamModal}
          />
        ) : (
          <SubscriptionInfo
            setTeamModal={setTeamModal}
            setTeamModalCancelConfirm={setTeamModalCancelConfirm}
            $state={$state}
          />
        )}
      </S.ModalContainer>
    </ReactModal>
  );
};

export default TeamModal;
