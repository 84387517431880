import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Select from 'react-select';
import shallow from 'zustand/shallow';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ProjectsFilterSelect from '../components/Select/ProjectsFilterSelect';
import Calendar from './Calendar';
import useProjectStore from '~/store/projects';
import useProjectsStore from '~/store/projects/projects';
import NewProjectModal from './components/NewProjectModal';
import Can from '../components/Can';

const ProgressBarContainer = styled.div`
  flex: 1;
  background: #e1e1e1;
  border-radius: 1rem;
  overflow: visible;
  min-height: 4px;
  width: 70%;
  position: relative;
`;

const Progress = styled.div`
  width: 70%;
  display: flex;
  align-content: center;
  :hover {
    .bubble {
      opacity: 1;
      visibility: visible;
      bottom: 45px;
      left: 35%;
      transform: translate(-50%, 0);
    }
    .bubble:after {
      border: 8px solid transparent;
      border-top-color: #5f5f5f;
      content: '';
      bottom: -15px;
      height: 0px;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      width: 0px;
    }
  }
`;

const ProgressBar = styled.span`
  background-color: ${({ tagColor }) => tagColor};
  width: ${({ width }) => width};
  display: block;
  height: 10%;
  min-height: 4px;
  border-radius: 1rem;
`;

const Bubble = styled.div`
  background: #5f5f5f;
  color: #e8eaf3;
  cursor: pointer;
  opacity: 0;
  padding: 5px;
  position: absolute;
  text-align: left;
  visibility: hidden;
  border-radius: 3px;
  width: 231px;
  height: 72px;
  span {
    color: #e8eaf3;
    font-size: 12px;
  }
  div {
    margin-left: 12px;
    margin-top: 6px;
  }
`;

const Percentage = styled.p`
  margin: 2px 0 0 0;
  font-size: 10px;
  color: #959595;
`;

const EndDateContainer = styled.div`
  background-color: white;
  width: ${({ width }) => width};
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: left;
  position: relative;
  :hover {
    background-color: #f6f7fb;
  }
  .text {
    margin-left: 10px;
    color: ${({ color }) => color};
  }
  font-size: 14px;
  .has-date__icon {
    font-size: 21px;
    margin-left: 5px;
  }
`;

const FILTER_OPTIONS = [
  { value: '', label: 'Todos' },
  { value: 'running', label: 'Em andamento' },
  { value: 'not_started', label: 'Não iniciados' },
  { value: 'finished', label: 'Concluídos' },
];

const STATUS_OPTIONS = [
  { value: 'not_started', label: 'Não iniciado' },
  { value: 'running', label: 'Em andamento' },
  { value: 'finished', label: 'Concluído' },
];

const STATUS_TRANSLATION = {
  not_started: 'Não iniciado',
  running: 'Em andamento',
  finished: 'Concluído',
};

const CATEGORY_OPTIONS = [
  { value: '', label: 'Todos' },
  { value: 'external', label: 'Pago' },
  { value: 'internal', label: 'Interno' },
];

const ORDER_OPTIONS = [
  { value: 'newest', label: 'Mais recente' },
  { value: 'oldest', label: 'Mais antigo' },
  { value: 'alphabetical', label: 'Ordem alfabética' },
];

export default function Projects(props) {
  const projects = useProjectsStore((state) => state.projects);
  const {
    fetchProjectsData,
    changeProjectDate,
    changeProjectStatus,
    setNotificationService,
  } = useProjectsStore((state) => ({
    fetchProjectsData: state.fetchProjectsData,
    changeProjectDate: state.changeProjectDate,
    changeProjectStatus: state.changeProjectStatus,
    setNotificationService: state.setNotificationService,
  }));
  const filters = useProjectsStore(
    (state) => ({
      status: state.status,
      setStatus: state.setStatus,
      order: state.order,
      setOrder: state.setOrder,
      category: state.category,
      setCategory: state.setCategory,
    }),
    shallow,
  );
  const { setActiveProjectId } = useProjectStore();

  const [rowCalendarVisibility, setRowCalendarVisibility] = useState();
  const [focusedProject, setFocusedProject] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchProjectsData(true);
    setNotificationService(props.NotificationService);
  }, []);

  const openProjectCreation = () => {
    setShowModal(true);
  };

  function angularRedirectToProject(project) {
    props.$state.go('app.main.projects-dashboard.planned-tasks', {
      projectId: project.id,
    });
    setActiveProjectId(project.id);
  }

  // eslint-disable-next-line
  function opactityProjectRow(project) {
    if (
      focusedProject &&
      project !== focusedProject &&
      projects.findIndex((element) => element.id === project.id) !==
        rowCalendarVisibility
    ) {
      return 'opactity-50';
    }
  }

  function handleDateClick(index) {
    setRowCalendarVisibility(index === rowCalendarVisibility ? null : index);
  }

  const tableStatusColumn = (project) => {
    if (
      ['administrador'].includes(
        JSON.parse(localStorage.getItem('loggedUser')).role,
      )
    ) {
      return (
        <td className="select" onClick={(e) => e.stopPropagation()}>
          <Select
            classNamePrefix="projects-select"
            className="projects-select project"
            isSearchable={false}
            onChange={(event) => {
              changeProjectStatus(event.value, project);
            }}
            value={project.status}
            placeholder={STATUS_TRANSLATION[project.status]}
            options={STATUS_OPTIONS}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </td>
      );
    }

    return <td className="select">{STATUS_TRANSLATION[project.status]}</td>;
  };
  const tableDateColumn = (project, index) => {
    if (
      ['administrador'].includes(
        JSON.parse(localStorage.getItem('loggedUser')).role,
      )
    ) {
      return (
        <td onClick={(e) => e.stopPropagation()}>
          {project.estimatedEndAt ? (
            <EndDateContainer
              className="has-date"
              width={'133px'}
              color={index === rowCalendarVisibility ? '#4F587A' : ''}
              onClick={() => {
                handleDateClick(index);
              }}
            >
              <span className="text">
                {moment(project.estimatedEndAt).format('DD / MM / YYYY')}
                <KeyboardArrowDownIcon
                  className="has-date__icon"
                  sx={{
                    verticalAlign: 'bottom',
                  }}
                />
              </span>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Calendar
                  disabled={index !== rowCalendarVisibility}
                  currentTime={moment(project.estimatedEndAt).toDate()}
                  className="has-date__calendar"
                  handleDateChange={changeProjectDate}
                  onClickOutside={() => {
                    setRowCalendarVisibility(null);
                  }}
                  project={project}
                />
              </div>
            </EndDateContainer>
          ) : (
            <EndDateContainer
              className="no-date"
              width={'123px'}
              color={index === rowCalendarVisibility ? '#4F587A' : ''}
              onClick={() => {
                handleDateClick(index);
              }}
            >
              <span className="text">
                <CalendarTodayIcon
                  className="no-date__icon"
                  sx={{
                    verticalAlign: 'bottom',
                  }}
                />
                Adicionar data
              </span>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Calendar
                  disabled={index !== rowCalendarVisibility}
                  currentTime={new Date()}
                  className="no-date__calendar"
                  handleDateChange={changeProjectDate}
                  onClickOutside={() => {
                    setRowCalendarVisibility(null);
                  }}
                  project={project}
                />
              </div>
            </EndDateContainer>
          )}
        </td>
      );
    }

    return (
      <td>
        <span className="text">
          {project.estimatedEndAt
            ? moment(project.estimatedEndAt).format('DD / MM / YYYY')
            : ''}
        </span>
      </td>
    );
  };

  function createProjectTableRow(project, index) {
    // Incase value is null, avoid NaN
    const durationIHNR = project.consumedHours ? project.consumedHours : 0;

    const remaining_hours =
      project.estimatedHours - durationIHNR > 0
        ? project.estimatedHours - durationIHNR
        : 0;

    const estimatedHours = project.estimatedHours || 1;

    const remaining_minutes = Math.floor(
      (remaining_hours - Math.floor(remaining_hours)) * 60,
    );
    const remaining_percentage = Math.round(
      (remaining_hours / estimatedHours) * 100,
    );
    const consumed_minutes = Math.floor(
      (durationIHNR - Math.floor(durationIHNR)) * 60,
    );
    const consumed_percentage = Math.round(
      (durationIHNR / estimatedHours) * 100,
    );

    // Avoid bar growing indefinitely
    const progressBarWidth =
      consumed_percentage < 100 ? consumed_percentage : 100;

    return (
      <tr
        key={project.id}
        style={{ '--project-color': project.tagColor }}
        onClick={() => angularRedirectToProject(project)}
        onMouseEnter={() => setFocusedProject(project)}
        onMouseLeave={() => setFocusedProject(null)}
        className={opactityProjectRow(project)}
      >
        <td>
          <span className="project-icon" />
          {project.name}
        </td>
        <td>
          {project.category === 'external' ? 'Projeto pago' : 'Projeto interno'}
        </td>
        {tableStatusColumn(project)}
        {project.category === 'external' ? (
          <td className="hours">
            <Progress>
              <ProgressBarContainer>
                <ProgressBar
                  tagColor="#4F587A"
                  width={`${progressBarWidth}%`}
                />
              </ProgressBarContainer>
              <Bubble className="bubble">
                <div>
                  <span>Horas estimadas: {project.estimatedHours}h</span>
                  <br />
                  <span>
                    Horas consumidas: {Math.floor(durationIHNR)}h e{' '}
                    {consumed_minutes}min ({consumed_percentage}%)
                  </span>
                  <br />
                  <span>
                    Horas restantes: {Math.floor(remaining_hours)}h e{' '}
                    {remaining_minutes}min ({remaining_percentage}%)
                  </span>
                  <br />
                </div>
              </Bubble>
            </Progress>
            <Percentage>
              <span className="hour">{Math.floor(durationIHNR)}h</span> de{' '}
              {project.estimatedHours}h ({consumed_percentage}%)
            </Percentage>
          </td>
        ) : (
          <td className="hours">
            <span className="hour">{Math.floor(durationIHNR)}h</span>{' '}
            trabalhadas
          </td>
        )}
        {tableDateColumn(project, index)}
      </tr>
    );
  }

  function createProjectTableBody(innerProjects) {
    const blankState = (
      <tr className="blank-state">
        <td>Não há projetos cadastrados</td>
      </tr>
    );

    const rows = innerProjects.map((project, index) =>
      createProjectTableRow(project, index),
    );

    return (
      <tbody className="project-table-body">
        {rows.length === 0 ? blankState : rows}
      </tbody>
    );
  }

  const ProjectNavigation = ({ options, selectedOptionValue, onClick }) => {
    const inactiveStyle = 'labor-btn project-navigation-btn';
    const activeStyle = `${inactiveStyle} project-navigation-btn_active`;
    return (
      <div className="project-navigation-btn-container">
        {options.map((option) => (
          <button
            className={
              option.value === selectedOptionValue ? activeStyle : inactiveStyle
            }
            key={option.value}
            onClick={() => onClick(option.value)}
          >
            <span>{option.label}</span>
          </button>
        ))}
      </div>
    );
  };

  ProjectNavigation.propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    selectedOptionValue: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div>
        <div className="project-header-section">
          <div className="project-header-title">
            <div>Projetos</div>
          </div>
          <div className="project-navigation">
            <ProjectNavigation
              options={FILTER_OPTIONS}
              selectedOptionValue={filters.status}
              onClick={filters.setStatus}
            />
            <Can do="create" of="Project">
              <button
                className="labor-btn primary icon-btn project-btn-add"
                onClick={openProjectCreation}
              >
                <span className="icon icon-svg icon-add-blue" />
                <span className="text">Novo Projeto</span>
              </button>
            </Can>
            <div className="project-header-line" />
          </div>
          <div className="project-filters">
            <div className="project-filter">
              <span>Tipo de projeto:</span>
              <ProjectsFilterSelect
                options={CATEGORY_OPTIONS}
                isSearchable={false}
                value={CATEGORY_OPTIONS.filter(
                  (option) => option.value === filters.category,
                )}
                onChange={(selected) => filters.setCategory(selected.value)}
              />
            </div>
            <div className="project-filter">
              <span>Ordenar por:</span>
              <ProjectsFilterSelect
                options={ORDER_OPTIONS}
                isSearchable={false}
                value={ORDER_OPTIONS.filter(
                  (option) => option.value === filters.order,
                )}
                onChange={(selected) => filters.setOrder(selected.value)}
              />
            </div>
          </div>
        </div>

        <table className="labor-table">
          <thead>
            <tr>
              <td width="30%">Nome do projeto</td>
              <td width="20%">Tipo de projeto</td>
              <td>Status</td>
              <td>Horas consumidas</td>
              <td>Data de término</td>
            </tr>
          </thead>
          {createProjectTableBody(projects)}
        </table>
      </div>
      {showModal ? ( // TODO move to the top page with return
        <NewProjectModal
          isOpen={showModal}
          NotificationService={props.NotificationService}
          onClose={() => {
            handleCloseModal();
            fetchProjectsData(true);
          }}
        />
      ) : null}
    </>
  );
}

Projects.propTypes = {
  ProjectService: PropTypes.shape({
    getFilteredProjects: PropTypes.func,
    saveProject: PropTypes.func,
  }),
  NotificationService: PropTypes.shape({
    showNotification: PropTypes.func,
  }),
  $state: PropTypes.shape({
    go: PropTypes.func,
  }),
  $scope: PropTypes.object.isRequired,
  $mdDialog: PropTypes.object.isRequired,
};
