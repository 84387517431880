import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  margin: 37px 0 40px 0;
  min-height: 340px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  background-color: white;
`;

export const SummaryHeader = styled.div`
  margin: 0px 50px;
  padding: 33px 0 27px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
`;

export const SummaryTitle = styled.div`
  font-size: 18px;
  height: 30px;
  color: #4f587a;
  margin-right: 10vw;
  font-weight: 600;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SummaryButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 15px 6px 15px;
  margin-left: 20px;
  background-color: #e8eaf3;
  border: none;
`;

export const SummaryMiddle = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 50px;
`;

export const SummaryText = styled.div`
  display: flex;
  flex-direction: column;
  width: 68%;
  margin: 5px 0 40px 0;
  justify-content: flex-start;
`;

interface Custom {
  customWidth?: string;
  customAlign?: string;
}

export const TableTitles = styled.div`
  display: flex;
  flex-direction: row;
  margin: 7px 50px 18px 50px;
  width: calc(100% - 100px);
`;

export const TableTitle = styled.div<Custom>`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #5f5f5f;
  width: ${(props) => props.customWidth};
  text-align: ${(props) => props.customAlign};
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
  border-top: 1px solid #eee;
  padding: 10px 0 0 0;
  justify-content: space-between;
`;

export const TotalItem = styled.div<Custom>`
  font-weight: 700;
  font-size: 14px;
  color: #5f5f5f;
  width: ${(props) => props.customWidth};
  text-align: ${(props) => props.customAlign};
`;

export const SummaryChart = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
`;

export const ChartTitle = styled.div`
  font-size: 18px;
  height: 30px;
  color: #4f587a;
  font-weight: 600;
`;

export const ChartContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  font-size: 14px;
  max-height: 180px;
  min-height: 180px;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const Table = styled.table`
  width: 100%;
  color: #959595;
  border-collapse: collapse;
  font-weight: normal;
  font-size: 16px;

  tr {
    td:nth-of-type(1) {
      width: 60%;
    }

    td:not(:first-of-type) {
      width: 20%;
    }

    width: 100%;
    height: 40px;
  }

  tr:not(:last-child) {
    border-bottom: solid 1px #efeff1;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    tr {
      td:nth-of-type(1) {
        margin-left: 10px;
      }

      td:last-of-type {
        margin-right: 10px;
      }
    }
  }
`;

const ProjectRowNotActive = css`
  opacity: 0.2;
`;

export const ProjectIcon = styled.span`
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid;
  margin-right: 10px;
  margin-bottom: 2px;
  color: ${(props) => props.color};
`;

export const ProjectRow = styled.tr`
  opacity: 1;
  ${(props: { active: boolean; someoneActive: boolean }) =>
    props.someoneActive && !props.active && ProjectRowNotActive}
`;

export const ProjectNameContainer = styled.div`
  text-align: left;
  font-size: 14px;
  color: #5f5f5f;
  margin-right: 10vw;
`;

export const ListTimeContainer = styled.div`
  text-align: right;
  font-size: 14px;
  color: #959595;
`;
