import React, { useRef } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { TitleText, SaveButton, ScrollInModal } from './styles';
import ProjectType from './ProjectType';
import ProjectData from './ProjectData';
import ProjectTeam from './ProjectTeam';
import Modal from '~/components/Modal';
import { unmaskPrice, initialCurrencyFormatter } from '~/app/utils/currency';
import API from '~/services/api';
import FormikScrollToError from '~/app/components/FormikScrollToError';
import NotificationServiceType from '~/models/ServicesTypes';

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    tagColor: Yup.string().nullable(),
    client: Yup.string().nullable(),
    estimatedStartAt: Yup.string().nullable(),
    estimatedEndAt: Yup.string()
      .nullable()
      .when('estimatedStartAt', {
        is: (estimatedStartAt: string) => !!estimatedStartAt,
        then: Yup.string()
          .nullable()
          .test(
            'after-estimated-start',
            'Data anterior à data início',
            (value, context) => {
              if (!value) return true;
              const startAt = moment(
                context.parent.estimatedStartAt,
                'DD/MM/YYYY',
              );
              const endAt = moment(value, 'DD/MM/YYYY');
              return endAt.isAfter(startAt);
            },
          ),
      }),
    category: Yup.string()
      .required('Campo obrigatório')
      .matches(/(external|internal)/, 'Tipo de projeto inválido'),
    status: Yup.string()
      .required('Campo obrigatório')
      .matches(/(not_started|running|finished)/, 'Status inválido'),
    estimatedHours: Yup.number().when('category', {
      is: 'internal',
      then: Yup.number().nullable(),
      otherwise: Yup.number()
        .min(1, 'Escolha um número maior que 0')
        .typeError('Número de horas estimadas inválido'),
    }),
    price: Yup.string().when('category', {
      is: 'internal',
      then: Yup.string().nullable(),
      otherwise: Yup.string().matches(
        /^R\$[^a-zA-Z]*$|^\d*$|^\d*.\d*$/,
        'Preço inválido',
      ),
    }),
    userIdList: Yup.array(),
  });

export type InitialValuesType = {
  name: string;
  tagColor: string | null;
  client: string | null;
  estimatedStartAt: string | null;
  estimatedEndAt: string | null;
  category: 'external' | 'internal';
  status: string;
  estimatedHours: number | null;
  price: number | string | null;
  userIdList: number[];
};

const formInitialValues: InitialValuesType = {
  name: '',
  tagColor: '#3D3389',
  client: '',
  estimatedStartAt: moment(new Date()).format('DD/MM/YYYY'),
  estimatedEndAt: '',
  category: 'external',
  status: 'not_started',
  estimatedHours: 10,
  price: initialCurrencyFormatter(0),
  userIdList: [],
};

const createProject = async (
  NotificationService: NotificationServiceType,
  values: typeof formInitialValues,
) => {
  const project = { ...values };
  project.estimatedHours = project.estimatedHours
    ? Number(String(project.estimatedHours).replace('h', ''))
    : null;
  project.price = project.price
    ? Number(unmaskPrice(project.price)) / 100
    : null;
  project.estimatedStartAt = project.estimatedStartAt
    ? moment(project.estimatedStartAt, 'DD/MM/YYYY').format('YYYY-MM-DD')
    : null;
  project.estimatedEndAt = project.estimatedEndAt
    ? moment(project.estimatedEndAt, 'DD/MM/YYYY').format('YYYY-MM-DD')
    : null;

  try {
    const response = await API.team.create_project(project);
    if (response.status === 201) {
      NotificationService.showNotification(
        'Projeto criado com sucesso!',
        'success',
      );
      return;
    }
    if (response.status === 422)
      throw new Error('Preencha os campos obrigatórios');

    if (response.status === 403)
      throw new Error(
        'Desculpe, no Plano Trial só são permitidos 2 projetos no time.',
      );
  } catch (error) {
    NotificationService.showNotification(error.message, 'error');
  }
};

interface NewProjectModalProps {
  NotificationService: NotificationServiceType;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

// TODO REFATORAR
const NewProjectModal = ({
  isOpen,
  onClose,
  NotificationService,
}: NewProjectModalProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      blurBackground
      overflowY="hidden"
      backgroundColor="#FFFFFF"
    >
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await createProject(NotificationService, values);
          onClose();
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <FormikScrollToError />
            <TitleText>Crie um novo projeto</TitleText>
            <hr />
            <ScrollInModal ref={ref}>
              <ProjectType
                name="category"
                typeData={values.category}
                onChange={setFieldValue}
                bold={true}
              />
              <ProjectData values={values} onChange={setFieldValue} />
              <ProjectTeam
                name={'userIdList'}
                values={values.userIdList}
                onChange={setFieldValue}
              />
              <div className="text-left">
                <SaveButton disabled={isSubmitting} type="submit">
                  Criar Projeto
                </SaveButton>
              </div>
            </ScrollInModal>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default NewProjectModal;
