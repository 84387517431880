import React, { useEffect, useState } from 'react';
import EmptyList from './EmptyList';
import TaskList from './TaskList';
import * as S from './styles';
import api from '~/services/api';
import useProjectStore from '~/store/projects';

const PlannedTasks = (props) => {
  const [plannedTasks, setPlannedTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { activeProjectId } = useProjectStore();
  const isAdmin =
    JSON.parse(localStorage.getItem('loggedUser') || '{}').role ===
    'administrador';

  const GetPlannedTasks = async () => {
    const response = await api.team.get_planned_tasks(activeProjectId);
    const data = await response.json();
    setPlannedTasks(data);
    setIsLoading(false);
  };
  useEffect(() => {
    GetPlannedTasks();
  }, []);
  return (
    !isLoading && (
      <S.Container>
        <EmptyList
          isAdmin={isAdmin}
          isEmpty={plannedTasks.length === 0}
          setPlannedTasks={setPlannedTasks}
        />
        <TaskList
          isAdmin={isAdmin}
          isEmpty={plannedTasks.length === 0}
          setPlannedTasks={setPlannedTasks}
          plannedTasks={plannedTasks}
        />
      </S.Container>
    )
  );
};

export default PlannedTasks;
