import angular from 'angular';

export default $mdDateLocaleProvider => {
  'ngInject';

  const $log = angular.injector(['ng']).get('$log');

  $mdDateLocaleProvider.months = [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ];
  $mdDateLocaleProvider.shortMonths = [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ];
  $mdDateLocaleProvider.days = [
    'domingo',
    'segunda',
    'terça',
    'quarta',
    'quinta',
    'sexta',
    'sábado',
  ];
  $mdDateLocaleProvider.shortDays = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S', 'D'];

  $mdDateLocaleProvider.parseDate = function(dateString) {
    if (!dateString) {
      return null;
    }

    const parts = dateString.split('/');

    const date = new Date();
    date.setDate(parseInt(parts[0]));
    date.setMonth(parseInt(parts[1]) - 1);
    date.setFullYear(parseInt(parts[2]));

    return date;
  };

  $mdDateLocaleProvider.formatDate = function(date) {
    if (!date) {
      return null;
    }

    // Fix problem with timezone
    date.setHours(date.getHours() + 3);

    const day = `0${date.getDate()}`;
    const month = `0${date.getMonth() + 1}`;
    const year = date.getFullYear();

    return `${`0${date.getDate()}`.slice(-2)}/${`0${date.getMonth() + 1}`.slice(
      -2,
    )}/${date.getFullYear()}`;
  };
};
