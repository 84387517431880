import angular from 'angular';
import { react2angular } from 'react2angular';

import TaskInput from '../../../../../../../react/app/TaskInput'
import { LoginService } from '../../users/login.service';

import './task-input.scss';

export const TaskInputModule = angular
  .module('root.components.app.components.main.tasks.taskInput', [])
  .component('taskInput', react2angular(TaskInput, [], ['TaskService', 'ProjectService', 'PlannedTaskService', '$rootScope', 'NotificationService', 'LoginService'])).name
