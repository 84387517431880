import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

const customStyles = {
  option: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  control: (provided) => ({
    ...provided,
    width: 'auto',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
    width: 'auto',
  }),
};

export default function Select(props) {
  return (
    <ReactSelect
      {...props}
      styles={props.customStyles ? props.customStyles : customStyles}
    />
  );
}

Select.propTypes = {
  customStyles: PropTypes.any,
};
