export class TimerService {
  constructor($interval, $filter, $log) {
    'ngInject';

    this.duration = '00:00:00';
    this.timer = null;
    this.isRunning = false;
    this.$interval = $interval;
    this.$filter = $filter;
    this.$log = $log;
  }

  startTimer(start = Date.now()) {
    this._initializeTimer(start);
  }

  resetTimer(start = Date.now()) {
    $interval.cancel(this.timer);
    this._initializeTimer(start);
  }

  stopTimer() {
    this.isRunning = false;
    this.$interval.cancel(this.timer);
    this.duration = '00:00:00';
  }

  _initializeTimer(start) {
    this.start = start;

    if (!angular.isDate(this.start)) {
      this.start = new Date(this.start);
    }

    this._changeDuration();

    if (!this.isRunning) {
      this.isRunning = true;
      this.timer = this.$interval(() => {
        this._changeDuration();
      }, 1000);
    }
  }

  _changeDuration() {
    this.duration = this.$filter('msToTime')(Date.now() - this.start.getTime());
  }
}
