import create from 'zustand';
import { persist } from 'zustand/middleware';

type OnboardingState = {
  stepIndex: number;
  isRunning: boolean;
  isDone: boolean;
  openWelcomeTourModal: boolean;
  onboardingPause: boolean;
  pauseModalFirstTime: boolean;
  modalIsWecome: boolean;
  isLoading: boolean;
  increaseStepIndex: () => void;
  decreaseStepIndex: () => void;
  changeStepIndexValue: (v: number) => void;
  changeIsRunning: () => void;
  changeIsDone: () => void;
  changeOpenWelcomeTourModal: (v: boolean) => void;
  changemodalIsWecome: (v: boolean) => void;
  changeOnboardingPause: (v: boolean) => void;
  changePauseModalFirstTime: (v: boolean) => void;
  changeIsLoading: (v: boolean) => void;
};

const useOnboardingStore = create<OnboardingState>(
  persist(
    (set) => ({
      stepIndex: 0,
      isRunning: false,
      isDone: false,
      openWelcomeTourModal: false,
      onboardingPause: false,
      pauseModalFirstTime: true,
      modalIsWecome: true,
      isLoading: false,
      increaseStepIndex: () =>
        set((state) => ({ stepIndex: state.stepIndex + 1 })),
      decreaseStepIndex: () =>
        set((state) => ({ stepIndex: state.stepIndex - 1 })),
      changeStepIndexValue: (v: number) => set(() => ({ stepIndex: v })),
      changeIsRunning: () => set((state) => ({ isRunning: !state.isRunning })),
      changeIsDone: () =>
        set(() => ({
          isDone: true,
          stepIndex: 0,
          isRunning: false,
          openWelcomeTourModal: false,
          onboardingPause: false,
          modalIsWecome: true,
        })),
      changeOpenWelcomeTourModal: (v: boolean) =>
        set(() => ({ openWelcomeTourModal: v })),
      changemodalIsWecome: (v: boolean) => set(() => ({ modalIsWecome: v })),
      changeOnboardingPause: (v: boolean) =>
        set(() => ({ onboardingPause: v })),
      changePauseModalFirstTime: (v: boolean) =>
        set(() => ({ pauseModalFirstTime: v })),
      changeIsLoading: (newStatus) => set(() => ({ isLoading: newStatus })),
    }),
    {
      name: 'useOnboardingStore',
    },
  ),
);

export default useOnboardingStore;
