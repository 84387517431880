/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';

import API from '~/services/api';
import Page from '~/site/components/Page';
import PageTitle from '~/site/components/PageTitle';
import Input from '~/components/Input';
import PasswordInput from '~/components/PasswordInput';
import Button from '~/components/Button';
import Container from '~/site/components/Container';
import AmplitudeUserStore from '~/services/api/amplitude';
import RegisterSvg from '~/site/RegisterPage/images/registerSvg'
import { Decrypt } from '~/helpers/encryption';

import * as S from './styles';

import RegisterSchema from './schema'

let amplitude;

const getParamsData = (query) =>{
  return JSON.parse('{"' + decodeURI(query.replace(/\?/g, "").replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}')
}


const CompleteRegisterPage = ({ $stateParams, AMPLITUDE_API_KEY, TOS_URL, UserService }) => {
  const [message, setMessage] = useState('');
  const [signinData, setSigniData] = useState({ team: '', email: '', id: '' });

  async function fechUser() {
    try {
      const userData = getParamsData(Decrypt($stateParams.userData, true))
      await UserService.validateCompleteSignin(userData)

      setSigniData(userData);
    } catch (e) {
      window.location.href = '/entrar';
    }
  }

  useEffect(() => {
    fechUser()
    amplitude = new AmplitudeUserStore(null, AMPLITUDE_API_KEY);
  }, []);

  async function doCompleteRegister(userData) {
    userData.id = signinData.id
    const response = await API.user.completeRegister(userData);

    const json = await response.json();

    if (response.status !== 201) {
      throw new Error(json.message);
    }

    const { user, rules } = json;

    const { headers } = response;

    function logRegister(_headers, _user) {
      const queryString = window.location.search;
      const deviceId = new URLSearchParams(queryString).get('amp_device_id');

      if (deviceId && deviceId !== 'null')
        amplitude.setAmplitudeUserDevice(deviceId);

      amplitude.setUserId(_headers.get('uid'));
      amplitude.setUserProperties({
        Team: _user.allocations[0].team.id,
      });
      amplitude.sendData('User Registered');
    }

    localStorage.removeItem('firstProjectCreated');
    localStorage.setItem('loggedUser', JSON.stringify(user));
    localStorage.setItem(
      'auth_headers',
      JSON.stringify({
        'access-token': headers.get('access-token'),
        'token-type': headers.get('token-type'),
        client: headers.get('client'),
        expiry: headers.get('expiry'),
        uid: headers.get('uid'),
      }),
    );
    localStorage.setItem('rules', JSON.stringify(rules));
    localStorage.setItem('currentConfigName', JSON.stringify('default'));

    logRegister(headers, user);

    window.location.href = '/app/main';
  }

  return (
    <Page>
      <Container>
        <S.FlexWrapper>
          <S.WelcomeBox>
            <PageTitle>Boas vindas!</PageTitle>
            <S.WelcomeText>
              Você recebeu um convite para o time <strong>{signinData.team}</strong>
            </S.WelcomeText>
            <S.WelcomeText>
              Falta pouco para registrar suas horas. Insira seu nome completo para concluir o cadastro.
            </S.WelcomeText>

            <S.WelcomeText>
            Não esqueça de guardar a senha que você recebeu por e-mail. <strong>Você pode criar uma nova assim que entrar.</strong>
            </S.WelcomeText>
            <RegisterSvg />
          </S.WelcomeBox>
          <S.FormBox>
            <Formik
              initialValues={{
                name: '',
                password: '',
              }}
              validationSchema={RegisterSchema}
              onSubmit={(values, { setSubmitting }) => {
                doCompleteRegister(values).catch(e => {
                  setMessage(e.message);
                });
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, setFieldValue, values, validateField, errors, isValid, touched }) => (
                <Form style={{ position: 'relative' }}>
                  <S.StyledSnackbar isVisible={!!message}>
                    {message}
                  </S.StyledSnackbar>
                  <Input
                    type="text"
                    name="email"
                    placeholder="E-mail"
                    value={signinData.email}
                    disabled={true}
                  />
                  <Input
                    type="text"
                    name="name"
                    placeholder="Nome completo"
                    value=""
                  />
                  <PasswordInput name="password" placeholder="Senha" value="" />
                  <S.TosText>Ao clicar em "Entrar" você concorda com os <a target="_blank" href={TOS_URL}>Termos de Serviço.</a></S.TosText>
                  <S.ButtonWrapper>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      style={{ minWidth: '168px' }}
                    >
                      Entrar
                    </Button>
                  </S.ButtonWrapper>
                </Form>
              )}
            </Formik>
          </S.FormBox>
        </S.FlexWrapper>
      </Container>
    </Page>
  );
}
export default CompleteRegisterPage;
