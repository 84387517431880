export default class Allocation {
  static get modelName() {
    return 'Allocation';
  }

  constructor(props) {
    Object.assign(this, props);
    this.user_id = props.user.id;
    this.team_id = props.team.id;
  }
}
