import angular from 'angular';
import { ProjectTrelloSettingsComponent } from './project-trello-settings.component';
import { TrelloBoardTileModule } from './trello-board-tile/trello-board-tile.module';
import { TrelloBoardSelectionModule } from './trello-board-selection/trello-board-selection.module';
import { TrelloBoardConnectionModule } from './trello-board-connection/trello-board-connection.module';
import TrelloSettings from '~/app/ProjectsPage/ProjectDashboard/TrelloSettings';
import { react2angular } from 'react2angular';

export const ProjectTrelloSettingsModule = angular
  .module(
    'root.components.app.components.main.projects.project-dashboard.project-trello-settings',
    [
      TrelloBoardTileModule,
      TrelloBoardSelectionModule,
      TrelloBoardConnectionModule,
    ],
  )
  .component('projectTrelloSettingsReact', react2angular(TrelloSettings,['projectId'])).name

