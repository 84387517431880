import styled from '@emotion/styled';

export const TitleText = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #5f5f5f;
  margin-bottom: 26px;
`;

export const SubTitleText = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin: 30px 0 16px 0;
  line-height: 22px;
  color: #5f5f5f;
`;

export const InputBox = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: row;
  height: 50px;
  border: 1px solid rgba(149, 149, 149, 0.2);
  box-sizing: border-box;
  padding: 15px 0px;
  background-color: white;
  align-items: center;
`;

export const GrayInputBox = styled(InputBox)`
  background-color: #f6f7fb;
`;

export const Input = styled.input`
  margin-left: 18px;
  width: 90%;
  border-width: 0px;
  border: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  &:focus-visible {
    outline: none;
  }
  margin-left: 18px;
`;

export const DateFieldContainer = styled.div`
  position: relative;
  flex: 1;
  &.description {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const Description = styled.div`
  margin: 5px 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`;

export const GrayText = styled.p`
  position: relative;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px 18px;
`;

export const Text = styled.p`
  margin: 2px 0 0 18px;
  color: black;
`;

export const DataContainer = styled.div`
  display: flex;
  gap: 4%;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    width: 48%;
    min-width: 210px;
    flex-grow: 1;
    @media (max-width: 600px) {
      min-width: 150px;
    }
  }
`;

export const TeamIndicator = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TeamText = styled.p`
  margin: 0px;
`;

export const UserCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  margin: 10px 5px;
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  background-color: #f6f7fb;
  &:hover {
    background-color: #4f587a;
  }
  &:hover p {
    color: #ffffff;
  }
  &:hover svg {
    color: #ffffff;
  }
`;

export const CardText = styled.p`
  color: #4f587a;
  position: relative;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CardTextContainer = styled.div`
  width: 77%;
  max-width: 180px;
  min-width: 0;
  position: relative;
  flex-grow: 1;
`;

export const ShowMoreButton = styled.p`
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
  color: #4f587a;
`;

export const RadioText = styled.p`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 6px;
`;

export const RadioLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ScrollInModal = styled.div`
  padding-right: 22px;
  margin-right: -22px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 700px;
  // firefox
  scrollbar-width: thin;
  scrollbar-color: #4f587a #d7d7d7;

  // webkit (edge/safari/chrome)
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 30px;
    background-color: #d7d7d7;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #4f587a;
  }
  @media screen {
    @media (max-height: 720px) { 
      height: 500px;
    }
    @media (max-height: 600px) { 
      height: 400px;
    }
  }
`;

export const SaveButton = styled.button`
  padding: 7px 20px 7px 20px;
  height: 35px;
  background-color: #ff3c58;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
`;
