import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as S from './styles';
import useReportsStore from '~/store/reports/reports';
import { ReportOrientation, ReportFormat } from '~/store/reports/types';
import NotificationServiceType from '~/models/ServicesTypes';

const DOWNLOAD_OPTIONS: {
  label: string;
  params: { orientation: ReportOrientation; format: ReportFormat };
}[] = [
  {
    label: 'PDF - Retrato',
    params: { orientation: 'Portrait', format: 'pdf' },
  },
  {
    label: 'PDF - Paisagem',
    params: { orientation: 'Landscape', format: 'pdf' },
  },
  {
    label: 'CSV',
    params: { orientation: '', format: 'csv' },
  },
];

type Props = {
  AMPLITUDE_API_KEY: string;
  NotificationService: NotificationServiceType;
};

const DownloadButton = ({ AMPLITUDE_API_KEY, NotificationService }: Props) => {
  const downloadReport = useReportsStore((state) => state.downloadReport);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
    null,
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (params: {
    orientation: ReportOrientation;
    format: ReportFormat;
  }) => {
    handleClose();
    const { orientation, format } = params;
    downloadReport(AMPLITUDE_API_KEY, NotificationService, orientation, format);
  };

  return (
    <>
      <S.SummaryButton onClick={handleClick}>
        <S.ButtonPrintIcon />
        <S.ButtonTitle>Baixar relatório</S.ButtonTitle>
      </S.SummaryButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {DOWNLOAD_OPTIONS.map((option) => (
          <MenuItem
            onClick={() => handleClickItem(option.params)}
            key={option.label}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DownloadButton;
