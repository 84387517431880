import moment from 'moment';

export const getDates = (
  reuseFirstSecondMonth?: boolean,
  startDate?: Date | null,
) => {
  let baseDate: Date;
  if (!reuseFirstSecondMonth || !startDate) {
    baseDate = new Date();
  } else baseDate = startDate;

  const firstDayThisMonth = new Date(baseDate);
  firstDayThisMonth.setDate(1);
  firstDayThisMonth.setHours(0, 0, 0, 0);

  const lastDayThisMonth = new Date(baseDate);
  lastDayThisMonth.setMonth(lastDayThisMonth.getMonth() + 1);
  lastDayThisMonth.setDate(0);
  lastDayThisMonth.setHours(0, 0, 0, 0);

  const firstDayNextMonth = new Date(baseDate);
  firstDayNextMonth.setMonth(firstDayThisMonth.getMonth() + 1);
  firstDayNextMonth.setDate(1);
  firstDayNextMonth.setHours(0, 0, 0, 0);

  return { firstDayThisMonth, lastDayThisMonth, firstDayNextMonth };
};

export const selectPeriodOption = (option: string) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  let starting: null | Date = null;
  let ending: null | Date = null;

  switch (option) {
    case 'whole':
      starting = null;
      ending = null;

      break;
    case 'last-7-days':
      starting = new Date(today);
      starting.setDate(today.getDate() - 6);
      ending = new Date(today);

      break;
    case 'month':
      starting = new Date(today.getFullYear(), today.getMonth(), 1);
      ending = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      break;
    case 'last-month':
      starting = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      ending = new Date(today.getFullYear(), today.getMonth(), 0);

      break;
    case 'last-3-months':
      starting = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        today.getDate(),
      );
      ending = new Date(today.getFullYear(), today.getMonth(), today.getDate());

      break;
    case 'last-6-months':
      starting = new Date(
        today.getFullYear(),
        today.getMonth() - 6,
        today.getDate(),
      );
      ending = new Date(today.getFullYear(), today.getMonth(), today.getDate());

      break;
    case 'year':
      starting = new Date(today.getFullYear(), 0, 1);
      ending = new Date(today.getFullYear() + 1, 0, 0);

      break;
    case 'last-year':
      starting = new Date(today.getFullYear() - 1, 0, 1);
      ending = new Date(today.getFullYear(), 0, 0);

      break;
    default:
      break;
  }

  return { starting, ending };
};

export const periodOptions = [
  {
    text: 'Mês atual',
    code: 'month',
  },
  {
    text: 'Últimos 7 dias',
    code: 'last-7-days',
  },
  {
    text: 'Último mês',
    code: 'last-month',
  },
  {
    text: 'Últimos 3 meses',
    code: 'last-3-months',
  },
  {
    text: 'Últimos 6 meses',
    code: 'last-6-months',
  },
  {
    text: 'Este ano',
    code: 'year',
  },
  {
    text: 'Último ano',
    code: 'last-year',
  },
  {
    text: 'Duração total do projeto',
    code: 'whole',
  },
];

export const selectPeriodName = (
  starting: Date | null,
  ending: Date | null,
) => {
  const daysDiff = moment(ending).diff(moment(starting), 'days');
  const monthsDiff = moment(Date.now()).diff(moment(starting), 'months');

  if (daysDiff === 7) return 'Últimos 7 dias';
  if (monthsDiff === 1) return 'Último mês';
  if (daysDiff != 7) return 'Mês atual';
  return 'Periodo Específico';
};
