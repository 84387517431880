import styled from '@emotion/styled';
import { css } from "@emotion/react";
import { mq } from '~/helpers/responsive';
import theme from '~/theme';

export const PageDiv = styled.div`
  background-color: ${theme.colors.containerBg}
`;

export const PageContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  })}
`;

export const Container = styled.div`
${mq({
  flex: 1,
  paddingTop: ['196px', '196px', '196px'],
})}
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 557px;
  height: 100%;
`;

export const GlobalStyle = css`
  * {
      box-sizing: border-box;
    }
`
