import React from 'react';
import styled from '@emotion/styled';
import Link from '../../../components/Link';
import Text from '../../../components/Text';
import { mq } from '../../../helpers/responsive';
import youtubeImg from './images/youtube.svg';
import instagramImg from './images/instagram.svg';
import heartImg from './images/heart.svg';

const BottomFooterGrid = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: ['row', 'row'],
    alignItems: ['flex-start', 'center'],
    justifyContent: ['space-between'],
  })}
`;

const CallToActionWrapper = styled.div`
  ${mq({
    flex: 1,
    textAlign: 'center',
    display: 'flex',
    width: '100%',
  })}
`;
const LoveTextWrapper = styled.div`
  ${mq({
    flex: 1,
    margin: ['5px 0 0', 0],
    textAlign: 'right',
  })}
`;

const LoveText = styled(Text)`
  ${mq({
    fontSize: ['13px', '15px'],
    display: 'inline',
  })}
`;

const LoveLink = styled(Link)`
  ${mq({
    fontSize: ['13px', '15px'],
  })}
`;

export default function BottomFooter() {
  return (
    <BottomFooterGrid>
      <CallToActionWrapper>
        <div>
          <Link href="https://www.instagram.com/get_labor" target="_blank">
            <img src={instagramImg} style={{ marginRight: '15px' }} />
          </Link>
          <Link
            href="https://www.youtube.com/channel/UCLw9e0ujAxCdSJEDLgTp5rg/videos"
            target="_blank"
          >
            <img src={youtubeImg} />
          </Link>
        </div>
      </CallToActionWrapper>
      <LoveTextWrapper>
        <LoveText>Feito com </LoveText>
        <img
          src={heartImg}
          style={{
            height: '13px',
            padding: '0 5px',
            position: 'relative',
            display: 'inline-block',
            top: '2px',
          }}
        />{' '}
        <LoveText>pela </LoveText>
        <LoveLink href="https://www.novatics.com.br" target="__blank">
          <strong>Novatics</strong>
        </LoveLink>
      </LoveTextWrapper>
    </BottomFooterGrid>
  );
}
