import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import RadioType from './RadioType';

const LabelP = styled.p`
  ${(props: { bold?: boolean }) =>
    props.bold &&
    css`
      font-weight: 600;
      font-size: 18px;
      margin-top: 15px;
      line-height: 26px;
      color: #5f5f5f;
    `}
`;

interface Props {
  name?: string;
  typeData?: string;
  onChange: Function;
  bold?: boolean;
}

const ProjectType: React.FC<Props> = ({ name, typeData, onChange, bold }) => (
  <div>
    <LabelP bold={bold}>
      <label htmlFor={name}>Tipo de projeto</label>
    </LabelP>
    <RadioType name={name} typeData={typeData} onChange={onChange} />
  </div>
);

export default ProjectType;
