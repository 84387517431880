import templateUrl from './dropdown.html';

export const Dropdown = (DropdownService, $log) => {
  'ngInject';

  return {
    require: 'ngModel',
    template: templateUrl,
    restrict: 'E',
    scope: {
      placeholder: '@',
      ngRequired: '=',
      options: '=',
      valueAttribute: '@',
      onChange: '&',
    },
    link: (scope, element, attrs, ngModelCtrl) => {
      const identifier = DropdownService.register(scope);

      scope.isOpened = false;

      scope.setSelect = function(option) {
        if (!option || !scope.options || !scope.options.length) {
          return;
        }

        scope.removeSelected();
        scope.placeholder = option.name;
        scope.isOpened = false;
        option.selected = true;

        if (scope.valueAttribute) {
          ngModelCtrl.$setViewValue(option[scope.valueAttribute]);
        } else {
          ngModelCtrl.$setViewValue(option);
        }

        scope.onChange({ option });
      };

      scope.$watch('options', () => {
        refreshSelected();
      });

      ngModelCtrl.$render = function() {
        refreshSelected();
      };

      function refreshSelected() {
        if (scope.valueAttribute && scope.options) {
          const option = scope.options.find(option => {
            return option[scope.valueAttribute] == ngModelCtrl.$viewValue;
          });
          scope.setSelect(option);
        } else {
          scope.setSelect(ngModelCtrl.$viewValue);
        }
      }

      scope.removeSelected = function() {
        scope.options.forEach(option => {
          option.selected = false;
        });
      };

      scope.openDropbox = function($event) {
        const openedState = scope.isOpened;
        $event.stopPropagation();

        DropdownService.closeAll(); // permite apenas um dropdown aberto.
        scope.isOpened = !openedState; // toggle
      };

      scope.$on('$destroy', function() {
        DropdownService.unregister(identifier);
      });

      // Se não tiver um placeholder definido,
      // pegar o primeiro elemento da lista.
      if (
        !scope.placeholder &&
        !!scope.options &&
        scope.options instanceof Array &&
        scope.options.length > 0
      ) {
        scope.setSelect(scope.options[0]);
      }

      // Adiciona parsers para funcionar com validação do formulário
      ngModelCtrl.$parsers.unshift(function(value) {
        ngModelCtrl.$setValidity('required', !!value);

        return value;
      });
    },
  };
};
