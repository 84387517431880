export const MsToHoursFilter = () => {
  'ngInject';

  return milisseconds => {
    const oneSecond = 1000;
    const oneMinute = oneSecond * 60;
    const oneHour = oneMinute * 60;

    return milisseconds / oneHour;
  };
};
