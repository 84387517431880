export class PopoverService {
  constructor($rootScope, $document, $timeout, $compile, $log) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$document = $document;
    this.$log = $log;
    this.$compile = $compile;
    this.$timeout = $timeout;

    this.popover = this.createPopover();
    this.$document.on('click', e => this.onDocumentClick(e));
    this.target = null;

    this.$document.on('keydown', event => {
      const ESC_KEY = 27;
      if (event.which === ESC_KEY) {
        this.closeAll();
      }
    });
  }

  createPopover() {
    const body = this.$document.find('body').eq(0);
    const popover = angular.element('<div class="lb-popover"></div>');
    body.append(popover);

    popover.on('click', e => e.stopPropagation());

    return popover;
  }

  closeAll() {
    this.close();
    this.$rootScope.$broadcast('popover-close-all');
  }

  onDocumentClick(e) {
    if (!e || this.popover.css('display') === 'none') return;

    if (e.target !== this.target) {
      this.close();
    }
  }

  open({ event, scope, template, style }) {
    this.$document.on('click', e => this.onDocumentClick(e));
    if (this.popover.css('display') == 'block') this.close();

    this.target = event.target;

    this.popover.append(this.$compile(template)(scope));
    this.popover.attr('style', getStyle(event, style));
    this.popover.css('display', 'block');
  }

  close() {
    this.$rootScope.$broadcast(
      'lb-popover-closed',
      this.popover.children(':first').scope(),
    );
    this.popover.css('display', 'none');
    this.popover.html('');
    this.target = null;
  }
}

function getHtml(event, template) {
  return `<div class="lb-popover" style="${getStyle(event)}">${template}</div>`;
}

function getStyle(event, style) {
  let {
    top,
    left,
    width,
    height,
  } = event.currentTarget.getBoundingClientRect();
  top = top + height + 16;
  left += width / 2;

  let styleStr = `top: ${top}px; left: ${left}px;`;

  if (style && style.width) {
    const width = style.width || 250;
    const marginLeft = width / 2;

    styleStr += `width: ${width}px; margin-left: -${marginLeft}px`;
  }

  return styleStr;
}
