import CryptoJS from 'crypto-js';

export const Encrypt = (token) => {
  const iv = CryptoJS.enc.Base64.parse(INTERNAL_CRYPT_INITIALIZATION_VECTOR);
  const key = CryptoJS.enc.Hex.parse(INTERNAL_CRYPT_KEY);

  return CryptoJS.AES.encrypt(token, key, {
    mode: CryptoJS.mode.CBC,
    keySize: 32,
    iv,
  }).toString();
};

export const Decrypt = (token, urlSafe = false) => {
  const iv = CryptoJS.enc.Base64.parse(INTERNAL_CRYPT_INITIALIZATION_VECTOR);
  const key = CryptoJS.enc.Hex.parse(INTERNAL_CRYPT_KEY);
  const data = urlSafe ? token.replace(/_/g, '/').replace(/-/g, '+') : token;

  return CryptoJS.AES.decrypt(data, key, {
    keySize: 32,
    iv,
    mode: CryptoJS.mode.CBC,
  }).toString(CryptoJS.enc.Utf8);
};
