import React, { useState } from 'react';
import { RootScope, State } from '~/models/types';
import NotificationServiceType, {
  UserServiceType,
  LoginServiceType,
  TeamServiceType,
} from '~/models/ServicesTypes';
import { ProjectService } from '~/models/types'
import { SidebarData } from './data';
import { SidebarProject } from './data';
import Menu from './Menu';
import BottomMenu from './BottomMenu';
import * as S from './styles';

interface SideBarProps {
  NotificationService: NotificationServiceType;
  UserService: UserServiceType;
  LoginService: LoginServiceType;
  TeamService: TeamServiceType;
  $rootScope: RootScope;
  $state: State;
  ProjectService: ProjectService;
}

const SideBar: React.FC<SideBarProps> = ({
  $state,
  UserService,
  NotificationService,
  TeamService,
  LoginService,
  $rootScope,
  ProjectService
}: SideBarProps) => {
  const [route, setRoute] = useState($state.router.globals.$current.name);

  return (
    <S.Container>
      <S.SideBarContainer>
        <Menu
          UserService={UserService}
          $state={$state}
          itemMenu={SidebarData}
          projectMenu = {SidebarProject}
          route={route}
          setRoute={setRoute}
          ProjectService={ProjectService}
        />

        <BottomMenu
          UserService={UserService}
          LoginService={LoginService}
          TeamService={TeamService}
          NotificationService={NotificationService}
          $rootScope={$rootScope}
          $state={$state}
          setRoute={setRoute}
        />
      </S.SideBarContainer>
    </S.Container>
  );
};

export default SideBar;
