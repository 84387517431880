import React, { useState, useEffect } from 'react';
import PageTitle from '~/site/components/PageTitle';
import ErrorPage from '~/components/ErrorPage';

import { LaborLogoSvg } from '~/icons';

import * as S from './styles';
import * as FS from '~/site/RegisterInvitedPage/styles';


interface ErrorLinkProps { }

const ErrorLink: React.FC<ErrorLinkProps> = ({ }: 
  ErrorLinkProps) => (
  <ErrorPage>
    <S.HomePageLink href={`${SITE_URL}`} callToAction={true}>
      <LaborLogoSvg width="137" height="40"/>
    </S.HomePageLink>
    <PageTitle>Algo deu errado!</PageTitle>
    <FS.WelcomeText>
      O link que você tentou acessar parece estar incorreto. Verifique o convite que você recebeu e tente novamente. 
    </FS.WelcomeText>
    <FS.WelcomeText>
      Se o problema continuar acontecendo, entre em contato com o (a) administrador (a) do seu time.
    </FS.WelcomeText>
  </ErrorPage>
);


export default ErrorLink;