import styled from '@emotion/styled';
import { mq } from '~/helpers/responsive';
import Link from '~/components/Link';

export const Container = styled.div`
  height: 100%;
`;

export const SideBarContainer = styled.div`
  height: 100%;
  position: static;
  display: flex;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ItemLink = styled(Link)`
  ${mq({
    fontSize: ['13px', '15px'],
  })}
`;
