import templateUrl from './trello-board-connection.html';
import './trello-board-connection.scss';

const DEFAULT_TODO_LIST_NAME = 'A Fazer';
const DEFAULT_DOING_LIST_NAME = 'Em andamento';
const DEFAULT_DONE_LIST_NAME = 'Finalizadas';

export const TrelloBoardConnectionComponent = {
  template: templateUrl,
  bindings: {
    project: '=',
    board: '=',
    onSave: '&',
  },
  controller: class TrelloBoardConnectionComponent {
    constructor($log, $mdDialog, TrelloService) {
      'ngInject';

      this.$log = $log;
      this.$mdDialog = $mdDialog;
      this.TrelloService = TrelloService;
    }

    todoListName = DEFAULT_TODO_LIST_NAME;
    doneListName = DEFAULT_DONE_LIST_NAME;

    $onInit() {
      if (this.board) {
        this.TrelloService.getLists(this.board.id).then(lists => {
          this.lists = lists;
        })
      } else if (this.project) {
        this.boardName = this.project.name;
      }
    }

    save() {
      if (this.board) {
        this.$mdDialog.hide({
          board: this.board,
          todoList: this.selectedTodoList,
          doneList: this.selectedDoneList,
        });
      } else {
        const useDefaultLists = false;
        this.TrelloService.createBoard(this.project, useDefaultLists).then(board => {
          this.TrelloService.createList(DEFAULT_DOING_LIST_NAME, board.id, 'DOING').then(() => {
            Promise.all([
              this.TrelloService.createList(this.todoListName, board.id, 'TO-DO'),
              this.TrelloService.createList(this.doneListName, board.id, 'DONE'),
            ]).then(lists => {
              this.$mdDialog.hide({
                board,
                todoList: lists[0],
                doneList: lists[1],
              });
            });
          });
        });
      }
    }

    cancel() {
      this.$mdDialog.cancel();
    }
  },
};
;
