export const HeaderInterceptorFactory = ($log, $state, $rootScope, TRELLO_API_URL) => {
  'ngInject';

  return {
    request: req => {
      if (!req.url.startsWith(TRELLO_API_URL)) {
        // TODO: remove this old header (X-Key-Inflection). I keept it only for deploy
        req.headers['X-Key-Inflection'] = 'camel';
        req.headers['Key-Inflection'] = 'camel';
      } else {
        delete req.headers['If-Modified-Since'];
      }
      return req;
    },
    response: resp => {
      $rootScope.trialSubscription = resp.headers('trial-period') === 'true';
      $rootScope.needToLogout = resp.headers('need-to-logout') === 'true';
      $rootScope.inactiveSubscription = resp.headers('active-subscription') === 'false';

      $rootScope.hasPendingInvoices = resp.headers('pending-invoices') === 'true';

      if ($rootScope.inactiveSubscription) {
        if ($state.current.name !== 'app.main.subscribe') {
          $state.go('app.main.subscribe');
        }
      }

      if ($rootScope.needToLogout) {
        $state.go('app.main.logout');
      }

      if (!$rootScope.inactiveSubscription && $rootScope.hasPendingInvoices) {
        if ($state.current.name !== 'app.main.payments') {
          // TODO: use this only for Novatics
          // $state.go('app.main.payments');
        }
      }

      return resp;
    },
  };
};
