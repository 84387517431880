import React, {CSSProperties, FunctionComponent} from 'react';
import styled from '@emotion/styled';
import { Field as FormikField } from 'formik';
import PropTypes from 'prop-types';

interface CheckboxProps {
  name: string;
  label: string;
  style: CSSProperties;
}

interface CheckboxHandlerProps {
  label: string
  style?: CSSProperties;
  error?: string;
  children?: FunctionComponent;
  name: string;
  checked: boolean;
}
const Wrapper = styled.label`
  user-select: none;
  margin-bottom: 1rem;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
`;

const Label = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7d7d7d;
`;

const StyledCheckbox = styled.input`
  margin: 0 5px 0 0;
`;

const ErrorMessage = styled.div`
  color: #ff6340;
  font-size: 12px;
  font-weight: 500;
  padding: 0.125rem 0;
`;

function CheckboxHandler({label, style, children, error, ...otherProps}: CheckboxHandlerProps) {

  return (
    <Wrapper style={style}>
      <StyledCheckbox type="checkbox" {...otherProps} />
      <div>
        {(children || label) && <Label>{children || label}</Label>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    </Wrapper>
  );
}


export default function Checkbox({name,label , style, ...otherProps} : CheckboxProps) {

  return (
    <FormikField name={name}>
      {({ field, form }) => (
        <CheckboxHandler
          {...otherProps}
          label={label}
          name={field.name}
          checked={field.value}
          error={form.touched[field.name] && form.errors[field.name]}
        />
      )}
    </FormikField>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
};
