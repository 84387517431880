import styled from '@emotion/styled';
import reactSelect from 'react-select';

export const TableContainer = styled.div`
  white-space: nowrap;
  overflow-x: auto;
  margin-bottom: 40px;
`;

export const Table = styled.table`
  width: 100%;
  color: #5f5f5f;
  border-collapse: collapse;
  font-weight: normal;
  font-size: 16px;

  tr {
    td:nth-of-type(1) {
      padding-left: 44px;
    }

    td:last-of-type {
      padding-right: 44px;
      text-align: center;
    }

    width: 100%;
    height: 49px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    tr {
      td:nth-of-type(1) {
        padding-left: 10px;
      }

      td:last-of-type {
        padding-right: 10px;
      }
    }
  }
`;

export const THead = styled.thead`
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #5f5f5f;
`;

export const Tbody = styled.tbody`
  tr {
    border-top: solid 1px rgba(149, 149, 149, 0.2);
    border-bottom: solid 1px rgba(149, 149, 149, 0.2);
    background-color: white;
  }
`;

export const BlankStateTr = styled.tr`
  border: none;
  background-color: inherit;
  text-align: center;
`;

export const ProjectNameContainer = styled.div`
  font-size: 12.8px;
  color: ${(props) => props.color};
`;
export const ProjectName = styled.span`
  color: #7d7d7d;
`;
export const ProjectIcon = styled.span`
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid;
  margin-right: 10px;
  margin-bottom: 2px;
`;
export const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #5f5f5f;
  margin-left: 44px;
  @media (max-width: 900px) {
    margin-left: 10px;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
`;
export const SelectContainer = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #7d7d7d;
`;
export const Select = styled(reactSelect)`
  -webkit-appearance: none;
  border: none;
  background: none;
  color: #5f5f5f;
  text-align: left;
  background-color: white;
  width: 170px;
  height: 32px;
  font-size: 12px;
  display: inline-block;
  margin-left: 8px;
  margin-right: 49px;
  border-radius: 0;

  .OrderSelect__single-value {
    margin-left: 2px;
    margin-right: 2px;
    overflow: visible;
    color: #5f5f5f;
    font-weight: 400;
  }
  .OrderSelect__control {
    background-color: inherit;
    border: 1px solid rgba(149, 149, 149, 0.2);
    border-radius: 0;
    color: #5f5f5f;
    font-weight: 400;

    :hover {
      border: 1px solid #4f587a;
    }
  }
  .OrderSelect__control--menu-is-open {
    box-shadow: none;
    border: 1px solid #4f587a;
  }

  .OrderSelect__control--is-focused {
    box-shadow: none;
  }
  .OrderSelect__menu {
    background-color: inherit;
    border: 1px solid rgba(149, 149, 149, 0.2);
    border-radius: 0;
    text-align: left;
    color: #5f5f5f;
    font-weight: 400;
  }
  .OrderSelect__indicators {
    .OrderSelect__dropdown-indicator {
      color: #7d7d7d;
    }
  }
  .OrderSelect__value-container {
    margin-left: 4px;
    overflow: visible;
    width: 21px;
  }
`;
