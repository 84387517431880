import React from 'react';
import PropTypes from 'prop-types';
import { Surface, Symbols } from 'recharts';

export default function CustomLegendContent(props) {
  const { payload } = props;

  return (
    <div className="customized-legend" style={{ textAlign: 'center' }}>
      {payload.map((entry) => {
        const { value, dataKey, color } = entry;
        return (
          <span
            className="legend-item"
            key={`overlay-${dataKey}`}
            style={{ marginRight: 20 }}
          >
            <Surface width={10} height={10}>
              <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
            </Surface>
            <span style={{ fontSize: '0.9rem', marginLeft: 5 }}>{value}</span>
          </span>
        );
      })}
    </div>
  );
}

CustomLegendContent.propTypes = {
  payload: PropTypes.object,
};
