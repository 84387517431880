import React, { useState, useEffect, useRef } from 'react';
import MonthPicker from '~/app/components/CalendarPicker/MonthPicker';
import DatePicker from '~/app/components/TimerCalendar/DatePicker';
import * as S from '~/app/components/TimerCalendar/styles';
import styled from '@emotion/styled';

const Calendarbox = styled.div`
  position: absolute;
  background-color: white;
  right: 63%;
  top: 100%;
  transform: translate(25%, 0);
  z-index: 100;
  box-shadow: 0px 2px 11px rgba(149, 149, 149, 0.2);
  display: flex;
  align-items: center;
  &.no-date__calendar {
    right: 67%;
  }
  .month {
    font-size: 14px;
    padding: 20px 0 20px 0;
    div {
      margin-bottom: 0;
    }
  }
  .days {
    padding: 0px 5px 10px 10px;
  }
  &.creating_project {
    right: 22%;
    width: 315px;
    height: 340px;
    min-height: 0px;
    top: 115%;
  }
`;
interface Props {
  disabled?: boolean;
  currentTime: Date;
  name?: string;
  className?: string;
  project?: object;
  index?: number;
  onClickOutside: () => void;
  handleDateChange?: (
    currentDate: Date,
    project: object,
    index: number,
  ) => void;
  handleDateChangeModal?: (currentDate: Date) => void;
}

export default function Calendar({
  currentTime,
  name,
  className,
  project,
  index,
  disabled,
  onClickOutside,
  handleDateChange,
  handleDateChangeModal,
}: Props) {
  const [date, setDate] = useState(currentTime);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
        event.stopPropagation();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <div>
      {!disabled && (
        <Calendarbox
          name={name}
          className={className}
          ref={ref}
          onClick={(e) => e.stopPropagation}
        >
          <S.CalendarContainer style={{ marginLeft: 0 }}>
            <div className="month">
              <MonthPicker month={date} onChange={(month) => setDate(month)} />
            </div>
            <div className="days">
              <DatePicker
                month={date}
                currentDate={date}
                setCurrentDate={(date) => {
                  setDate(date);
                  handleDateChange && handleDateChange(date, project, index);
                  handleDateChangeModal && handleDateChangeModal(date);
                }}
              />
            </div>
          </S.CalendarContainer>
        </Calendarbox>
      )}
    </div>
  );
}
