import angular from 'angular';
import { AllocationService } from './allocation.service';
import { CheckVersionService } from './check-version.service';
import { LevelService } from './level.service';
import { NotificationService } from './notification.service'
import { TimerService } from './timer.service';
import { TeamService } from './teams.service';
import { TourService } from './tour.service';
import { UtilsService } from './utils.service';
import { WebsocketService } from './websocket.service';
import { FirebaseService } from './firebase.service';
import { TrelloService } from './trello.service';
import { InternalService } from './internal.service';
import { ProjectAllocationService } from './project-allocation.service'

export const ServicesModule = angular
  .module('root.components.app.common.services', [])
  .service('AllocationService', AllocationService)
  .service('CheckVersionService', CheckVersionService)
  .service('LevelService', LevelService)
  .service('TimerService', TimerService)
  .service('TeamService', TeamService)
  .service('TourService', TourService)
  .service('NotificationService', NotificationService)
  .service('UtilsService', UtilsService)
  .service('WebsocketService', WebsocketService)
  .service('FirebaseService', FirebaseService)
  .service('TrelloService', TrelloService)
  .service('InternalService', InternalService)
  .service('ProjectAllocationService', ProjectAllocationService)
  .name;
