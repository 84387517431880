import AuthAPI from './auth';
import UserAPI from './user';
import TeamAPI from './team';
import ReportAPI from './report';
import ProjectAPI from './project';
import TrelloAPI from './trello';

class API {
  auth = AuthAPI;

  user = UserAPI;

  team = TeamAPI;

  report = ReportAPI;

  project = ProjectAPI;

  trello = TrelloAPI;
}

export default new API();
