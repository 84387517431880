import React from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import Link from '../../../components/Link';

import { CookieConsentButton, CookieContainer, CookieText } from './styles';

function CookiesBanner({ COOKIES_PRIVACY_URL }) {
  const [cookies, setCookie] = useCookies(['acceptedCookies']);
  const handleCookiesAcceptedClick = () => {
    setCookie('acceptedCookies', true, {
      path: '/',
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  };

  return (
    <>
      {!cookies.acceptedCookies && (
        <CookieContainer>
          <CookieText>
            Cookies: guardamos estatísticas de visitas para melhorar sua
            experiência de navegação, saiba mais em nossa{' '}
            <Link
              style={{ fontSize: '16px' }}
              target="_blank"
              href={COOKIES_PRIVACY_URL}
              callToAction={true}
            >
              política de privacidade
            </Link>
            .
          </CookieText>
          <CookieConsentButton onClick={() => handleCookiesAcceptedClick()}>
            Entendi
          </CookieConsentButton>
        </CookieContainer>
      )}
    </>
  );
}

CookiesBanner.propTypes = {
  COOKIES_PRIVACY_URL: PropTypes.string,
};

export default CookiesBanner;
