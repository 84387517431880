import firebase from 'firebase/compat/app';

export default FIREBASE_SENDER_ID => {
  'ngInject';
  
  firebase.initializeApp({
    messagingSenderId: FIREBASE_SENDER_ID
  });

};
