import templateUrl from './datetimepicker.html';
import './datetimepicker.scss';

export const DatetimepickerComponent = {
  template: templateUrl,
  bindings: {
    task: '=',
    running: '<',
  },
  controller: class DatetimepickerController {
    constructor($log) {
      'ngInject';

      this.$log = $log;
    }

    $onInit() {
      this.today = new Date();

      if (this.task.start) {
        this.task.start = angular.isDate(this.task.start)
          ? this.task.start
          : new Date(this.task.start);
      } else {
        this.task.start = this.today;
        if (!this.running) {
          this.task.end = this.today;
        }
      }

      this.currentMonth = new Date(
        this.task.start.getFullYear(),
        this.task.start.getMonth(),
        1,
      );

      if (this.task.end) {
        this.task.end = angular.isDate(this.task.end)
          ? this.task.end
          : new Date(this.task.end);
      }

      this.weekDays = this._getDaysInMonth(
        this.task.start.getFullYear(),
        this.task.start.getMonth(),
      );
    }

    previousMonth() {
      this.currentMonth.setMonth(this.currentMonth.getMonth() - 1);
      this.weekDays = this._getDaysInMonth(
        this.currentMonth.getFullYear(),
        this.currentMonth.getMonth(),
      );
    }

    nextMonth() {
      this.currentMonth.setMonth(this.currentMonth.getMonth() + 1);
      this.weekDays = this._getDaysInMonth(
        this.currentMonth.getFullYear(),
        this.currentMonth.getMonth(),
      );
    }

    changeDay(date) {
      this.task.start.setFullYear(date.getFullYear());
      this.task.start.setMonth(date.getMonth());
      this.task.start.setDate(date.getDate());

      if (this.task.end) {
        this.task.end.setFullYear(date.getFullYear());
        this.task.end.setMonth(date.getMonth());
        this.task.end.setDate(date.getDate());
      }
    }

    _getDaysInMonth(year, month) {
      const result = [];

      const firstMonthDay = new Date(year, month, 1);
      const lastMonthDay = new Date(year, month + 1, 0);
      const firstCalendarDay = new Date(firstMonthDay.getTime());
      firstCalendarDay.setDate(
        firstMonthDay.getDate() - firstMonthDay.getDay(),
      );
      const numberOfWeeks = Math.ceil(
        (firstMonthDay.getDay() + lastMonthDay.getDate()) / 7,
      );

      const date = new Date(firstCalendarDay.getTime());
      for (let i = 0; i < numberOfWeeks; i++) {
        result.push([]);
        for (let j = 0; j < 7; j++) {
          result[i].push(new Date(date.getTime()));
          date.setDate(date.getDate() + 1);
        }
      }

      return result;
    }
  },
};
