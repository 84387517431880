import styled from '@emotion/styled';

export const PeriodContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CalendarContainer = styled.div`
  flex: 1;
  margin: 0 15px;
  &:first-of-type {
    margin-left: 0;
  }
`;

export const OptionsWrapper = styled.div`
  border-top: 2px solid #f4f4f4;
  margin-top: 30px;
  padding-top: 20px;
`;

export const OptionsTable = styled.table`
  color: #7d7d7d;
  clear: both;
  width: 100%;
  text-align: center;
  font-size: 0.9em;
  font-weight: 600;
`;

export const Option = styled.td`
  &:hover {
    color: #ff3c58;
    cursor: pointer;
    outline: none;
  }
`;
