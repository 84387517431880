import React from 'react';
import PropTypes from 'prop-types';

import Modal, { ModalTitle, ModalText } from '../../../../components/Modal';
import Button from '../../../../components/Button';

const PaymentModal = ({ payData, onClose, onConfirm }) => (
  <Modal isOpen={payData.open} onRequestClose={onClose} width="538px">
    <ModalTitle>{payData.header}</ModalTitle>
    <ModalText>
      Você deseja confirmar o <strong>pagamento</strong> desta fatura?
    </ModalText>
    <div className="text-center">
      <Button onClick={onConfirm}>Confirmar</Button>
    </div>
  </Modal>
);

PaymentModal.propTypes = {
  payData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default PaymentModal;
