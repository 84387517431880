export const EditableAsInputDirective = (
  $rootScope,
  $document,
  $compile,
  $log,
  $timeout,
  EventEmitter,
) => {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      editableAsInput: '<',
      editableInputType: '@',
      editableAttribute: '@',
      editableSuffix: '@',
      editableStartOpened: '<',
      editableIndex: '<',
      editablePlaceholder: '@',
      onChangeFunction: '&',
    },
    link: (scope, element, attrs) => {
      let input = null;
      let span = null;
      let closeListener = null;
      scope.objectClone = angular.copy(scope.editableAsInput);
      scope.textClone = angular.copy(
        scope.objectClone[scope.editableAttribute],
      );

      onInit();

      // //////////////

      function onInit() {
        scope.$watch(
          'editableAsInput',
          () => {
            scope.objectClone = angular.copy(scope.editableAsInput);
            scope.textClone = angular.copy(
              scope.objectClone[scope.editableAttribute],
            );
          },
          true,
        );

        element.bind('click', e => {
          e.stopPropagation();
          showInput();
        });

        $timeout(() => {
          if (scope.editableStartOpened) {
            showInput();
          } else {
            hideInput();
          }
        });
      }

      function hideInput(text) {
        if (input) {
          input.remove();
        }

        span = $compile('<span></span>')(scope);

        if (text) {
          span.append(formatType(text));
        } else {
          span.append(formatType(scope.textClone || scope.editablePlaceholder));
        }

        if (scope.editableSuffix) {
          span.append(scope.editableSuffix);
        }

        element.html('');
        element.append(span);
        element.prop('editable-as-input-on', 'false');
        scope.$emit('editable-as-input-closed');

        function formatType(value) {
          return scope.editableInputType == 'number'
            ? value.toLocaleString('pt')
            : value;
        }
      }

      function showInput() {
        $rootScope.$broadcast('editable-as-input.opening');
        $document.on('click', submitHandler);

        closeListener = scope.$on('editable-as-input.opening', () => {
          submitHandler();
        });

        if (element.prop('editable-as-input-on') == 'true') {
          return;
        }

        let inputDefinition = null;
        if (scope.editableInputType && scope.editableInputType == 'number') {
          inputDefinition = `<input type="number" ng-model="textClone" ${
            scope.editablePlaceholder
              ? `placeholder="${scope.editablePlaceholder}"`
              : ''
          }>`;
        } else {
          inputDefinition = `<input type="text" ng-model="textClone" ${
            scope.editablePlaceholder
              ? `placeholder="${scope.editablePlaceholder}"`
              : ''
          }>`;
        }

        input = $compile(inputDefinition)(scope);
        element.html('');
        element.append(input);
        input.focus();
        element.prop('editable-as-input-on', 'true');
        scope.$emit('editable-as-input-opened');

        input.on('click', e => {
          e.stopPropagation();
        });

        input.bind('keydown keypress', submitHandler);
      }

      function submitHandler(event) {
        if (
          !event ||
          event.type == 'click' ||
          event.which === 13 ||
          event.which === 27
        ) {
          callOnChangeFunction(element, input, event);
        }
      }

      function callOnChangeFunction(element, input, event) {
        $document.off('click');

        if (scope.editableInputType && scope.editableInputType == 'number') {
          scope.textClone = parseFloat(input.val());
        } else {
          scope.textClone = input.val();
        }

        hideInput();

        if (
          scope.objectClone[scope.editableAttribute] != scope.textClone &&
          (!event || event.which != 27)
        ) {
          scope.objectClone[scope.editableAttribute] = scope.textClone;
          scope.onChangeFunction(
            EventEmitter({
              object: scope.objectClone,
              index: parseInt(scope.editableIndex),
            }),
          );
        } else if (event && event.which === 27) {
          hideInput(scope.objectClone[scope.editableAttribute]);
        }
      }
    },
  };
};
