import styled from '@emotion/styled';
import { ToastContainer as Toast } from 'react-toastify';

export const Wrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 60px;
  z-index: 61;
  transition: top 0.3s linear;
  color: #7d7d7d;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);

  @media (max-width: 600px) {
    height: 120px;
  }
`;

export const ToastContainer = styled(Toast)`
  top: 4.5em;
  right: 4em;
  .Toastify__toast {
    cursor: inherit;
    border-left: 4px solid #4680fe;
    min-height: 188px;
    min-width: 365px;
  }
  .Toastify__close-button {
    margin-top: 5px;
  }
`;

export const ToastContainer2 = styled(Toast)`
  height: 116px;
  width: 535px;
  top: 4.5em;
  right: 4em;
  .Toastify__toast {
    cursor: inherit;
    border-left: 5px solid red;
  }
  .Toastify__close-button {
    margin-top: 10px;
    margin-right: 10px;
  }
`;