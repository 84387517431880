import styled from '@emotion/styled';

interface LabelProps {
  ColorValue: string;
}

interface ButtonProps {
  colorPicked: string | null | undefined;
}

interface ColorContainerProps {
  customMargin?: string
}

export const ColorPickerContainer = styled.div<ColorContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  margin: ${(props) => (props.customMargin ? props.customMargin : '10px 6px 0 24px')};
  `;

export const ColorsContainer = styled.ul`
  display: flex;
  right: 20%;
  top: 80%;
  width: 179px;
  position: absolute;
  background-color: #ffffff;
  margin-top: 10px;
  flex-wrap: wrap;
  padding-left: 0;
  overflow-x: auto;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  z-index: 1;
`;

export const ColorOption = styled.li`
  list-style-type: none;
  margin: 5px;
  background-color: ${(props) => props.key};
  input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
`;

export const StyledLabel = styled.label<LabelProps>`
  display: flex;
  align-items: center;
  width: 23px;
  height: 23px;
  border-radius: 4px;
  background-color: ${(props) => props.ColorValue};
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    opacity: 0.5;
  }
`;

export const ColorButton = styled.button<ButtonProps>`
  background-color: ${(props) => props.colorPicked};
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 4px;
`;
