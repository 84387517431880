// import React from 'react';
// import PropTypes from 'prop-types';
import styled from '@emotion/styled';

interface ButtonProps {
  hasIcon?: boolean
}
const Button = styled.button<ButtonProps>`
  ${(props) => `
    display: inline-block;
    color: #ffffff;
    background: #ff3c58;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
    transition: background 0.5s;
    outline: none;
    border: 0;
    padding: 10px 18px;
    cursor: pointer;
    :focus,
    :hover {
      background: #4680fe;
    }
    :disabled {
      opacity: 0.5;
    }
    text-decoration: none;
    text-align: center;
    ${
      !props.hasIcon
        ? ''
        : `
      svg:first-of-type {
        margin-right: 1em;
      }
    `
    }
  `}
`;

export default Button;

export const LightButton = styled(Button)`
  background: #e8eaf3;
  color: #4f587a;
  font-size: 14px;
  :focus,
  :hover {
    background: #cbccd4;
  }
`;
