export const MsToTimeFilter = () => {
  'ngInject';

  return (duration, format) => {
    let seconds = parseInt((duration / 1000) % 60, 10);
    let minutes = parseInt((duration / (1000 * 60)) % 60, 10);
    let hours = parseInt(duration / (1000 * 60 * 60), 10);

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    if (!format) {
      return `${hours}:${minutes}:${seconds}`;
    }

    return angular
      .copy(format)
      .replace('%H', parseInt(hours))
      .replace('%m', parseInt(minutes))
      .replace('%s', parseInt(seconds));
  };
};
