import styled from '@emotion/styled';

const Container = styled.section`
  background-color: #fff;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 1rem 40px;
  margin-bottom: 2rem;
  display: flex;
`;

export default Container;
