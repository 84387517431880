import angular from 'angular';
import { react2angular } from 'react2angular';
import { HexToRgbaFilter } from './hex-to-rgba.filter';
import { ReportService } from './report.service';
import { ReportsComponent } from './reports.component';
import ReportsPage from '~/app/ReportsPage';

export const ReportsModule = angular
  .module('root.components.app.components.main.reports', [])
  .config(($stateProvider) => {
    'ngInject';

    $stateProvider
      .state('app.main.reports', {
        url: '/reports',
        component: 'reportsReact',
      });
  })
  .service('ReportService', ReportService)
  .filter('hexToRgba', HexToRgbaFilter)
  .component(
    'reportsReact',
    react2angular(
      ReportsPage,
      [],
      ['NotificationService', 'AMPLITUDE_API_KEY'],
    ),
  )
  .component('reports', ReportsComponent).name;
