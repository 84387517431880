import React from 'react';
import Task from './Task';
import { Task as TaskType } from '~/models/types';
import DailyTasksHeader from './DailyTasksHeader';
import { DailyTasksContainer } from './styles';

type DailyTasksProps = {
  date: string;
  duration: number;
  tasksInTheDay: TaskType[];
  projectId?:number;
};
const DailyTasks = ({ date, duration, tasksInTheDay,projectId }: DailyTasksProps) => (
  <DailyTasksContainer>
    <DailyTasksHeader date={date} duration={duration} />
    {tasksInTheDay.map((task) => (
      <Task key={task.id} task={task}  projectId={projectId}/>
    ))}
  </DailyTasksContainer>
);

export default DailyTasks;
