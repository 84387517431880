import React, { useState, useEffect } from 'react';
import { MenuProps } from '../Menu';
import { ProjectMenuProps } from '../Menu';
import navigateFor from '../Menu';
import { State } from '~/models/types';
import * as P from './styles';
import * as S from '~/components/SideBar/Menu/styles';
import * as icons from '@mui/icons-material';
import API from '~/services/api';
import { ProjectService } from '~/models/types';
import useProjectStore from '~/store/projects';
import { UrlRouter } from '@uirouter/angularjs';

interface DropdownMenuProps {
  dropdownItem?: DropdownMenuItemProps[];
  projectMenu: ProjectMenuProps;
  route: string;
  userRole: string;
  navigateFor: Function;
  ProjectService: ProjectService;
  activeProjectId: number;
  setActiveProjectId: Function;
}

interface BaseProjectProps {
  id: number;
  name: string;
  tagColor: string;
}

interface DropdownMenuItemProps extends BaseProjectProps {
  $state?: State;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  setActiveProjectId,
  activeProjectId,
  dropdownItem,
  projectMenu,
  route,
  userRole,
  navigateFor,
  ProjectService
}) => {
  const [isProjectMenuOpen, setIsProjectMenuOpen] = useState(true);
  const [recentProjects, setRecentProjects] = useState([]);

  async function getRecentProjects() {
    const response = await ProjectService.getRecentProjects();
    setRecentProjects(response.data);
  }

  const handleProject = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setIsProjectMenuOpen(!isProjectMenuOpen);
  };

  const toLimit = (name: string) => {
    return name.length > 15 ? name.substring(0, 15) + '...' : name;
  };

  const Icon =
    icons[
      isProjectMenuOpen ? projectMenu.isClosedIcon : projectMenu.isOpenIcon
    ];

  const handleProjectNavigation = (id: number) => {
    navigateFor('app.main.projects-dashboard.planned-tasks', id);
    setActiveProjectId(id);
  };

  const Projects = (props: DropdownMenuItemProps) => {
    return (
      <P.SubItem
        onClick={() => handleProjectNavigation(props.id)}
        isActive={activeProjectId === props.id}
      >
        <P.ProjectItemIcon iconColor={props.tagColor}></P.ProjectItemIcon>
        <P.ProjectItemText>{toLimit(props.name)}</P.ProjectItemText>
      </P.SubItem>
    );
  };

  useEffect(() => {
    getRecentProjects();
  }, []);

  const renderProjects = () => {
    if (isProjectMenuOpen) return null;
    return (
      <>
        {recentProjects.map((project: BaseProjectProps, index: number) => (
          <Projects
            key={project.id}
            id={project.id}
            name={project.name}
            tagColor={project.tagColor}
          />
        ))}
        <P.SubItem onClick={() => navigateFor('app.main.projects')}>
          <P.SeeAllItemText>Ver todos</P.SeeAllItemText>
        </P.SubItem>
      </>
    );
  };

  return (
    <>
      <S.Item
        isActive={route?.match(/projects/)}
        onClick={() => navigateFor('app.main.projects')}
      >
        <S.ItemIcon onClick={(event) => handleProject(event)}>
          <Icon style={S.Icon} />
        </S.ItemIcon>
        <S.ItemText>{projectMenu.name}</S.ItemText>
      </S.Item>
      {renderProjects()}
    </>
  );
};

export default DropdownMenu;
