import React, { useEffect } from 'react';
import shallow from 'zustand/shallow';
import HeaderOptions from './HeaderOptions';
import TaskList from './TaskList';
import useProjectsStore from '~/store/projects/projects';
import useReportsStore from '~/store/reports/reports';
import useTeamStore from '~/store/team';
import SummaryChart from './SummaryChart';
import NotificationServiceType from '~/models/ServicesTypes';

type Props = {
  AMPLITUDE_API_KEY: string;
  NotificationService: NotificationServiceType;
};

const ReportsPage = ({ AMPLITUDE_API_KEY, NotificationService }: Props) => {
  const { fetchProjectsData, resetProjectsFilters } = useProjectsStore(
    (state) => ({
      fetchProjectsData: state.fetchProjectsData,
      resetProjectsFilters: state.resetProjectsFilters,
    }),
    shallow,
  );
  const {
    fetchTasksData,
    setCurrentUserId,
    fetchReportsData,
    resetReportsStore,
  } = useReportsStore(
    (state) => ({
      fetchTasksData: state.fetchTasksData,
      setCurrentUserId: state.setCurrentUserId,
      fetchReportsData: state.fetchReportsData,
      resetReportsStore: state.resetReportsStore,
    }),
    shallow,
  );
  const fetchTeamUsersData = useTeamStore((state) => state.fetchTeamUsersData);

  const start = async () => {
    resetReportsStore();
    resetProjectsFilters();
    setCurrentUserId();
    await fetchProjectsData();
    await fetchTasksData();
    await fetchTeamUsersData();
    await fetchReportsData();
  };

  useEffect(() => {
    start();
  }, []);

  return (
    <div>
      <HeaderOptions NotificationService={NotificationService} />
      <SummaryChart
        AMPLITUDE_API_KEY={AMPLITUDE_API_KEY}
        NotificationService={NotificationService}
      />
      <TaskList />
    </div>
  );
};

export default ReportsPage;