export class EditableAsInputService {
  constructor($rootScope) {
    'ngInject';

    this.$rootScope = $rootScope;
  }

  closeAll() {
    this.$rootScope.$broadcast('editable-as-input.close-all');
  }
}
