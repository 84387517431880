import styled from '@emotion/styled';
import CalendarToday from '@mui/icons-material/CalendarToday';

export const PeriodContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CalendarContainer = styled.div`
  flex: 1;
  padding: 0 15px;
  &:first-of-type {
    border-right: 2px solid #f4f4f4;
  }
`;

export const ComponentContainer = styled.div`
  background-color: white;
  padding: 20px 5px;
`;

export const CalendarIcon = styled(CalendarToday)`
  font-size: 18px;
`;
