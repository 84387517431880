import styled from '@emotion/styled';

export const HeaderText = styled.h2`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em;
`;

export const DeleteModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  margin-bottom: 10%;
`;
