import useLoadingStateStore from '~/store/loadingState';

const loadingState = useLoadingStateStore.getState();

export default async function apiFetch(url, method, headers, body) {
  loadingState.setLoading(true);
  const authHeaders = JSON.parse(localStorage.getItem('auth_headers'));

  const response = await fetch(`${API_URL}${url}`, {
    method: method || 'GET',
    headers: {
      'Content-type': 'application/json;charset=UTF-8',
      'Key-Inflection': 'camel',
      ...authHeaders,
      ...headers,
    },
    body: JSON.stringify(body),
  });

  localStorage.setItem(
    'auth_headers',
    JSON.stringify({
      'access-token':
        response.headers.get('access-token') || authHeaders['access-token'],
      'token-type':
        response.headers.get('token-type') || authHeaders['token-type'],
      client: response.headers.get('client') || authHeaders.client,
      expiry: response.headers.get('expiry') || authHeaders.expiry,
      uid: response.headers.get('uid') || authHeaders.uid,
    }),
  );

  loadingState.setLoading(false);
  return response;
}
