import create from 'zustand';
import { persist } from 'zustand/middleware';

type ProjectState = {
  activeProjectId: number;
  setActiveProjectId: (activeProjectId) => void;
};

const useProjectStore = create<ProjectState>(
  persist(
    (set, get) => ({
      activeProjectId: 0 as number,
      setActiveProjectId: (projectId) =>
        set((state) => ({ activeProjectId: projectId })),
    }),
    {
      name: 'useProjectStore',
    },
  ),
);

export default useProjectStore;
