export class ProjectAllocationService {
  constructor($http, $log, TimerService, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.TimerService = TimerService;
    this.API_URL = API_URL;
    this.loggedUser = null;
  }

  getAllProjectAllocatons(user) {
    return this.$http({
      url: `${this.API_URL}/users/${user.id}/project_allocations`,
      method: 'GET'
    });
  }

  createProjectAllocation(projectAllocation) {
    return this.$http.post(`${this.API_URL}/users/${projectAllocation.user}/project_allocations`, projectAllocation.body);
  }

  updateProjectAllocation(projectAllocation) {
    return this.$http.put(`${this.API_URL}/users/${projectAllocation.user}/project_allocations/${projectAllocation.id}`, projectAllocation.body);
  }

  deleteProjectAllocation(projectAllocation) {
    return this.$http.delete(`${this.API_URL}/users/${projectAllocation.user.id}/project_allocations/${projectAllocation.id}`);
  }

}
