import styled from '@emotion/styled';

type StepNumberProps = {
  color: string;
};

export const StepProgressContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 3%;
`;
export const StepNumber = styled.div<StepNumberProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid ${(props) => props.color};

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
  text-align: center;
  border-radius: 50%;
`;

export const CompleteStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  border: 1px solid #29c5a9;

  border-radius: 50%;
`;

export const StepText = styled.p`
  font-style: normal;
  font-weight: normal;
  margin: 0 15px 0 15px;
  font-size: 16px;
  line-height: 22px;
  color: #a6a6a6;
`;

export const DividerLine = styled.hr`
  width: 20%;
  border: none;
  height: 1px;
  background-color: #d7d7d7;
  margin: 0;
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
`;
