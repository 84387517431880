import templateUrl from './project-trello-settings.html';
import './project-trello-settings.scss';

export const ProjectTrelloSettingsComponent = {
  template: templateUrl,
  controller: class ProjectTrelloSettingsComponent {
    constructor($log, $mdDialog, $stateParams, $state, $scope, NotificationService, TrelloService, ProjectService) {
      'ngInject';

      this.$log = $log;
      this.$mdDialog = $mdDialog;
      this.$stateParams = $stateParams;
      this.$state = $state;
      this.$scope = $scope;
      this.NotificationService = NotificationService;
      this.TrelloService = TrelloService;
      this.ProjectService = ProjectService;
    }

    newBoardTemplate = {
      id: 'new-board', name: 'Criar novo', prefs: { backgroundColor: '#e8eaf3' }
    };

    $onInit() {
      this.ProjectService.getProjectById(this.$stateParams.projectId).then(
        response => {
          this.project = response.data;
          if (this.project.hasTrelloBoard) {
            this.ProjectService.getTrelloBoard(this.project.id).then(projectTrelloBoard => {
              this.project.trelloBoard = projectTrelloBoard;
              this.TrelloService.getBoard(projectTrelloBoard.boardId, true).then(board => {
                this.selectedBoard = board;
                this.lists = board.lists;
                this.labels = board.labels.map((l) => Object.assign(l, { color: this.translateColor(l.color) }));

                if (projectTrelloBoard.todoListId) {
                  this.selectedTodoList = this.lists.find(l => l.id === projectTrelloBoard.todoListId);
                }
                if (projectTrelloBoard.doneListId) {
                  this.selectedDoneList = this.lists.find(l => l.id === projectTrelloBoard.doneListId);
                }
                if (projectTrelloBoard.projectLabelId) {
                  this.selectedLabel = this.labels.find(l => l.id === projectTrelloBoard.projectLabelId);
                }
              });
            });
          } else {
            this.$state.go('app.main.projects-dashboard.trello-board-selection', {
              projectId: this.project.id,
            });
          }
        },
      );
    }

    translateColor(color) {
      return {
        'green': '#61bd4f',
        'yellow': '#f2d600',
        'orange': '#ff9f1a',
        'red': '#eb5a46',
        'purple': '#c377e0',
        'blue': '#0079bf',
        'sky': '#00c2e0',
        'lime': '#51e898',
        'pink': '#ff78cb',
        'black': '#344563'
      }[color]
    }

    updateSettings() {
      this.project.trelloBoardAttributes = {
        id: this.project.trelloBoard.id,
        boardId: this.selectedBoard.id,
        todoListId: this.selectedTodoList.id,
        doneListId: this.selectedDoneList.id,
      };
      if (this.selectedLabel && this.selectedLabel.id) {
        this.project.trelloBoardAttributes.projectLabelId = this.selectedLabel.id;
      }
      this._saveProject(this.project);
    }

    async disconnectBoard() {
      Object.assign(this.project, {
        hasTrelloBoard: false,
        trelloBoardAttributes: {
          id: this.project.trelloBoard.id,
          _destroy: true,
        }
      })

      try {
        await this.TrelloService.deleteTrelloBoardFromProject(this.project.id);
        await this.ProjectService.getAllProjects(true);
        this.$state.go('app.main.projects-dashboard.trello-board-selection', {
          projectId: this.project.id,
        });
      } catch (e) {
        this.NotificationService.showNotification(
          'Houve um erro ao tentar desconectar o projeto do Trello.',
          'error'
        );
      }
    }

    _saveProject(project) {
      return this.ProjectService.updateProject(project).then(
        result => {
          this.NotificationService.showNotification(
            'Dados do Projeto atualizados com sucesso.',
          );
          return result.data;
        },
        error => {
          this.NotificationService.showNotification(
            'Houve um erro ao atualizar os dados do Projeto.',
            'error',
          );
          this.$log.log('error', error);
          return null;
        },
      );
    }
  },
};
