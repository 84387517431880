import React, { useState, useEffect } from 'react';
import { Item } from './CheckItem';
import DataSelectDropdown from './DataSelectDropdown';
import NestedMenu, { OptionsType } from '../NestedMenu';

/*
  SearchableSelect component
  (Examples in ReportsPage/HeaderOptions)

  Necessary props: data and setData
  data can be anything following the Item type:
    - id: for control.
    - name or description: text to be displayed.
    - checked: boolean representing state of the checkbox.
    - disabled(optional): disable the specific item.

  you can turn any kind of data array into Item[] by using map
  and adding the 'checked' property to each element.

  displayName: text to be displayed on the default button of the component.
  CustomAnchorElement: can pass your own component to be the button.
  anchorOrigin: position of dropdown related to button.
  onOpen: executed when dropdown opens.
  onClose: executed when dropdown closes.
  isPending: changes the default button border to blue.

  handleSave: passing a function to this prop adds 'confirm' and 'cancel'
  buttons to the DataSelect. 
  Click on 'cancel' won't call onClose, will just close the dropdown.
  Click on 'confirm' will call handleSave, but not onClose.
  onClose is only called when user clicks outside of the dropdown.

  options: if this prop is present, an OptionSelect menu will appear before
  the DataSelect. You can add custom predefined selection options and
  another option to open the DataSelect.
  onClickAction = 'OPEN_COMPONENT' will open the DataSelect.
  onClickAction = 'CLOSE_DROPDOWN' will call option.onClick() and close.
  Example on ReportsPage/HeaderOptions/ProjectSelect
*/

type Props = {
  data: Item[];
  setData: (data: Item[]) => void;
  displayName?: string;
  CustomAnchorElement?: React.ReactNode;
  anchorOrigin?: {
    vertical: number | 'bottom' | 'top' | 'center';
    horizontal: number | 'left' | 'right' | 'center';
  };
  onOpen?: () => void;
  onClose?: () => void;
  handleSave?: () => void;
  searchPlaceholder?: string;
  emptyMessage?: React.ReactNode;
  options?: OptionsType[];
  isPending?: boolean;
};

const SearchableSelect = ({
  data,
  setData,
  displayName,
  CustomAnchorElement,
  anchorOrigin,
  onOpen,
  onClose,
  handleSave,
  searchPlaceholder,
  emptyMessage,
  options,
  isPending,
}: Props) => {
  // Because setState is async, for the options dropdown we need to manually
  // call onClose, as the dropdown is closed before the state is updated.
  const [optionWithCloseClicked, setOptionWithCloseClicked] = useState(false);
  useEffect(() => {
    if (optionWithCloseClicked && onClose) {
      onClose();
      setOptionWithCloseClicked(false);
    }
  }, [data]);

  return (
    <NestedMenu
      displayName={displayName}
      isPending={isPending}
      DefaultComponent={
        <DataSelectDropdown
          data={data}
          setData={(items: Item[]) => setData(items)}
          handleSave={handleSave}
          searchPlaceholder={searchPlaceholder}
          emptyMessage={emptyMessage}
        />
      }
      CustomAnchorElement={CustomAnchorElement}
      anchorOrigin={anchorOrigin}
      onOpen={onOpen}
      onClose={onClose}
      options={options}
      setOptionWithCloseClicked={() => setOptionWithCloseClicked(true)}
    />
  );
};

export default SearchableSelect;
export type { Item };
export type { OptionsType };
