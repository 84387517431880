import styled from '@emotion/styled';
import ProjectSelect from '~/app/components/Select/ProjectSelect';

export const Wrapper = styled.div`
  display: flex;
  width: 82%;
  height: 100%;
  align-items: center;
  margin: 0 15px 0 40px;
  margin-left: 18%;
  justify-content: space-between;

  @media (max-width: 600px) {
    margin: 0;
  }
`;

export const ProjectSelectWrapper = styled(ProjectSelect)`
  display: flex;
  height: 100%;
`;

export const SelectWrapper = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 600px) {
    margin: 0;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

interface InputWrapperProps {
  errorState: boolean;
}

export const InputWrapper = styled.input<InputWrapperProps>`
  border: none;
  width: 600px;
  margin-left: 48px;
  height: 100%;
  ${({ errorState }) =>
    errorState &&
    `
    color: #fe3b5b;
    border-bottom: solid 1px #ff3c56;
    ::placeholder {
      color: #fe3b5b;
    }
    `}
  &:focus {
    outline: none;
  }

  @media (max-width: 600px) {
    margin: 0 8px 0 8px;
    width: 100%;
    border-width: 0 0 1px;
    border-radius: 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    &:focus {
      border-color: rgba(0, 0, 0, 0.12);
    }
  }
`;

export const TimerIconWrapper = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const TaskSelectWrapper = styled.div`
  margin-right: 3rem;
`;

export const CalendarContainer = styled.div`
  flex: 1;
  margin: 0 15px;
  &::first-of-type: {
    margin-left: 0;
  }
`;

export const TasksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
  width: 100%;
  height: 100%;

  @media (max-width: 600px) {
    margin: 0;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }
`;

export const TimerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

export const SelectContainer = styled.div``;

interface ErrorMessageProps {
  descriptionError: boolean;
}

export const ErrorMessage = styled.p<ErrorMessageProps>`
  color: #e21300;
  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  position: absolute;
  ${({ descriptionError }) =>
    descriptionError &&
    `
    margin-left: 48px;
    `}
`;
