import React, { useState } from 'react';
import ReactModal from 'react-modal';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import NotificationServiceType, {
  UserServiceType,
} from '~/models/ServicesTypes';

import * as S from './styles';
import './reactModal.scss';
import useUserStore from '~/store/user';

const INPUT_TYPES = {
  USER_NAME: 'USER_NAME',
  USER_EMAIL: 'USER_EMAIL',
  USER_PASSWORD: 'USER_PASSWORD',
  USER_NEW_PASSWORD: 'USER_NEW_PASSWORD',
  USER_NEW_PASSWORD_CONFIRMATION: 'USER_NEW_PASSWORD_CONFIRMATION',
};

const Schema = Yup.object().shape({
  userNewPassword: Yup.string()
    .min(6, 'A senha deve ter tamanho mínimo de 6 dígitos')
    .oneOf(
      [Yup.ref('userNewPasswordConfirmation'), null],
      'As senhas devem ser identicas',
    ),
  userNewPasswordConfirmation: Yup.string()
    .min(6, 'A senha deve ter tamanho mínimo de 6 dígitos')
    .oneOf([Yup.ref('userNewPassword'), null], 'As senhas devem ser identicas'),
});

interface UserProfileModalProps {
  setUserProfileModal: (status: boolean) => void;
  isOpen: boolean;
  UserService: UserServiceType;
  NotificationService: NotificationServiceType;
}

const UserProfile: React.FC<UserProfileModalProps> = ({
  setUserProfileModal,
  isOpen,
  UserService,
  NotificationService,
}: UserProfileModalProps) => {
  const [isInputOnFocus, setInputOnFocs] = useState<string | null>(null);
  const { user, fetchUserData } = useUserStore();
  const onSubmitUserProfile = async (values) => {
    try {
      const userdata = {
        user: {
          ...user,
          name: values.userName,
        },
      };
      if (values.userNewPassword && values.userNewPasswordConfirmation) {
        userdata.user.current_password = values.userPassword;
        userdata.user.password = values.userNewPassword;
        userdata.user.password_confirmation =
          values.userNewPasswordConfirmation;
      }
      const response = await UserService.updateUser(userdata);
      if (response.status === 200) {
        setUserProfileModal(false);
        NotificationService.showNotification(
          'Usuário editado com sucesso!',
          'success',
        );
        fetchUserData();
      }
    } catch (e) {
      NotificationService.showNotification(
        'Ocorreu um erro ao tentar editar o usuário.',
        'error',
      );
    }
  };

  return (
    <ReactModal
      closeTimeoutMS={180}
      isOpen={isOpen}
      style={S.customStyles}
      ariaHideApp={false}
      onRequestClose={() => setUserProfileModal(false)}
    >
      <S.ModalContainer>
        <S.ModalHeader>
          <S.ModalClose>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              onClick={() => setUserProfileModal(false)}
            >
              <path
                fill="#959595"
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </S.ModalClose>
        </S.ModalHeader>
        <S.FormContainer>
          <Formik
            initialValues={{
              userName: user.name,
              userEmail: user.email,
              userPassword: '',
              userNewPassword: '',
              userNewPasswordConfirmation: '',
            }}
            onSubmit={(values) => {
              onSubmitUserProfile(values);
            }}
            validationSchema={Schema}
          >
            <Form>
              <S.InputWrapper fullWidth={true}>
                <S.StyledLabel
                  isOnFocus={isInputOnFocus === INPUT_TYPES.USER_NAME}
                >
                  Nome *
                </S.StyledLabel>
                <S.StyledInput
                  name="userName"
                  type="text"
                  value=""
                  placeholder=""
                  onFocus={() => setInputOnFocs(INPUT_TYPES.USER_NAME)}
                  onBlur={() => setInputOnFocs(null)}
                />
              </S.InputWrapper>
              <S.InputWrapper fullWidth={true}>
                <S.StyledLabel
                  isOnFocus={isInputOnFocus === INPUT_TYPES.USER_EMAIL}
                >
                  Email
                </S.StyledLabel>
                <S.StyledInput
                  name="userEmail"
                  type="text"
                  value=""
                  placeholder=""
                  disabled={true}
                  onFocus={() => setInputOnFocs(INPUT_TYPES.USER_EMAIL)}
                  onBlur={() => setInputOnFocs(null)}
                />
              </S.InputWrapper>
              <S.PasswordContainer>
                <S.InputWrapper>
                  <S.StyledLabel
                    isOnFocus={isInputOnFocus === INPUT_TYPES.USER_PASSWORD}
                  >
                    Senha Atual
                  </S.StyledLabel>
                  <S.StyledInput
                    name="userPassword"
                    type="password"
                    value=""
                    placeholder=""
                    onFocus={() => setInputOnFocs(INPUT_TYPES.USER_PASSWORD)}
                    onBlur={() => setInputOnFocs(null)}
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.StyledLabel
                    isOnFocus={isInputOnFocus === INPUT_TYPES.USER_NEW_PASSWORD}
                  >
                    Nova senha
                  </S.StyledLabel>
                  <S.StyledInput
                    name="userNewPassword"
                    type="password"
                    value=""
                    placeholder=""
                    onFocus={() =>
                      setInputOnFocs(INPUT_TYPES.USER_NEW_PASSWORD)
                    }
                    onBlur={() => setInputOnFocs(null)}
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.StyledLabel
                    isOnFocus={
                      isInputOnFocus ===
                      INPUT_TYPES.USER_NEW_PASSWORD_CONFIRMATION
                    }
                  >
                    Confirmação de senha
                  </S.StyledLabel>
                  <S.StyledInput
                    name="userNewPasswordConfirmation"
                    type="password"
                    value=""
                    placeholder=""
                    onFocus={() =>
                      setInputOnFocs(INPUT_TYPES.USER_NEW_PASSWORD_CONFIRMATION)
                    }
                    onBlur={() => setInputOnFocs(null)}
                  />
                </S.InputWrapper>
              </S.PasswordContainer>
              <S.ButtonWrapper>
                <S.StyledButton type="submit" style={{ minWidth: '168px' }}>
                  Salvar Alterações
                </S.StyledButton>
              </S.ButtonWrapper>
            </Form>
          </Formik>
        </S.FormContainer>
      </S.ModalContainer>
    </ReactModal>
  );
};

export default UserProfile;
