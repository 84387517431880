import React, { useState } from 'react';
import CheckItem, { Item, ItemsWrapper } from '../CheckItem';
import SearchBar from '../SearchBar';
import * as S from './styles';

type Props = {
  data: Item[];
  setData: (data: Item[]) => void;
  handleSave?: () => void;
  closeDropdown?: () => void;
  searchPlaceholder?: string;
  emptyMessage?: React.ReactNode;
};

const DataSelectDropdown = ({
  data,
  setData,
  handleSave,
  closeDropdown,
  searchPlaceholder,
  emptyMessage,
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const areAllSelected = data.every((item) => item.checked);
  const areAllDisabled = data.every((item) => item.disabled);
  const areNoneSelected = data
    .filter((item) => !item.disabled)
    .every((item) => !item.checked);

  const handleOnClick = (index: number) => {
    const updatedData = data.map((item, i) => ({
      ...item,
      checked: index === i ? !item.checked : item.checked,
    }));
    setData(updatedData);
  };

  const handleSelectAll = () => {
    const updatedData = data.map((item) => ({
      ...item,
      checked: !item.disabled === true ? !areAllSelected : item.checked,
    }));
    setData(updatedData);
  };

  // Filters items based on search input
  const RenderedItems = data.reduce((acc: React.ReactNode[], item, index) => {
    const itemName = item.name ? item.name : item.description;
    if (itemName && itemName.toLowerCase().includes(inputValue.toLowerCase()))
      acc.push(
        <CheckItem
          key={item.id}
          item={item}
          handleOnClick={() => handleOnClick(index)}
          checked={item.checked}
          disabled={item.disabled}
        />,
      );
    return acc;
  }, []);

  return (
    <S.SelectWrapper
      onClick={(e) => e.stopPropagation()}
      saveCancelBtn={!!handleSave}
    >
      <SearchBar
        inputValue={inputValue}
        setInputValue={setInputValue}
        searchPlaceholder={searchPlaceholder}
      />
      <S.HorizontalLine />
      {data.length === 0 &&
        (emptyMessage || <div>Nenhuma opção disponível</div>)}
      {data.length !== 0 && (
        <S.SelectAllContainer>
          <CheckItem
            handleOnClick={() => !areAllDisabled && handleSelectAll()}
            checked={areAllSelected}
            bold={true}
            // this 'checked' is ignored
            item={{ id: -1, name: 'Selecionar Todos', checked: true }}
            disabled={areAllDisabled}
          />
        </S.SelectAllContainer>
      )}
      <ItemsWrapper>{RenderedItems}</ItemsWrapper>
      {handleSave && closeDropdown && (
        <S.ButtonContainer>
          <S.SaveButton
            disabled={areAllDisabled || areNoneSelected}
            onClick={() => {
              if (!areAllDisabled && !areNoneSelected) {
                closeDropdown();
                handleSave();
              }
            }}
          >
            Adicionar
          </S.SaveButton>
          <S.CancelButton
            onClick={() => {
              closeDropdown();
              setData(
                data.map((item) => ({ ...item, checked: !!item.disabled })),
              );
            }}
          >
            Cancelar
          </S.CancelButton>
        </S.ButtonContainer>
      )}
    </S.SelectWrapper>
  );
};

export default DataSelectDropdown;
