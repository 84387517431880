import angular from 'angular';
import { PopoverWrapper } from './popover-wrapper.directive';
import { PopoverTrigger } from './popover-trigger.directive';
import { PopoverHtml } from './popover-html.directive';
import { PopoverService } from './popover.service';
import './popover.scss';
import './lb-popover.scss';

export const PopoverModule = angular
  .module('root.components.app.components.main.popover', [])
  .directive('popoverWrapper', PopoverWrapper)
  .directive('popoverTrigger', PopoverTrigger)
  .directive('popoverHtml', PopoverHtml)
  .service('PopoverService', PopoverService).name;
