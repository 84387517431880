import React, { useState, useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';
import moment from 'moment';
import {
  MdPlayCircleFilled,
  MdPauseCircleFilled,
  MdCheckCircle,
} from 'react-icons/md';
import TaskDescriptionSelect from '~/app/components/Select/TaskDescriptionSelect';
import TimerCalendar from '~/app/components/TimerCalendar';
import PlannedTaskSelect from '~/app/components/Select/PlannedTaskSelect'
import { TaskService, ProjectService, PlannedTaskService, RootScope, NotificationService } from '~/models/types';
import { LoginServiceType } from '~/models/ServicesTypes';

import useOnboardingStore from '~/store/onboarding';

import * as S from './styles';
import useProjectsTasksStore from '~/store/projectsTasks';
import useReplayTaskStore from '~/store/replayTask';
import checkAlerts from './TaskAlert/checkAlerts';
import useUserStore from '~/store/user';

interface TaskBarProps {
  TaskService: TaskService;
  ProjectService: ProjectService;
  PlannedTaskService: PlannedTaskService;
  $rootScope: RootScope;
  NotificationService: NotificationService;
  LoginService: LoginServiceType;
}

const iconStyles = {
  color: '#FF3C58',
  width: '36px',
  height: '36px',
};

const allPlannedTasksOption = {
  value: null,
  label: 'Por favor selecione o projeto antes.',
};

const shouldWatchAutoStart = (task) => {
  if (task && task.id && task.start) return true;

  return false;
};

const currentDuration = (date, endDate = new Date()) => {
  if (!date) return 0;

  const currentDate = moment(endDate);
  const duration = currentDate.diff(moment(date));

  return new Date().setSeconds(
    new Date().getSeconds() + (moment.duration(duration) as any) / 1000,
  );
};

const useStartTimerEffectHandler = (task, isRunning, start, reset, setDisabledInputs) =>
  useEffect(() => {
    if (shouldWatchAutoStart(task) && !isRunning) {
      reset(currentDuration(task?.start));
      start();
      setDisabledInputs(true);
    }
  }, [task]);

const TimerDisplay = ({hours, minutes, seconds}) => {
  return `${hours >= 10 ? hours : `0${hours}`}:${minutes >= 10 ? minutes : `0${minutes}`}:${seconds >= 10 ? seconds : `0${seconds}`}`;
};

const TaskBar: React.FC<TaskBarProps> = ({
  TaskService,
  ProjectService,
  PlannedTaskService,
  $rootScope,
  NotificationService,
  LoginService,
}) => {
  const [description, setDescription] = useState<any>('');
  const [task, setTask] = useState<any>(null);
  const [dateSelect, setDateSelect] = useState<any>(false);
  const [manualTimeSet, setManualTimeSet] = useState<any>(false);
  const [projects, setProjects] = useState<any>([]);
  const [project, setProject] = useState<any>(null);
  const [plannedTasks, setPlannedTasks] = useState([allPlannedTasksOption]);
  const [plannedTask, setPlannedTask] = useState<any>(null);
  const [previousTasks, setPreviousTasks] = useState<any>([]);
  const [projectErrorState, setProjectErrorState] = useState<any>(false);
  const [descriptionErrorState, setDescriptionErrorState] = useState<any>(false);
  const [errorMessage, setErrorMessage] = useState<any>(false);
  const [disabledInputs, setDisabledInputs] = useState<any>(false);
  const loggedUser = useUserStore((state) => state.user);
  
  const increaseStepIndex = useOnboardingStore((state) => state.increaseStepIndex);
  const tourIsRunning = useOnboardingStore((state) => state.isRunning);
  const changeIsLoading = useOnboardingStore((state) => state.changeIsLoading);
  const stepIndex = useOnboardingStore((state) => state.stepIndex);
  const changeIsDone = useOnboardingStore((state) => state.changeIsDone);
  const openWelcomeTourModal = useOnboardingStore((state) => state.openWelcomeTourModal);

  async function fetchOpenedTaskAndProjects() {
    try {
      const taskResponse = await TaskService.getOpenedTask();
      const task = taskResponse && taskResponse.data ? taskResponse.data : null;
      if (task && task.id) {
        setTask(taskResponse.data)
        updateTaskBarData(taskResponse.data);
      }
    } catch (e) {
      console.log(e)
    } 

    const projectsResponse = (await ProjectService.getAllProjects()).data.filter((p) => !p.adminOnly);
      const newProjects = [
        ...projectsResponse.map((p) => ({
          value: p,
          label: p.name,
          color: p.tagColor,
        })),
      ];
    setProjects(newProjects)
  }

  async function fetchPreviousProject() {
    const response = await TaskService.getLastTaskProject();
    if (response.data) {
      if (projects.some((p) => p.value.id === response.data.id)){
        setProject({ value: response.data, label: response.data?.name, color: response.data?.tagColor, });
      }
      return;
    }
    if (tourIsRunning){
      setProject(projects[0]);
    }
  }

  async function fetchPlannedAndPreviousTasks() {
    const plannedResponse = await PlannedTaskService.getPlannedTasks(
      project?.value?.id,
      );
    if (plannedResponse.data.length === 0) {
      plannedResponse.data = [
        {
          id: 0,
          description: 'Não existem tarefas planejadas para este projeto.',
          disabled: true,
        },
      ];
    }
    const PlannedTasks = [
      {
        value: {id:null},
        label: 'Nenhuma',
        disabled: false,
      },
      ...plannedResponse.data.map((p) => ({
        value: p,
        label: p.description,
        disabled: p.disabled,
      })),
    ];
    setPlannedTasks(PlannedTasks)
    
    const previousResponse = await ProjectService.getMonthTasks(
      project?.value?.id,
    );
    setPreviousTasks([{ label: 'Descrições Existentes', options: previousResponse.data.map((p) =>({ value: p, label: p})) }]);
  }

  async function fetchPreviousTasks() {
    const response = await ProjectService.getMonthTasks(
      project?.value?.id,
    );
    setPreviousTasks([{ label: 'Descrições Existentes', options: response.data.map((p) =>({ value: p, label: p})) }]);
  }
 
  useEffect(() => {
    fetchOpenedTaskAndProjects()
  }, []);

  useEffect(() => {
    if (tourIsRunning) changeIsLoading(true);
    if (projects.length !== 0) {
      fetchPreviousProject();
      changeIsLoading(false);
    }
  }, [projects]);

  useEffect(() => {
    if (project) {
      updateTask();
      fetchPlannedAndPreviousTasks();
    }
  }, [project]);

  const { seconds, minutes, hours, isRunning, start, pause, reset } =
    useStopwatch({
      autoStart: false,
    });

  useEffect(() => {
    if (isRunning && stepIndex < 3) {
      changeIsDone();
    }
  }, [tourIsRunning, openWelcomeTourModal]);

  useEffect(() => {
    if(isRunning){
      document.title = `${TimerDisplay({hours, minutes, seconds})} - Labor`;
    }else{
      document.title = 'Labor'
    }
  }, [hours, minutes, seconds, isRunning]);


  async function updateTask(startDate = null) {
    if (isRunning && task && task?.id) {
      try {
        const response = await TaskService.updateTask({
          id: task?.id,
          description: description.value,
          projectId: project?.value?.id,
          plannedTaskId: plannedTask?.value?.id,
          start: startDate || task?.start,
        } as any); // FIX

        setTask(response.data);
        reset(currentDuration(response?.data?.start) as any);
      } catch (e) {
        console.log(e);
      }
    }
  }

  const updateTaskBarData = async (newTask) => {
    setDescription({ value: newTask.description, label: newTask.description });
    if (newTask.projectId) {
      const res = await ProjectService.getProjectById(newTask.projectId);
      setProject({
        value: res.data,
        label: res.data.name,
        color: res.data.tagColor,
      });
    }
    if(newTask.plannedTask){
      setPlannedTask({ value: newTask?.plannedTask, label: newTask?.plannedTask?.description })
    }
  };

  useStartTimerEffectHandler(task, isRunning, start, reset, setDisabledInputs);

  const resetTaskData = () => {
    setTask(null);
    setManualTimeSet(false);
    setDescription('');
    setPlannedTask(null);
  };

  const handleErrorTask = (e) => {
    reset();
    pause();
    setDisabledInputs(false);
    fetchPreviousTasks();

    if (e && e.status === 401) {
      NotificationService.showNotification(
        'Você precisa realizar o login novamente',
        'error',
        4000
      );
      LoginService.logout();
    }
    resetTaskData();
  };

  const stopTask = async () => {
    try {
      TaskService.saveTask({
        description: description.value,
        projectId: project?.value?.id,
        plannedTaskId: plannedTask?.value?.id,
        start: new Date(),
      }).then(
        (response) => {
          setTask({ ...response.data });
          start();
          reset();
          setDisabledInputs(true);
        }).catch((e) => handleErrorTask(e));
    } catch (e) {
      handleErrorTask(e);
    }
  };

  const playValidation = () => {
    let error = ''
    let notification = ''
    if(!project && !description){
      error = 'Você precisa selecionar um projeto e inserir uma tarefa para continuar'
      notification = 'Preencha os campos indicados para continuar.'
    } else if (!description){
      error = 'Você precisa de uma tarefa para continuar.'
      notification = 'Preencha o campo indicado para continuar.'
    } else if (!project){
      error = 'Por favor, selecione um projeto.'
      notification = 'Selecione um projeto para continuar.'
    }
    if (!!notification){
      NotificationService.showNotification(
        notification,
        'error',
        4000
      );
    }
    setProjectErrorState(!project)
    setDescriptionErrorState(!description)
    setErrorMessage(error ? error : false)
    return !error
  }


  // tourIsRunning is the global statement of tour
  const onboardingOpenProjects = () => {
    if (tourIsRunning) increaseStepIndex();
  }

  const { getState } = useProjectsTasksStore;
  const handlePlay = async () => {
    if (tourIsRunning) increaseStepIndex();

    try {
      if (manualTimeSet && playValidation()) {
        TaskService.saveTask({
          description: description.value,
          projectId: project?.value?.id,
          plannedTaskId: plannedTask?.value?.id,
          start: manualTimeSet.startDateTime,
          end: manualTimeSet.endDateTime,
        }).then((response)=>{
          let data = response.data
          getState().fetchProjectsTasks()
          checkAlerts(
            loggedUser,
            data.alerts
          );
        });
        reset();
        pause();
        resetTaskData();
      } else if (isRunning) {
        TaskService.stopTask({
          ...task,
          end: new Date() as any + 60,
        }).then((response) =>{
          let data = response.data
          checkAlerts(
            loggedUser,
            data.alerts
          );
          reset();
          pause();
          getState().fetchProjectsTasks();
          setDisabledInputs(false);
          fetchPreviousTasks();
        }).catch((e) => {
          handleErrorTask(e);
        });
  
        resetTaskData();
      } else if (!isRunning && !task) {
        if(playValidation()){
          setErrorMessage(false)
          stopTask();
        }
      }
    } catch (e) {
      handleErrorTask(e);
    }
  };

  const dateIsValid = (date) => date instanceof Date && !isNaN(date as any);  

  const createRangedTask = (startDateTime, endDateTime) => {
    if (dateIsValid(startDateTime) && dateIsValid(endDateTime) ){ 
      if (endDateTime < startDateTime) {
        reset(
          currentDuration(
            startDateTime,
            (moment(endDateTime.setDate(endDateTime.getDate() + 1))) as any
          ) as any,
        );
      } else {
        reset(currentDuration(startDateTime, endDateTime) as any);
      }
      setTask({...task, start: startDateTime, end: endDateTime});
      setManualTimeSet({ startDateTime, endDateTime });
      pause();
    }
  };
  const {replayTask , cleanReplayTask} = useReplayTaskStore();
  const handleReplay = async (replayTask) => {

    try {
      cleanReplayTask();
      const res = await TaskService.saveTask({
        description: replayTask.description,
        projectId: replayTask.projectId,
        plannedTaskId: replayTask.plannedTaskId,
        start: new Date(),
      });
  
      setTask({ ...res.data });
      updateTaskBarData(res.data);
      reset();
      getState().fetchProjectsTasks();
      start();
      setDisabledInputs(true);
    } catch (e) {
      handleErrorTask(e);
    }
  };

  if(replayTask.projectId){
     if (isRunning) {
        stopTask();
      }
      handleReplay(replayTask);
  }

  const handleProjectChange = (value) => {
    setProject(value)
    setDescription('')
    setPlannedTask(null);
  };
  const handlePlannedTaskChange = (optionSelected) => {
     if (optionSelected.value.id){
       setPlannedTask(optionSelected)
     }
     else{
       setPlannedTask(null)
     }
  }

  $rootScope.$on('replayTask', (event, replayTask) => {
    if (!event.defaultPrevented) {
      event.defaultPrevented = true; // eslint-disable-line no-param-reassign

      if (isRunning) {
        stopTask();
      }
      handleReplay(replayTask);
    }
  });

  return (
    <S.Wrapper id={'taskbar-id'}>
      <S.TasksWrapper>
        <S.SelectWrapper>
          <div data-testid="select" id="select-project">
            <S.ProjectSelectWrapper
              options={projects as any}
              value={project}
              onChange={handleProjectChange}
              onMenuOpen={onboardingOpenProjects}
              placeholder={'Projetos'}
              errorState={projectErrorState}
              isDisabled={disabledInputs}
            />
            {projectErrorState && <S.ErrorMessage descriptionError={false}>{errorMessage}</S.ErrorMessage>}
          </div>
          <S.SelectContainer>
            <PlannedTaskSelect
              options={plannedTasks}
              value={plannedTask}
              onChange={handlePlannedTaskChange}
              isDisabled={disabledInputs}
            />
          </S.SelectContainer>
          <S.SelectContainer id="desciption-container">
            <TaskDescriptionSelect
              options={previousTasks as any}
              value={description}
              setValue={setDescription}
              onChange={setDescription}
              placeholder={'Crie uma nova descrição ou selecione uma existente'}
              errorState={descriptionErrorState}
              isDisabled={disabledInputs}
            />
            {(descriptionErrorState && !projectErrorState) && <S.ErrorMessage descriptionError={true}>{errorMessage}</S.ErrorMessage>}
          </S.SelectContainer>
        </S.SelectWrapper>
        <S.TimerWrapper>
          {manualTimeSet ? (
            <S.TimerIconWrapper onClick={handlePlay} data-testid="play-svg">
              <MdCheckCircle style={iconStyles} />
            </S.TimerIconWrapper>
          ) : (
            <S.TimerIconWrapper id="timer-wrapper" onClick={handlePlay} data-testid="play-svg">
              {isRunning ? (
                <MdPauseCircleFilled style={iconStyles} />
              ) : (
                <MdPlayCircleFilled style={iconStyles} />
              )}
            </S.TimerIconWrapper>
          )}
          <div
            style={{ fontSize: '16px', marginLeft: '12px' }}
            onClick={() => setDateSelect(!dateSelect)}
          >
            <span>{TimerDisplay({hours, minutes, seconds})}</span>
          </div>
        </S.TimerWrapper>
      </S.TasksWrapper>

      {dateSelect && (
        <TimerCalendar
          disabled={isRunning}
          start={!isRunning && dateIsValid(task?.start) ? moment(task?.start).toDate() : new Date()}
          end={!isRunning && dateIsValid(task?.end) ? moment(task?.end).toDate() : new Date()}
          closeCalendar={() => {
            setDateSelect(false);
          }}
          updateTask={updateTask as any}
          createRangedTask={createRangedTask}
        />
      )}
    </S.Wrapper>
  );
}

export default TaskBar;
