import React, { useEffect, useRef } from 'react';
import * as S from './styles';
import { Tooltip } from '@mui/material';
import { msToTimeString } from '~/app/utils/duration';
import api from '~/services/api';
import DeletePlannedTaskModal from './DeletePlannedTaskModal';
import useReplayTaskStore from '~/store/replayTask';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

type Props = {
  isAdmin: boolean;
  isEmpty: boolean;
  setPlannedTasks: Function;
  plannedTasks: object[];
};
const TaskList = ({
  isAdmin,
  isEmpty,
  setPlannedTasks,
  plannedTasks,
}: Props) => {
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [taskToDelete, setTaskToDelete] = React.useState(-1);
  const projectId = parseInt(window.location.pathname.split('/')[4]);
  const { setReplayTask } = useReplayTaskStore();

  const moveRow = async (dragIndex, hoverIndex) => {
    const dragRecord = plannedTasks[dragIndex];
    const newPlannedTasks = plannedTasks.filter(
      (_, index) => index !== dragIndex,
    );
    newPlannedTasks.splice(hoverIndex, 0, dragRecord);
    setPlannedTasks(newPlannedTasks);
  };
  const handleEditTask = async (task: { id: number }) => {
    const response = await api.team.change_planned_task(
      {
        ...task,
      },
      projectId,
      task.id,
    );
    const newTask = await response.json();
    const newPlannedTasks = plannedTasks.map((plannedTask: { id: number }) => {
      if (plannedTask.id === task.id) {
        return newTask;
      }
      return plannedTask;
    });
    setPlannedTasks(newPlannedTasks);
  };
  const handleAddTask = async (task: Object, index) => {
    const response = await api.team.add_planned_task({ ...task }, projectId);
    const newTask = await response.json();
    const newPlannedTasks = plannedTasks.map((elem, idx) =>
      idx === index ? newTask : elem,
    );
    setPlannedTasks(newPlannedTasks);
  };

  const DragableRow = ({ task, index }) => {
    const dropRef = React.useRef(null);
    const dragRef = React.useRef(null);
    const [, drop] = useDrop({
      accept: 'Row',
      hover(item: { index: number }, monitor) {
        if (!dropRef.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = dropRef.current.getBoundingClientRect();

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();

        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        moveRow(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
      drop: async (item, monitor) => {
        await api.team.reorder_planned_tasks(projectId, {
          list: plannedTasks.map((task: { id: number }) => ({
            id: task.id,
            order: plannedTasks.indexOf(task),
          })),
        });
      },
    });
    const [{ isDragging }, drag, preview] = useDrag({
      item: { index },
      type: 'Row',
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });
    preview(drop(dropRef));
    drag(dragRef);

    return (
      <S.Row
        ref={dropRef}
        className="bodyRow editable"
        style={{
          opacity: isDragging ? 0.5 : 1,
        }}
      >
        <S.Cell className="description" ref={dragRef}>
          {' '}
          <S.DragHandleIcon />
          <input
            className="descriptionInput"
            defaultValue={task.description}
            onBlur={(e) => {
              if (task.description !== '') {
                handleEditTask({
                  ...task,
                  description: e.target.value,
                });
              } else {
                handleAddTask(
                  {
                    ...task,
                    description: e.target.value,
                  },
                  index,
                );
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.target.blur();
              }
            }}
          />
        </S.Cell>
        <S.Cell className="duration">
          <input
            className="durationInput"
            type="number"
            defaultValue={task.duration}
            onBlur={(e) => {
              if (task.description !== '') {
                handleEditTask({
                  ...task,
                  duration: e.target.value,
                });
              } else {
                task.duration = e.target.value;
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.target.blur();
              }
            }}
          />
        </S.Cell>
        <S.Cell>{msToTimeString(task.timeSpent)}</S.Cell>
        <S.Cell>
          <div className="actions">
            <Tooltip title="Iniciar tarefa planejada" placement="bottom">
              <S.PlayArrowIcon
                className="icon"
                onClick={() => {
                  setReplayTask({
                    description: task.description,
                    projectId: projectId,
                    plannedTaskId: task.id,
                  });
                }}
              />
            </Tooltip>
            <Tooltip title="Remover tarefa planejada" placement="bottom">
              <S.CloseIcon
                className="icon"
                onClick={() => {
                  setTaskToDelete(task.id);
                  setOpenDeleteModal(true);
                }}
              />
            </Tooltip>
          </div>
        </S.Cell>
      </S.Row>
    );
  };

  return !isEmpty ? (
    isAdmin ? (
      <>
        <DndProvider backend={HTML5Backend}>
          <S.Table>
            <S.Row className="header">
              <S.Cell className="description firstCell">
                Tarefas planejadas
              </S.Cell>
              <S.Cell className="duration">
                <Tooltip title="Duração esperada em horas" placement="bottom">
                  <S.AssignmentIcon />
                </Tooltip>
              </S.Cell>
              <S.Cell>
                <Tooltip title="Tempo já consumido" placement="bottom">
                  <S.AlarmIcon />
                </Tooltip>
              </S.Cell>
              <S.Cell className="actions"></S.Cell>
            </S.Row>

            {plannedTasks.map((task: any) => {
              return (
                <DragableRow
                  key={task.id}
                  task={task}
                  index={plannedTasks.indexOf(task)}
                />
              );
            })}
            <S.Row>
              <S.Cell className="addTask">
                <S.AddTaskButton
                  onClick={() => {
                    const newTask = {
                      description: '',
                      duration: 1,
                    };
                    setPlannedTasks([...plannedTasks, newTask]);
                  }}
                >
                  <S.AddIcon />
                  Adicionar Tarefa Planejada
                </S.AddTaskButton>
              </S.Cell>
              <S.Cell className="duration">
                {plannedTasks.reduce((acc: number, task: any) => {
                  return acc + task.duration;
                }, 0)}
                h
              </S.Cell>
              <S.Cell className="timeSpent">
                {msToTimeString(
                  plannedTasks.reduce((acc: number, task: any) => {
                    return acc + task.timeSpent;
                  }, 0),
                )}
              </S.Cell>
            </S.Row>
          </S.Table>
        </DndProvider>

        <DeletePlannedTaskModal
          modalIsOpen={openDeleteModal}
          setModalOpen={setOpenDeleteModal}
          PlannedTaskId={taskToDelete}
          RemoveTask={async () => {
            const tasks = plannedTasks.filter(
              (task: { id: number }) => task.id !== taskToDelete,
            );
            setPlannedTasks(tasks);
          }}
        />
      </>
    ) : (
      <>
        <S.Table>
          <S.Row className="header">
            <S.Cell className="description firstCell">
              Tarefas planejadas
            </S.Cell>
            <S.Cell className="duration">
              <Tooltip title="Duração esperada em horas" placement="bottom">
                <S.AssignmentIcon />
              </Tooltip>
            </S.Cell>
            <S.Cell>
              <Tooltip title="Tempo já consumido" placement="bottom">
                <S.AlarmIcon />
              </Tooltip>
            </S.Cell>
            <S.Cell className="actions"></S.Cell>
          </S.Row>
          {plannedTasks.map(
            (task: {
              description: string;
              duration: number;
              timeSpent: number;
              id: number;
            }) => {
              return (
                <S.Row className="bodyRow editable">
                  <S.Cell className="description nonAdmin">
                    {' '}
                    <span>{task.description}</span>
                  </S.Cell>
                  <S.Cell className="duration">
                    <span className="durationInput">{task.duration}h</span>
                  </S.Cell>
                  <S.Cell>{msToTimeString(task.timeSpent)}</S.Cell>
                  <S.Cell>
                    <div className="actions">
                      <Tooltip
                        title="Iniciar tarefa planejada"
                        placement="bottom"
                      >
                        <S.PlayArrowIcon
                          className="icon"
                          onClick={() => {
                            setReplayTask({
                              description: task.description,
                              projectId: projectId,
                              plannedTaskId: task.id,
                            });
                          }}
                        />
                      </Tooltip>
                    </div>
                  </S.Cell>
                </S.Row>
              );
            },
          )}
          <S.Row>
            <S.Cell></S.Cell>
            <S.Cell className="duration">
              {plannedTasks.reduce(
                (acc: number, task: { duration: number }) => {
                  return acc + task.duration;
                },
                0,
              )}
              h
            </S.Cell>
            <S.Cell className="timeSpent">
              {msToTimeString(
                plannedTasks.reduce(
                  (acc: number, task: { timeSpent: number }) => {
                    return acc + task.timeSpent;
                  },
                  0,
                ),
              )}
            </S.Cell>
          </S.Row>
        </S.Table>
        <S.Message>
          Caso deseje alterar as tarefas planejadas, peça ao administrador do
          seu escritório no Labor.
        </S.Message>
      </>
    )
  ) : null;
};

export default TaskList;
