import React, { useCallback } from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell, Label } from 'recharts';
import shallow from 'zustand/shallow';
import useReportsStore from '~/store/reports/reports';
import * as S from './styles';

const Chart = ({
  active,
  setActive,
}: {
  active: number | null;
  setActive: React.Dispatch<React.SetStateAction<number | null>>;
}) => {
  const { projectsDuration, setSelectedProjectsIds, fetchTasksData } =
    useReportsStore(
      (state) => ({
        projectsDuration: state.projectsDuration,
        setSelectedProjectsIds: state.setSelectedProjectsIds,
        fetchTasksData: state.fetchTasksData,
      }),
      shallow,
    );

  const mapDurationsData = useCallback(
    () =>
      projectsDuration.projectsDuration.map((project) => ({
        ...project,
        value: project.duration,
        fill: project.color,
      })),
    [projectsDuration],
  );

  const onMouseOver = useCallback(
    (data: { name: string; value: number }, index: number) => {
      setActive(index);
    },
    [],
  );

  const onMouseLeave = useCallback(() => {
    setActive(null);
  }, []);

  const onClick = useCallback(
    (data: { name: string; value: number; id: number }) => {
      setSelectedProjectsIds([data.id]);
      fetchTasksData();
    },
    [],
  );

  const calculateCenterName = useCallback((): string => {
    if (active === null) return '';
    const percentage =
      (projectsDuration.projectsDuration[active].duration /
        projectsDuration.totalDuration) *
      100;
    return `${Math.round(percentage)}%`;
  }, [active]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    if (active !== null || percent < 0.05) return <></>;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <S.ChartContainer>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie
            dataKey="value"
            data={mapDurationsData()}
            cx="50%"
            cy="50%"
            innerRadius="52%"
            outerRadius="100%"
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            isAnimationActive={false}
            blendStroke={true}
            labelLine={false}
            label={renderCustomizedLabel}
          >
            <Label
              position="center"
              value={calculateCenterName()}
              style={{ fill: '#5F5F5F', fontWeight: 600 }}
            />
            {active !== null &&
              mapDurationsData().map((item, index) => (
                <Cell
                  key={`cell-${index}`}
                  opacity={index === active ? 1.0 : 0.3}
                />
              ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </S.ChartContainer>
  );
};

// Necessary for animations
const MemoizedChart = React.memo(Chart);

export default MemoizedChart;
