import ChartJs from 'chart.js';

export const ChartJsDirective = ($document, $compile, $log) => {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      chartConfig: '<',
    },
    link: (scope, element, attrs) => {
      let chart;
      scope.$watch('chartConfig', () => {
        if (chart) {
          chart.destroy();
        }
        chart = new ChartJs(element[0].getContext('2d'), scope.chartConfig);
        ChartJs.pluginService.register({
          afterDraw(chart) {
            if (chart.config.options.elements.center) {
              const { helpers } = Chart;
              const centerX =
                (chart.chartArea.left + chart.chartArea.right) / 2;
              const centerY =
                (chart.chartArea.top + chart.chartArea.bottom) / 2;

              const { ctx } = chart.chart;
              ctx.save();
              const fontSize = helpers.getValueOrDefault(
                chart.config.options.elements.center.fontSize,
                Chart.defaults.global.defaultFontSize,
              );
              const fontStyle = helpers.getValueOrDefault(
                chart.config.options.elements.center.fontStyle,
                Chart.defaults.global.defaultFontStyle,
              );
              const fontFamily = helpers.getValueOrDefault(
                chart.config.options.elements.center.fontFamily,
                Chart.defaults.global.defaultFontFamily,
              );
              const font = helpers.fontString(fontSize, fontStyle, fontFamily);
              ctx.font = font;
              ctx.fillStyle = helpers.getValueOrDefault(
                chart.config.options.elements.center.fontColor,
                Chart.defaults.global.defaultFontColor,
              );
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillText(
                chart.config.options.elements.center.text,
                centerX,
                centerY,
              );
              ctx.restore();
            }
          },
        });
      });
    },
  };
};
