import angular from 'angular';
import ngSortable from 'ng-sortable';

import { PlannedTaskService } from './planned-task.service';
import { PlannedTemplatesModule } from './planned-templates/planned-templates.module';
import PlannedTasksReact from '~/app/ProjectsPage/ProjectDashboard/PlannedTasks';
import { react2angular } from 'react2angular';

export const PlannedTasksModule = angular
  .module(
    'root.components.app.components.main.projects.project-dashboard.planned-tasks',
    [PlannedTemplatesModule, 'as.sortable'],
  )
  .service('PlannedTaskService', PlannedTaskService)
  .component('plannedTasks', 
    react2angular(PlannedTasksReact)
  ).name
