import create, { GetState, SetState } from 'zustand';
import { persist } from 'zustand/middleware';
import { Project } from '~/models/types';
import {
  ProjectsCategory,
  ProjectsStatus,
  ProjectsOrder,
  ProjectsState,
} from './types';
import {
  fetchProjectsData,
  changeProjectDate,
  changeProjectStatus,
  fetchProjectsByNewest,
} from './utils';

const useProjectsStore = create<ProjectsState>(
  persist(
    (set: SetState<ProjectsState>, get: GetState<ProjectsState>) => ({
      projects: [],
      all: true,
      has_planned_tasks: false,
      status: '',
      order: 'newest',
      category: '',
      projectsByNewest: [],
      NotificationService: null,
      fetchProjectsData: async (consumed_hours?: boolean) =>
        fetchProjectsData(set, get, consumed_hours),
      fetchProjectsByNewest: async () => fetchProjectsByNewest(set, get),
      setAll: (all: boolean) => {
        set({ all });
        get().fetchProjectsData();
      },
      setHasPlannedTasks: (has_planned_tasks: boolean) => {
        set({ has_planned_tasks });
        get().fetchProjectsData();
      },
      setStatus: (status: ProjectsStatus) => {
        set({ status });
        get().fetchProjectsData();
      },
      setOrder: (order: ProjectsOrder) => {
        set({ order });
        get().fetchProjectsData();
      },
      setCategory: (category: ProjectsCategory) => {
        set({ category });
        get().fetchProjectsData();
      },
      setNotificationService: (NotificationService) => {
        set({ NotificationService });
      },

      resetProjectsFilters: () => {
        set({
          projects: [],
          all: true,
          has_planned_tasks: false,
          status: '',
          order: 'alphabetical',
          category: '',
        });
      },

      changeProjectDate: (currentDate: Date, project: Project) =>
        changeProjectDate(get, currentDate, project),

      changeProjectStatus: (status: ProjectsStatus, project: Project) =>
        changeProjectStatus(get, status, project),
    }),
    {
      name: 'useProjectsStore',
      // Excluding projects and persisting only the settings in storage
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              !['projects', 'projectsByNewest', 'NotificationService'].includes(
                key,
              ),
          ),
        ),
    },
  ),
);

export default useProjectsStore;
