import angular from 'angular';
import { DropdownService } from './dropdown.service';
import { Dropdown } from './dropdown.directive';

import './dropdown.scss';

export const DropdownModule = angular
  .module('root.components.app.components.main.dropdown', [])
  .service('DropdownService', DropdownService)
  .directive('laborDropdown', Dropdown).name;
