import angular from 'angular';
import { TaskInputModule } from './task-input/task-input.module';
import { react2angular } from 'react2angular';
import { PlannedTasksSelectModule } from './planned-tasks-select/planned-tasks-select.module';
import { TaskService } from './task.service';
import TasksPage from '~/app/TaskPage';


export const TasksModule = angular
  .module('root.components.app.components.main.tasks', [
    TaskInputModule,
    PlannedTasksSelectModule,
  ])
  .config($stateProvider => {
    'ngInject';
    $stateProvider.state('app.main.tasks', {
      url: '/tasks',
      component: 'tasks',
    });
  })
  .service('TaskService', TaskService)
  .component('tasks',  react2angular(TasksPage, [], ['TaskService', 'ProjectService', 'PlannedTaskService', '$rootScope','$stateParams', 'NotificationService'])).name;
