import React, { useEffect, useState } from 'react';
import SearchableSelect, {
  Item,
} from '~/app/components/Select/SearchableSelect';
import { ProjectAllocation, BasicUser } from '~/models/types';
import * as S from './styles';

type Props = {
  allocations: BasicUser[];
  projectAllocations: ProjectAllocation[];
  handleSave: Function;
};

const UserSelect = ({ allocations, projectAllocations, handleSave }: Props) => {
  const [userList, setUserList] = useState<Item[]>([]);

  useEffect(() => {
    const newAllocations = allocations.map((obj) => ({
      ...obj,
      checked:
        projectAllocations.find((PA) => PA.user.id === obj.id) !==
        undefined,
    }));

    setUserList(
      newAllocations.map((obj) => ({
        ...obj,
        disabled: obj.checked,
      })),
    );
  }, [allocations, projectAllocations]);

  const selectedUsers = userList.filter((item) => item.checked);
  const selectedUsersIds = selectedUsers.map((item) => item.id);

  const newSelectedUsers = selectedUsers.filter((item) => !item.disabled);
  const newSelectedUsersIds = newSelectedUsers.map((item) => item.id);

  const calculateAlocated = () => {
    const len = selectedUsersIds.length;
    if (len === 0) {
      return ['Nenhum', 'membro alocado'];
    }
    if (len === 1) {
      return ['1', 'membro alocado'];
    }
    if (len === userList.length) {
      return ['Todos', 'os membros alocados'];
    }
    return [String(len), 'membros alocados'];
  };

  const allocated = calculateAlocated();

  return (
    <>
      <SearchableSelect
        data={userList}
        setData={(data) => setUserList(data)}
        handleSave={() => handleSave(newSelectedUsersIds)}
        searchPlaceholder={'Pesquisar membros'}
        CustomAnchorElement={
          <S.UserSelectBox>
            <S.UserSelectText>Selecione os membros</S.UserSelectText>
            <S.ArrowDown />
          </S.UserSelectBox>
        }
      />
      <S.MemberText>
        <span>
          <strong>{allocated[0]}</strong> {allocated[1]}
        </span>
        <br />
        <S.TotalMembers>
          Total de membros na empresa: {allocations.length}
        </S.TotalMembers>
      </S.MemberText>
    </>
  );
};

export default UserSelect;
