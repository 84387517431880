import React, { useState, useEffect } from 'react';
import API from '~/services/api';
import { ProjectAllocationWithCheck } from '~/models/types';
import TableRow from './TableRow';
import styled from '@emotion/styled';
import { IStateProvider } from 'angular-ui-router';
type Props = {
  projectAllocations: ProjectAllocationWithCheck[];
  setProjectAllocations: Function;
  getProjectAllocations: Function;
  rowsPerPage: number;
  activePage: number;
  OpenModal: Function;
  $state: IStateProvider;
  $window: Window;
}
const Td = styled.td`
  text-align: center;
  cursor: default;
`;
const TableBody = ({projectAllocations,rowsPerPage, activePage, OpenModal,setProjectAllocations,getProjectAllocations,$state,$window}:Props ) => {
  const blankState = (
    <tr className="user-table-body">
      <Td className='no-users' colSpan={3}>
        <span>
          Não há usuários cadastrados
        </span>
      </Td>
    </tr>
  );
  const handleProjectAllocationChange = (changedProjectAllocation) => {
    const index = projectAllocations.findIndex((obj) => obj.id === changedProjectAllocation.id);
    const newProjectAllocations = [...projectAllocations];
    newProjectAllocations[index] = changedProjectAllocation;
    setProjectAllocations(newProjectAllocations);
  } 
  const isAnySelected = !projectAllocations.every((user) => !user.checked);
  const [focus,setFocus] = useState(null);
  const rows = projectAllocations.slice((activePage-1)*rowsPerPage,rowsPerPage*activePage).map((project) =>
    <TableRow 
      projectAllocation={project}
      setProjectAllocations={(projectAllocation) => handleProjectAllocationChange(projectAllocation)}
      isAnySelected={isAnySelected}
      key={project.id}
      getProjectAllocations={getProjectAllocations}
      OpenModal={OpenModal}
      focus = {focus}
      setFocus = {setFocus}
      $state = {$state}
      $window = {$window}
    />
  );

  return (
    <tbody className="user-table-body">
      {rows.length === 0 ? blankState : rows}
    </tbody>
  )
}
export default TableBody;