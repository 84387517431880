import React from 'react';

import SocialButtonStyle from './styles';

interface SocialButtonProps {
  onClick: (string) => void;
  children: React.ReactNode;
}

const SocialButton = ({ onClick, children }: SocialButtonProps) => (
  <SocialButtonStyle onClick={onClick}>{children}</SocialButtonStyle>
);

export default SocialButton;
