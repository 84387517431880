export const DateInputDirective = $log => {
  'ngInject';

  return {
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, ngModelController) => {
      ngModelController.$parsers.push(function(value) {
        if (!/^\d{2}\/\d{2}\/\d{4}$/g.test(value)) {
          return angular.copy(ngModelController.$modelValue);
        }

        let newValue = angular.copy(ngModelController.$modelValue);

        if (!angular.isDate(newValue)) {
          newValue = new Date(newValue);
        }

        const [date, month, year] = value.split('/');
        newValue.setFullYear(year);
        newValue.setMonth(month - 1);
        newValue.setDate(date);

        return newValue;
      });
      ngModelController.$formatters.push(function(value) {
        if (!value) {
          return value;
        }

        if (!angular.isDate(value)) {
          value = new Date(value);
        }

        const pad = n => n.toString().padStart(2, '0');

        const year = value.getFullYear();
        const month = value.getMonth();
        const date = value.getDate();
        return `${pad(date)}/${pad(month + 1)}/${pad(year)}`;
      });
    },
  };
};
