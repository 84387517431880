import styled from '@emotion/styled';
import Button from '~/components/Button';

export const SubmitButton = styled(Button)`
  display: flex;
  margin-top: 4%;
`;
export const ProjectContainer = styled.div``;

export const ProjectText = styled.p`
  span {
    margin-bottom: 0;
    font-weight: 600;
  }
  font-style: normal;
  font-weight: 400;
  margin-top: 0;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: #7d7d7d;
`;

export const InputsProjectContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  > label {
    width: 80%;
  }
  Button {
    margin-left: 30%;
  }
`;

export const ModalSubtitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;

  color: #5f5f5f;
`;
