const logRegister = (_headers, _user, amplitude) => {
  const queryString = window.location.search;
  const deviceId = new URLSearchParams(queryString).get('amp_device_id');

  if (deviceId && deviceId !== 'null')
    amplitude.setAmplitudeUserDevice(deviceId);

    amplitude.setUserId(_headers.get('uid'));
    amplitude.setUserProperties({
    Team: _user.allocations[0].team.id,
  });
  amplitude.sendData('User Registered');
}

export default logRegister;