import styled from '@emotion/styled';
import { Check } from '@mui/icons-material';

export const HeaderText = styled.h2`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em;
`;

export const Text = styled.p`
  margin-bottom: 10%;
`;
export const ModalTitle = styled.h2`
  font-family: 'Bitter', serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  color: #5f5f5f;
  margin: 0 0 20px 0;
`;
export const ModalText = styled.p`
  font-size: 15px;
  color: #7d7d7d;
  margin: 0 0 20px 0;
`;
export const ModalClose = styled.div`
  top: 25px;
  right: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const ModalContent = styled.div`
  justify-content: center;

  .MuiTabs-flexContainer {
    background-color: #f6f7fb;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: #0000002e;
  }
  .MuiTabs-indicator {
    background-color: #ff3c58;
  }
  .MuiButtonBase-root.Mui-selected {
    color: #ff3c58;
  }
`;
export const TabContent = styled.div`
  padding: 24px 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const TabMessage = styled.div`
  font-size: 16px;
  color: #000000de;
  margin: 10px 0 16px 0;
`;
export const TemplatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  border-width: 1px;
  border-style: solid;
  border-color: #0000001e;
  overflow-y: auto;
  max-height: 130px;
`;
export const Template = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  background-color: #f6f7fb;
  justify-content: space-between;
  color: #000000de;
  :hover {
    background-color: #efefef;
    cursor: pointer;
  }
  .selected {
    background-color: #efefef;
  }
`;
export const CheckIcon = styled(Check)`
  height: 20px;
  width: 20px;
`;
