import angular from 'angular';
import { ConstantsModule } from './constants/constants.module';
import { DirectivesModule } from './directives/directives.module';
import { FiltersModule } from './filters/filters.module';
import { ServicesModule } from './services/services.module';

export const CommonModule = angular.module('root.components.app.common', [
  ConstantsModule,
  DirectivesModule,
  FiltersModule,
  ServicesModule,
]).name;
