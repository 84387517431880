import React from 'react';
import useProjectsTasksStore from '~/store/projectsTasks';
import DailyTasks from './DailyTasks';
import { NoTaskText } from './styles';

const TasksList = (props) => {
  const { projectsTasks } = useProjectsTasksStore();
  return (
    <>
      {Object.entries(projectsTasks)
        .reverse()
        .map(([dailyTasksDate, dailyTasks]) => (
          <DailyTasks
            projectId={props.$stateParams.projectId}
            date={dailyTasksDate}
            duration={dailyTasks.duration}
            tasksInTheDay={dailyTasks.tasks.filter(
              (task) => {
                if(!props.$stateParams.projectId)
                  return task
                if(props.$stateParams.projectId == task.projectId)
                  return task
              }
            )}
            key={dailyTasksDate}
          />
        ))}
      {Object.keys(projectsTasks).length === 0 && (
        <NoTaskText>
          Não existem tarefas registradas para o período atual.
        </NoTaskText>
      )}
    </>
  );
};

export default TasksList;
