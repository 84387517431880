import React, { CSSProperties, KeyboardEvent } from 'react';

import * as S from './styles';
import { isValid, convertNumberToTime } from './utils';

interface Props {
  label: string;
  timeValue: string;
  onChange: (time: string) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  customStyles?: CSSProperties | undefined;
  disabled?: boolean;
}

export default function TimeInput({
  label,
  timeValue,
  onChange,
  onKeyPress,
  customStyles,
  disabled,
}: Props) {
  function onChangeTime(e) {
    const { value } = e.currentTarget;
    const val = value;

    if (isValid(val)) {
      onChange(convertNumberToTime(val));
    }
  }

  return (
    <S.Container style={customStyles}>
      <S.Label>{label}</S.Label>
      <S.Input
        type="text"
        value={timeValue}
        onChange={onChangeTime}
        onKeyPress={onKeyPress}
        disabled={disabled}
      />
    </S.Container>
  );
}
