export class ProjectService {
  constructor($rootScope, $http, $log, API_URL) {
    'ngInject';

    this.allProjects = null;
    this.recentProjects = null;
    this.$rootScope = $rootScope;
    this.$http = $http;
    this.$log = $log;
    this.API_URL = API_URL;
  }

  clearProjectsSingleton() {
    this.allProjects = null;
  }

  getRecentProjects() {
    this.recentProjects = this.$http.get(`${this.API_URL}/projects/last_three_task_projects`);
    return this.recentProjects
  }

  
    
      getAllProjects(reload = false) {
        if (this.allProjects == null || reload == true) {
          this.allProjects = this.$http.get(`${this.API_URL}/projects?all=true`);
        }
    
        return this.allProjects;
      }

  getActiveProjects(reload = false) {
    return this.getAllProjects(reload).then(response => {
      return {
        data: response.data.filter(p => !p.deletedAt && p.status != 'finished'),
      };
    });
  }

  getFinishedProjects(reload = false) {
    return this.getAllProjects(reload).then(response => {
      return {
        data: response.data.filter(p => !p.deletedAt && p.status == 'finished'),
      };
    });
  }

  getProjectsByCategory(category) {
    return this.getAllProjects().then(response => {
      return { data: response.data.filter(p => p.category === category) };
    });
  }

  getProjectById(id, reload=false) {
    id = parseInt(id);

    return this.getAllProjects(reload).then(response => {
      return { data: response.data.find(p => p.id === id) };
    });
  }

  getProjectsWithPrice() {
    return this.getAllProjects().then(response => {
      return { data: response.data.filter(p => p.price !== null) };
    });
  }

  getAllProjectsWithPlannedTasks() {
    return this.$http.get(
      `${this.API_URL}/projects?all=true&has_planned_tasks=true`,
    );
  }

  getTrelloBoard(id) {
    return this.$http.get(
      `${this.API_URL}/projects/${id}/trello_board`,
    ).then(response => response.data);
  }

  saveProject(project) {
    return this.$http
      .post(`${this.API_URL}/projects`, project)
      .then(response => {
        this.$rootScope.$broadcast('project-created');
        return response;
      });
  }

  updateProject(project) {
    return this.$http
      .put(`${this.API_URL}/projects/${project.id}`, project)
      .then(response => {
        this.$rootScope.$broadcast('project-updated');
        return response;
      });
  }

  getProjectStatistics(project, starting, ending) {
    return this.$http({
      url: `${this.API_URL}/projects/${project.id}/statistics`,
      method: 'GET',
      params: { starting, ending },
    });
  }

  disableProject(project) {
    return this.$http
      .post(`${this.API_URL}/projects/${project.id}/disable`)
      .then(response => {
        this.$rootScope.$broadcast('project-updated');

        return response;
      });
  }

  restoreProject(project) {
    return this.$http
      .post(`${this.API_URL}/projects/${project.id}/restore`)
      .then(response => {
        this.$rootScope.$broadcast('project-updated');
        return response;
      });
  }

  deleteProject(project) {
    return this.$http
      .delete(`${this.API_URL}/projects/${project.id}`)
      .then(response => {
        this.$rootScope.$broadcast('project-updated');
        return response;
      });
  }

  getFilteredProjects(filters) {
    const queryString = Object.keys(filters)
                              .map((key) => `${key}=${filters[key]}`)
                              .join("&")

    return this.$http.get(`${this.API_URL}/projects?${queryString}`);
  }

  getMonthTasks(id) {
    id = parseInt(id);

    return this.$http.get(
      `${this.API_URL}/projects/${id}/month_tasks`,
    );
  }
}
