import React, { useState, useEffect } from 'react';
import API from '~/services/api';
import { ProjectAllocationWithCheck } from '~/models/types';
import { RoleSelect } from './styles';
import Checkbox from '@mui/material/Checkbox';
import * as S from './styles'
import { IStateProvider } from 'angular-ui-router';
const ROLE_OPTIONS = [
  { value: 'gerente', label: 'Gerente' },
  { value: 'colaborador', label: 'Colaborador' },
];
const formatOptionLabel = (data,context) => {

  return (context.context === "value") || (data.value != context.selectValue["0"].value) ? (
    <div style={{ display: "flex"}}>
      <div style={{marginLeft: "10px"}}>{data.label}</div>
    </div>
  ):(
    <div style={{ display: "flex",justifyContent: "space-between"}}>
      <div style={{marginLeft: "10px"}}>
        {data.label} 
      </div>
      <div>
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginLeft:"10px"}}>
          <path d="M4.0001 7.7799L1.2201 4.9999L0.273438 5.9399L4.0001 9.66656L12.0001 1.66656L11.0601 0.726562L4.0001 7.7799Z" fill="#959595"/>
        </svg>
      </div>
    </div>
  )
  
};
type Props = {
  projectAllocation:ProjectAllocationWithCheck,
  OpenModal:Function,
  setProjectAllocations:Function,
  getProjectAllocations: Function,
  isAnySelected:Boolean,
  focus: Number | null,
  setFocus: Function,
  $state: IStateProvider;
  $window: Window;
}
const TableRow = ({projectAllocation,OpenModal,setProjectAllocations,isAnySelected,focus,setFocus,getProjectAllocations,$state,$window}:Props) => {
  const handleRoleChange = async (allocation, role) => {
    const newAllocation = { ...allocation, role: role };
    await API.user.change_project_allocation(newAllocation, allocation.user.id,allocation.id);
    getProjectAllocations()
  }
  const handleCheckBoxClick = () =>{
    const newProjectAllocation = {...projectAllocation, checked: !projectAllocation.checked};
    setProjectAllocations(newProjectAllocation);
  }
  const [role, setRole] = useState(projectAllocation.role);

  useEffect(() => {
    setRole(projectAllocation.role);
  }, [projectAllocation])
  return (
    <S.Tr
    onMouseOver={() => {setFocus(projectAllocation.id)}}
    onMouseOut={() => {setFocus(null)}}
    >
        <td className='name'>
          <Checkbox
            disableRipple={true}
            checked={projectAllocation.checked}
            name={projectAllocation.user.name}
            onChange={handleCheckBoxClick}
            size="small"
            sx={{
              color: '#959595',
              '&.Mui-checked': {
                color: '#FF3C58',
              },
              visibility: (focus === projectAllocation.id) || isAnySelected ? "visible" : "hidden",
            }}
          />
          <span className='user_name'
          onClick={  () =>{

            const url = $state.href('app.main.user', {
              userId: projectAllocation.user.id,
            });
            $window.open(url,'_blank');
          }}
          >
            {projectAllocation.user.name}</span>
          </td>
        <td className='Select'>
          <RoleSelect
              classNamePrefix="UserTableSelect"
              className="UserTableSelect"
              isSearchable={false}
              onChange={(event) => {
                handleRoleChange(projectAllocation,event.value);
              }}
              formatOptionLabel={formatOptionLabel}
              value={ROLE_OPTIONS.find((elem) => elem.value === role)}
              defaultValue={ROLE_OPTIONS.find((elem) => elem.value === role)}
              placeholder={ROLE_OPTIONS.find((elem) => elem.value === role).label}
              options={ROLE_OPTIONS}
              styles={{
                option: (base) => ({
                  ...base,
                  backgroundColor:'white',
                  color: "#5F5F5F",
                  ":hover":{
                    backgroundColor: "#F6F7FB",
                  },
                }),
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
          />
        </td>
        {/* <td><span>permissions?</span></td> */}
        <S.RemoveMemberCell className='remove_member'>
          <S.RemoveMember onClick={
            () =>
            OpenModal([projectAllocation])}>
            <S.RemovePerson />
              Remover membro deste projeto
          </S.RemoveMember>
        </S.RemoveMemberCell>
    </S.Tr>
  )
}
export default TableRow;