import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const UserSelectBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 50px;
  border: 1px solid rgba(149, 149, 149, 0.2);
  box-sizing: border-box;
  padding: 15px 0px;
  background-color: white;
  align-items: center;
`;

export const UserSelectText = styled.span`
  margin-left: 20px;
`;

export const ArrowDown = styled(KeyboardArrowDownIcon)`
  margin-right: 16px;
`;

export const MemberText = styled.div``;

export const TotalMembers = styled.span`
  font-size: 12px;
  color: #7d7d7d;
`;
