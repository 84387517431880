export const PopoverWrapper = ($document, $timeout, $parse) => {
  'ngInject';

  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      const triggerElement = angular.element(
        element[0].querySelectorAll('[popover-trigger]')[0],
      );
      const htmlElement = angular.element(
        element[0].querySelectorAll('[popover-html]')[0],
      );

      triggerElement.bind('click', e => {
        if (htmlElement.css('display') == 'block') {
          return;
        }

        scope.$broadcast('popover-opened');

        htmlElement.css('display', 'block');
        $timeout(() => {
          $document.on('click', closePopover);
        });
      });

      htmlElement.bind('click', e => {
        e.stopPropagation();
      });

      scope.$on('popover-close-all', closePopover);

      // //////////////

      function closePopover() {
        $document.off('click', closePopover);
        htmlElement.css('display', 'none');
        scope.$broadcast('popover-closed');
      }
    },
  };
};
