import styled from '@emotion/styled';
import { mq } from '../../../helpers/responsive';

export default styled.section`
  ${mq({
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    paddingLeft: ['21px', '47px', '67px', '88px'],
    paddingRight: ['21px', '47px', '67px', '88px'],
  })}
`;
