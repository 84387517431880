import React from 'react';
import TextFieldColorPicker from './TextFieldColorPicker';
import TextClient from './TextClient';
import DateField from './DateField';
import StatusList from './StatusList';
import EstimatedHours from './EstimatedHours';
import styled from '@emotion/styled';
import TotalCharged from './TotalCharged';
import HourlyCharged from './HourlyCharged';
import { Input } from '../NewProjectModal/styles';
import SelectTeam from './SelectTeam';

// TODO: Rework this component to avoid Eslint errors

interface TypesOfQuestions {
  [type: string]: React.ReactNode;
}

export enum TypesOfInput {
  textColorPicker = 'textColorPicker',
  textClient = 'textClient',
  dateField = 'dateField',
  statusList = 'statusList',
  estimatedHours = 'estimatedHours',
  totalCharged = 'totalCharged',
  hourlyCharged = 'hourlyCharged',
}
interface Props {
  title: string;
  // type:
  //   | 'textColorPicker'
  //   | 'textClient'
  //   | 'dateField'
  //   | 'statusList'
  //   | 'estimatedHours'
  //   | 'totalCharged'
  //   | 'hourlyCharged'
  //   | 'selectTeam';
}

interface ProjectValueProps {
  name: string;
  colorName?: string | null;
  statusName?: string;
  dateData?: string | null;
  onChange?: Function;
  value?: string | null;
  initialHours?: number | null;
  totalPrice?: number | string | null;
  estimatedHours?: number | null;
}
interface Props extends ProjectValueProps {
  title: string;
  type: TypesOfInput;
}

const Container = styled.div``;

const InputField: React.FC<Props> = ({
  title,
  type,
  name,
  colorName,
  statusName,
  dateData,
  onChange,
  value,
  initialHours,
  totalPrice,
  estimatedHours,
}) => {
  const questionProps: ProjectValueProps = {
    name,
    colorName,
    statusName,
    dateData,
    onChange,
    value,
    initialHours,
    totalPrice,
    estimatedHours,
  };
  const TYPES_OF_QUESTIONS: TypesOfQuestions = {
    textColorPicker: <TextFieldColorPicker {...questionProps} />,
    textClient: <TextClient {...questionProps} />,
    dateField: <DateField {...questionProps} />,
    statusList: <StatusList {...questionProps} />,
    estimatedHours: <EstimatedHours {...questionProps} />,
    totalCharged: <TotalCharged {...questionProps} />,
    hourlyCharged: <HourlyCharged {...questionProps} />,
  };

  return (
    <div>
      <p>
        <label htmlFor={name}>{title}</label>
      </p>
      {TYPES_OF_QUESTIONS[type]}
    </div>
  );
};

export default InputField;
