import amplitude from 'amplitude-js';

class AmplitudeUserStore {
  constructor(user, amplitudeKey) {
    amplitude.getInstance().init(amplitudeKey, user, {
      includeUtm: true,
      includeReferrer: true,
    });
  }

  setUserId(userId) {
    amplitude.getInstance().setUserId(userId);
  }

  sendData(eventType, eventProperties) {
    this.validateDeviceId();
    amplitude.getInstance().logEvent(eventType, eventProperties);
  }

  setUserProperties(properties) {
    amplitude.getInstance().setUserProperties(properties);
  }

  setAmplitudeUserDevice(deviceId) {
    amplitude.getInstance().setDeviceId(deviceId);
    this.validateDeviceId();
  }

  validateDeviceId() {
    if (!amplitude.getInstance().options.deviceId) {
      amplitude.getInstance().regenerateDeviceId();
    }
  }

  logout() {
    this.setUserId(null);
    amplitude.getInstance().regenerateDeviceId();
  }
}

export default AmplitudeUserStore;
