export class AllocationService {
  constructor($http, $log, TimerService, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.TimerService = TimerService;
    this.API_URL = API_URL;
    this.loggedUser = null;
  }

  getAllocations(team) {
    return this.$http({
      url: `${this.API_URL}/teams/${team.id}/allocations`,
      method: 'GET',
      params: { enabled: team.enabled }
    });
  }

  saveAllocation(team, user) {
    return this.$http.post(
      `${this.API_URL}/teams/${team.id}/allocations`,
      user,
    );
  }

  getAllocation(team, id) {
    return this.$http.get(`${this.API_URL}/teams/${team.id}/allocations/${id}`);
  }

  updateAllocation(team, allocation) {
    return this.$http.put(
      `${this.API_URL}/teams/${team.id}/allocations/${allocation.id}`,
      allocation,
    );
  }

  disableAllocation(team, allocation) {
    return this.$http.put(
      `${this.API_URL}/teams/${team.id}/allocations/${allocation.id}/disable`,
    );
  }

  restoreAllocation(team, allocation) {
    return this.$http.put(
      `${this.API_URL}/teams/${team.id}/allocations/${allocation.id}/restore`,
    );
  }

  deleteAllocation(team, allocation) {
    return this.$http.delete(
      `${this.API_URL}/teams/${team.id}/allocations/${allocation.id}`,
    );
  }
}
