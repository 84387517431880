import styled from "@emotion/styled";
import select from "react-select";

export const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
`
export const Content = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  align-items: start;
  justify-content: start;
`
export const Title = styled.span`
  font-size: 1.5rem;
  font-weight: bold ;
  color: #5f5f5f;
  margin-bottom: 2.5rem;
`
export const SectionTitle = styled.span`
  color: #7d7d7d;
  font-weight: bold;
  &.board{
    margin: 0 0 45px 0;
  }
  &.column{
    margin: 35px 0 15px 0;
  }
`
export const Disclaimer = styled.span`
  color: #7d7d7d;
  max-width: 592px;
  font-size: 0.8125rem;
  `
export const Description = styled.span`
  color: #5f5f5f;
  margin-bottom: 25px;
`
export const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`
export const ButtonRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  gap: 15px;
`
export const Select = styled(select)`
  -webkit-appearance: none;
  border: none;
  background: none;
  color: inherit;
  text-align: right;
  background-color: white;
  width: 226px;
  height: 36px;
  font-size: 14px;
  display: inline-block;
  /* margin-left: 8px; */
  margin-right: 21px;

  .columnSelect__single-value{
    color: hsl(0, 0%, 50%);
    margin-left: 2px;
    margin-right: 2px;
    overflow: visible;

  }
  .columnSelect__control {
    background-color: inherit;
    border: 1px solid rgba(149, 149, 149, 0.2);
    border-radius: 0;
    :hover{
      border: 1px solid #4F587A;
    }
  }
  .columnSelect__control--menu-is-open {
    box-shadow: none;
    border: 1px solid #4F587A;
  }

  .columnSelect__control--is-focused {
    box-shadow: none;
  }
  .columnSelect__menu{
    background-color: inherit;
    border: 1px solid rgba(149, 149, 149, 0.2);
    border-radius: 0;
    text-align: left;

  }
  .columnSelect__indicators{
    .columnSelect__dropdown-indicator{
      color: #7D7D7D;
      padding: 8px 20px 8px 8px;
    }
  }
  .columnSelect__value-container{
    margin-left: 4px;
    overflow: visible;
    width:21px;
    padding: 14px 20px;
  }
  .columnSelect__placeholder {
    text-align: start;
  }
`;
export const ColumnLabel = styled.label`
  display: block;
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
`