import styled from '@emotion/styled';
import theme from '~/theme';
import Input from '~/components/Input';
import Button from '~/components/Button';

export const ModalClose = styled.div`
  display: flex;
  cursor: pointer;
  width: 24px;
  margin-right: 14px;
`;

export const ModalContainer = styled.div`
  background-color: ${theme.colors.white};
  box-shadow: 0 0 8px 12px rgba(0, 0, 0, 0.02);
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  margin-top: 8px;

  .sunset-header-text {
    margin-left: 20px;
    font-weight: 700;
    font-size: 18px;
  }
`;

export const FormContainer = styled.div`
  padding: 24px 42px;
`;

export const InputWrapper = styled.div<{ fullWidth?: boolean }>`
  width: ${(props) => props.fullWidth ? '100%' : '30%'};
`;

export const StyledLabel = styled.label<{ isOnFocus: boolean }>`
  font-size: 11px;
  color: ${(props) => props.isOnFocus ? theme.colors.primary : theme.colors.black2};
`;

export const StyledInput = styled(Input)`
  height: 2px;
  border-width: 0 0 1px;
  padding: 14px 20px 14px 0;

  &:focus {
    border-color: ${theme.colors.primary};
  }
`;

export const PasswordContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  height: 36px;
  width: 194px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  justify-content: center;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  &:hover {
    background-color: ${theme.colors.primary};
  }
`;

export const TextContent = styled.div`
  padding: 0 20px;
`

export const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(246, 247, 251, 0.97)',
    zIndex: 100,
  },
  content: {
    position: 'relative',
    top: '60%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    backgroundColor: 'transparent',
    borderRadius: 0,
    border: 'none',
    padding: '40px 35px',
    width: '580px',
    transform: 'translate(-50%, -80%)',
    marginTop: '200px',
  },
};