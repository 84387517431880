export class LevelService {
  constructor($http, $log, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.API_URL = API_URL;
  }

  getLevels() {
    return this.$http.get(`${this.API_URL}/levels.json`);
  }
}
