import React from 'react';
import ReactModal from 'react-modal';

import * as S from './styles';
import './reactModal.scss';

interface SunsetModalProps {
  setSunsetModal: (status: boolean) => void;
  isOpen: boolean;
}

const SunsetModal: React.FC<SunsetModalProps> = ({
  setSunsetModal,
  isOpen,
}: SunsetModalProps) => (
  <ReactModal
    closeTimeoutMS={180}
    isOpen={isOpen}
    style={S.customStyles}
    ariaHideApp={false}
    onRequestClose={() => setSunsetModal(false)}
  >
    <S.ModalContainer>
      <S.ModalHeader>
        <div className="sunset-header-text">
          Encerramento das Atividades do Labor
        </div>
        <S.ModalClose>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            onClick={() => setSunsetModal(false)}
          >
            <path
              fill="#959595"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </S.ModalClose>
      </S.ModalHeader>
      <S.TextContent>
        <p>
          Após mais de 6 anos ajudando pessoas e empresas a controlarem melhor
          seu tempo e a gerirem seus projetos de forma eficiente, tomamos a
          decisão de encerrar nossas atividades.
        </p>

        <p>
          Ao longo desses anos, foi um privilégio colaborar com você e sua
          equipe, proporcionando uma experiência que facilitasse a gestão de
          horas e a eficiência dos seus projetos. Somos extremamente gratos pela
          confiança depositada em nós e pelo feedback constante que nos ajudou a
          melhorar continuamente.
        </p>

        <p>
          Com esta decisão de encerrar nossas atividades o Labor deixará de
          operar em 60 dias a partir desta data.
        </p>

        <p>
          <b>O que isso significa para você?</b>
        </p>

        <p>
          <ol>
            <li>
              A plataforma Labor permanecerá ativa pelos próximos 60 dias, até
              30/08/2024. Durante esse período você continuará com acesso à
              plataforma e a todas as suas funcionalidades.
            </li>
            <li>
              Faremos o estorno de quaisquer valores de planos cujos pagamentos
              se estendam para além da data de encerramento.
            </li>
            <li>
              Após 30/08/2024 o Labor será desativado e você não terá mais
              acesso à plataforma.
            </li>
          </ol>
        </p>

        <p>
          <b>Exportação de Dados</b>
        </p>

        <p>
          Reconhecemos a importância das informações armazenadas no Labor para a
          continuidade dos seus projetos. Portanto, caso você precise exportar
          seus dados de projetos por favor envie um email para{' '}
          <u>contato@getlabor.com.br</u> com o título &quot;Exportação de
          Dados&quot; ou entre em contato por meio do nosso chat de suporte.
        </p>

        <p>
          <b>Importante!</b> O prazo máximo de recebimento de pedidos de
          exportação de dados será de 30 dias após a data de encerramento de
          Labor, até 29/09/2024.
        </p>

        <p>
          Outras dúvidas podem ser sanadas através do nosso chat de suporte ou
          pelo email <u>contato@getlabor.com.br</u>
        </p>

        <p>
          Mais uma vez, agradecemos por fazer parte da nossa jornada e por nos
          permitir contribuir com o sucesso dos seus projetos.
        </p>

        <p>
          Atenciosamente,
          <br />
          Equipe Labor
        </p>
      </S.TextContent>
    </S.ModalContainer>
  </ReactModal>
);

export default SunsetModal;
