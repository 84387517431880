import styled from '@emotion/styled';
import Button from '~/components/Button';
import theme from '~/theme';

interface WrapperProps {
  margin?: string
}

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${theme.colors.blue1};
  border-radius: 6px;
  padding: 24px;
  width: 359px;
  margin: ${props => props.margin};
  position: absolute;
  z-index: 2;
`;

export const HeaderWrapper = styled.div `
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.colors.containerBg};
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BodyWrapper = styled.div `
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.containerBg};
  overflow-wrap: break-word;
  margin-top: 12px;
`;

export const InfoWrapper = styled.div `
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.containerBg};
  overflow-wrap: break-word;
  margin-top: 12px;
`;

export const StyledButton = styled(Button) `
  background: ${theme.colors.secondary};
  color: ${theme.colors.gray25};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: 40px;
`;

export const ModalClose = styled.div`
  display: flex;
  cursor: pointer;
  width: 24px;
  margin-right: 14px;
`;

