export const TimeInputDirective = $log => {
  'ngInject';

  return {
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, ngModelController) => {
      ngModelController.$parsers.push(function(value) {
        if (!/^\d{1,2}:\d{1,2}$/g.test(value)) {
          return angular.copy(ngModelController.$modelValue);
        }

        let newValue = angular.copy(ngModelController.$modelValue);

        if (!angular.isDate(newValue)) {
          newValue = new Date(newValue);
        }

        const [hour, minute] = value.split(':');
        newValue.setHours(hour);
        newValue.setMinutes(minute);

        return newValue;
      });
      ngModelController.$formatters.push(function(value) {
        if (!value) {
          return value;
        }

        if (!angular.isDate(value)) {
          value = new Date(value);
        }

        const hours = `0${value.getHours()}`;
        const minutes = `0${value.getMinutes()}`;
        return `${hours.substring(
          hours.length - 2,
          hours.length,
        )}:${minutes.substring(minutes.length - 2, minutes.length)}`;
      });
    },
  };
};
