import React, { useEffect, useState } from 'react';
import useProjectsTasksStore from '~/store/projectsTasks';
import TaskDurationBar from './TaskDurationBar';
import TasksList from './TasksList';

import {
  LoadMoreTasksButton,
  ButtonContainer,
  StyledCircularProgress,
} from './styles';

const TasksPage = (props) => {
  const {
    fetchProjectsTasks,
    increaseNumberOfLoadMorePressed,
    removeNumberOfLoadMorePressed,
  } = useProjectsTasksStore();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    removeNumberOfLoadMorePressed();
    fetchProjectsTasks(props.$stateParams.projectId);
    return () => {
      removeNumberOfLoadMorePressed();
    };
  }, []);
  return (
    <div>
      {!props.$stateParams.projectId && <TaskDurationBar />}
      {TasksList(props)}

      <ButtonContainer>
        {loading ? (
          <StyledCircularProgress color="warning" />
        ) : (
          <LoadMoreTasksButton
            onClick={() => {
              setLoading(true);
              increaseNumberOfLoadMorePressed();
              fetchProjectsTasks(props.$stateParams.projectId);
              setTimeout(() => {
                setLoading(false);
              }, 500);
            }}
          >
            Carregar Mês Anterior
          </LoadMoreTasksButton>
        )}
      </ButtonContainer>
    </div>
  );
};

export default TasksPage;
