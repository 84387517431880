import templateUrl from './list-select.html';

export const ListSelectComponent = {
  template: templateUrl,
  bindings: {
    list: '<',
    onSelect: '&',
    valueAttribute: '@',
    textAttribute: '@',
    selectedValue: '<',
    emptyOption: '@',
  },
  controller: class ListSelectController {
    constructor($scope, EventEmitter) {
      'ngInject';

      this.$scope = $scope;
      this.EventEmitter = EventEmitter;
    }

    $onInit() {
      this.selectedOption = null;
      this.updateSelectedOption();

      this.$scope.$watch('$ctrl.selectedValue', () => {
        this.updateSelectedOption();
      });
    }

    updateSelectedOption() {
      if (this.selectedValue) {
        this.selectedOption = this.list.find(option => {
          return option[this.valueAttribute] === this.selectedValue;
        });
      } else {
        this.selectedOption = null;
      }
    }

    select(option) {
      this.selectedOption = option;
      this.onSelect(
        this.EventEmitter({
          selected: option,
        }),
      );
    }
  },
};
