export class UtilsService {
  getLastMonths(count) {
    count = count || 12;

    const months = [];
    const currentDate = new Date();
    const monthDescriptions = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    for (let i = 0; i < count; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1,
      );
      months.push({
        id: `${date.getFullYear()}-${date.getMonth()}`,
        name: `${monthDescriptions[date.getMonth()]}, ${date.getFullYear()}`,
        month: date.getMonth(),
        year: date.getFullYear(),
      });
    }

    return months;
  }

  pad(number, width = 2, padCharacter = '0') {
    number += '';
    return number.length >= width
      ? number
      : new Array(width - number.length + 1).join(padCharacter) + number;
  }
}
