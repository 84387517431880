import styled from '@emotion/styled';

export const Title = styled.p`
  margin-left: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: #5f5f5f;
`;

export const BodyText = styled.p`
  margin-top: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #5f5f5f;

  span {
    font-weight: bold;
  }
`;

export const Container = styled.div``;

export const LinkButton = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-left: 17px;
  cursor: pointer;
  text-decoration-line: underline;

  color: #4f587a;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: #edf2ff;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
