import React, { useState, useEffect, createRef } from 'react';
import moment from 'moment';
import MonthPicker from '~/app/components/CalendarPicker/MonthPicker';
import DatePicker from '~/app/components/TimerCalendar/DatePicker';
import TimeInput from '~/app/components/TimeInput';

import * as S from './styles';

const getTime = (time: number) => (time < 10 ? `0${time}` : `${time}`);

const generateDateTime = (date, time) => {
  const formatedTime = moment(time, 'HH:mm:ss').toDate();
  return moment(
    date.setHours(
      formatedTime.getHours(),
      formatedTime.getMinutes(),
      formatedTime.getSeconds(),
    ),
  ).toDate();
};

interface Props {
  disabled?: boolean;
  start: Date;
  end: Date;
  inTaskList?: boolean;
  closeCalendar: () => void;
  updateTask: (startDate: Date) => void;
  createRangedTask: (startDate: Date, endDate: Date) => void;
}

export default function TimerCalendar({
  disabled = false,
  start,
  end,
  closeCalendar,
  updateTask,
  inTaskList = false,
  createRangedTask,
}: Props) {
  const calendarRef: React.RefObject<HTMLInputElement> = createRef(); // TODO: Fix type
  const firstDayThisMonth = start;
  const startSeconds = getTime(start.getSeconds());
  const endSeconds = getTime(end.getSeconds());
  const [currentMonth, setCurrentMonth] = useState(firstDayThisMonth);
  const [date, setDate] = useState(start);
  const [startTime, setStartTime] = useState(
    `${getTime(start.getHours())}:${getTime(start.getMinutes())}`,
  );
  const [endTime, setEndTime] = useState(
    `${getTime(end.getHours())}:${getTime(end.getMinutes())}`,
  );

  const shouldUpdateTask = () => {
    const endDateTime = moment(endTime, 'HH:mm').toDate();

    if (currentTime.getTime() !== endDateTime.getTime()) {
      updateTask(
        endDateTime > currentTime
          ? moment(endDateTime.setDate(endDateTime.getDate() + 1)).toDate()
          : endDateTime,
      );
    }
  };

  const shouldCreateRangedTask = () => {
    const startDateTime = generateDateTime(date, startTime);
    const endDateTime = generateDateTime(date, endTime);
    if (inTaskList) {
      startDateTime.setSeconds(parseInt(startSeconds, 10));
      endDateTime.setSeconds(parseInt(endSeconds, 10));
      createRangedTask(startDateTime, endDateTime);
    } else if (endDateTime.getTime() !== startDateTime.getTime()) {
      createRangedTask(startDateTime, endDateTime);
    }
  };

  const handleOnClickEvent = (e) => {
    const eventTarger = e.target;

    if (
      calendarRef &&
      calendarRef.current &&
      !calendarRef.current.contains(eventTarger)
    ) {
      if (disabled) {
        shouldUpdateTask();
      } else {
        shouldCreateRangedTask();
      }
      closeCalendar();
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', (e) => handleOnClickEvent(e));
    return () => {
      window.removeEventListener('mousedown', handleOnClickEvent);
    };
  }, [calendarRef]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (disabled) {
        shouldUpdateTask();
      } else {
        shouldCreateRangedTask();
      }
      closeCalendar();
    }
  };

  return (
    <S.Wrapper ref={calendarRef}>
      <S.InputContainer>
        <div data-testid="start-time-input">
          <TimeInput
            label={'Inicio'}
            timeValue={startTime}
            onChange={setStartTime}
            customStyles={{ marginRight: '10px' }}
            onKeyPress={handleKeyPress}
          />
        </div>
        <TimeInput
          label={'Fim'}
          timeValue={endTime}
          onChange={setEndTime}
          disabled={disabled}
          onKeyPress={handleKeyPress}
        />
      </S.InputContainer>
      {!disabled && (
        <S.CalendarContainer style={{ marginLeft: 0 }}>
          <MonthPicker
            month={currentMonth}
            onChange={(month) => setCurrentMonth(month)}
          />
          <DatePicker
            month={currentMonth}
            currentDate={date}
            setCurrentDate={setDate}
          />
        </S.CalendarContainer>
      )}
    </S.Wrapper>
  );
}
