import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import chroma from 'chroma-js';

import CustomLegendContent from './CustomLegendContent';
import AxisLabel from './CustomAxisLabel';

function formatDate(string) {
  const parts = string.match(/(\d{4})(\d{2})(\d{2})/);
  const month = parts[2];
  const day = parts[3];

  return `${day}/${month}`;
}

export default function DailyChart({ dailyHours, fillColor }) {
  const data = Object.entries(dailyHours).map(([date, values]) => ({
    date: formatDate(date),
    ...Object.entries(values).reduce(
      (acc, [key, value]) => ({
        ...acc, // eslint-disable-next-line
        [key]: parseInt(value),
      }),
      {},
    ),
  }));

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="7 7" />
        <XAxis dataKey="date" tick={{ fontSize: '0.8em' }} />
        <YAxis
          tick={{ fontSize: '0.8em' }}
          label={
            <AxisLabel axisType="yAxis" x={10} y={70} height={200}>
              Quantidade de Horas
            </AxisLabel>
          }
        />
        <Tooltip />
        <Legend verticalAlign="top" height={40} content={CustomLegendContent} />
        <Bar
          dataKey="accomplished"
          stroke={fillColor || '#8884d8'}
          fillOpacity={1}
          fill={fillColor || '#8884d8'}
          name="Horas realizadas"
        />
        <Bar
          dataKey="expected"
          stroke={chroma(fillColor || '#8884d8')
            .brighten(1.5)
            .hex()}
          fillOpacity={1}
          fill={chroma(fillColor || '#8884d8')
            .brighten(1.5)
            .hex()}
          name="Horas previstas"
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

DailyChart.propTypes = {
  dailyHours: PropTypes.shape(
    PropTypes.shape({
      accomplished: PropTypes.number,
      cumulativeAccomplished: PropTypes.number,
      cumulativeExpected: PropTypes.number,
      expected: PropTypes.number,
    }),
  ),
  fillColor: PropTypes.string,
};
