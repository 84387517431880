import React from 'react';
import styled from '@emotion/styled';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StoreIcon from '@mui/icons-material/Store';
import { RadioLabel, RadioText } from './styles'; // TODO rever pq ja ta declarado

const FormControlLabel = styled(MuiFormControlLabel)`
  display: flex;
  flex-direction: row;
`;

interface Props {
  name?: string;
  typeData?: string;
  onChange: Function;
}

const RadioType: React.FC<Props> = ({ name, typeData, onChange }) => {
  const handleChange = (e) => {
    onChange(name, e.target.value);
  };

  return (
    <FormControl>
      <RadioGroup row name={name} onChange={handleChange} value={typeData}>
        <FormControlLabel
          value="external"
          label={
            <RadioLabel>
              <AttachMoneyIcon />
              <RadioText>Pago</RadioText>
            </RadioLabel>
          }
          control={
            <Radio
              sx={{
                '&:hover': { color: '#FF3C58' },
                '&.Mui-checked': { color: '#FF3C58' },
              }}
            />
          }
        />
        <FormControlLabel
          value="internal"
          label={
            <RadioLabel>
              <StoreIcon />
              <RadioText>Interno</RadioText>
            </RadioLabel>
          }
          control={
            <Radio
              sx={{
                '&:hover': { color: '#FF3C58' },
                '&.Mui-checked': { color: '#FF3C58' },
              }}
            />
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioType;
