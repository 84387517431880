export class CheckVersionService {
  constructor($http, $log, $timeout, $rootScope) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.$timeout = $timeout;
    this.checkRunning = false;
    this.$rootScope = $rootScope;
    this.$rootScope.newVersionAvailable = false;
  }

  checkVersion() {
    if (this.checkRunning || this.$rootScope.newVersionAvailable) {
      return;
    }

    this.checkRunning = true;
    // TODO: Pegar o BaseURL de um serviço que pegue da variável de ambiente
    let baseUrl = 'https://app.getlabor.com.br';

    if (NODE_ENV === 'production') {
      baseUrl = 'https://app.getlabor.com.br';
    } else if (NODE_ENV === 'staging') {
      baseUrl = 'https://app.staging.getlabor.com.br';
    } else if (NODE_ENV === 'development' && USE_SSL === 'true') {
      baseUrl = 'https://labor.local:3002';
    } else {
      baseUrl = 'http://localhost:3001';
    }

    this.$http
      .get(`${baseUrl}/check-version?version=${SOURCE_VERSION}`)
      .then(response => {
        if (response.data.version && response.data.version != SOURCE_VERSION) {
          this.$rootScope.newVersionAvailable = true;
          this.$rootScope.$broadcast('newVersionAvailable');
        } else if (!response.data.version) {
          this._dealWithPollingResponse();
        }

        return response;
      })
      .catch(err => {
        this._dealWithPollingResponse();
      });
  }

  _dealWithPollingResponse() {
    this.checkRunning = false;
    this.$timeout(() => {
      this.checkVersion();
    }, 10000);
  }

  isCheckVersionPending() {
    return !!this.$http.pendingRequests.find(request => {
      return request.url.indexOf('/check-version?') >= 0;
    });
  }
}
