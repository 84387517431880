import React, { useEffect, useState } from 'react';
import trello from '~/services/api/trello';
import * as S from '../ConnectionModal/styles';
import * as TS from '../styles';
import Modal from '~/components/Modal';
import Button, { LightButton } from '~/components/Button';
import styled from '@emotion/styled';
import team from '~/services/api/team';
interface ModalProps {
  isOpen?: boolean;
  onRequestClose: () => void;
  selectedBoard?: any;
  projectId: string;
}

const Input = styled.input`
  width: 80%;
  height: 26px;
  padding: 14px 20px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  border-color: rgba(149, 149, 149, 0.2);
  border-width: 1px;
  border-radius: 0px;
  max-width: 546px;
  :focus {
    outline: none;
  }
`;
const NewBoardModal = ({
  isOpen,
  onRequestClose,
  selectedBoard,
  projectId,
}: ModalProps) => {
  const [boardName, setBoardName] = useState('');
  const [columnNames, setColumnNames] = useState(['A Fazer', 'Finalizadas']);
  const [valid, setValid] = useState([true, true, true]);

  const handleConnect = async () => {
    try{

      let board = await trello.createBoard(boardName,false);
      const todoList = await trello.createList(board.id, columnNames[0], 1);
      const doneList = await trello.createList(board.id, columnNames[1], 1);
      const body = {
        board_id: board.id,
        done_list_id: doneList.id,
        todo_list_id: todoList.id,
      };
      await trello.createTrelloBoard(parseInt(projectId), body);
      setColumnNames(['A Fazer', 'Finalizadas']);
    }
    catch(e){
      console.log(e)
    }
  };
  const getProjectName = async () => {
    const response = await team.get_projects();
    const data = await response.json();
    const project = data.find((project) => project.id === parseInt(projectId));
    setBoardName(project.name);
  };
  useEffect(() => {
    getProjectName();
  }, [isOpen, projectId]);

  return (
    <Modal
      isOpen={isOpen}
      width="100%"
      maxWidth="95%"
      height="100%"
      maxHeight="90%"
      onRequestClose={() => {
        onRequestClose();
      }}
      backgroundColor="transparent"
    >
      <S.Container>
        <S.Content>
          <S.Title>Conectar ao Trello</S.Title>
          <S.SectionTitle className="board">Quadro</S.SectionTitle>
          <S.ColumnLabel style={{ color: valid[0] ? '#5F5F5F' : '#FF0000' }}>
            Adicionar título do quadro*
          </S.ColumnLabel>
          <Input defaultValue={boardName}></Input>
          <S.SectionTitle className="column">Colunas</S.SectionTitle>
          <S.Description>
            Demos um nome para a primeira coluna que receberá as suas tarefas
            planejadas e um nome para a última coluna, que receberá as tarefas
            finalizadas. Você pode alterar se desejar.
          </S.Description>
          <S.Row style={{ gap: '47px' }}>
            <div>
              <S.ColumnLabel
                style={{ color: valid[1] ? '#5F5F5F' : '#FF0000' }}
              >
                Coluna de tarefas a fazer*
              </S.ColumnLabel>
              <Input
                defaultValue={columnNames[0]}
                onChange={(e) => {
                  setColumnNames([e.target.value, columnNames[1]]);
                }}
              ></Input>
            </div>
            <div>
              <S.ColumnLabel
                style={{ color: valid[2] ? '#5F5F5F' : '#FF0000' }}
              >
                Coluna de finalizadas*
              </S.ColumnLabel>
              <Input
                defaultValue={columnNames[1]}
                onChange={(e) => {
                  setColumnNames([columnNames[0], e.target.value]);
                }}
              ></Input>
            </div>
          </S.Row>
          <S.ButtonRow>
            <LightButton
              style={{ padding: '10px 25px' }}
              onClick={() => {
                onRequestClose();
              }}
            >
              Voltar
            </LightButton>
            <Button
              style={{ padding: '10px 25px' }}
              onClick={() => {
                if (columnNames[0] && columnNames[1] && boardName) {
                  handleConnect();
                  onRequestClose();
                } else {
                  const valid = [
                    boardName ? true : false,
                    columnNames[0] ? true : false,
                    columnNames[1] ? true : false,
                  ];
                  setValid(valid);
                }
              }}
            >
              Conectar ao Trello
            </Button>
          </S.ButtonRow>
        </S.Content>
      </S.Container>
    </Modal>
  );
};

export default NewBoardModal;
