import create from 'zustand';

type TaskReplayParams = {
  description: string;
  projectId: number;
  plannedTaskId: number;
}
type ReplayTaskState = {
  replayTask: TaskReplayParams;
  setReplayTask: (params: TaskReplayParams) => void;
  cleanReplayTask: () => void;
}
const useReplayTaskStore = create<ReplayTaskState>(
  (set) => ({
    replayTask: {} as TaskReplayParams,
    setReplayTask: (taskReplay) => {
      set({replayTask: taskReplay})
    },
    cleanReplayTask:() => {
      set({replayTask: {} as TaskReplayParams})
    }
  })
)

export default useReplayTaskStore;
