import styled from '@emotion/styled';

const Table = styled.table`
  width: 100%;
  color: rgb(149, 149, 149);
  border-collapse: collapse;

  & > thead {
    text-align: left;
  }

  & > tbody {
    font-size: 1em;
    cursor: default;
    background-color: #fff;
    border-top: 1px solid #eee;
  }

  & > thead > tr,
  & > tbody > tr {
    th,
    td {
      font-weight: normal;
      position: relative;
      padding: 15px;
      .actions {
        display: none;
        position: absolute;
        top: 30%;
        right: 25px;
        a {
          md-icon {
            color: #c7c7c7;
            &:hover {
              color: darken(#c7c7c7, 20%);
            }
          }
        }
      }
      &.center {
        text-align: center;
      }
    }

    th:first-child,
    td:first-child {
      padding-left: 40px;
    }

    th:last-child,
    td:last-child {
      position: relative;
      padding-right: 75px;
    }

    &:hover td .actions {
      display: block;
    }
  }
  md-switch {
    margin: 0;
  }

  tbody tr {
    border-bottom: 1px solid #eee;
    outline: none;
  }
`;

export default Table;
