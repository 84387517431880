import templateUrl from './trello-board-selection.html';
import './trello-board-selection.scss';

export const TrelloBoardSelectionComponent = {
  template: templateUrl,
  controller: class TrelloBoardSelectionComponent {
    constructor($log, $mdDialog, $scope, $state, $stateParams, NotificationService, TrelloService, ProjectService) {
      'ngInject';

      this.$log = $log;
      this.$mdDialog = $mdDialog;
      this.$scope = $scope;
      this.$state = $state;
      this.$stateParams = $stateParams;
      this.NotificationService = NotificationService;
      this.TrelloService = TrelloService;
      this.ProjectService = ProjectService;
    }

    newBoardTemplate = {
      id: 'new-board', name: 'Criar novo', prefs: { backgroundColor: '#e8eaf3' }
    };

    $onInit() {
      this.ProjectService.getProjectById(this.$stateParams.projectId).then(response => {
        this.project = response.data;
        this.TrelloService.getBoards().then(boards => {
          const newBoard = Object.assign({}, this.newBoardTemplate);
          this.boards = [newBoard, ...boards];
        });
      });
    }

    async select(selectedBoard) {
      this.selectedBoard = selectedBoard.id !== this.newBoardTemplate.id ? selectedBoard : null;
      const selectedBoardConfigs = await this.$mdDialog.show({
        scope: this.$scope,
        template:
          '<md-dialog class="fullscreen-modal labor-modal"><trello-board-connection project="$ctrl.project" board="$ctrl.selectedBoard" ></trello-board-connection></md-dialog>',
        clickOutsideToClose: false,
        preserveScope: true,
      });
    
      const { board, todoList, doneList } = selectedBoardConfigs;
      const params = {
        boardId: board.id,
        todoListId: todoList.id,
        doneListId: doneList.id,
      };

      try {
        await this.TrelloService.addTrelloBoardToProject(this.project.id, params);
        await this.ProjectService.getAllProjects(true);
        this.NotificationService.showNotification(
          'O projeto está conectado ao Trello!',
        );
        this.$state.go('app.main.projects-dashboard.trello-settings', {
          projectId: this.project.id,
        });
      } catch (error) {
        this.NotificationService.showNotification(
          'Houve um erro ao conectar o projeto ao Trello.',
          'error',
        );
        this.$log.log('error', error);
      }
    }

    createBoard() {
      this.$mdDialog.show({
        scope: this.$scope,
        template:
          '<md-dialog class="fullscreen-modal labor-modal"><trello-board-connection project="$ctrl.project"></trello-board-connection></md-dialog>',
        clickOutsideToClose: false,
        preserveScope: true,
      }).then(boardCreationResult => {
        const { board, todoList, doneList } = boardCreationResult;
        this.project.trelloBoardAttributes = {
          boardId: board.id,
          todoListId: todoList.id,
          doneListId: doneList.id,
        };
        this._saveProject(this.project);
      });
    }
  },
};
