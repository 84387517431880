export const ExecuteTourAfterLoadDirective = (
  $log,
  $window,
  TourService,
  UserService,
) => {
  'ngInject';

  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      if (scope.$last && UserService.getLoggedUser().signInCount == 1) {
        element.ready(function() {
          scope.$apply(function() {
            TourService.mainTour();
          });
        });
      }
    },
  };
};
