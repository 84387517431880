import React, { useState } from 'react';
import { Popover } from '@mui/material';
import { SelectContainer, ExpandMoreIcon } from './styles';

/* 
  Select popover => button with diplayText and arrowdownIcon,
  when you click it, a popover opens with the children passed.

  children: component to render in the dropdown
  displayName: text to be displayed on the default button of the component.
  CustomAnchorElement: can pass your own component to be the button.
  anchorOrigin: position of dropdown related to button.
  onOpen: executed when dropdown opens.
  onClose: executed when dropdown closes.
  isPending: changes the default button border to blue.
*/

type Props = {
  children?: React.ReactNode;
  displayName?: string;
  CustomAnchorElement?: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  anchorOrigin?: {
    vertical: number | 'bottom' | 'top' | 'center';
    horizontal: number | 'left' | 'right' | 'center';
  };
  isPending?: boolean;
};

const SelectPopover = ({
  children,
  displayName,
  CustomAnchorElement,
  onOpen,
  onClose,
  anchorOrigin,
  isPending,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const openDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onOpen) onOpen();
    setAnchorEl(event.currentTarget);
  };

  const closeDropdown = () => setAnchorEl(null);

  const newOnClose = () => {
    closeDropdown();
    if (onClose) onClose();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {(React.isValidElement(CustomAnchorElement) &&
        React.cloneElement(CustomAnchorElement, {
          onClick: openDropdown,
          opened: open,
        })) || (
        <SelectContainer
          onClick={openDropdown}
          opened={open}
          pending={isPending}
        >
          <span>{displayName}</span>
          <ExpandMoreIcon />
        </SelectContainer>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={newOnClose}
        anchorOrigin={
          anchorOrigin || { vertical: 'bottom', horizontal: 'left' }
        }
      >
        {React.isValidElement(children) &&
          React.cloneElement(children, { closeDropdown })}
      </Popover>
    </>
  );
};

export default SelectPopover;
