import React, { useEffect } from 'react';
import * as S from './styles';
import useUserStore from '~/store/user';

type Props = {
  alertType: string;
};

const TaskAlert = ({
  alertType,
}: Props) => {
  return (
    <>
      <S.TaskAlert>
        <S.AlertIcon />
        {alertType.split('_')[0] === 'exceedingHours' && (
          <S.TaskAlertText>
            Você já trabalhou{' '}
            <S.TaskAlertPercentage>
              {alertType.split('_')[1]}%
            </S.TaskAlertPercentage>{' '}
            da quantidade de horas estimadas para o mês (contando com a margem
            de 10%). Quando enviar seu relatório mensal, seu gestor será
            notificado caso você tenha excedido esse limite.
          </S.TaskAlertText>
        )}
        {alertType.split('_')[0] === 'pdiHours' && (
          <S.TaskAlertText>
            Você já utilizou{' '}
            <S.TaskAlertPercentage>
              {alertType.split('_')[1]}%
            </S.TaskAlertPercentage>{' '}
            da quantidade de horas recomendadas para o seu PDI. Quando enviar
            seu relatório mensal, seu gestor será notificado caso você tenha
            excedido esse limite.
          </S.TaskAlertText>
        )}
        {alertType === 'dayOffHours' && (
          <S.TaskAlertText>
            Você já usou <S.TaskAlertPercentage>100%</S.TaskAlertPercentage>{' '}
            dos dias de folga remunerados adquiridos, mas pode tirar dias de
            folga não remunerados. Avise os colegas de projeto e a área de
            Pessoas, mas não registre esse período no Labor.
          </S.TaskAlertText>
        )}
      </S.TaskAlert>
    </>
  );
};

export default TaskAlert;
