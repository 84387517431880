import templateUrl from './planned-tasks-select.html';

export const PlannedTasksSelectComponent = {
  template: templateUrl,
  bindings: {
    onSelect: '&',
    task: '<',
  },
  controller: class PlannedTasksSelectController {
    constructor($scope, PopoverService, PlannedTaskService) {
      'ngInject';

      this.$scope = $scope;
      this.PopoverService = PopoverService;
      this.PlannedTaskService = PlannedTaskService;
    }

    $onInit() {
      this.resetPlannedTasks();


      this.$scope.$watch('$ctrl.task', () => {
        this.resetPlannedTasks();
        if (this.task.plannedTask)
          this.setCurrentPlannedTask(this.task.plannedTask);
      });
    }

    resetPlannedTasks() {
      this.plannedTasksPromise = null;
      this.plannedTasks = [];
    }

    loadPlannedTasks() {
      if (!this.task.projectId) {
        return;
      }

      this.plannedTasksPromise = this.PlannedTaskService.getPlannedTasks(
        this.task.projectId,
      ).then(response => {
        this.plannedTasks = response.data;
      });
    }

    setCurrentPlannedTask(plannedTask) {
      this.$scope.plannedTaskDescription = plannedTask ? plannedTask.description : null;
    }

    choosePlannedTask($event) {
      const plannedTask = $event.selected;
      this.setCurrentPlannedTask(plannedTask);
      this.PopoverService.closeAll();
      this.task.plannedTaskId = plannedTask ? plannedTask.id : null;
      this.onSelect({ task: this.task });
    }
  },
};
