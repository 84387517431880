import React, { useEffect, useState } from 'react';
import { State } from '~/models/types';
import API from '~/services/api';
import MonthlyNotification from './MonthlyNotification';
import WeeklyNotification from './WeeklyNotification';

interface PeriodicNotificationsProps {
  $state: State;
}

interface NotificationProps {
  notification: boolean;
  frequency: string;
  monthday: string;
  weekday: string;
  hour: string;
  email: boolean;
}

const PeriodicNotification: React.FC<PeriodicNotificationsProps> = ({
  $state,
}) => {
  const notificationModalWasSeen = localStorage.getItem(
    'notificationModalWasSeen',
  );
  const notificationModalWasNotSeen = () => {
    localStorage.setItem('notificationModalWasSeen', 'false');
  };
  const [shouldRenderNotificationModal, setRenderNotificationModal] = useState(
    !notificationModalWasSeen,
  );
  const [show, setShow] = React.useState(false);
  const [notificationData, setNotificationData] = useState<NotificationProps>({
    notification: true,
    frequency: 'montlhy',
    monthday: '1',
    weekday: 'monday',
    hour: 'h12',
    email: false,
  });
  const [route, setRoute] = useState($state.router.globals.$current.name);

  const getNotificationData = async () => {
    let response = await API.report.get_reports_notifications();
    const data = await response.json();
    setNotificationData(data);
    setRenderNotificationModal(data ? data.notification : false);
  };

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const weekday = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thrusday',
    'friday',
    'saturday',
  ];
  const hours = () => {
    if (notificationData.hour === 'h8') {
      return '07:59';
    }
    if (notificationData.hour === 'h12') {
      return '11:59';
    }
    if (notificationData.hour === 'h18') {
      return '17:59';
    }
  };

  const today = new Date();
  const currentHour = today.getHours() + ':' + today.getMinutes();
  const currentWeekday = weekday[today.getDay()];
  const currentMonthday = today.getDate();
  const monthNumber = Number(today.getMonth());
  const currentMonth = months[monthNumber];
  const currentYear = today.getFullYear();

  const monthlyNotificationMonth =
    currentMonth === 'Janeiro' ? 'Dezembro' : months[monthNumber - 1];
  const monthlyNotificationYear =
    currentMonth === 'Janeiro'
      ? Number(today.getFullYear()) - 1
      : Number(today.getFullYear());
  const monthlyStartDate = new Date(currentYear, monthNumber - 1);
  monthlyStartDate.setDate(1);
  const monthlyEndDate = new Date(currentYear, monthNumber, 0);

  const weeklyStartDate = new Date();
  weeklyStartDate.setDate(weeklyStartDate.getDate() - 7);
  const weeklyNotificationInit =
    weeklyStartDate.getDate() + '/' + Number(weeklyStartDate.getMonth() + 1);
  const weeklyEndDate = new Date();
  const weeklyNotificationEnd =
    weeklyEndDate.getDate() + '/' + Number(weeklyEndDate.getMonth() + 1);

  const showNotifications = () => {
    if (
      notificationData.frequency === 'weekly' &&
      currentWeekday === notificationData.weekday &&
      currentHour === hours()
    ) {
      notificationModalWasNotSeen();
      setRenderNotificationModal(true);
    }
    if (
      notificationData.frequency === 'monthly' &&
      currentMonthday === Number(notificationData.monthday) &&
      currentHour === hours()
    ) {
      notificationModalWasNotSeen();
      setRenderNotificationModal(true);
    }
  };

  useEffect(() => {
    getNotificationData();
  }, []);

  React.useEffect(() => {
    let timer = setTimeout(() => {
      setShow(!show);
      showNotifications();
    }, 60000);
    return () => {
      clearTimeout(timer);
    };
  }, [show]);

  return (
    <>
      {shouldRenderNotificationModal &&
        notificationData.frequency === 'monthly' && (
          <MonthlyNotification
            modalWasSeen="notificationModalWasSeen"
            closeModal={setRenderNotificationModal}
            month={monthlyNotificationMonth}
            year={monthlyNotificationYear}
            startDate={monthlyStartDate}
            endDate={monthlyEndDate}
            setRoute={setRoute}
            $state={$state}
          />
        )}
      {shouldRenderNotificationModal &&
        notificationData.frequency === 'weekly' && (
          <WeeklyNotification
            modalWasSeen="notificationModalWasSeen"
            closeModal={setRenderNotificationModal}
            stringStartDate={weeklyNotificationInit}
            stringEndDate={weeklyNotificationEnd}
            startDate={weeklyStartDate}
            endDate={weeklyEndDate}
            setRoute={setRoute}
            $state={$state}
          />
        )}
    </>
  );
};

export default PeriodicNotification;
