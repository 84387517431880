import AmplitudeUserStore from '../../../../../../react/services/api/amplitude';
export class LoginService {
  constructor(
    $auth,
    $log,
    $state,
    UserService,
    ProjectService,
    AMPLITUDE_API_KEY,
  ) {
    'ngInject';

    this.$auth = $auth;
    this.$log = $log;
    this.$state = $state;
    this.UserService = UserService;
    this.ProjectService = ProjectService;
    this.AMPLITUDE_API_KEY = AMPLITUDE_API_KEY;
  }

  login(loginForm) {
    return this.$auth.submitLogin(loginForm).then(response => {
      this.UserService.setLoggedUser(response);

      this.$state.go(this.UserService.getUserBaseState());

      return response;
    });
  }

  logout() {
    return this.$auth
      .signOut()
      .then(() => {
        this.UserService.removeLoggedUser();
        this.ProjectService.clearProjectsSingleton();
        new AmplitudeUserStore(null, AMPLITUDE_API_KEY).logout();

        this.$state.go('login');
      })
      .catch(error => {
        this.$log.log(error);

        // mesmo com o erro, o front deve encerrar sua sessão.
        this.UserService.removeLoggedUser();
        new AmplitudeUserStore.init(null, this.AMPLITUDE_API_KEY).logout();

        this.$state.go('login');
        return error;
      });
  }

  requestPasswordReset(email) {
    return this.$auth.requestPasswordReset({ email });
  }

  updatePassword(params) {
    return this.$auth.updatePassword(params);
  }
}
