import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  color: #fe3c57;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 20px;
`;

const Month = styled.span`
  display: inline-block;
  margin: 0 10px;
`;

const ClickableIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  padding: 2px 5px;
  position: relative;
  top: 2px;
`;

export default function MonthHandler({ month, onChange }) {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  function previousMonth(selected) {
    const innerMonth = new Date(selected.getTime());
    innerMonth.setMonth(innerMonth.getMonth() - 1);

    return innerMonth;
  }

  function nextMonth(selected) {
    const innerMonth = new Date(selected.getTime());
    innerMonth.setMonth(innerMonth.getMonth() + 1);

    return innerMonth;
  }

  return (
    <Container>
      <ClickableIcon
        icon={faChevronLeft}
        onClick={() => onChange(previousMonth(month))}
      />
      <Month>{`${months[month.getMonth()]}, ${month.getFullYear()}`}</Month>
      <ClickableIcon
        icon={faChevronRight}
        onClick={() => onChange(nextMonth(month))}
      />
    </Container>
  );
}

MonthHandler.propTypes = {
  month: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
};
