export const MonthNameByIndexFilter = $filter => {
  'ngInject';

  return input => {
    const today = new Date();
    if (angular.isString(input) || angular.isNumber(input)) {
      today.setMonth(input);
      return $filter('date')(today, 'MMMM');
    }
    return input;
  };
};
