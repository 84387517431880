export const EditableAsSelectDirective = (
  $document,
  $compile,
  $mdSelect,
  $timeout,
  $log,
) => {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      editableAsSelect: '<',
      editableObject: '@',
      selectOptions: '<',
      onChangeFunction: '&',
    },
    link: (scope, element, attrs) => {
      let select;

      scope.submitHandler = submitHandler;

      scope.objectClone = angular.copy(
        scope.editableAsSelect[scope.editableObject],
      );
      showAsText();

      element.bind('click', e => {
        e.stopPropagation();
        $document.on('click', submitHandler);

        if (element.prop('editable-as-select-on') == 'true') {
          return;
        }

        select = $compile(
          '<md-select ng-model="objectClone"' +
            'ng-model-options="{trackBy: \'$value.id\'}"' +
            'ng-change="submitHandler()"' +
            'md-container-class="editable-as-select-menu"' +
            'md-no-asterisk>' +
            '<md-option ng-value="project" style="color: {{ project.tagColor }}">' +
            '<span class="project-text" style="color: {{ project.tagColor }}">' +
            '<span class="project-icon"></span>' +
            'Sem projeto' +
            '</span>' +
            '</md-option>' +
            '<md-option ng-value="project" ng-repeat="project in selectOptions" style="color: {{ project.tagColor }}">' +
            '<span class="project-text" style="color: {{ project.tagColor }}">' +
            '<span class="project-icon"></span>' +
            '{{project.name}}' +
            '</span>' +
            '</md-option>' +
            '</md-select>',
        )(scope);
        element.html('');
        element.append(select);
        element.prop('editable-as-select-on', 'true');
        scope.$emit('editable-as-select-opened');

        select.on('click', e => {
          e.stopPropagation();
        });

        select.bind('keydown keypress', submitHandler);

        // Abre o select
        select[0].click();
        // $timeout(() => {
        // }, 100);
      });

      // //////////////
      function submitHandler() {
        callOnChangeFunction(element, select);
      }

      function showAsText() {
        element.html(
          `<span class="project-icon"></span> ${
            scope.objectClone ? scope.objectClone.name : 'Sem projeto'
          }`,
        );
      }

      function callOnChangeFunction(element, select) {
        $document.off('click');
        select.remove();

        element.prop('editable-as-select-on', 'false');
        scope.$emit('editable-as-select-closed');

        if (
          scope.objectClone != scope.editableAsSelect[scope.editableObject] ||
          (scope.objectClone &&
            scope.editableAsSelect[scope.editableObject] &&
            scope.editableAsSelect[scope.editableObject].id !=
              scope.objectClone.id)
        ) {
          scope.editableAsSelect[scope.editableObject] = scope.objectClone;
          scope.onChangeFunction({ task: scope.editableAsSelect });
        }

        showAsText();
      }
    },
  };
};
