export const loginRequest = {
  scopes: ['user.read', 'mail.send'],
};

export const msalConfig = {
  auth: {
    clientId: MICROSOFT_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common/',
    redirectUri: MICROSOFT_REDIRECT_URL,
  },
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const googleConfig = {
  auth: {
    clientId: GOOGLE_CLIENT_ID,
  },
};
