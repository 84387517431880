import { useFormikContext } from 'formik';
import { useState, useEffect } from 'react';

const FormikScrollToError = () => {
  const { submitCount, isValid, errors } = useFormikContext();
  const [lastHandled, setLastHandled] = useState(0);
  useEffect(() => {
    if (submitCount > lastHandled && !isValid) {
      const keys = Object.keys(errors);
      if (keys.length > 0) {
        const element = document.querySelector(`[name=${keys[0]}]`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
      setLastHandled(submitCount);
    }
  }, [submitCount, isValid, lastHandled]);
  return null;
};

export default FormikScrollToError;
