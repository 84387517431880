import React from 'react';
import Floater from 'react-floater';
import { MdTrendingUp, MdLinearScale } from 'react-icons/md';

import useOnboardingStore from '~/store/onboarding';

import * as S from './styles';

const TourButton: React.FC = () => {
  const changeStepIndexValue = useOnboardingStore(
    (state) => state.changeStepIndexValue,
  );
  const changePauseModalFirstTime = useOnboardingStore(
    (state) => state.changePauseModalFirstTime,
  );
  const changeTourIsRunning = useOnboardingStore(
    (state) => state.changeIsRunning,
  );
  const changeIsDone = useOnboardingStore((state) => state.changeIsDone);
  const pauseModalFirstTime = useOnboardingStore(
    (state) => state.pauseModalFirstTime,
  );
  const changeOnboardingPause = useOnboardingStore(
    (state) => state.changeOnboardingPause,
  );
  const onboardingPause = useOnboardingStore((state) => state.onboardingPause);

  const resumeTour = () => {
    changeStepIndexValue(0);
    changeTourIsRunning();
    changeOnboardingPause(false);
  };

  const content = () => (
    <>
      <S.ModalText>
        Você pode continuar acessando o tour guiado a qualquer momento clicando
        neste botão. Se preferir ou já conhecer o Labor, você também pode
        encerrá-lo agora.
      </S.ModalText>
      <S.Buttons>
        <S.StyledButton onClick={() => changePauseModalFirstTime(false)}>
          Entendi
        </S.StyledButton>
        <S.NextWrapper onClick={() => changeIsDone()}>
          Encerrar tour
        </S.NextWrapper>
      </S.Buttons>
    </>
  );

  return (
    <>
      {onboardingPause && (
        <Floater
          open={onboardingPause && pauseModalFirstTime}
          content={content()}
          placement="auto"
          offset={4}
          styles={S.Modal}
        >
          <S.Container>
            <S.ButtonComponent onClick={() => resumeTour()} hasIcon={true}>
              <MdLinearScale size={'18px'} />
              Continuar tour
            </S.ButtonComponent>
          </S.Container>
        </Floater>
      )}
    </>
  );
};

export default TourButton;
