import styled from '@emotion/styled';
import { ButtonBase } from '@mui/material';

export const SelectWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 150px;
  padding: 8px 0 8px 0;
  color: #5f5f5f;
`;

export const Item = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 8px;
  padding: 8px 16px 8px 16px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: #9595950d;
  }

  &.Mui-disabled {
    opacity: 50%;
  }
`;
