export default {
  projectColors: [
    '#8070b2',
    '#ff8000',
    '#13727c',
    '#398a38',
    '#870300',
    '#5b91bb',
    '#a02aa9',
    '#3d3389',
    '#ffaa00',
    '#ffc729',
    '#e21300',
    '#004720',
    '#009245',
    '#29c5a9',
    '#e85d7e',
    '#303030',
    '#707070',
    '#00abbd',
    '#4680fe',
  ],
};
