import angular from 'angular';
import { ProjectSettingsModule } from './project-settings/project-settings.module';
import { ProjectTrelloSettingsModule } from './project-trello-settings/project-trello-settings.module';
import { PlannedTasksModule } from './planned-tasks/planned-tasks.module';
import { ProjectDashboardComponent } from './project-dashboard.component';

export const ProjectDashboardModule = angular
  .module('root.components.app.components.main.projects.project-dashboard', [
    ProjectSettingsModule,
    PlannedTasksModule,
    ProjectTrelloSettingsModule,
  ])
  .component('projectDashboard', ProjectDashboardComponent).name;
