import React from 'react';
import styled from '@emotion/styled';
import Input from '~/components/Input';

interface Props {
  name: string;
  onChange?: Function;
  value?: string | null;
}

const ClientInput = styled(Input)``;

const TextClient: React.FC<Props> = ({ name, onChange, value }) => (
  <ClientInput
    value={value ? value : ''}
    type="text"
    name={name}
    onChange={(e) => {if(onChange) onChange(name, e.target.value)}}
    placeholder="Ex.: Roberval da Costa"
    wrapperStyle={{
      width: '100%',
      margin: '0 0 0 0px',
    }}
  />
);
export default TextClient;
