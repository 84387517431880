import templateUrl from './project-input.html';
import { Project } from './project';
import './project-input.scss';

export const ProjectInputComponent = {
  template: templateUrl,
  bindings: {
    project: '=?',
    onSave: '&',
  },
  controller: class ProjectInputController {
    constructor($scope, $log, $mdDialog, $timeout, COLORS, TeamService) {
      'ngInject';

      this.$scope = $scope
      this.$log = $log;
      this.$mdDialog = $mdDialog;
      this.$timeout = $timeout;
      this.project = this.project || new Project();
      this.projectColors = COLORS.projectColors;

      TeamService.getTeamsIAmAdmin().then(response => {
        this.teamList = response.data;
      });

      if (!this.project.id) {
        this.project = {};
        this.project.tagColor = this.projectColors[0];
      }
    }

    $onInit() {
      this.step = 1;
      this.saving = false;

      this.$timeout(() => {
        this.focusOnTitle = true;
      });
    }

    onSaveProject() {
      delete this.project.team;
      this.saving = true;
      this.$scope.$parent.onAddProject(this.project)
      this.project = new Project();
      this.projectInputForm.$setPristine();
      this.projectInputForm.$setUntouched();
      this.$mdDialog.hide()
    }

    cancel() {
      this.$mdDialog.cancel();
    }
  },
};
