import angular from 'angular';
import { EditableAsInputDirective } from './editable-as-input.directive';
import { EditableAsSelectDirective } from './editable-as-select.directive';
import { EditableAsInputService } from './editable-as-input.service';
import './editable.scss';

export const EditableModule = angular
  .module('root.components.app.common.directives.editable', [])
  .directive('editableAsInput', EditableAsInputDirective)
  .directive('editableAsSelect', EditableAsSelectDirective)
  .service('EditableAsInputService', EditableAsInputService).name;
