import styled from '@emotion/styled';
import Select from 'react-select';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

export const Select_ = styled(Select)`
  -webkit-appearance: none;
  border: none;
  background: none;
  color: inherit;
  text-align: right;
  background-color: white;
  width: 65px;
  height: 36px;
  font-size: 14px;
  display: inline-block;
  margin-left: 8px;
  margin-right: 21px;

  .UserTableSelect__single-value{
    color: hsl(0, 0%, 50%);
    margin-left: 2px;
    margin-right: 2px;
    overflow: visible;

  }
  .UserTableSelect__control {
    background-color: inherit;
    border: 1px solid rgba(149, 149, 149, 0.2);
    border-radius: 0;
    :hover{
      border: 1px solid #4F587A;
    }
  }
  .UserTableSelect__control--menu-is-open {
    box-shadow: none;
    border: 1px solid #4F587A;
  }

  .UserTableSelect__control--is-focused {
    box-shadow: none;
  }
  .UserTableSelect__menu{
    background-color: inherit;
    border: 1px solid rgba(149, 149, 149, 0.2);
    border-radius: 0;
    text-align: left;

  }
  .UserTableSelect__indicators{
    .UserTableSelect__dropdown-indicator{
      color: #7D7D7D;
    }
  }
  .UserTableSelect__value-container{
    margin-left: 4px;
    overflow: visible;
    width:21px;
  }
`;
export const RoleSelect = styled(Select)`
  .UserTableSelect__single-value{
    color: hsl(0, 0%, 50%);
    margin-left: 2px;
    margin-right: 2px;
    overflow: visible;

  }
  .UserTableSelect__control {
    background-color: inherit;
    border: none;
    border-radius: 0;
    height: 48px;
    margin: -1px;
    cursor: pointer;
    
    :hover{
      border: 1px solid #4F587A;
    }
  }
  .UserTableSelect__indicators{
    .UserTableSelect__dropdown-indicator{
      color: #7D7D7D;
      margin-right: 17px;
    }
  }
  .UserTableSelect__menu{
    background-color: inherit;
    border: none;
    border-radius: 0;
    text-align: left;
    z-index: 1;
    background-color: white;
    cursor: pointer;

  }
  .UserTableSelect__control--menu-is-open {
    box-shadow: none;
    border: 1px solid #4F587A;
  }

  .UserTableSelect__control--is-focused {
    box-shadow: none;
  }
`
export const BulkEditSelect = styled(RoleSelect)`
  .UserTableSelect__control {
    background-color: inherit;
    border: none;
    border-radius: 0;
    height: 48px;
    margin: -1px;
    cursor: pointer;
    
    :hover{
      border: none;
    }
  }
  .UserTableSelect__control--menu-is-open {
    box-shadow: none;
  }
`

export const Td_ = styled.td`
  &.items_per_page{
    text-align: end;
  }
  span{
    margin-left: 20px;
  }
`;
export const Tr = styled.tr`
  td .name {
    display: flex;
    align-items: center;
    span{
      padding-left: 0;
      margin-left: 0;
    }
  }
  /* Grade: Increase the typeface's relative weight/density */
  :hover{
    .user_name{
      @supports (-webkit-text-stroke-width: 0.6px) {
        -webkit-text-stroke-width: 0.6px; 
      }
      /* Failover for older browsers: tiny shadows at right & left of the text
      * (replace both instances of "black" with the font color) */
      @supports not (-webkit-text-stroke-width: 0.6px) {
        font-weight: 600;
      }
      text-decoration-line: underline;
      color: #4F587A;
      cursor: pointer;
    }
    .remove_member{
      div{
        visibility: visible;
      }
    }
  }

`;
export const Table = styled.table`
  width: 100%;

  thead {
    font-size: 14px;

    tr {
      width: 100%;
      height: 49px;
      color: #7D7D7D;
      font-weight: 400;
      font-size:16px;
      .name{
        font-weight: 600;
      }


      td:nth-of-type(1) {
        padding-left: 44px;
        display: flex;
        align-items: center;
      }
    }
  }

  tbody {
    tr {
      .name {
        span {
          margin-left: 0;
        }
      }
      
      td {
        border-right: solid 1px rgba(149, 149, 149, 0.2);
        padding: 0;
        height: 49px;
        color: #5F5F5F;
        font-weight: 400;
        span {
          margin-left: 20px;
        }
        
        .projects-select {
          margin-left: 20px;
        }
      }
      td:nth-of-type(1) {
        padding-left: 63px;
        display: flex;
        align-items: center;
        &.no-users{
          display: table-cell;
        }
      }
      width: 100%;
      height: 49px;
      border-top: solid 1px rgba(149, 149, 149, 0.2);
      border-bottom: solid 1px rgba(149, 149, 149, 0.2);
      background-color: white;
      font-size: 16px;
      &.blank-state {
        border: none;
        background-color: inherit;
      }
      
      .no-date{
        font-size: 12px;
        color: #A6A6A6;
        .no-date__icon{
          font-size: 16px;
          margin-right: 5px;
        }
      }
      .has-date{
        color: #7D7D7D;
      }
      .hours{
        position: relative;
        font-size: 12px;
        color: #959595;
        .hour{
          font-weight: bold;
        }
      }
      table {
        tr {
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
  }
`;
export const Div = styled.div`
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-family: "Open Sans";
  align-items: center;
  justify-content: center;
  color: #5F5F5F;
  display: inline-flex;

`;
export const Div_ = styled(Div)`

  cursor: pointer;
  :hover {
    background: #4f587a8d;
    color: white;
    border-radius: 50%;
  }
  &.active {
    cursor: default;
    background: #4F587A;
    color: white;
    border-radius: 50%;
  }
`;
export const Container = styled.div `
  display:flex;
  justify-content: end;
  margin-top: 29px;
  gap: 7px;
  margin-right: 21px;
`;
export const Svg = styled.svg `
  fill: #5F5F5F;
  cursor: pointer;
  &.deactivated {
    fill: #A6A6A6;
    cursor: default;
  }
  &.right {
    transform: rotate(180deg);
  }
`;

export const PaginationDiv = styled.div`
  display: inline-flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.deactivated {
    cursor: default;
  }
`
export const RemovePerson = styled(PersonRemoveIcon)`
  margin-right: 7px;
`
export const RemoveMember = styled.div`
  height: 40px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  font-size: 14px;
  :hover{
    background-color: #F6F7FB;
  }
  :active{
    color: #4F587A;
    &.svg{
      fill: black;
      background: black;
      color: black;
    }
  }
`
export const RemoveMemberCell = styled.td`
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  :hover {
    div{
      cursor: pointer;
    }
  }
  
`
export const ModalTitle = styled.div `
  font-size: 18px;
  color: #5F5F5F;
  font-weight: 600;
  margin-bottom: 24px;
`;
export const ModalProjectName = styled.span `
  font-weight: 700;
`;
export const StaticText = styled.div `
  color: #5f5f5f;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 30px;
`;
export const ModalUserName = styled.span `
  color: #4F587A;
  font-size: 16px;
  font-weight: 600;
`;
export const ModalUserRole = styled.span `
  color: #4F587A;
  font-size: 16px;
  font-weight: 400;
`;
export const ModalUser = styled.div`

`;
export const ModalUserContainer = styled.div`
  margin-bottom: 59px;
`
export const ModalButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  button{
    height: 35px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  `
export const BulkEditContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  border-bottom: 1px solid #D7D7D7;
  margin-left: 63px;
  font-size: 16px;
  align-items: center;
  visibility: hidden;
  &.visible{
    visibility: visible;
  }
  width: 580px;
`;
export const SelectedText = styled.div`
  color: #5F5F5F;
  font-weight: 700;
  border-right: 2px solid #D7D7D7;
  height: 26px;
  padding-right: 16px;
  margin-left: 38px;
`
export const BulkEditRemoveMember = styled.div`
  height: 40px;
  width: 168px;
  padding: 0 10px 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  :hover{
    background-color: #e7e8eb;
    cursor: pointer;
  }
  :active{
    color: #4F587A;
    &.svg{
      fill: black;
      background: black;
      color: black;
    }
  }
`;