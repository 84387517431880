import React, { useState, useEffect } from 'react';
import ProjectSelect from './ProjectSelect';
import PlannedSelect from './PlannedSelect';
import FilterButton from './FilterButton';
import UserSelect from './UserSelect';
import DateRange from './DateRange';
import * as S from './styles';
import NotificationServiceType from '~/models/ServicesTypes';
import PeriodicNotificationConfig from './PeriodicNotificationConfig/PeriodicNotificationConfig'

type Props = {
  NotificationService: NotificationServiceType;
};

const HeaderOptions = ({ NotificationService }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [notificationHover, setNotificationHover] = useState(false)

  const handleClick = () => {
    setModalOpen(true);
  };

  const changeNotificationIcon = (state) => {
    setNotificationHover(state)
  }
  

  useEffect(() => {
    setNotificationHover(false);
  }, []);

  return (
  <S.HeaderContainer>
    <S.ReportsTitle>Relatórios
      {!notificationHover && 
      <S.NotificationIcon  
      onClick={() => handleClick()}
      onMouseOver={() => changeNotificationIcon(true)}
      />
    }
    {notificationHover && 
      <S.NotificationIconActive  
      onClick={() => handleClick()}
      onMouseLeave={() => changeNotificationIcon(false)}/>
    }
      <PeriodicNotificationConfig
          isModalOpen={modalOpen}
          setIsModalOpen={setModalOpen}
          NotificationService={NotificationService}
        />
    </S.ReportsTitle>
    <S.SelectsContainer>
      <UserSelect />
      <ProjectSelect />
      <PlannedSelect />
      <DateRange />
      <FilterButton />
    </S.SelectsContainer>
  </S.HeaderContainer>
  );
}

export default HeaderOptions;
