import styled from '@emotion/styled';
import { Assignment } from '@mui/icons-material';

export const Header = styled.h1`
  color: #5f5f5f;
  letter-spacing: -1px;
  font-size: 1.8rem;
  margin: 2rem 0 0.5rem;
`;
export const SubHeader = styled.h2`
  font-size: 17.6px;
  font-weight: 500;
  margin: 0 0 2rem;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Button = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  opacity: 0.8;
  width: 630px;
  padding: 1em 0em;
  padding-left: 48px;
  border: solid rgba(150, 150, 150, 0.3);
  border-width: 1px 0 0 0;
  :last-of-type {
    border-width: 1px 0 1px 0;
  }
  :hover {
    cursor: pointer;
    opacity: 1;
    .icon {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
`;
export const ButtonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ButtonText = styled.span`
  font-size: 19.2px;
  color: #5f5f5f;
  font-weight: 500;
`;
export const ButtonSubText = styled.span`
  font-size: 14.4px;
  color: #959595;
`;
export const AddIcon = styled(Assignment)`
  color: #3bd788;
  width: 32px;
  height: 32px;
  margin-right: 24px;
  -webkit-transition: -webkit-transform 0.2s
    cubic-bezier(0.36, 0.655, 0.745, 1.575);
  transition: -webkit-transform 0.2s cubic-bezier(0.36, 0.655, 0.745, 1.575);
  transition: transform 0.2s cubic-bezier(0.36, 0.655, 0.745, 1.575);
  transition: transform 0.2s cubic-bezier(0.36, 0.655, 0.745, 1.575),
    -webkit-transform 0.2s cubic-bezier(0.36, 0.655, 0.745, 1.575);
`;
export const ImportIcon = styled(Assignment)`
  color: #f3b109;
  width: 32px;
  height: 32px;
  margin-right: 24px;
  -webkit-transition: -webkit-transform 0.2s
    cubic-bezier(0.36, 0.655, 0.745, 1.575);
  transition: -webkit-transform 0.2s cubic-bezier(0.36, 0.655, 0.745, 1.575);
  transition: transform 0.2s cubic-bezier(0.36, 0.655, 0.745, 1.575);
  transition: transform 0.2s cubic-bezier(0.36, 0.655, 0.745, 1.575),
    -webkit-transform 0.2s cubic-bezier(0.36, 0.655, 0.745, 1.575);
`;
