import React, { useState } from 'react';
import * as S from './styles';
import CalendarPicker from '~/app/components/CalendarPicker';
import { getDates } from './dateUtils';

type props = {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  reuseFirstSecondMonth?: boolean;
};

const CoreDateRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  reuseFirstSecondMonth = false,
}: props) => {
  const { firstDayThisMonth, firstDayNextMonth } = getDates(
    reuseFirstSecondMonth,
    startDate,
  );

  const [firstMonth, setFirstMonth] = useState(firstDayThisMonth);
  const [secondMonth, setSecondMonth] = useState(firstDayNextMonth);

  const onFirstMonthChange = (newMonth: Date) => {
    setFirstMonth(newMonth);

    const nextMonth = new Date(newMonth);
    nextMonth.setMonth(newMonth.getMonth() + 1);
    setSecondMonth(nextMonth);
  };

  const onSecondMonthChange = (newMonth: Date) => {
    setSecondMonth(newMonth);

    const previousMonth = new Date(newMonth);
    previousMonth.setMonth(newMonth.getMonth() - 1);
    setFirstMonth(previousMonth);
  };

  const onStartDateChange = (innerStartDate: Date | null) => {
    setStartDate(innerStartDate);

    if (innerStartDate === null || endDate === null) return;

    if (innerStartDate.getMonth() !== endDate.getMonth()) {
      const startMonth = new Date(innerStartDate);
      startMonth.setDate(1);
      startMonth.setHours(0, 0, 0, 0);
      onFirstMonthChange(startMonth);
    }
  };

  const onEndDateChange = (innerEndDate: Date | null) => {
    setEndDate(innerEndDate);

    if (startDate === null || innerEndDate === null) return;

    if (startDate.getMonth() !== innerEndDate.getMonth()) {
      const endMonth = new Date(innerEndDate);
      endMonth.setDate(1);
      endMonth.setHours(0, 0, 0, 0);
      onSecondMonthChange(endMonth);
    }
  };

  return (
    <S.ComponentContainer>
      <S.PeriodContainer>
        <S.CalendarContainer style={{ marginLeft: 0 }}>
          <CalendarPicker
            month={firstMonth}
            onChangeMonth={onFirstMonthChange}
            startDate={startDate}
            onChangeStartDate={onStartDateChange}
            endDate={endDate}
            onChangeEndDate={onEndDateChange}
          />
        </S.CalendarContainer>
        <S.CalendarContainer style={{ marginRight: 0 }}>
          <CalendarPicker
            mode="endDate"
            month={secondMonth}
            onChangeMonth={onSecondMonthChange}
            startDate={startDate}
            onChangeStartDate={onStartDateChange}
            endDate={endDate}
            onChangeEndDate={onEndDateChange}
          />
        </S.CalendarContainer>
      </S.PeriodContainer>
    </S.ComponentContainer>
  );
};

export default CoreDateRangePicker;
