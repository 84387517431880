export const colorPickerOptions = [
  '#3D3389',
  '#A02AA9',
  '#8070B2',
  '#5B91BB',
  '#00ABBD',
  '#13727C',
  '#004720',
  '#009245',
  '#398A38',
  '#29C5A9',
  '#870300',
  '#E21300',
  '#FFAA00',
  '#FFC729',
  '#E85D7E',
  '#303030',
  '#707070',
];
