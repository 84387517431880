import React from 'react';
import PropTypes from 'prop-types';

import Modal, { ModalTitle, ModalText } from '../../../components/Modal';
import Button, { LightButton } from '../../../components/Button';

export default function UserAllocationModal(props) {
  const { allocation, ...otherProps } = props;

  return (
    <Modal
      {...otherProps}
      modalTrasformPosition="translate(-30%, -60%)"
      width="30%"
      height="80%"
    >
      <ModalTitle>Desativar usuário</ModalTitle>
      <ModalText>
        Ao desativar este usuário o Labor restringe o seu login à plataforma. A
        pessoa receberá um email comunicando que ele foi retirado do time e que
        a conta dela foi suspensa.
      </ModalText>

      <ModalText>
        Isso quer dizer que ele não pode mais entrar na conta, mas as
        informações deste usuário continua disponível na plataforma como
        histórico de salários, horas computadas, projetos em que atuou, entre
        outras.
      </ModalText>

      <LightButton
        style={{ margin: '0 30px 30px 0' }}
        onClick={() => props.onRequestClose()}
      >
        Cancelar
      </LightButton>
      <Button onClick={() => props.handleDisableAllocation()}>
        Desativar Usuário
      </Button>
    </Modal>
  );
}

UserAllocationModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  handleDisableAllocation: PropTypes.func,
  allocation: PropTypes.object,
};
