import React from 'react';
import styled from '@emotion/styled';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import useLoadingStateStore from '~/store/loadingState';

const StyledLoadingBar = styled(LinearProgress)`
  background-color: #ffbcc5;
  position: fixed;
  top: 0px;
  height: 5px;
  width: 100%;
  z-index: 999;
  animation-duration: 550ms;
  .${linearProgressClasses.bar1Indeterminate} {
    background-color: #ff3c58;
  }
  .${linearProgressClasses.bar2Indeterminate} {
    background-color: #ff3c58;
  }
`;

const LoadingBar = () => {
  const loading = useLoadingStateStore((state) => state.loading);
  if (!loading) return null;
  return loading && <StyledLoadingBar />;
};

export default LoadingBar;
