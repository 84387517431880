import styled from '@emotion/styled';

export const Container = styled.div`
  width: auto;
  margin: 0 79px 0 50px;
`;

export const Container2 = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const Hr = styled.div`
  color: #d7d7d7;
  margin-top: 40px;
  margin-bottom: 7px;
  border-bottom: 1px solid #d7d7d7;
  margin-left: 63px;
  width: 93.7%;
`;
