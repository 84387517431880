import templateUrl from './project-dashboard.html';
import theme from '~/theme/index';
import './project-dashboard.scss';

export const ProjectDashboardComponent = {
  template: templateUrl,
  controller: class ProjectDashboardController {
    constructor(
      $rootScope,
      $scope,
      $state,
      $log,
      $mdDialog,
      $stateParams,
      ProjectService,
      PlannedTaskService,
      NotificationService,
      UserService,
      TrelloService
    ) {
      'ngInject';

      this.$rootScope = $rootScope;
      this.$scope = $scope;
      this.$state = $state;
      this.$log = $log;
      this.$mdDialog = $mdDialog;
      this.$stateParams = $stateParams;
      this.ProjectService = ProjectService;
      this.PlannedTaskService = PlannedTaskService;
      this.UserService = UserService;
      this.NotificationService = NotificationService;
      this.TrelloService = TrelloService;
    }

    $onInit() {
      this.project = null;
      this.plannedTasks = [];
      this.plannedTasksTotals = { duration: 0, spent: 0 };
      this.isAdmin = this.UserService.isLoggedUserAdmin();

      this.ProjectService.getProjectById(this.$stateParams.projectId, true).then(
        response => {
          this.project = response.data;
        },
      );
    }

    openProjectSettings() {
      this.$state.go('app.main.projects-dashboard.project-config')     
    }

    displayHeaderName() {
      const isOnProjectConfig = this.$state.router.globals.$current.name.includes("project-config") 
      if (this.project?.name && !isOnProjectConfig) return this.project.name
      if (isOnProjectConfig) return 'Configurações de projeto'
      return ""
    }

    connectToTrello() {
      if (!this.TrelloService.isTrelloConnected())
        return this.TrelloService.authenticate(() => this.openProjectTrello())

      this.openProjectTrello()
    }

    getUpdatedProject() {
      return this.ProjectService.getProjectById(this.$stateParams.projectId).then(
        response => response.data
      );
    }

    async openProjectTrello() {
      const updatedProject = await this.getUpdatedProject();
      this.$state.go('app.main.projects-dashboard.trello-settings', {
        projectId: updatedProject.id,
      });
    }

    onEditProject(project) {
      this.$mdDialog.hide();
      this.ProjectService.updateProject(project).then(
        () => {
          this.refreshProjectList(true);
          this.NotificationService.showNotification(
            'Dados do Projeto atualizados com sucesso.',
          );
        },
        error => {
          this.NotificationService.showNotification(
            'Houve um erro ao atualizar os dados do Projeto.',
            'error',
          );
          this.$log.log('error', error);
        },
      );
    }

    onDeleteProject(project) {

      const confirm = this.$mdDialog
        .prompt()
        .title(`Deseja realmente remover o projeto ${project.name}?`)
        .ariaLabel('Remover Projeto')
        .textContent(
          'Todas as tarefas relacionadas ao projeto serão excluidas. Digite o nome do projeto para confirmar a remoção:',
        )
        .placeholder('nome do projeto')
        .ok('Confirmar')
        .cancel('close');

      this.$mdDialog.show(confirm).then((result) => {
        if(result === project.name) {
          this.ProjectService.deleteProject(project)
          .then(() => {
            this.refreshProjectList(true);
            this.NotificationService.showNotification(
              'Projeto removido com sucesso.',
            );
            this.$state.go('app.main.projects');
          })
          .catch(error => {
            this.$log.log(error);
            const alert = this.$mdDialog
              .alert()
              .title('Ops, ocorreu um erro!')
              .textContent(error.data.message)
              .ok('OK');
            this.$mdDialog.show(alert);
          });
        } else {
          const alert = this.$mdDialog
          .alert()
          .title('Ops, esse não é o nome do projeto')
          .ok('OK');
        this.$mdDialog.show(alert);
        }
      });
    }

    refreshProjectList(reload = false) {
      this.ProjectService.getAllProjects(reload).then(response => {
        this.projectList = response.data;
      });
    }
  },
};
