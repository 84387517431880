export default function formatCurrency(number) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(number);
}

export const maskPrice = (str) => {
  let mask;
  let y = str.replace(/\D/g, '');
  if (y === '') {
    return 'R$ 0,00';
  }
  if (y.length < 3) {
    if (y < 10) {
      return 'R$ 0,0'.concat(y);
    }
    return 'R$ 0,'.concat(y);
  }
  y = String(Number(y));
  const integer = String(Number(y.slice(0, -2)));
  const decimals = y.slice(-2);
  mask = integer.concat(',').concat(decimals);
  mask = mask.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');
  return 'R$ '.concat(mask);
};

export const unmaskPrice = (x) => {
  const y = Number(String(x).replace(/\D/g, ''));
  return y;
};

export const truncate2decimals = (number) => Math.round(number * 100) / 100;

export const currencyFormatter = (value) => {
  if (!Number(value)) return '';
  if (value.isSafeInteger) return `${value}`;

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);

  return `${amount}`;
};

export const initialCurrencyFormatter = (value) => {
  if (!Number(value)) return '';

  return value.toFixed(2);
};
