/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';

export const NoTaskText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  padding-right: 75px;
  padding-left: 40px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 24px;
  background-color: #fff;
`;
