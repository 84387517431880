import React, { Dispatch, SetStateAction } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Input from '~/components/Input';
import API from '~/services/api';
import ColorPicker from '../ColorPicker';

import {
  InputsProjectContainer,
  ModalSubtitle,
  ProjectContainer,
  SubmitButton,
  ProjectText,
} from './styles';
import NotificationServiceType from '~/models/ServicesTypes';

const validationSchema = () =>
  Yup.object().shape({
    projectName: Yup.string().required('Campo obrigatório'),
  });

const colorOptions = [
  '#3D3389',
  '#A02AA9',
  '#8070B2',
  '#5B91BB',
  '#00ABBD',
  '#13727C',
  '#004720',
  '#009245',
  '#398A38',
  '#29C5A9',
  '#870300',
  '#E21300',
  '#FFAA00',
  '#FFC729',
  '#E85D7E',
  '#303030',
  '#707070',
];

type ProjectCreationProps = {
  setCurrentStep: (value: number) => void;
  multipleUsers: boolean;
  NotificationService: NotificationServiceType;
  onRequestModalClose: () => void;
  setProjectIdCreated: Dispatch<SetStateAction<number | undefined>>;
};

const ProjectCreation = ({
  setCurrentStep,
  multipleUsers,
  onRequestModalClose,
  setProjectIdCreated,
  NotificationService,
}: ProjectCreationProps) => {
  const handleProjectCreation = ({ projectName, projectColor }) => {
    const project = {
      name: projectName,
      tagColor: projectColor,
    };
    API.team
      .get_projects()
      .then((response) => response.json())
      .then((teamData) => {
        API.team
          .change_project(project, teamData[0].id)
          .then((response) => {
            if (response.status === 200) {
              localStorage.setItem('firstProjectCreated', 'true');
              NotificationService.showNotification(
                'Projeto criado com sucesso!',
                'success',
              );
            } else
              NotificationService.showNotification(
                'Ocorreu um erro ao adicionar projeto',
                'error',
              );
            return response.json();
          })
          .then((projectData) => {
            const { id } = projectData;
            setProjectIdCreated(id);
          });
      });
  };
  return (
    <Formik
      initialValues={{
        projectName: '',
        projectColor: colorOptions[0],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleProjectCreation(values);
        setSubmitting(false);
        if (multipleUsers) setCurrentStep(2);
        else onRequestModalClose();
      }}
    >
      {({ setFieldValue, isSubmitting, isValid, values }) => (
        <Form style={{ position: 'relative' }}>
          <ProjectContainer>
            <ModalSubtitle>Crie seu primeiro projeto</ModalSubtitle>
            <InputsProjectContainer>
              <Input
                type="text"
                name="projectName"
                placeholder="Ex.: Casa Laranja"
                value=""
                label="Nome do projeto*"
              />
              <ColorPicker
                name="projectColor"
                options={colorOptions}
                onChange={(e) => {
                  setFieldValue('projectColor', e.target.value);
                }}
              />
            </InputsProjectContainer>
            <ProjectText>
              <span>
                Todas suas tarefas no Labor precisam estar vinculadas a um
                projeto.{' '}
              </span>
              O nome e a cor são importantes para você se organizar visualmente,
              mas você poderá inserir mais informações depois.
            </ProjectText>
            <SubmitButton
              type="submit"
              disabled={isSubmitting || !isValid || values.projectName === ''}
            >
              Criar projeto
            </SubmitButton>
          </ProjectContainer>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectCreation;
