import React from 'react';

import * as S from './styles';

function getDaysByWeek({ year, month }) {
  const result: Date[][] = [];

  const firstMonthDay = new Date(year, month, 1);
  const lastMonthDay = new Date(year, month + 1, 0);
  const firstCalendarDay = new Date(firstMonthDay.getTime());
  firstCalendarDay.setDate(firstMonthDay.getDate() - firstMonthDay.getDay());
  const numberOfWeeks = Math.ceil(
    (firstMonthDay.getDay() + lastMonthDay.getDate()) / 7,
  );

  const date = new Date(firstCalendarDay.getTime());
  for (let i = 0; i < numberOfWeeks; i += 1) {
    result.push([]);
    for (let j = 0; j < 7; j += 1) {
      result[i].push(new Date(date.getTime()));
      date.setDate(date.getDate() + 1);
    }
  }

  return result;
}

function renderTableHeader() {
  const weekDays = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
  return (
    <tr>
      {weekDays.map((weekDay, index) => (
        <S.ColumnHeader key={index}>{weekDay}</S.ColumnHeader>
      ))}
    </tr>
  );
}

function renderDays({ daysByWeek, month, currentDate, setCurrentDate }) {
  function onDayClick(day) {
    setCurrentDate(day);
  }

  function renderDay(day) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return (
      <S.DayContainer>
        <S.Day
          selected={currentDate.toDateString() === day.toDateString()}
          onClick={() => onDayClick(day)}
        >
          {day.getDate()}
        </S.Day>
      </S.DayContainer>
    );
  }

  return (
    <>
      {daysByWeek.map((week, index) => (
        <tr key={index}>
          {week.map((day, weekIndex) => (
            <td key={weekIndex} style={{ padding: 0 }}>
              {day.getMonth() === month.getMonth() ? renderDay(day) : ''}
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
interface DatePickerProps {
  monthProp?: Date;
  currentDate: Date;
  setCurrentDate: (date: Date) => void;
  month: Date;
}
export default function DatePicker(props: DatePickerProps) {
  // TODO: type it
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const { month: monthProp } = props;
  const [year, month] = [monthProp.getFullYear(), monthProp.getMonth()];

  const daysByWeek = getDaysByWeek({ year, month });
  return (
    <S.Table>
      <thead>{renderTableHeader()}</thead>
      <tbody>{renderDays({ daysByWeek, ...props })}</tbody>
    </S.Table>
  );
}
