import React from 'react';
import Modal from '~/components/Modal';
import Button from '~/components/Button';
import API from '~/services/api';
import { Text, HeaderText, DeleteModalContainer } from './styles';

type DeletePlannedTaskModalProps = {
  PlannedTaskId: number;
  setModalOpen: (boolean) => void;
  modalIsOpen: boolean;
  RemoveTask: Function;
};
const DeletePlannedTaskModal = ({
  PlannedTaskId,
  setModalOpen,
  modalIsOpen,
  RemoveTask,
}: DeletePlannedTaskModalProps) => {
  const projectId = parseInt(window.location.pathname.split('/')[4]);
  const onRequestClose = () => {
    setModalOpen(false);
  };
  const handleDeletePlannedTask = (id) => {
    API.team.remove_planned_task(projectId, id);
    RemoveTask();
    onRequestClose();
  };
  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={modalIsOpen}
      blurBackground={true}
      backgroundColor="#ffffff"
      width="500px"
      modalTransformPosition="translate(-50%, -80%)"
    >
      <DeleteModalContainer>
        <HeaderText>Deseja realmente remover a tarefa planejada?</HeaderText>
        <Text>Essa ação não pode ser desfeita.</Text>
        <Button
          style={{ padding: '10px 50px' }}
          onClick={() => {
            handleDeletePlannedTask(PlannedTaskId);
          }}
        >
          Confirmar
        </Button>
      </DeleteModalContainer>
    </Modal>
  );
};

export default DeletePlannedTaskModal;
