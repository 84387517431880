import React, { useState, useEffect } from 'react';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import Input from '~/components/Input';
import Select from '~/components/Select';
import { LightButton } from '~/components/Button';
import Tooltip from '../Tooltip';
import API from '~/services/api';

import NotificationServiceType from '~/models/ServicesTypes';

import {
  ModalSubtitle,
  LinkContainer,
  TeamContainer,
  AddMoreButton,
  LinkText,
  InputsTeamContainer,
  InputsLinkContainer,
  TeamDivider,
  TeamDividerLine,
  SubmitButton,
  TeamDividerText,
  LinkCopiedText,
  LinkButtonContainer,
} from './styles';

const validationInvitesSchema = () =>
  Yup.object().shape({
    personsInvited: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .email('Email inválido')
          .required('Campo obrigatório'),
        permission: Yup.string().required('Campo obrigatório'),
      }),
    ),
  });

const permissionOptions = {
  colaborador: 'Colaborador',
  gerente: 'Gerente',
};

type InviteTeamProps = {
  onRequestModalClose: () => void;
  userInTrial: boolean;
  projectIdCreated: number | undefined;
  NotificationService: NotificationServiceType;
};
const InviteTeam = ({
  onRequestModalClose,
  projectIdCreated,
  NotificationService,
  userInTrial,
}: InviteTeamProps) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('loggedUser') || '{}');
    const inviteCode = user?.allocations[0].team.cod_invite;
    // @ts-ignore
    setInviteLink(`${SITE_URL}/invite/${inviteCode}`);
  }, []);

  const handleUsersInvite = async (values) => {
    const allocations = values.personsInvited.map(({ email, permission }) => ({
      email,
      project_allocation: {
        project_id: projectIdCreated,
        role: permission,
      },
    }));
    const data = { allocations };
    const {
      team: { id: teamId },
    } = JSON.parse(localStorage.getItem('loggedUser') || '{}');
    const response = await API.team.add_multiple_users(teamId, data);
    if (response.status === 201) {
      NotificationService.showNotification(
        'Convite(s) enviados com sucesso.',
        'success',
      );
    } else {
      NotificationService.showNotification(
        'Ocorreu um erro ao adicionar usuário',
        'error',
      );
    }
    onRequestModalClose();
  };

  return (
    <Formik
      initialValues={{
        personsInvited: [
          {
            id: Math.random(),
            email: '',
            permission: 'colaborador',
          },
        ],
      }}
      validationSchema={validationInvitesSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleUsersInvite(values);
        setSubmitting(false);
      }}
    >
      {({ values, setFieldValue, isSubmitting, errors, touched, isValid }) => (
        <Form style={{ position: 'relative' }}>
          <TeamContainer>
            <ModalSubtitle>
              Convide um time para atuar no projeto
              <Tooltip>
                <span>
                  Durante o período de teste gratuito você pode convidar apenas
                  um novo usuário.
                </span>
              </Tooltip>
            </ModalSubtitle>
            <FieldArray name="personsInvited">
              {({ push }) => (
                <>
                  {values.personsInvited.map((person, index) => {
                    const email = `personsInvited[${index}].email`;
                    const permission = `personsInvited[${index}].permission`;
                    const touchedEmail = getIn(touched, email);
                    const errorEmail = getIn(errors, email);

                    return (
                      <InputsTeamContainer key={person.id}>
                        <Input
                          key={person.id}
                          type="text"
                          touched={touchedEmail}
                          errors={errorEmail}
                          name={email}
                          placeholder="email@exemplo.com.br"
                          value={person.email}
                          label="E-mail*"
                        />
                        <Select
                          name={permission}
                          label="Permissão no projeto*"
                          value={person.permission}
                          options={Object.entries(permissionOptions).map(
                            ([value, label]) => ({
                              value,
                              label,
                            }),
                          )}
                          onChange={(e) => {
                            setFieldValue(`${permission}`, e.target.value);
                          }}
                        />
                        <Tooltip>
                          <p>
                            A permissão determina as ações que o usuário executa
                            dentro de um projeto. Gerente: pode alocar usuários
                            em projetos ou removê-los, e alterar a função.
                            Colaborador: cria e realiza tarefas.
                          </p>
                        </Tooltip>
                      </InputsTeamContainer>
                    );
                  })}
                  {!userInTrial && (
                    <AddMoreButton
                      type="button"
                      onClick={() => {
                        push({ id: Math.random(), email: '', permission: '1' });
                      }}
                    >
                      Adicionar mais
                    </AddMoreButton>
                  )}
                  <SubmitButton
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    Enviar convite
                  </SubmitButton>
                </>
              )}
            </FieldArray>
            <TeamDivider>
              <TeamDividerLine />
              <TeamDividerText> ou </TeamDividerText>
              <TeamDividerLine />
            </TeamDivider>
          </TeamContainer>
          <LinkContainer>
            <ModalSubtitle>Convidar via link</ModalSubtitle>
            <LinkText>
              Os membros serão cadastrados como{' '}
              <span style={{ fontWeight: 'bold' }}>colaboradores</span>. Você
              pode alterar isso depois.
            </LinkText>
            <InputsLinkContainer>
              <Input
                type="text"
                disabled={true}
                name="inviteLink"
                value={inviteLink}
                placeholder=""
              />
              <LinkButtonContainer>
                <LightButton
                  hasIcon
                  type="button"
                  onClick={() => {
                    setLinkCopied(true);
                    setTimeout(() => setLinkCopied(false), 1000);
                    navigator.clipboard.writeText(inviteLink);
                  }}
                >
                  <FontAwesomeIcon icon={faClone} />
                  Copiar link
                </LightButton>
                {linkCopied && <LinkCopiedText>Link Copiado</LinkCopiedText>}
              </LinkButtonContainer>
            </InputsLinkContainer>
            <AddMoreButton
              type="button"
              onClick={() => {
                onRequestModalClose();
              }}
            >
              Pular etapa
            </AddMoreButton>
          </LinkContainer>
        </Form>
      )}
    </Formik>
  );
};

export default InviteTeam;
