import styled from '@emotion/styled';
import { ProjectDuration } from '~/store/projectsTasks';

type ProjectTimeProps = {
  project: ProjectDuration;
  totalTime: number;
};
export const ProjectsBarGraph = styled.div`
  display: flex;
  padding: 0 1rem 2rem 1rem;
  align-items: center;

  @media (min-width: 600px) {
    padding: 0 25px 50px 40px;
  }
`;

export const ProjectTime = styled.div<ProjectTimeProps>`
  flex: ${(props) => (100 * props.project.duration) / props.totalTime || 0};
  background-color: ${(props) =>
    props.project.color ? props.project.color : '#959595'};
  transition: 2s;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 3px 10px;
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  min-height: 25px;
  height: 19px;
  span::before {
    color: white;
    content: 'timer';
    font-size: 15px;
    padding-right: 5px;
    display: inline-block;
    position: relative;
    top: 2px;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
`;
