export const CapitalizeFilter = () => {
  'ngInject';

  return input => {
    if (angular.isString(input) && input.length > 0) {
      return input.charAt(0).toUpperCase() + input.substr(1).toLowerCase();
    }
    return input;
  };
};
