import styled from '@emotion/styled';
import theme from '~/theme'
import Checkbox from '@mui/material/Checkbox';
import { Field } from 'formik';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const HeaderText = styled.h2`
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.gray18};
  padding-bottom: 26px;
  margin: 0;
  border-bottom: 1px solid #eee;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  align-items: left;
  margin-bottom: 23px;
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CheckboxContainer = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 55px;
  align-items: center;
`;

export const CheckboxInput = styled(Checkbox)`
  border-width: 0 0 2px;
  /* margin: 0 17.5px 0px; */
  max-height: 30px;
`;

// export const CheckboxContainer = styled.div`
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 22px;
//   margin-bottom: 55px;
//   align-items: center;
// `;

// export const StyledCheckbox = styled(Checkbox)`
//   border-width: 0 0 2px;
//   padding: 14px 20px 14px 0;
//   max-height: 30px;
// `;


export const SaveButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 7px 20px 6px;
  margin-top: 48px;
  width: 88px;
  height: 35px;
  background-color: ${theme.colors.primary};
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  border: none;
`;