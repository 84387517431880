import angular from 'angular';

export class DropdownService {
  constructor($document) {
    'ngInject';

    this.actions = [];

    $document.on('click', () => {
      // dispara todos os eventos registrados via "register"
      this.actions.forEach(scope => {
        scope.isOpened = false;
        scope.$apply();
      });
    });
  }

  closeAll() {
    // executa todos os eventos registrados via "register"
    this.actions.forEach(scope => {
      scope.isOpened = false;
    });
  }

  register(scope) {
    this.actions.push(scope);
    return this.actions.length - 1;
  }

  unregister(index) {
    // * não usar splice *
    // o index não pode ser alterado,
    // ao usar o splice o index será auto ajustado.
    delete this.actions[index];
  }
}
