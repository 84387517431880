import React from 'react';
import Modal from '~/components/Modal';
import laborLogo from '../Header/images/labor-logo.svg';
import ProgressBar from '../ProgressBar/index';
import * as S from './style';

interface LoadingStateProps {
  RedirectUrl: string;
  isOpen: boolean;
}

const LoadingState = ({ RedirectUrl, isOpen }: LoadingStateProps) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={() => {}}
    hasCloseButton={false}
    width="100%"
    height="100%"
    maxHeight="100%"
    maxWidth="100%"
    padding="0px"
  >
    <ProgressBar />
    <S.LoadingStateBody>
      <img src={laborLogo} style={{ width: '106px' }} />

      <S.LoadingStateText>
        Em poucos instantes você poderá começar a otimizar seu tempo
      </S.LoadingStateText>

      <S.LoadingStateText>
        A página está demorando muito para carregar? Clique{' '}
        <S.LoadingStateLink href={RedirectUrl}>aqui</S.LoadingStateLink> para
        atualizar.
      </S.LoadingStateText>
    </S.LoadingStateBody>
    <></>
  </Modal>
);

export default LoadingState;
