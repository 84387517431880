import { GetState, SetState } from 'zustand';
import {
  ReportsState,
  ProjectDuration,
  ReportOrientation,
  ReportFormat,
} from './types';
import { DailyTasks, MultipleDailyTasks, Task } from '~/models/types';
import useProjectsStore from '~/store/projects/projects';
import useTeamStore from '../team';
import useUserStore from '../user';
import API from '~/services/api';
import { dateToString } from '~/app/utils/date';
import AmplitudeUserStore from '~/services/api/amplitude';
import NotificationServiceType from '~/models/ServicesTypes';

export const checkArrayEquals = (a: number[], b: number[]) =>
  a.length === b.length && a.every((v) => b.includes(v));

export const newDateRange = () => {
  const firstDayOfMonth = new Date();
  firstDayOfMonth.setDate(1);
  firstDayOfMonth.setHours(0, 0, 0, 0);

  const lastDayOfMonth = new Date();
  lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
  lastDayOfMonth.setDate(0);
  lastDayOfMonth.setHours(0, 0, 0, 0);

  return { startDate: firstDayOfMonth, endDate: lastDayOfMonth };
};

const parseCost = (cost: number | string) =>
  typeof cost === 'string' ? parseFloat(cost) : cost;

export const resetChangeFlags = (set: SetState<ReportsState>) => {
  set({
    hasChangeFlags: {
      projects: false,
      users: false,
      planneds: false,
      date: false,
    },
  });
};

export const setIsFetchingReports = (set: SetState<ReportsState>, state: boolean) => {
  set({ isFetchingReports: state });
};

export const resetReportsStore = (set: SetState<ReportsState>, get: GetState<ReportsState>) => {
  if(get().shouldResetReportsDate){
    set ({dateRange: newDateRange()})
  }
  set({
    reports: [],
    selectedProjectsIds: [],
    selectedUsersIds: [],
    selectedPlannedsIds: [],
    tasks: {},
    projectsDuration: { projectsDuration: [], totalDuration: 0 },
    totalCost: null,
    shouldResetReportsDate: true,
  });
  resetChangeFlags(set);
};

export const fetchReportsData = async (set: SetState<ReportsState>) => {
  const response = await API.report.get_reports(
    undefined,
    undefined,
    useUserStore.getState().user.id,
  );
  const data = await response.json();
  set({ reports: data });
};

const getProjectsStoreState = useProjectsStore.getState;

export const calculateProjectDuration = (
  set: SetState<ReportsState>,
  taskList: MultipleDailyTasks,
) => {
  const projectData = getProjectsStoreState().projects;
  let tempProjectDurationArray: ProjectDuration[] = [];
  if (projectData.length === 0 || Object.values(taskList).length === 0) {
    set({ projectsDuration: { projectsDuration: [], totalDuration: 0 } });
    return;
  }
  let tempTotalDuration = 0;
  let totalCost = 0;
  Object.values(taskList).forEach((tasksInDay: DailyTasks) => {
    tasksInDay.tasks.forEach((task: Task) => {
      if (
        tempProjectDurationArray.find(
          (project) => project.id === task.projectId,
        )
      ) {
        const newProjectDuration = tempProjectDurationArray.map(
          (projectDuration) =>
            projectDuration.id === task.projectId
              ? {
                  ...projectDuration,
                  duration: projectDuration.duration + task.duration,
                  cost: projectDuration.cost + parseCost(task.cost),
                }
              : projectDuration,
        );
        tempTotalDuration += task.duration;
        tempProjectDurationArray = newProjectDuration;
      } else {
        const proj = projectData?.find(
          (project) => project.id === task.projectId,
        );

        let name: string;
        let tagColor: string;
        let price: number;
        let estimatedHours: number;
        let category: string;

        if (proj) {
          name = proj.name;
          tagColor = proj.tagColor || '#000000';
          price = proj.price || 0;
          estimatedHours = proj.estimatedHours || 0;
          category = proj.category || 'internal';
        } else
          [name, tagColor, price, estimatedHours, category] = [
            'Sem projeto',
            '#959595',
            0,
            0,
            'internal',
          ];

        const newProjectDuration = {
          duration: task.duration,
          cost: parseCost(task.cost),
          name,
          id: task.projectId,
          color: tagColor,
          price,
          estimatedHours,
          category,
        };
        tempTotalDuration += task.duration;
        tempProjectDurationArray.push(newProjectDuration);
      }
    });
    totalCost += parseCost(tasksInDay.cost);
  });
  set({ totalCost });
  set({
    projectsDuration: {
      projectsDuration: tempProjectDurationArray,
      totalDuration: tempTotalDuration,
    },
  });
};

const createQuerys = (
  projectIds: number[],
  userIds: number[],
  plannedIds: number[],
) => {
  // To pass arrays encoded in the url, we need pass each id separately
  // Done here to avoid changing the apiFetch function.

  // if all are selected, you don't need to send anything
  const allUsers = userIds.length === useTeamStore.getState().teamUsers.length;
  const allProjects =
    projectIds.length === useProjectsStore.getState().projects.length;

  const projectIdsQuery =
    projectIds.length > 0 && !allProjects
      ? projectIds.join('&project_id%5B%5D=')
      : null;
  const userIdsQuery =
    userIds.length > 0 && !allUsers ? userIds.join('&user_id%5B%5D=') : null;
  const plannedIdsQuery =
    plannedIds.length > 0 ? plannedIds.join('&planned_task_id%5B%5D=') : null;

  return { projectIdsQuery, userIdsQuery, plannedIdsQuery };
};

export const fetchTasksData = async (
  set: SetState<ReportsState>,
  get: GetState<ReportsState>,
) => {
  const { startDate, endDate } = get().dateRange;
  const projectIds = get().selectedProjectsIds;
  const userIds = get().selectedUsersIds;
  const plannedIds = get().selectedPlannedsIds;

  const { projectIdsQuery, userIdsQuery, plannedIdsQuery } = createQuerys(
    projectIds,
    userIds,
    plannedIds,
  );

  try {
    const responseTasks = await API.user.get_tasks({
      starting: startDate?.toISOString(),
      ending: endDate?.toISOString(),
      userIds: userIdsQuery,
      includes: 'planned_task',
      projectIds: projectIdsQuery,
      plannedTaskIds: plannedIdsQuery,
      cost: true,
    });
  
    set({ tasks: {} });
    const tasksData = await responseTasks.json();

    set({ tasks: tasksData.lenght === 0 ? {} : tasksData });

    calculateProjectDuration(set, get().tasks);
    setIsFetchingReports(set, false);
  } catch (e) {
    console.log(e);
    setIsFetchingReports(set, false);
  }
};

export const downloadReport = async (
  get: GetState<ReportsState>,
  AMPLITUDE_API_KEY: string,
  NotificationService: NotificationServiceType,
  orientation: ReportOrientation = 'Portrait',
  format: ReportFormat = 'pdf',
) => {
  const { startDate, endDate } = get().dateRange;
  const projectIds = get().selectedProjectsIds;
  const userIds = get().selectedUsersIds;
  const plannedIds = get().selectedPlannedsIds;

  const { projectIdsQuery, userIdsQuery, plannedIdsQuery } = createQuerys(
    projectIds,
    userIds,
    plannedIds,
  );

  const amplitude = new AmplitudeUserStore(null, AMPLITUDE_API_KEY);
  amplitude.sendData('Download Report Click', {
    reportOrientation: orientation,
    plannedIds,
    projectIds,
    userIds,
    format,
  });

  try {
    const response = await API.report.download_report({
      starting: startDate?.toISOString(),
      ending: endDate?.toISOString(),
      userIds: userIdsQuery,
      projectIds: projectIdsQuery,
      plannedTaskIds: plannedIdsQuery,
      format,
      orientation,
    });
    if (!response.ok) {
      const json = await response.json();
      const error = new Error(json.error);
      Object.assign(error, json);
      throw error;
    }

    const data = await response.arrayBuffer();

    const blob = new Blob([data], {
      type: format === 'csv' ? 'text/csv' : 'application/pdf',
    });

    // parts holds the file title
    const parts = [`Relatório Labor de ${dateToString(new Date())}`];

    if (startDate && endDate) {
      parts.push(
        `Período de ${dateToString(startDate)} a ${dateToString(endDate)}`,
      );
    }

    const users = useTeamStore.getState().teamUsers;
    if (userIds && userIds.length < users.length) {
      const userNames = users
        .filter((user) => userIds.includes(user.id))
        .map((item) => item.name);
      parts.push(`Usuários ${userNames.join(', ')}`);
    }

    const { projects } = useProjectsStore.getState();
    if (projectIds && projectIds.length < projects.length) {
      const projectNames = projects
        .filter((project) => projectIds.includes(project.id))
        .map((item) => item.name);
      parts.push(`Projetos ${projectNames.join(', ')}`);
    }

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${parts.join(' - ')}.${format}`;
    a.click();
    window.URL.revokeObjectURL(url);
    amplitude.sendData(`${format} Download Succeeded`, {
      starting: startDate?.toISOString(),
      ending: endDate?.toISOString(),
      userIds,
      projectIds,
      plannedTaskIds: plannedIds,
      reportOrientation: orientation,
      format,
    });
  } catch (error) {
    NotificationService.showNotification(
      'Falha no download do relatório',
      'error',
    );
    amplitude.sendData(`${format} Download Failed`, {
      error: error.json,
      starting: startDate?.toISOString(),
      ending: endDate?.toISOString(),
      userIds,
      projectIds,
      plannedTaskIds: plannedIds,
      reportOrientation: orientation,
      format,
    });
  }
};
