import React from 'react';
import styled from '@emotion/styled';
import { Field as FormikField } from 'formik';
import Select from 'react-select';

const SelectCustom = styled(Select)`
  .select__control {
    border: 1px solid rgba(149, 149, 149, 0.2);
    border-radius: 0;
    height: 52px;
    box-shadow: none;
    cursor: pointer;
    &:hover {
      border-color: rgba(149, 149, 149, 0.2);
    }
  }
  .select__indicator {
    color: #959595;
    margin-right: 6px;
  }
  .select__indicator-separator {
    display: none;
  }
  .select__single-value {
    margin-left: 8px;
  }
  .select__input-container {
    caret-color: transparent;
  }
  .select__placeholder {
    color: #5f5f5f;
  }
  .select__menu {
    border-radius: 0;
  }
  .select__option {
    background-color: #fff;
    color: #5f5f5f;
    cursor: pointer;
  }
  .select__option--is-focused {
    background-color: #f6f7fb;
  }
`;

interface Props {
  name: string;
  onChange?: Function ;
}

const StatusList: React.FC<Props> = ({ name, onChange }) => {
  const STATUS_OPTIONS = [
    { value: 'not_started', label: 'Não iniciado' },
    { value: 'running', label: 'Em andamento' },
    { value: 'finished', label: 'Concluído' },
  ];

  const handleStatusChange = (selected: { value: string; label: string }) => {
    if(onChange)
      onChange(name, selected.value);
  };

  return (
    <FormikField name={name}>
      {({ field }) => (
        <SelectCustom
          options={STATUS_OPTIONS}
          value={STATUS_OPTIONS.filter(
            (option) => option.value === field.value,
          )}
          onChange={handleStatusChange}
          classNamePrefix="select"
        />
      )}
    </FormikField>
  );
};

export default StatusList;
