import styled from '@emotion/styled';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import theme from '~/theme';

export const TaskAlert = styled.div`
  display: flex;
  background: ${theme.colors.white};
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); */
  /* border-left: 5px solid red; */

`;

export const TaskAlertText = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 0px 10px 0px 10px;
  color: ${theme.colors.gray18};
`;

export const TaskAlertPercentage = styled.span`
  font-weight: 600;
  color: ${theme.colors.primary};
`;

export const AlertIcon = styled(ErrorOutlineIcon)`
  width: 15px;
  height: 15px;
  color: ${theme.colors.primary};
  background: rgba(226, 19, 0, 0.1);
  border-radius: 100%;
  border: 2.5px solid rgba(226, 19, 0, 0.1);
`;
export const Closeicon = styled(CloseIcon)`
  height: 15px;
  width: 15px;
  margin-top: 16px;
  margin-right: 16px;
  cursor: pointer;
`;
