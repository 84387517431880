export const DatetimepickerTrigger = (
  $rootScope,
  $compile,
  $document,
  $log,
  UtilsService,
) => {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      task: '<',
      running: '<',
      triggerFunction: '&',
    },
    link: (scope, element, attrs) => {
      let datetimepicker = null;
      let listener = null;

      scope.$watch('task', function() {
        resetTaskClone();
      });

      element.on('click', e => {
        e.stopPropagation();

        if (element.prop('datetimepicker-on') == 'true') {
          return;
        }

        datetimepicker = $compile(
          `<datetimepicker style="top: ${element.parent().prop('offsetHeight') +
            12}px" task="taskClone" running="running"></datetimepicker>`,
        )(scope);
        element.parent().addClass('datetimepicker-parent');
        element.parent().append(datetimepicker);
        element.prop('datetimepicker-on', 'true');
        $rootScope.$broadcast('datetimepicker-opened');

        listener = $rootScope.$on('datetimepicker-opened', function() {
          removeElementAndListeners();
        });

        datetimepicker.on('click', e => {
          e.stopPropagation();
        });

        $document.on('keydown', eventHandler);
        datetimepicker.find('input').on('keydown keypress', eventHandler);
        $document.on('click', eventHandler);

        function removeElementAndListeners() {
          listener();
          $document.off('click', eventHandler);
          element.prop('datetimepicker-on', 'false');
          scope.$emit('datetimepicker-closed');
          resetTaskClone();
          datetimepicker.remove();
        }

        function eventHandler(event) {
          const ENTER_KEY = 13;
          if (event.type == 'click' || event.which === ENTER_KEY) {
            if (scope.taskClone.start != scope.taskClone.end) {
              submitDatetimepicker();
            }
            removeElementAndListeners();
          } else if (event.which === 27) {
            removeElementAndListeners();
          }
        }
      });

      // //////////////

      function resetTaskClone() {
        scope.taskClone = angular.copy(scope.task);
      }

      function submitDatetimepicker() {
        const initialStart = new Date(scope.task.start);
        const initialEnd = scope.task.end ? new Date(scope.task.end) : null;

        if (!angular.isDate(scope.taskClone.start)) {
          scope.taskClone.start = new Date(scope.taskClone.start);
        }

        if (
          scope.taskClone.end != null &&
          !angular.isDate(scope.taskClone.end)
        ) {
          scope.taskClone.end = new Date(scope.taskClone.end);
        }

        // Fix start after end
        if (scope.task.end != null) {
          if (
            scope.taskClone.start.getHours() > scope.taskClone.end.getHours() ||
            (scope.taskClone.start.getHours() ===
              scope.taskClone.end.getHours() &&
              scope.taskClone.start.getMinutes() >
                scope.taskClone.end.getMinutes())
          ) {
            scope.taskClone.start.setDate(scope.taskClone.end.getDate() - 1);
          } else {
            scope.taskClone.end.setDate(scope.taskClone.start.getDate());
          }
        } else {
          const now = new Date();

          if (scope.taskClone.start > now) {
            scope.taskClone.start.setDate(now.getDate() - 1);
          }
        }

        if (
          (initialEnd != null &&
            initialEnd.getTime() != scope.taskClone.end.getTime()) ||
          (initialStart != null &&
            initialStart.getTime() != scope.taskClone.start.getTime())
        ) {
          scope.triggerFunction({ task: scope.taskClone });
        }

        scope.$apply();
      }
    },
  };
};
