import styled from '@emotion/styled';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  text-align: center;
`;

export const ColumnHeader = styled.th`
  font-weight: bold;
  color: #d6d6d6;
  margin: 0.3em;
  line-height: 2.5em;
`;

export const DayContainer = styled.div`
  margin: 0.3em 0;
  height: 2.5em;
`;

export const Day = styled.span`
  display: inline-block;
  cursor: pointer;
  color: #7d7d7d;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  margin: 0 0.3em;
  line-height: 2.5em;
  &: hover {
    background-color: #f3f3f3;
  }
${({ selected }: { selected: boolean | undefined }) =>
  selected
    ? `
    background-color: #fe3b5b;
    color: #ffffff;
    &:hover {
      background-color: #fe3b5b;
      color: #ffffff;
    }
  `
    : ''}
`;
