import create, { GetState, SetState } from 'zustand';
import {
  newDateRange,
  fetchReportsData,
  fetchTasksData,
  checkArrayEquals,
  downloadReport,
  resetChangeFlags,
  resetReportsStore,
  setIsFetchingReports,
} from './utils';
import { ReportsState } from './types';
import useUserStore from '../user';

const useReportsStore = create<ReportsState>(
  (set: SetState<ReportsState>, get: GetState<ReportsState>) => ({
    reports: [],
    selectedProjectsIds: [],
    selectedUsersIds: [],
    selectedPlannedsIds: [],
    dateRange: newDateRange(),
    tasks: {},
    projectsDuration: { projectsDuration: [], totalDuration: 0 },
    totalCost: null,
    shouldResetReportsDate: true,
    hasChangeFlags: {
      projects: false,
      users: false,
      planneds: false,
      date: false,
    },
    isFetchingReports: false,
    resetReportsStore: () => resetReportsStore(set, get),
    setResetReportsDate: (should: boolean) => {
      set({ shouldResetReportsDate: should });
    },
    setSelectedProjectsIds: (selectedProjectsIds) => {
      if (checkArrayEquals(selectedProjectsIds, get().selectedProjectsIds))
        return;
      set({ selectedPlannedsIds: [] });
      set({ selectedProjectsIds });
      const hasChangeFlags = { ...get().hasChangeFlags, projects: true };
      set({ hasChangeFlags });
    },
    setSelectedUsersIds: (selectedUsersIds) => {
      if (checkArrayEquals(selectedUsersIds, get().selectedUsersIds)) return;
      set({ selectedUsersIds });
      const hasChangeFlags = { ...get().hasChangeFlags, users: true };
      set({ hasChangeFlags });
    },
    setSelectedPlannedsIds: (selectedPlannedsIds) => {
      if (checkArrayEquals(selectedPlannedsIds, get().selectedPlannedsIds))
        return;
      set({ selectedPlannedsIds });
      const hasChangeFlags = { ...get().hasChangeFlags, planneds: true };
      set({ hasChangeFlags });
    },
    setDateRange: (dateRange: {
      startDate: Date | null;
      endDate: Date | null;
    }) => {
      if (
        dateRange.startDate?.getTime() ===
          get().dateRange.startDate?.getTime() &&
        dateRange.endDate?.getTime() === get().dateRange.endDate?.getTime()
      )
        return;
      set({ dateRange });
      const hasChangeFlags = { ...get().hasChangeFlags, date: true };
      set({ hasChangeFlags });
    },
    setCurrentUserId: () => {
      const selectedUsersIds = [useUserStore.getState().user.id];
      set({ selectedUsersIds });
    },
    fetchReportsData: async () => fetchReportsData(set),
    fetchTasksData: async () => {
      setIsFetchingReports(set, true);
      resetChangeFlags(set);
      await fetchTasksData(set, get);
    },
    downloadReport: async (
      AMPLITUDE_API_KEY,
      NotificationService,
      orientation,
      format,
    ) =>
      downloadReport(
        get,
        AMPLITUDE_API_KEY,
        NotificationService,
        orientation,
        format,
      ),
  }),
);

export default useReportsStore;
