export default class User {
  static get modelName() {
    return 'User';
  }

  constructor(props) {
    Object.assign(this, props);
    this.allocation = {
      ...props.allocation,
      user_id: props.allocation.user.id,
      team_id: props.allocation.team.id,
    };
  }
}
