import styled from '@emotion/styled';

type PageIndicatorType = {
  currentPage: number;
  page: number;
};
export const Container = styled.div`
  display: inline-flex;
  margin-top: 7%;
`;

export const PageIndicator = styled.div<PageIndicatorType>`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.currentPage === props.page ? '#5F5F5F' : '#D7D7D7'};
`;
