import React from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';

interface ModalProps {
  children?: React.ReactNode;
  width?: string;
  height?: string;
  maxWidth?: string;
  padding?: string;
  backgroundColor?: string;
  isOpen?: boolean;
  onRequestClose: () => void;
  modalTransformPosition?: string;
  blurBackground?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  hasCloseButton?: boolean;
  maxHeight?: string;
  overflowY?: string;
  overflowX?: string;
  styles?: Object;
}

export const ModalTitle = styled.h2`
  font-family: 'Bitter', serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  color: #5f5f5f;
  margin: 0 0 20px 0;
`;
export const ModalText = styled.p`
  font-size: 15px;
  color: #7d7d7d;
  margin: 0 0 20px 0;
`;
export const ModalClose = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`;

export default function Modal({
  hasCloseButton = true,
  height,
  width,
  maxHeight = '754px',
  backgroundColor,
  shouldCloseOnOverlayClick = true,
  isOpen,
  blurBackground = false,
  modalTransformPosition = 'translate(-50%, -60%)',
  onRequestClose,
  children,
  padding = '40px 35px',
  maxWidth = '45%',
  overflowY = 'visible',
  overflowX = 'visible',
  styles,
  ...otherProps
}: ModalProps) {
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: `${
        blurBackground ? 'rgba(79, 88, 122, 0.7)' : 'rgba(246, 247, 251, 0.97)'
      }`,
      zIndex: 100,
    },
    content: {
      position: 'relative',
      top: '60%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: modalTransformPosition,
      backgroundColor: `${
        backgroundColor ? `${backgroundColor}` : 'rgba(246, 247, 251, 0.97)'
      }`,
      borderRadius: 0,
      border: 'none',
      padding,
      maxWidth,
      maxHeight,
      width,
      height,
      overflow: 'visible',
      overflowY,
      overflowX,
    },
  };

  return (
    <ReactModal
      {...otherProps}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={styles || customStyles}
      ariaHideApp={false}
    >
      {hasCloseButton && (
        <ModalClose>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            onClick={() => onRequestClose()}
          >
            <path
              fill="#959595"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </ModalClose>
      )}
      {children}
    </ReactModal>
  );
}
