export const FileModelDirective = () => {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      fileModel: '=',
    },
    link: (scope, element) => {
      element.bind('change', event => {
        const fileModel = event.target.files[0];
        scope.fileModel = fileModel || undefined;
        scope.$apply();
      });
    },
  };
};
