import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Card, CardText, CardTextContainer } from './styles';

type Props = {
  title: string;
  userId: number;
  userListCallback: (index: number) => void;
};

const UserCard: React.FC<Props> = ({ title, userId, userListCallback }) => {
  const handleClose = () => {
    userListCallback(userId);
  };

  return (
    <Card>
      <CardTextContainer>
        <CardText>{title}</CardText>
      </CardTextContainer>
      <IconButton
        size={'small'}
        onClick={handleClose}
        sx={{
          margin: '0px',
        }}
      >
        <CloseIcon
          sx={{
            verticalAlign: 'bottom',
            position: 'relative',
            color: '#4F587A',
            zIndex: 1,
            width: '16px',
            height: '16px',
            margin: '0px',
          }}
        />
      </IconButton>
    </Card>
  );
};

export default UserCard;
