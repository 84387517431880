import styled from '@emotion/styled';
import Button from '~/components/Button';
import theme from '~/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.colors.blue1};
  width: 195px;
  height: 117px;
  border-radius: 6px;
  padding: 24px 38px 24px 24px;
  position: fixed;
  right: 145px;
  bottom: 15px;
  z-index: 2;
`;

export const HeaderWrapper = styled.div `
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.colors.containerBg};
  overflow-wrap: break-word;
`;

export const StyledButton = styled(Button) `
  background: ${theme.colors.secondary};
  color: ${theme.colors.gray25};
  width: 126px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const ModalClose = styled.div`
  display: flex;
  position: relative;
  left: 200px;
  bottom: 60px;
  cursor: pointer;
  width: 24px;
`;

