import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import {
  StepNumber,
  StepText,
  CompleteStep,
  StepProgressContainer,
  DividerLine,
  StepContainer,
} from './styles';

type StepProgressProps = {
  steps: Array<string>;
  currentStep: number;
};

type StepProps = {
  index: number;
  currentStep: number;
  children: string;
};

const Step = ({ index, currentStep, children }: StepProps) => (
  <>
    {index < currentStep ? (
      <CompleteStep>
        <FontAwesomeIcon color="#29C5A9" icon={faCheck} />
      </CompleteStep>
    ) : (
      <StepNumber color={index === currentStep ? '#FF3C58' : '#A6A6A6'}>
        {index}
      </StepNumber>
    )}
    <StepText>{children}</StepText>
  </>
);

const StepProgress = ({ steps, currentStep }: StepProgressProps) => {
  const stepProgressSize = steps.length;

  return (
    <StepProgressContainer>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <StepContainer>
            <Step index={index + 1} currentStep={currentStep}>
              {step}
            </Step>
          </StepContainer>
          {index + 1 < stepProgressSize && <DividerLine />}
        </React.Fragment>
      ))}
    </StepProgressContainer>
  );
};

export default StepProgress;
