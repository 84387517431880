import * as Yup from 'yup';

const CompleteRegisterSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    email: Yup.string().email('Email inválido'),
    password: Yup.string()
      .required('Campo obrigatório')
      .min(6, 'A senha deve ter ao menos 6 caracteres'),
  });

export default CompleteRegisterSchema;
