export class TrelloService {
  constructor($http, $log, $mdDialog, UserService, API_URL, TRELLO_API_URL, TRELLO_API_KEY) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.$mdDialog = $mdDialog;
    this.UserService = UserService;
    this.API_URL = API_URL;
    this.TRELLO_API_URL = TRELLO_API_URL;
    this.TRELLO_API_KEY = TRELLO_API_KEY;
    this.processQueue = [];
  }

  _authenticate() {
    this.loadOrganizationFromProject({ name: 'Teste' });
  }

  authenticate(successfulCallback) {
    const width = 550;
    const height = window.outerHeight * 0.95;
    const left = window.screenX + (window.innerWidth - width) / 2;
    const top = window.screenY + (window.innerHeight - height) / 2;

    const authEndpoint = 'https://trello.com';
    const authWindow = window.open(
      `https://trello.com/1/authorize?redirect_uri=${location.href}&callback_method=postMessage&scope=read,write&expiration=never&name=Labor&key=8cb86522ac85f19a2eb017050753be72`,
      'trello',
      `width=${width},height=${height},left=${left},top=${top}`
    );
    const receiveMessage = (event) => {
      if (
        event.origin !== authEndpoint ||
        event.source !== authWindow
      ) {
        return;
      }

      if (event.source != null) {
        event.source.close();
      }

      if (event.data != null && /[0-9a-f]{64}/.test(event.data)) {
        this._storeToken(event.data);
        if(successfulCallback) successfulCallback()
      }

      window.removeEventListener('message', receiveMessage, false);
    };

    window.addEventListener('message', receiveMessage, false);
  }

  addTrelloBoardToProject(projectId, { boardId, todoListId, doneListId }) {
    return this.$http.post(
      `${this.API_URL}/projects/${projectId}/create_trello_board`,
      {
        board_id: boardId,
        todo_list_id: todoListId,
        done_list_id: doneListId,
      }
    );
  }

  deleteTrelloBoardFromProject(projectId) {
    return this.$http.delete(`${this.API_URL}/projects/${projectId}/destroy_trello_board`);
  }

  createBoard(project, useDefaultLists = true) {
    const params = {
      name: project.name,
      defaultLists: useDefaultLists,
    };
    const idOrganization = this._getOrganization();
    if (idOrganization) {
      params.idOrganization = idOrganization;
    }

    return this._sendTrelloRequest('POST', 'boards/', params);
  }

  createList(name, boardId, type) {
    const params = {
      name,
      idBoard: boardId,
    }
    if (type === 'TO-DO') {
      params.pos = 'top';
    } else if (type === 'DONE') {
      params.pos = 'bottom';
    }
    return this._sendTrelloRequest('POST', 'lists', params).then(r => r.data);
  }

  getBoards() {
    const organizationId = this._getOrganization();
    const requestUrl = organizationId ? `organizations/${organizationId}/boards` : `members/me/boards`;
    return this._sendTrelloRequest('GET', requestUrl).then(r => r.status === 200 ? r.data : []);
  }

  getBoard(id, loadNestedElements) {
    let params = {};
    if (loadNestedElements) {
      params = {
        labels: 'all',
        label_fields: 'name,color',
        lists: 'open',
        list_fields: 'name',
      };
    }
    return this._sendTrelloRequest('GET', `boards/${id}`, params).then(r => r.data);
  }

  getLists(boardId) {
    return this._sendTrelloRequest('GET', `boards/${boardId}/lists`).then(r => r.data);
  }

  getLabels(boardId) {
    return this._sendTrelloRequest('GET', `boards/${boardId}/labels`).then(r => r.status === 200 ? r.data : []);
  }

  async loadOrganizationFromProject(project) {
    const trelloOrganizations = await this._sendTrelloRequest('GET', 'members/me/organizations', {
      fields: 'displayName',
    });

    return trelloOrganizations;
  }

  selectOrganization(organization) {
    if (organization && organization.id) {
      this._storeOrganization(organization.id);
    }
  }

  isTrelloConnected() {
    let trello = localStorage.getItem('trello');
    return trello !== null
  }

  _sendTrelloRequest(method, path, queryData = {}) {
    const token = this._getToken();
    const queryAuthData = { key: this.TRELLO_API_KEY, token };
    const queryParams = this._toQueryParams({ ...queryData, ...queryAuthData });
    const fullUrl = `${this.TRELLO_API_URL}/${path}?${queryParams}`;
    return this.$http({
      method,
      url: fullUrl,
    });
  }

  _getToken() {
    if (this.trelloConf) return this.trelloConf.token;
    let trello = localStorage.getItem('trello');
    trello = trello ? JSON.parse(trello) : {};
    if (trello.token) {
      this.trelloConf = trello;
      return trello.token;
    } else {
      const trello = this._getTrelloConfiguration();
      return trello.token;
    }
  }

  _storeToken(token) {
    let trello = localStorage.getItem('trello');
    trello = trello ? JSON.parse(trello) : {};
    trello.token = token;
    this._updateUserTrelloToken(token)
    localStorage.setItem('trello', JSON.stringify(trello));
  }

  _getTrelloConfiguration() {
    let trello = localStorage.getItem('trello');
    return trello ? JSON.parse(trello) : {};
  }

  _getOrganization() {
    let trello = localStorage.getItem('trello');
    trello = trello ? JSON.parse(trello) : {};
    return trello.organizationId;
  }

  _storeOrganization(organizationId) {
    let trello = localStorage.getItem('trello');
    trello = trello ? JSON.parse(trello) : {};
    trello.organizationId = organizationId;
    localStorage.setItem('trello', JSON.stringify(trello));
  }

  _toQueryParams(jsonObj) { // be carefull, this expects an one depth object
    return Object.entries(jsonObj).map(([k,v]) => `${k}=${v}`).join('&');
  }

  _updateUserTrelloToken(token) {
    return this.$http({
      method: 'PUT',
      url: `${this.API_URL}/users/update_trello_token`,
      data: { 'trello-token': token }
    });
  }
}
