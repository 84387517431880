/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';
import { get } from 'lodash';
import API from '~/services/api';
import Page from '~/site/components/Page';
import PageTitle from '~/site/components/PageTitle';
import Input from '~/components/Input';
import Select from '~/components/Select';
import PasswordInput from '~/components/PasswordInput';
import Link from '~/components/Link';
import Button from '~/components/Button';
import Container from '~/site/components/Container';
import AmplitudeUserStore from '~/services/api/amplitude';
import SocialMediaSignIn from '~/site/components/SocialMediaSignIn/index';
import RegisterSvg from './images/registerSvg';
import { logRegister, saveLoginUserDataLocalStorage } from '~/helpers';
import LoadingState from '~/site/components/LoadingState/index';

import {
  FlexWrapper,
  WelcomeBox,
  WelcomeText,
  WelcomeLink,
  FormBox,
  StyledSnackbar,
  ButtonWrapper,
  LoginText,
  TosText,
  ErrorMessage,
  RegisterImage,
  SizeTeamText,
  SizeBox,
  ContinueButton,
  SizeOption
} from './styles'

import RegisterSchema from './schema'
import socialMediaSignInSchema from './socialMediaSignInSchema'

const segmentOptions = {

  1: 'Arquitetura e/ou Urbanismo',
  2: 'Designer de Interiores',
  3: 'Designer de produto',
  4: 'Audiovisual',
  5: 'Desenvolvimento de Software',
  6: 'Outros',

};

const teamSizeOptions = {

  1: 'Somente eu',
  2: '2 - 5 pessoas',
  3: '6 - 10 pessoas',
  4: '11 - 25 pessoas',
  5: '26 - 50 pessoas',
  6: '50 ou mais'
}

let amplitude;

async function doRegister(userData) {
  const response = await API.user.register(userData);

  const json = await response.json();

  if (response.status !== 201) {
    throw new Error(json.message);
  }
  localStorage.removeItem('firstProjectCreated');
  localStorage.setItem('needToCompleteOnboarding',"true");
  localStorage.setItem('taskBarModalWasSeen', 'true');
  localStorage.setItem('userModalWasSeen', 'true');
  const { user, rules } = json;

  const { headers } = response;

  logRegister(headers, user, amplitude);
  saveLoginUserDataLocalStorage(user, rules, headers, () => amplitude.setUserId);
}


async function handleSocialMediaSignIn(data, setLoading) {
  setLoading(true)
  const response = await API.auth.loginWithSocialMedia(
    localStorage.getItem('socialMediaToken'),
    localStorage.getItem('socialMediaType'),
    null,
    data.segment,
    data.teamSize
  )

  if (response) {
    const json = await response.json();
    ['socialMediaToken', 'socialMediaType'].forEach(k => localStorage.removeItem(k))

    if (!response.ok) {
      throw new Error(json.errors ? json.errors[0] : json.error);
    }
    localStorage.setItem('needToCompleteOnboarding',"true");
    const { headers } = response;


    saveLoginUserDataLocalStorage(json.data, json.rules, headers, () => amplitude.setUserId);
  }
}

const SignInFunctions = {
  ['socialMedia']: (data, setLoading) =>
    handleSocialMediaSignIn(data, setLoading),
  ['default']: (data) =>
    doRegister(data),
};

function askSegmentDetailInput(segmentSelected) {
  if (segmentOptions[segmentSelected] === 'Outros') {
    return (
      <Input
        type="text"
        name="segmentDetail"
        placeholder="Qual seu segmento?"
        value=""
      />
    );
  }

  return null;
}

const FirstPageText = ({ SITE_URL }) => (
  <>
    <PageTitle>Cadastre-se e comece a controlar seu tempo</PageTitle>
    <WelcomeText>
      Basta <strong>criar uma conta</strong> ao lado para começar seus
      <strong> 30 dias de teste gratuito.</strong>
    </WelcomeText>
    <WelcomeText>
      Depois deste período guiaremos você para a assinatura do plano anual ou mensal, como você preferir!
    </WelcomeText>

    <WelcomeLink href={`${SITE_URL}/planos`} callToAction={true}>
      Conheça os planos disponíveis
    </WelcomeLink>
  </>

);

const SecondPageText = ({ username }) => (
  <>
    <PageTitle>Olá{ username ? `, ${username.split(' ')[0]}` : ' '}!</PageTitle>
    <WelcomeText>
      Estamos muito felizes em ter você à bordo.
    </WelcomeText>
    <WelcomeText>
      Falta pouco para começar a usar o Labor. Preencha os dados ao lado para concluir o seu cadastro.
    </WelcomeText>
    <RegisterSvg />
  </>

);

const RegisterPage = (props) => {
  const socialMediaSignin = !!localStorage.getItem('socialMediaToken')

  const [message, setMessage] = useState('');
  const [username, setUsername] = useState('');
  const [segmentSelected, setSegmentSelected] = useState('');
  const [continuePressed, setContinuePressed] = useState(socialMediaSignin);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    amplitude = new AmplitudeUserStore(null, props.AMPLITUDE_API_KEY);
  }, []);

  return (
    <Page>
      <Container>
        <FlexWrapper>
          <WelcomeBox>
            {
              continuePressed ?
                <SecondPageText username={username} />
                :
                <FirstPageText  SITE_URL={props.SITE_URL} />
            }

          </WelcomeBox>
          <FormBox>
            <Formik
              initialValues={{
                name: '',
                segment: '',
                teamSize: '',
                email: '',
                password: '',
                phone: '',
                acceptedTerms: true,
              }}
              validationSchema={socialMediaSignin ? socialMediaSignInSchema() : RegisterSchema()}
              onSubmit={(values, { setSubmitting }) => {
                const signin = get(SignInFunctions, socialMediaSignin ? 'socialMedia' : 'default');
                signin(values, setLoading).catch(e => {
                  setMessage(e.message);

                });
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, setFieldValue, values, validateField, errors, isValid, touched }) => (
                <Form style={{ position: 'relative' }}>
                  <StyledSnackbar isVisible={!!message}>
                    {message}
                  </StyledSnackbar>
                  {continuePressed ?
                    <>
                      <Select
                        name="segment"
                        value={segmentSelected}
                        placeholder="Em qual setor você trabalha?"
                        options={Object.entries(segmentOptions).map(
                          ([value, label]) => ({
                            value,
                            label,
                          }),
                        )}
                        onChange={e => {
                          if (!isNaN(e.target.value)) {
                            setFieldValue('segment', e.target.value);
                            setSegmentSelected(e.target.value);
                          }
                        }}
                      />
                      {askSegmentDetailInput(segmentSelected)}
                      <SizeTeamText>Quantas pessoas trabalham na sua equipe?</SizeTeamText>
                      <Field name="teamSize">
                        {() => (
                          <SizeBox>
                            {
                              Object.entries(teamSizeOptions).map(([value, label]) => (
                                <SizeOption key={value} error={!!(touched.teamSize && errors.teamSize)}>
                                  <input type="radio" id={`radio${value}`} name="teamSize" onClick={(e) => { setFieldValue('teamSize',  (e.target as HTMLInputElement).value); }} value={value} />
                                  <label htmlFor={`radio${value}`}>{label}</label>
                                </SizeOption>
                              ))

                            }
                            <ErrorMessage> {touched.teamSize && errors.teamSize}</ErrorMessage>
                          </SizeBox>
                        )}

                      </Field>
                      <TosText>Ao clicar em "Cadastrar" você concorda com os <a target="_blank" href={props.TOS_URL}>Termos de Serviço.</a></TosText>
                    </>
                    :
                    <>
                      <SocialMediaSignIn
                        AMPLITUDE_API_KEY={props.AMPLITUDE_API_KEY}
                        NotificationService={props.NotificationService}
                        googleText="Cadastrar com Google"
                        microsoftText="Cadastrar com Microsoft" codInvite={''}
                      />
                      <Input
                        type="text"
                        name="name"
                        placeholder="Nome completo"
                        value=""
                      />
                      <Input
                        type="text"
                        name="email"
                        placeholder="E-mail"
                        value=""
                      />
                      <PasswordInput name="password" placeholder="Senha" value="" />
                    </>
                  }
                  <ButtonWrapper>
                    {
                      continuePressed ?
                        <>
                          <Button
                            type="submit"
                            disabled={isSubmitting}
                            style={{ minWidth: '168px' }}
                          >
                            Cadastrar
                          </Button>
                        </>
                        :
                        <>
                          <ContinueButton
                            type="button"
                            style={{ minWidth: '168px' }}
                            disabled={(!!errors.name || !!errors.email || (!!errors.password) || (values.name.length === 0 || values.email.length === 0 || values.password.length === 0))}
                            onClick={() => {
                              setContinuePressed(true);
                              setUsername(values.name);
                            }}
                          >
                            Continuar
                          </ContinueButton>
                          <LoginText>
                            Já possui cadastro? Faça o{' '}
                            <Link href="/entrar" callToAction={true}>
                              login
                            </Link>
                          </LoginText>
                        </>
                    }
                  </ButtonWrapper>
                </Form>
              )}
            </Formik>
          </FormBox>
        </FlexWrapper>
      </Container>
      <LoadingState RedirectUrl={window.location.search} isOpen={loading} />
    </Page>
  );
}
export default RegisterPage;
