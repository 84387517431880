import styled from '@emotion/styled';
import theme from '~/theme/index';

export const CookieContainer = styled.div`
  padding: 12px;
  width: 85vw;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  transition: 400ms;
  background-color: ${theme.colors.white};
  color: ${theme.colors.containerBg};
  opacity: 0.9;
  border-left: 4px solid ${theme.colors.borderLine};
`;

export const CookieText = styled.p`
  color: #5f5f5f;
`;

export const CookieConsentButton = styled.button`
  padding: 0.4em;
  border: none;
  font-size: 18px;
  font-weight: bold;
  margin-left: auto;
  margin-right: 10%;
  color: ${theme.colors.white};
  background-color: ${theme.colors.primary};
  transition: 0.3s;
  &:hover {
    background-color: ${theme.colors.hoverBg};
    color: ${theme.colors.white};
    transition: 0.3s;
  }
`;
