import styled from '@emotion/styled';
import Button from '~/components/Button';
import theme from '~/theme';

export const Container = styled.div`
  padding: 24px 42px;
  color: ${theme.colors.black1};
`;

export const Paragraph = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 16px;
`;

export const StyledButton = styled(Button)`
  height: 36px;
  width: 170px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  justify-content: center;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  margin: 6px 8px;
  padding: 0 40px;
  &:hover {
    background-color: ${theme.colors.primary};
  }
`;

export const SmallText = styled.p`
  font-size: 0.8rem;
  margin-top: 3rem;
`;

export const StyledCancellButton = styled(Button)`
  padding: 0 40px;
  min-width: 160px;
  color: ${theme.colors.black3};
  margin: 6px 8px
  font-size: 14px;
  background-color: ${theme.colors.gray24};

  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  border: 0;
  min-height: 36px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  border-radius: 2px;
  box-sizing: border-box;
  &:hover {
    background-color:  ${theme.colors.gray24};
  }
`;

export const SmallTextButton = styled.p`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
`;