import apiFetch from '~/helpers/apiFetch';

class ReportAPI {
  get_reports(year?: string, month?: string, user_id?: number) {
    let parameters = '?';
    if (year) {
      parameters = parameters.concat(`&year=${year}`);
      if (month) {
        parameters = parameters.concat(`&month=${month}`);
      }
    }
    if (user_id) {
      parameters = parameters.concat(`&user_id=${user_id}`);
    }
    return apiFetch(`/reports${parameters}`);
  }

  create_reports_notifications(notification: boolean, frequency: string, monthday: string, weekday: string, hour: string, email: boolean) {
    const parameters = `?notification=${notification}&frequency=${frequency}&monthday=${monthday}&weekday=${weekday}&hour=${hour}&email=${email}`;

    return apiFetch(`/report_notifications${parameters}`, 'POST', null, {});
  }

  update_reports_notifications(notification?: boolean, frequency?: string, monthday?: string, weekday?: string, hour?: string, email?: boolean) {
    const params = {
      notification,
      frequency,
      monthday,
      weekday,
      hour,
      email,
    };
    const queryString = Object.keys(params).reduce((_qs, k) => {
      if (params[k] === null || params[k] === undefined) {
        return `${_qs}`;
      }

      return `${_qs}&${k}=${params[k]}`;
    }, '')
    .substring(1);
    return apiFetch(`/report_notifications?${queryString}`, 'PUT', null, {});
  }

  get_reports_notifications() { 
    return apiFetch(`/report_notifications`)
  }
  save_report(date: Date) {
    const year = date.getFullYear();
    // + 1 because getMonth returns result in 0-11
    const month = date.getMonth() + 1;

    const parameters = `?year=${year}&month=${month}`;

    return apiFetch(`/reports${parameters}`, 'POST', null, {});
  }

  download_report({
    starting,
    ending,
    userIds,
    projectIds,
    plannedTaskIds,
    orientation,
    format,
  }: {
    starting: string | undefined;
    ending: string | undefined;
    userIds: number | string | null;
    projectIds?: number[] | string[] | null | string;
    plannedTaskIds?: number[] | string[] | null | string;
    orientation: string;
    format: string;
  }) {
    const params = {
      format,
      starting,
      ending,
      orientation,
      'user_id%5B%5D': userIds,
      'project_id%5B%5D': projectIds,
      'planned_task_id%5B%5D': plannedTaskIds,
    };

    const queryString = Object.keys(params)
      .reduce((_qs, k) => {
        if (params[k] === null || params[k] === undefined) {
          return `${_qs}`;
        }

        return `${_qs}&${k}=${params[k]}`;
      }, '')
      .substring(1);
    return apiFetch(`/tasks/download?${queryString}`);
  }
}

export default new ReportAPI();
