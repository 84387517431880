const saveLoginUserDataLocalStorage = (user, rules, headers, amplitude) => {
  localStorage.setItem('loggedUser', JSON.stringify(user));
  localStorage.setItem('rules', JSON.stringify(rules || user.rules));
  localStorage.setItem(
    'auth_headers',
    JSON.stringify({
      'access-token': headers.get('access-token'),
      'token-type': headers.get('token-type'),
      client: headers.get('client'),
      expiry: headers.get('expiry'),
      uid: headers.get('uid'),
    }),
  );
  localStorage.setItem('currentConfigName', JSON.stringify('default'));
  if (amplitude) amplitude(headers.get('uid'));
  window.location.href = '/app/main';
};

export default saveLoginUserDataLocalStorage;
