export class PlannedTemplateService {
  constructor($http, $log, API_URL) {
    'ngInject';

    this.$http = $http;
    this.$log = $log;
    this.API_URL = API_URL;
  }

  getAll() {
    return this.$http
      .get(`${this.API_URL}/planned_templates`)
      .then(response => {
        return response;
      });
  }
}
