import React from 'react';
import SelectTeam from '../InputField/SelectTeam';
import { SubTitleText } from './styles';
interface Props {
  onChange: (
    field: string,
    value: number[] | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  name: string;
  values: number[] | undefined; 
}
const ProjectTeam: React.FC<any> = ({ values, onChange, name }: Props) => (
  <>
    <SubTitleText>Equipe do projeto</SubTitleText>
    <SelectTeam values={values} onChange={onChange} name={name} />
  </>
);

export default ProjectTeam;
