import React from 'react';
import { Field as FormikField } from 'formik';
import NumberFormat from 'react-number-format';
import Input from '~/components/Input';
import { currencyFormatter } from '~/app/utils/currency';

interface Props {
  name: string;
  onChange?:
    | ((
        field: string,
        value: string | number | null,
        shouldValidate?: boolean | undefined,
      ) => void)
    | Function;
}

const TotalCharged: React.FC<Props> = ({ name, onChange }) => {
  const handleInputChange = (value: number | string) => {
    if (onChange) onChange(name, value);
  };

  return (
    <FormikField name={name}>
      {({ field }) => (
        <NumberFormat
          format={currencyFormatter}
          value={field.value}
          displayType={'text'}
          thousandSeparator={'.'}
          name={name}
          decimalSeparator={','}
          decimalScale={2}
          fixedDecimalScale={true}
          prefix={'R$'}
          type="text"
          allowNegative={false}
          renderText={(value) => (
            <Input
              wrapperStyle={{ margin: '0' }}
              value={value}
              name={name}
              type="text"
              placeholder="R$ 0,00"
              disabled={false}
              onChange={(e) => handleInputChange(e.target.value)}
            />
          )}
        />
      )}
    </FormikField>
  );
};

export default TotalCharged;
