import ActionCable from 'actioncable';

// eslint-disable-next-line
export class WebsocketService {
  constructor(
    $rootScope,
    WS_URL,
    $log,
    $auth,
    UserService,
    TimerService,
    ProjectService,
  ) {
    'ngInject';

    this.$auth = $auth;
    this.WS_URL = WS_URL;
    this.UserService = UserService;
    this.$rootScope = $rootScope;
    this.TimerService = TimerService;
    this.ProjectService = ProjectService;

    this.startConsumer();

    $rootScope.$on('auth:session-expired', () => {
      this.stopConsumer();
    });

    $rootScope.$on('auth:invalid', () => {
      this.stopConsumer();
    });
  }

  startConsumer() {
    const authData = this.$auth.retrieveData('auth_headers');
    const loggedUser = this.UserService.getLoggedUser();
    if (authData && loggedUser) {
      this.cable = ActionCable.createConsumer(
        `${this.WS_URL}/?uid=${loggedUser.email}&atoken=${
          authData['access-token']
        }&client=${authData['client']}`,
      );
      this.channel = this.cable.subscriptions.create(
        { channel: 'TasksChannel', user_id: loggedUser.id },
        {
          received: payload =>
            this.$rootScope.$broadcast('remoteTaskSync', payload),
        },
      );
    }
  }

  stopConsumer() {
    if (this.channel) {
      this.channel.unsubscribe();
    }
    if (this.cable) {
      this.cable.disconnect();
    }
    this.cable = null;
    this.channel = null;
  }
}
