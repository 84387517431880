import styled from '@emotion/styled';
import {
  Assignment,
  Alarm,
  PlayArrow,
  Close,
  DragHandle,
  Add,
} from '@mui/icons-material';

export const Row = styled.tr`
  width: 100%;
  color: #5f5f5f;
  font-weight: 600;
  line-height: 40px;
  align-items: center;
  &.bodyRow {
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
    color: #959595;
    font-weight: 400;
    line-height: 28px;
  }
  &.editable {
    input {
      padding: 0 0.2rem;
      /* margin-left: 10px; */
      border: none;
      display: inline-block;
      color: #959595;
      &:hover {
        background: #f5f5f5;
        outline: none;
      }
      &span {
        &:hover {
          background-color: white;
        }
      }
      &:focus {
        outline: none;
        background: white;
      }
    }

    input {
      width: 90%;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
    }
    span {
      padding: 0 0.2rem;
      /* margin-left: 10px; */
      border: none;
      display: inline-block;
      color: #959595;
    }
  }

  .addTask {
    color: #999999;
    font-weight: 400;
    font-size: 14px;
  }
  .actions {
    text-align: end;
    margin-right: 22px;
    .icon {
      color: white;
    }
    :hover {
      .icon {
        color: #d7d7d7;
      }
    }
  }
`;
export const Cell = styled.td`
  align-items: center;
  padding: 0.7rem 1rem;
  max-height: 49px;
  overflow: hidden;
  text-align: start;
  &.firstCell {
    padding-left: 40px;
  }
  &.description {
    width: 50%;
  }
  &.duration {
    width: 10%;
  }
  &.nonAdmin {
    :first-of-type {
      padding-left: 40px;
    }
  }
`;
export const Header = styled.th``;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;
export const AssignmentIcon = styled(Assignment)`
  width: 20px;
  height: 20px;
  vertical-align: middle;
`;
export const AlarmIcon = styled(Alarm)`
  width: 20px;
  height: 20px;
  vertical-align: middle;
`;
export const PlayArrowIcon = styled(PlayArrow)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  vertical-align: middle;
`;
export const CloseIcon = styled(Close)`
  width: 25px;
  height: 25px;
  margin-left: 5px;
  cursor: pointer;
  vertical-align: middle;
`;
export const AddIcon = styled(Add)`
  width: 25px;
  height: 25px;
  vertical-align: middle;
  color: #0000008a;
`;

export const DragHandleIcon = styled(DragHandle)`
  cursor: grab;
  vertical-align: inherit;
`;
export const AddTaskButton = styled.div`
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
`;
export const Message = styled.div`
  width: 100%;
  text-align: start;
  margin-left: 80px;
`;
