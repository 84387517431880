import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { MdExpandMore } from 'react-icons/md';

const openedBorder = css`
  border-color: #7d7d7d;
`;

const pendingBorder = css`
  border-color: #4680fe;
`;

type SelectContainerProps = {
  opened?: boolean;
  pending?: boolean;
};

export const SelectContainer = styled.div<SelectContainerProps>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 6px 6px 8px;
  gap: 5px;
  background-color: #ffffff;
  color: #5f5f5f;
  border: 1px solid rgba(149, 149, 149, 0.2);
  height: 20px;

  ${(props) => props.pending && pendingBorder}

  ${(props) => props.opened && openedBorder}
`;

export const ExpandMoreIcon = styled(MdExpandMore)`
  color: #7d7d7d;
  font-size: 16px;
  vertical-align: bottom;
`;
