import styled from '@emotion/styled';


interface LinkProps {
  callToAction?: boolean;
  onClick?: () => void;
  children?: string;
}
export default styled.a<LinkProps>`
  color: #7d7d7d;
  font-size: 13px;
  text-decoration: none;
  ${p =>
    p.callToAction &&
    `
    color: #ff3c58;
    font-weight: 600;
  `}
  :hover {
    color: #4680fe;
  }
  transition: color 0.5s;
`;
