import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { googleConfig } from '~/config/authConfig.js';

import { GoogleButtonSvg } from '~/icons';
import NotificationServiceType from '~/models/ServicesTypes';
import SocialButton from '~/site/components/SocialButton';

interface GoogleSignInButtonProps {
  doLoginWithGoogle: (string) => void;
  NotificationService: NotificationServiceType;
  textArea: string;
}

const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = ({
  doLoginWithGoogle,
  NotificationService,
  textArea,
}: GoogleSignInButtonProps) => (
  <GoogleLogin
    clientId={googleConfig.auth.clientId}
    buttonText="Login"
    onSuccess={doLoginWithGoogle}
    onFailure={() =>
      NotificationService.showNotification(
        'Ocorreu um erro. Tente novamente mais tarde.',
        'error',
      )
    }
    cookiePolicy={'single_host_origin'}
    render={(renderProps) => (
      <SocialButton onClick={renderProps.onClick}>
        <GoogleButtonSvg />
        {textArea}
      </SocialButton>
    )}
  />
);

export default GoogleSignInButton;
