import React, { useState, useRef, useEffect } from 'react';
import { Field as FormikField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {
  ColorOption,
  StyledLabel,
  ColorsContainer,
  ColorButton,
  ColorPickerContainer,
} from './styles';

interface ColorPickerProps {
  name: string;
  options: Array<string>;
  onChange?: ((e: React.MouseEvent) => void) | ((e: object) => void);
  colorName?: string | null;
  customMargin?: string;
}

export default function ColorPicker({
  name,
  colorName,
  options,
  onChange,
  customMargin
}: ColorPickerProps) {
  const [colorPicked, setColorPicked] = useState(colorName);
  const [showColorModal, setShowColorModal] = useState(false);
  const ref = useRef<HTMLUListElement>(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target))
      setShowColorModal(false);
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <FormikField name={name}>
      {() => (
        <ColorPickerContainer customMargin={customMargin}>
          <ColorButton
            type="button"
            colorPicked={colorPicked}
            onClick={() => {
              setShowColorModal(!showColorModal);
            }}
          >
            <FontAwesomeIcon
              style={{ marginLeft: '40px' }}
              icon={faChevronDown}
            />
          </ColorButton>
          {showColorModal && (
            <ColorsContainer ref={ref}>
              {options.map((color, index) => (
                <ColorOption key={color}>
                  <input
                    type="radio"
                    id={`radio${index}`}
                    name="color"
                    onClick={(e) => {
                      if(onChange)
                        onChange(e);
                      setColorPicked(e.currentTarget.value);
                      setShowColorModal(false);
                    }}
                    value={color}
                  />
                  <StyledLabel
                    ColorValue={color}
                    htmlFor={`radio${index}`}
                  ></StyledLabel>
                </ColorOption>
              ))}
            </ColorsContainer>
          )}
        </ColorPickerContainer>
      )}
    </FormikField>
  );
}
