import React, { useState, useEffect } from 'react';
import API from '~/services/api';
import { ProjectAllocationWithCheck } from '~/models/types';
import TableBody from './TableBody';
import PaginationSelector from './PaginationSelector';
import Select, { components } from 'react-select';
import Checkbox from '@mui/material/Checkbox';
import DeleteUserModal from './DeleteUserModal';
import * as S from './styles';
import ProjectAllocationList from '~/app/UserProfile/ProjectAllocationList';
import { IStateProvider } from 'angular-ui-router';
type Props = {
  $state: IStateProvider;
  $window: Window;
  projectAllocations: ProjectAllocationWithCheck[];
  setProjectAllocations: Function;
  getProjectAllocations: Function;
};
const ROW_OPTIONS = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
];
const ROLE_OPTIONS = [
  { value: 'gerente', label: 'Gerente' },
  { value: 'colaborador', label: 'Colaborador' },
];

const formatOptionLabel = (data, context) => {
  return context.context === 'value' ||
    data.value != context.selectValue['0'].value ? (
    <div style={{ display: 'flex' }}>
      <div>{data.label}</div>
    </div>
  ) : (
    <div style={{ display: 'flex' }}>
      <div>
        {data.label}
        <svg
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: '10px' }}
        >
          <path
            d="M4.0001 7.7799L1.2201 4.9999L0.273438 5.9399L4.0001 9.66656L12.0001 1.66656L11.0601 0.726562L4.0001 7.7799Z"
            fill="#959595"
          />
        </svg>
      </div>
    </div>
  );
};
const formatOptionLabelRole = (data, context) => {
  if (context.context === 'value')
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ marginLeft: '10px' }}>Editar função</div>
      </div>
    );
  return data.value != context.selectValue['0'].value ? (
    <div style={{ display: 'flex' }}>
      <div style={{ marginLeft: '10px' }}>{data.label}</div>
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ marginLeft: '10px' }}>{data.label}</div>
      <div>
        <svg
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: '10px' }}
        >
          <path
            d="M4.0001 7.7799L1.2201 4.9999L0.273438 5.9399L4.0001 9.66656L12.0001 1.66656L11.0601 0.726562L4.0001 7.7799Z"
            fill="#959595"
          />
        </svg>
      </div>
    </div>
  );
};
const UserTable = ({
  projectAllocations,
  getProjectAllocations,
  setProjectAllocations,
  $state,
  $window,
}: Props) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tablePage, setTablePage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [usersToDelete, setUsersToDelete] = useState<
    ProjectAllocationWithCheck[]
  >([]);
  const pageRows = projectAllocations.slice(
    (tablePage - 1) * rowsPerPage,
    rowsPerPage * tablePage,
  ).length;
  const areAllSelected = projectAllocations.length
    ? projectAllocations.every((allocation) => allocation.checked)
    : false;
  const isAnySelected = !projectAllocations.every(
    (allocation) => !allocation.checked,
  );
  const selectedAmount = projectAllocations.filter(
    (allocation) => allocation.checked,
  ).length;
  const selectedText =
    selectedAmount > 1
      ? `${selectedAmount} Selecionados`
      : `${selectedAmount} Selecionado`;

  const HandleDelete = async () => {
    if (usersToDelete.length === 1) {
      const projectId = usersToDelete[0].project.id;
      await API.project.delete_allocation(
        usersToDelete[0].project.id,
        usersToDelete[0].id,
      );
      getProjectAllocations();
      if (
        Math.ceil((projectAllocations.length - 1) / rowsPerPage) < tablePage &&
        tablePage > 1
      )
        setTablePage(tablePage - 1);
    } else {
      const projectId = usersToDelete[0].project.id;
      await API.project.remove_multiple_users(usersToDelete[0].project.id, {
        user_id_list: usersToDelete.map((obj) => obj.user.id),
      });
      getProjectAllocations();
      if (
        Math.ceil((projectAllocations.length - 1) / rowsPerPage) < tablePage &&
        tablePage > 1
      )
        setTablePage(Math.ceil((projectAllocations.length - 1) / rowsPerPage));
    }
  };
  const HandleClickSelectAll = () => {
    const newProjectAllocations = [...projectAllocations].map((obj) => ({
      ...obj,
      checked: !areAllSelected,
    }));
    setProjectAllocations(newProjectAllocations);
  };
  const handleRoleChange = async (projectData, UserIds, role) => {
    const newProject = {
      ...projectData,
      user_id_list: UserIds,
      allocation_update_params: { role },
    };
    await API.team.change_project(newProject, projectData.id);
    getProjectAllocations();
    const newProjectAllocations = [...projectAllocations].map((obj) => ({
      ...obj,
      checked: false,
    }));
    setProjectAllocations(newProjectAllocations);
  };
  return (
    <>
      <S.BulkEditContainer className={isAnySelected ? 'visible' : ''}>
        <S.SelectedText>{selectedText}</S.SelectedText>
        <S.BulkEditSelect
          classNamePrefix="UserTableSelect"
          className="UserTableSelect"
          isSearchable={false}
          onChange={(event) => {
            handleRoleChange(
              projectAllocations[0].project,
              projectAllocations
                .filter((obj) => obj.checked)
                .map((obj) => obj.user.id),
              event.value,
            );
          }}
          defaultValue={{ value: 0 }}
          formatOptionLabel={formatOptionLabelRole}
          options={ROLE_OPTIONS}
          styles={{
            option: (base) => ({
              ...base,
              backgroundColor: 'white',
              color: '#5F5F5F',
              ':hover': {
                backgroundColor: '#F6F7FB',
              },
            }),
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
        <S.BulkEditRemoveMember
          onClick={() => {
            setUsersToDelete(projectAllocations.filter((obj) => obj.checked));
            setIsModalOpen(true);
          }}
        >
          <S.RemovePerson />
          Retirar deste projeto
        </S.BulkEditRemoveMember>
      </S.BulkEditContainer>
      <S.Table className="">
        <thead>
          <tr>
            <S.Td_ className="name">
              <Checkbox
                disableRipple={true}
                checked={areAllSelected}
                name={'Todos'}
                onChange={HandleClickSelectAll}
                size="small"
                sx={{
                  color: '#959595',
                  '&.Mui-checked': {
                    color: '#FF3C58',
                  },
                }}
              />
              Nome
            </S.Td_>
            <S.Td_ className="function">
              <span>Função</span>
            </S.Td_>
            {/* <S.Td_><span>Permissão</span></S.Td_> */}
            <S.Td_ className="items_per_page">
              <span>
                Exibindo{' '}
                {rowsPerPage < projectAllocations.length
                  ? pageRows
                  : projectAllocations.length}{' '}
                de {projectAllocations.length}
              </span>
              <S.Select_
                classNamePrefix="UserTableSelect"
                className="UserTableSelect"
                isSearchable={false}
                onChange={(event) => {
                  setRowsPerPage(event.value);
                  setTablePage(1);
                }}
                formatOptionLabel={formatOptionLabel}
                defaultValue={ROW_OPTIONS[0]}
                options={ROW_OPTIONS}
                styles={{
                  option: (base) => ({
                    ...base,
                    backgroundColor: 'white',
                    color: '#5F5F5F',
                    ':hover': {
                      backgroundColor: '#F6F7FB',
                    },
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </S.Td_>
          </tr>
        </thead>
        <TableBody
          projectAllocations={projectAllocations}
          setProjectAllocations={setProjectAllocations}
          rowsPerPage={rowsPerPage}
          activePage={tablePage}
          getProjectAllocations={getProjectAllocations}
          OpenModal={(users) => {
            setIsModalOpen(true);
            setUsersToDelete(users);
          }}
          $state={$state}
          $window={$window}
        />
      </S.Table>
      <PaginationSelector
        activePage={tablePage}
        pageSize={rowsPerPage}
        userAmount={projectAllocations.length}
        SetPage={setTablePage}
      />
      {isModalOpen && (
        <DeleteUserModal
          onClose={() => {
            setIsModalOpen(false);
          }}
          onConfirm={() => {
            HandleDelete();
          }}
          isOpen={isModalOpen}
          projectAllocations={usersToDelete}
        />
      )}
    </>
  );
};
export default UserTable;
