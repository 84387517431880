class AuthAPI {
  constructor($rootScope, $http, $log, API_URL) {
    'ngInject';

    this.allProjects = null;
    this.$rootScope = $rootScope;
    this.$http = $http;
    this.$log = $log;
    this.API_URL = API_URL;
  }

  login({ email, password }) {
    return fetch(`${API_URL}/auth/sign_in`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({ email, password }),
    });
  }

  loginWithSocialMedia(
    accessToken,
    service,
    codInvite,
    segment = null,
    teamSize = null,
  ) {
    const headers = {
      'Content-type': 'application/json;charset=UTF-8',
      accessToken,
      service,
    };
    if (codInvite) headers.codInvite = codInvite;
    return fetch(`${API_URL}/social_media_authentication`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ segment, size: teamSize }),
    });
  }

  validateUserSocialMedia(accessToken, service) {
    return fetch(`${API_URL}/social_media_authentication/validate_user`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        accessToken,
        service,
      },
    });
  }

  forgotPassword({ email }) {
    return fetch(`${API_URL}/auth/password`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        email,
        redirect_url: 'https://app.getlabor.com.br/entrar',
      }),
    });
  }

  getLoggedUser() {
    const loggedUser = localStorage.getItem('loggedUser');

    if (!loggedUser) {
      return null;
    }

    return JSON.parse(loggedUser);
  }

  resetPassword({ email, token, password, passwordConfirm }) {
    return fetch(`${API_URL}/auth/password`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        email,
        reset_password_token: token,
        password,
        passwordConfirm,
        redirect_url: 'https://app.getlabor.com.br/entrar',
      }),
    });
  }
}

export default new AuthAPI();
