import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 75px 15px 40px;
  margin-bottom: 0;
`;

export const DayOfWeek = styled.p`
  font-weight: 600;
  display: inline;
  text-transform: capitalize;
`;
export const Date = styled.div``;
export const Duration = styled.div`
  font-weight: 600;
  color: #7d7d7d;
`;
