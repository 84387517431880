import create from 'zustand';
import { persist } from 'zustand/middleware';
import API from '~/services/api';
import { User } from '~/models/types';

type UserState = {
  user: User;
  fetchUserData: () => void;
  isLoggedUserAdmin: () => boolean;
};

const useUserStore = create<UserState>(
  persist(
    (set, get) => ({
      user: {} as User,
      fetchUserData: async () => {
        const response = await API.user.get_user_information();
        const data = await response.json();
        set({ user: data });
      },
      isLoggedUserAdmin: () =>
      get().user.allocations &&
        get().user.allocations.some(
          (allocation) => allocation.role == 'administrador',
        ),
    }),
    {
      name: 'useUserStore',
    },
  ),
);

export default useUserStore;
