import moment from 'moment';
import { SetState, GetState } from 'zustand';
import { omit } from 'lodash';
import { Project } from '~/models/types';
import {
  ProjectsCategory,
  ProjectsStatus,
  ProjectsState,
  ProjectsOrder,
} from './types';
import API from '~/services/api';

const filterCategory = (projects: Project[], category: ProjectsCategory) =>
  projects.filter((prj) => prj.category === category);

const fetchProjectsData = async (
  set: SetState<ProjectsState>,
  get: GetState<ProjectsState>,
  consumed_hours?: boolean,
) => {
  const { all, has_planned_tasks, status, order, category } = get();
  const params = { all, has_planned_tasks, status, order};
  if (!!consumed_hours)
    params['consumed_hours'] = true;
  const response = await API.team.get_projects_with_params(params);
  const data = await response.json();
  const projects = category === '' ? data : filterCategory(data, category);
  set({ projects });
};

// If you need them in alphabetical and newest at the same time
const fetchProjectsByNewest = async (
  set: SetState<ProjectsState>,
  get: GetState<ProjectsState>,
) => {
  const { all, has_planned_tasks, status, category } = get();
  const order: ProjectsOrder = 'newest';
  const params = { all, has_planned_tasks, status, order };
  const response = await API.team.get_projects_with_params(params);
  const data = await response.json();
  const projects = category === '' ? data : filterCategory(data, category);
  set({ projectsByNewest: projects });
};

const changeProjectDate = async (
  get: GetState<ProjectsState>,
  currentDate: Date,
  project: Project,
) => {
  if (moment(currentDate).isSameOrBefore(moment(project.estimatedStartAt))) {
    get().NotificationService?.showNotification(
      `Data de término não pode ser à data de início (${moment(
        project.estimatedStartAt,
      ).format('DD/MM/YYYY')})!`,
      'error',
      2000,
    );
    return;
  }

  const newProject = {
    ...omit(project, ['consumedHours', 'pendingProfitInfo', 'hasTrelloBoard', 'format']),
    estimatedEndAt: currentDate
  };
  const response = await API.team.change_project(newProject, project.id);
  if (response.ok) {
    get().NotificationService?.showNotification(
      'Projeto alterado com sucesso!',
      'success',
      2000,
    );
  } else {
    get().NotificationService?.showNotification(
      `Erro ao alterar projeto! (${response.statusText})`,
      'error',
      2000,
    );
  }

  get().fetchProjectsData();
};

const changeProjectStatus = async (
  get: GetState<ProjectsState>,
  status: ProjectsStatus,
  project: Project,
) => {
  const newProject = {
    ...omit(project, ['consumedHours', 'pendingProfitInfo', 'hasTrelloBoard', 'format']),
    status
  };
  const response = await API.team.change_project(newProject, project.id);
  if (response.ok) {
    get().NotificationService?.showNotification(
      'Projeto alterado com sucesso!',
      'success',
      2000,
    );
  } else {
    get().NotificationService?.showNotification(
      `Erro ao alterar projeto! (${response.statusText})`,
      'error',
      2000,
    );
  }

  get().fetchProjectsData();
};

export {
  fetchProjectsData,
  fetchProjectsByNewest,
  changeProjectDate,
  changeProjectStatus,
  filterCategory,
};
