import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Checkbox from '@mui/material/Checkbox';
import MuiFormControlLabel from '@mui/material/FormControlLabel';

export const CheckLabel = styled.div`
  color: ${(props: { bold?: boolean }) => (props.bold ? '#5F5F5F' : '#7D7D7D')};
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${(props: { bold?: boolean }) => (props.bold ? '600' : '400')};
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
`;

const disabledFormControl = css`
  cursor: default;
  opacity: 0.5;
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  .MuiFormControlLabel-label {
    width: 80%;
  }

  ${(props: { disabled?: boolean }) => props.disabled && disabledFormControl}
`;

export const ItemsWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;

  // firefox
  scrollbar-width: thin;
  scrollbar-color: #4f587a #d7d7d7;

  // webkit (edge/safari/chrome)
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 30px;
    background-color: #d7d7d7;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #4f587a;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  box-sizing: 'border-box';
  height: 20px;
`;
