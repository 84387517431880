import templateUrl from './planned-templates.html';
import './planned-templates.scss';

export const PlannedTemplatesComponent = {
  template: templateUrl,
  bindings: {
    onChoose: '&',
  },
  controller: class PlannedTemplatesController {
    constructor($log, $mdDialog, PlannedTemplateService, ProjectService) {
      'ngInject';

      this.$log = $log;
      this.$mdDialog = $mdDialog;
      this.PlannedTemplateService = PlannedTemplateService;
      this.ProjectService = ProjectService;
    }

    $onInit() {
      this.plannedTemplates = [];
      this.projects = [];
      this.project = null;
      this.template = null;

      this.PlannedTemplateService.getAll().then(response => {
        this.plannedTemplates = response.data;
      });

      this.ProjectService.getAllProjectsWithPlannedTasks().then(response => {
        this.projects = response.data;
      });
    }

    selectTemplate($event) {
      this.plannedTemplate = $event.selected;
    }

    selectProject($event) {
      this.plannedTemplate = null;
      this.project = $event.selected;
    }

    importTemplateTabSelected() {
      this.project = null;
    }

    importProjectTabSelected() {
      this.plannedTemplate = null;
    }

    submit() {
      this.onChoose({ template: this.plannedTemplate, project: this.project });
    }

    cancel() {
      this.$mdDialog.cancel();
    }
  },
};
