import angular from 'angular';
import { FileModelModule } from './file-model/file-model.module';
import { TimeInputModule } from './time-input/time-input.module';
import { DateInputModule } from './date-input/date-input.module';
import { EditableModule } from './editable/editable.module';
import { ExecuteTourAfterLoadModule } from './execute-tour-after-load/execute-tour-after-load.module';
import { FocusMeModule } from './focus-me/focus-me.module';
import { ChartJsModule } from './chart-js/chart-js.module';

export const DirectivesModule = angular.module(
  'root.components.app.common.directives',
  [
    FileModelModule,
    TimeInputModule,
    DateInputModule,
    EditableModule,
    ExecuteTourAfterLoadModule,
    FocusMeModule,
    ChartJsModule,
  ],
).name;
