import styled from '@emotion/styled';
import theme from '~/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 130px;
`;

export const Item = styled.a<{ isActive: boolean }>`
  flex-wrap: wrap;
  border-left: 4px solid ${theme.colors.white};
  color: ${(props) => props.isActive ? theme.colors.primary : theme.colors.gray22};
  height: 24px;
  border-left-color: ${(props) => props.isActive ? theme.colors.primary : theme.colors.white};
  display: flex;
  align-items: center;
  text-decoration: none;  
  flex-direction: row;
  padding: 10px 10px 10px 20px;
  :hover {
    color: ${theme.colors.primary};
  }
  transition: color 0.5s;
  min-height: "24px";
`;



export const ItemText = styled.span`
  font-size: 14px;
  font-weight: normal;
`;


export const ItemIcon = styled.div`
  display: flex;
  align-items: center;
  background-repeat: "no-repeat";
  fill: "currentColor";
  height: "24px";
  width: "24px";
  min-height: "24px";
  min-width: "24px";
`

//Style for icon
export const Icon = {
  fontSize: "20px",
  marginRight: "8px",
}
