import React, { useState, useEffect } from 'react';
import * as S from './styles';
type Props = {
  activePage: number;
  pageSize: number;
  userAmount: number;
  SetPage: Function;
};

const PaginationSelector = ({
  activePage,
  pageSize,
  userAmount,
  SetPage,
}: Props) => {
  const maxPages = Math.ceil(userAmount / pageSize);
  const pageNumberArray = Array(maxPages)
    .fill(1)
    .map((x, y) => x + y)
    .filter(
      (obj) =>
        obj === 1 ||
        obj === maxPages ||
        (obj >= activePage - 2 && obj <= activePage + 2) ||
        maxPages <= 7,
    );
  const numbers = pageNumberArray.map((number) => (
    <div key={number}>
      {activePage + 2 < maxPages - 1 && maxPages > 7 && number === maxPages ? (
        <S.Div>
          <span>. . .</span>
        </S.Div>
      ) : (
        ''
      )}
      <S.Div_
        className={number === activePage ? 'active' : ''}
        onClick={() => {
          SetPage(number);
        }}
      >
        <span>{number}</span>
      </S.Div_>
      {activePage - 2 > 2 && maxPages > 7 && number === 1 ? (
        <S.Div>
          <span>. . .</span>
        </S.Div>
      ) : (
        ''
      )}
    </div>
  ));
  return numbers.length > 1 ? (
    <S.Container>
      <S.PaginationDiv
        className={activePage === 1 ? 'deactivated' : ''}
        onClick={() => {
          if (activePage > 1) {
            SetPage(activePage - 1);
          }
        }}
      >
        <S.Svg
          className={activePage === 1 ? 'left deactivated' : 'left'}
          width="7"
          height="10"
          viewBox="0 0 7 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.84155 1.175L3.02489 5L6.84155 8.825L5.66655 10L0.666552 5L5.66655 -5.13609e-08L6.84155 1.175Z" />
        </S.Svg>
      </S.PaginationDiv>
      {numbers}
      <S.PaginationDiv
        className={activePage === maxPages ? 'deactivated' : ''}
        onClick={() => {
          if (activePage < maxPages) {
            SetPage(activePage + 1);
          }
        }}
      >
        <S.Svg
          className={activePage === maxPages ? 'right deactivated' : 'right'}
          width="7"
          height="10"
          viewBox="0 0 7 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.84155 1.175L3.02489 5L6.84155 8.825L5.66655 10L0.666552 5L5.66655 -5.13609e-08L6.84155 1.175Z" />
        </S.Svg>
      </S.PaginationDiv>
    </S.Container>
  ) : null;
};
export default PaginationSelector;
