import styled from '@emotion/styled';
import Text from '~/components/Text';
import Snackbar from '~/components/Snackbar';
import loginImg from '~/site/LoginPage/images/login-bg.png';
import { mq } from '~/helpers/responsive';

export const InnerWraper = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    // paddingBottom: ['52px', '232px', '123px'],
    backgroundImage: [null, `url(${loginImg})`],
    backgroundRepeat: [null, 'no-repeat'],
    backgroundPosition: [null, 'top right'],
    backgroundSize: [null, null, 'contain'],
    margin: [null, null, '0 36px', '0 72px 0 36px'],
  })}
`;

export const FormWrapper = styled.main`
  ${mq({
    marginTop: ['25px', 0],
    maxWidth: ['auto', '318px', '463px'],
  })}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubmitWrapper = styled.div`
  ${mq({
    marginTop: '30px',
    display: 'flex',
    alignItems: ['flex-start', 'flex-start', 'center'],
    flexDirection: ['column', 'column', 'row'],
  })}
`;

export const RegisterText = styled(Text)`
  ${mq({
    marginTop: ['25px', '25px', 0],
    marginLeft: [null, null, '25px'],
  })}
`;

export const StyledSnackbar = styled(Snackbar)`
  position: absolute;
  top: -60px;
  right: 0;
`;

export const LoginDivider = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px 20px 0px;
`;

export const LoginDividerLine = styled.div`
  width: 100%;
  border-bottom: 2px solid #d7d7d7;
`;

export const LoginDividerText = styled.div`
  margin: 0px 10px 0px 10px;
`;
