import templateUrl from './main.html';
import './main.scss';

export const MainComponent = {
  template: templateUrl,
  controller: class MainController {
    constructor(
      $http,
      CheckVersionService,
      UserService,
      $window,
      $log,
      TourService,
      $scope,
      WebsocketService,
      FirebaseService,
    ) {
      'ngInject';
      this.$http = $http;
      this.UserService = UserService;
      this.TourService = TourService;
      this.$window = $window;
      this.$scope = $scope;
      this.$log = $log;
      this.CheckVersionService = CheckVersionService;
      this.WebsocketService = WebsocketService;
      this.FirebaseService = FirebaseService;
    }

    $onInit() {
      this.newVersionAvailable = false;
      this.$scope.$on('newVersionAvailable', () => {
        this.newVersionAvailable = true;
      });
      this.FirebaseService.activate();
    }

    pendingRequests() {
      return this.CheckVersionService.isCheckVersionPending()
        ? this.$http.pendingRequests.length > 1
        : this.$http.pendingRequests.length > 0;
    }
  },
};
