import { PlayLessonOutlined } from "@mui/icons-material";
import { isPartiallyEmittedExpression } from "typescript";

const palette = {
  // Brand colors
  primary: '#ff3c58',
  secondary: '#e8eaf3',

  // Shades of grey
  gray18: '#5f5f5f',
  gray19: 'rgba(149, 149, 149, 0.2)',
  gray20: '#d7d7d7',
  gray21: '#f6f7fb',
  gray22: '#707070',
  gray23: '#959595',
  gray24: '#eceff1', // rgb(236,239,241)
  gray25: '#4F587A',

  white: '#ffffff',

  // Shades of red
  red20: '#e21300',
  red40: '#e63751',

  // blacks
  black: '#000000',
  black1: 'rgba(0,0,0,0.87)',
  black2: 'rgba(0,0,0,0.54)',
  black3: '#212121', // rgb(33,33,33)
  black4: '#303030',

  // shades of blue
  blue1: '#3B487B',
};

const colors = {
  primary: palette.primary,
  secondary: palette.secondary,

  containerBg: palette.gray21,

  borderLine: palette.red20,

  borderButton: palette.gray19,

  hoverBg: palette.red40,

  white: palette.white,

  gray18: palette.gray18,

  gray20: palette.gray20,

  gray21: palette.gray21,

  gray22: palette.gray22,

  gray23: palette.gray23,

  gray24: palette.gray24,

  gray25: palette.gray25,

  black1: palette.black1,

  black2: palette.black2,

  black3: palette.black3,

  black4: palette.black4,

  black: palette.black,

  blue1: palette.blue1,
};
const theme = {
  colors,
};

export default theme;
